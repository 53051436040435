import React, { FC } from "react";
import styled from "styled-components";

const StyledNavigation = styled.div``;

const Navigation: FC<{ className?: string }> = ({ children, className }) => {
  return <StyledNavigation className={className}>{children}</StyledNavigation>;
};

export default Navigation;
