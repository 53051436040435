import React, { FC } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Blacker = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(28px);
    z-index: 104;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        width: 100%;
    }
`;

const Container = styled(motion.div)``;

const Modal: FC<{ closeModal?: () => void; className?: string }> = ({
    closeModal,
    className,
    children,
}) => {
    return (
        <AnimatePresence>
            <Blacker onClick={closeModal}>
                <Container
                    className={className}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    {children}
                </Container>
            </Blacker>
        </AnimatePresence>
    );
};

export default Modal;
