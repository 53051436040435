import React, { FC, memo, useEffect, useState } from "react";
import { GAME_STATE } from "../../../constants/interfaces/Game";
import { useErrorsState } from "../../../config/store/state/app.state";
import { MultiplierContainer, StyledMultiplier } from "./GameMultiplier.styles";
import { roundNumber } from "../../../helpers/functions/round-number";
import { ErrorMessagesState } from "../../errorMessages/store/state";

export const MULTIPLIER_STEP = 100;
export const MULTIPLIER_DELAY = 145;

export const calculateMultiplierGrow = (currentMultiplier: number) => {
  return Number(
    (currentMultiplier + currentMultiplier / MULTIPLIER_STEP / 2).toFixed(2)
  );
};

export const getFinalMultiplier = (
  gameCrushed: boolean,
  gameError: boolean,
  localMultiplier: number,
  multiplier: number
) => {
  return gameCrushed ||
    gameError ||
    localMultiplier - calculateMultiplierGrow(multiplier + multiplier * 0.5) > 0.5
    ? multiplier
    : localMultiplier;
};

const GameMultiplier: FC<{
  multiplier: number;
  gameState?: GAME_STATE;
  wins?: ErrorMessagesState[]; 
}> = ({ multiplier = 1, gameState, wins }) => {
  const animate = gameState === GAME_STATE.LAUNCHING;
  const gameCrushed = gameState === GAME_STATE.CRUSHED;
  const gameError = useErrorsState().isError;

  // BEGIN TEMP LOCAL CALCULATIONS
  const [localMultiplier, setLocalMultiplier] = useState(1);

  useEffect(() => {
    setLocalMultiplier(multiplier || 1);
  }, [multiplier]);

  useEffect(() => {
    if (gameState === GAME_STATE.RUNNING) {
      const interval = setInterval(() => {
        setLocalMultiplier((prevValue) => calculateMultiplierGrow(prevValue));
      }, MULTIPLIER_DELAY);

      return () => {
        clearInterval(interval);
      };
    }
  }, [multiplier, gameState, setLocalMultiplier]);

  let finalMultiplayer = getFinalMultiplier(
    gameCrushed,
    gameError,
    localMultiplier,
    multiplier
  );

  useEffect(() => {
    if (wins && wins.length > 0) {
      const str = wins[0].message;
      const number = Number( str.substring(0, str.indexOf(' ')) );
      console.log(multiplier, number)
      if (multiplier < number) {
        setLocalMultiplier( Number(number) );
      }
    }
  }, [wins, multiplier])

  return (
    <MultiplierContainer
      initial={animate ? { x: 200 } : undefined}
      animate={animate ? { x: 0 } : undefined}
      transition={{ duration: 0.3 }}
    >
      <StyledMultiplier crushed={gameCrushed}>
        <div className="game-counter-sec">
          {roundNumber(finalMultiplayer).toString().split(".")[0]}
        </div>
        <div className="game-counter-mlsec">
          {roundNumber(finalMultiplayer).toString().split(".")[1]}
        </div>
      </StyledMultiplier>
    </MultiplierContainer>
  );
};

export default memo(GameMultiplier);