import React, { FC } from "react";
import {
  TOP_LIST_FILTER_INDEXES,
  TOP_LIST_INDEXES,
  TopListFilters,
  TopListItems,
} from "../configs/tools";
import {
  TabsNav,
  StyledNavigation,
  StyledNavigationItem,
  Inner,
  BetsNav,
  StyledBetsNavigationItem,
} from "./TopBestActions.styles";
import useTranslate from "../../../../config/language";

const TopBetsActions: FC<{
  active: number;
  setActive(value: TOP_LIST_INDEXES): void;
  activeFilter: string;
  setActiveFilter(value: TOP_LIST_FILTER_INDEXES): void;
}> = ({ active, setActive, activeFilter, setActiveFilter }) => {
  const translate = useTranslate();

  return (
    <>
      <TabsNav>
        <StyledNavigation>
          {TopListFilters.map((item) => {
            return (
              <StyledNavigationItem
                key={item.index}
                active={item.index === activeFilter}
                onClick={() => setActiveFilter(item.index)}
              >
                <Inner className="tabs-nav-item-link">
                  {translate(item.text)}
                </Inner>
              </StyledNavigationItem>
            );
          })}
        </StyledNavigation>
      </TabsNav>

      <BetsNav className="clearfix">
        {TopListItems.map((item) => {
          return (
            <StyledBetsNavigationItem
              key={item.index}
              active={item.index === active}
              onClick={() => setActive(item.index)}
            >
              <Inner className="bets-nav-link">{translate(item.text)}</Inner>
            </StyledBetsNavigationItem>
          );
        })}
      </BetsNav>
    </>
  );
};

export default TopBetsActions;
