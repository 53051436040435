import styled from "styled-components";

export const LinkTo = styled.div`
  text-align: center;
  margin-bottom: 19px;
  font-feature-settings: "case" on;

  .popup-link-ic {
    position: absolute;
    left: 6px;
    top: 7px;
    width: 12px;
    height: 12px;
    fill: #6d5cce;
  }

  .popup-link-arrow {
    fill: #fff;
    position: absolute;
    width: 8px;
    height: 14px;
    right: 7px;
    top: 6px;
  }

  @media (min-width: ${1366}px) {
    margin-bottom: 32px;

    .popup-link-ic {
      width: 20px;
      height: 20px;
      left: 6px;
      top: 5px;
    }

    .popup-link-arrow {
      width: 15px;
      height: 18px;
      right: 9px;
    }
  }
`;

export const Link = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  width: 192px;
  font-size: 10px;
  line-height: 12px;
  padding: 6px 27px 6px 26px;
  position: relative;
  display: inline-block;
  color: #ffffff;
  text-align: left;

  &:hover {
    cursor: pointer;
    border: 1px solid #17ae43;
  }

  @media (min-width: ${1366}px) {
    width: 242px;
    font-size: 16px;
    line-height: 19px;
    padding-left: 35px;
  }
`;

export const ContentWrapper = styled.ol<{ height: number }>`
  overflow-y: auto;
  max-height: calc(${(props) => props.height}px - 270px);
  padding-bottom: 30px;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  @media (min-width: ${1366}px) {
    max-height: calc(${(props) => props.height}px - 365px);
  }

  @media (min-width: ${1920}px) {
    max-height: calc(${(props) => props.height}px - 397px);
  }
`;

export const Questions = styled.ol`
  margin: 0 0 0 16px;
  font-feature-settings: "case" on;

  @media (min-width: ${1366}px) {
    margin: 0 14px 0 29px;
    font-feature-settings: "case" off;
    font-size: 20px;
  }

  @media (min-width: ${1920}px) {
    font-size: 24px;
  }
`;

export const Item = styled.li`
  margin-top: 26px;
  list-style-type: none;
  overflow-wrap: break-word;

  &.questions-item-first {
    margin-top: 0px;
  }

  @media (min-width: ${1366}px) {
    margin-top: 31px;
  }
`;

export const Title = styled.span`
  font-size: 14px;
  line-height: 17px;
  display: block;

  @media (min-width: ${1366}px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (min-width: ${1920}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Desc = styled.span`
  display: block;
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "case" off;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.005em;
  margin-top: 8px;
  // margin-left: -3px;

  @media (min-width: ${1366}px) {
    font-size: 15px;
    line-height: 25px;
    margin-top: 13px;
    // margin-left: -20px;
    letter-spacing: normal;
  }

  @media (min-width: ${1920}px) {
    font-size: 18px;
    line-height: 22px;
    margin-top: 13px;
    // margin-left: -25px;
    letter-spacing: normal;
  }
`;
