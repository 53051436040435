import React, { memo, useEffect, useRef, useState, FC } from "react";
import Title from "./features/title";
import {
  LandscapeIconWrapper,
  Columns,
  InnerWrapper,
  Wrapper,
  Body,
} from "./Game.styles";
import ErrorModal from "./components/Modals";
import { useErrorsState } from "./config/store/state/app.state";
import HeaderSection from "./layout/Header";
import { usePingService } from "./config/store/services";
import { useMixpanelServices } from "./services/mixpanel/MixpanelServices";
import { useProfile } from "./features/profile/configs/store/state";
import { ITenant } from "./constants/interfaces/Tenant";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SelectTenantState } from "./config/store/selectors";
import { useSentryServices } from "./services/sentry/SentryServices";
import { gameErrorAction } from "./config/store/actions/app.actions";
import { ErrorListNames } from "./components/Modals/ErrorList";
import Popup from "./components/Popup/Popup";
import SidebarList from "./features/topBoards";
import Sidebar from "./layout/Sidebar";
import Content from "./layout/Content";
import OddsList from "./features/oddsList";
import GameContainer from "./features/game";
import ActionPanel from "./features/actionPanel";
import { AudioPlayer } from "./features/audioPlayer";
import useBackgroundsMusic from "./components/BackgroundMusic";
import { getQuery } from "./helpers/hooks/useQuery";
import { useErrorMessagesState } from "./features/errorMessages/store/state";
import ErrorMessages from "./features/errorMessages/ErrorMessages";
import { useIsMobile } from "./helpers/hooks/useIsMobile";
import RotationIcon from "./components/Icons/RotationIcon";
import ConnectionModal from "./components/ConnectionModal";
import useConnectionModal from "./components/ConnectionModal/useConnectionModal";

function useTenant(): ITenant {
  return useSelector(SelectTenantState, shallowEqual);
}

const Game: FC<{ loaded: boolean }> = ({ loaded }) => {
  const errors = useErrorsState();
  const { Player } = useProfile();
  const { identify } = useMixpanelServices();
  const { init } = useSentryServices();
  const tenant = useTenant();
  useBackgroundsMusic(loaded);
  const pingService = usePingService();
  const dispatch = useDispatch();
  let lang = getQuery(window.location.search, "lang");
  const messages = useErrorMessagesState();
  const ref = useRef<any>(null);
  const isMobile = useIsMobile();
  const [networkModal, setNetworkModal] = useState(true);
  const { isOnline } = useConnectionModal({ networkModal, setNetworkModal });
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (
    lang !== "ge" &&
    lang !== "en" &&
    lang !== "ru" &&
    lang !== "uk" &&
    lang !== "pt" &&
    lang !== "fr" &&
    lang !== "es" &&
    lang !== "tu"
  ) {
    lang = "en";
  }

  useEffect(() => {
    const ping = setInterval(() => {
      pingService();
    }, 10000);

    return () => {
      clearInterval(ping);
    };
  }, [pingService]);

  useEffect(() => {
    identify(
      {
        name: Player.N,
        id: `${Player.I}`,
      },
      tenant
    );
    // alert(1);
  }, [Player.I, Player.N, identify, tenant]);

  useEffect(() => {
    init(tenant);
  }, [init, tenant]);

  useEffect(() => {
    if (Player.B) {
      dispatch(gameErrorAction(ErrorListNames.BlockedUser));
    }
  }, [Player.B, dispatch]);

  return (
    <>
      {/* <EuropeBet>Europe Bet</EuropeBet> */}
      {errors.isError && <ErrorModal type={errors.type} />}
      {networkModal && !isOnline && (
        <ConnectionModal close={() => setNetworkModal(false)} />
      )}

      <LandscapeIconWrapper>
        <RotationIcon />
      </LandscapeIconWrapper>

      <Title />
      {loaded && <AudioPlayer />}

      <Wrapper
        className={`${(lang === "ru" || lang === "uk") && "montserrat"} ${
          lang === "en" && "roboto"
        } ${(!lang || lang === "ka") && ""}`}
        ref={ref}
      >
        <InnerWrapper
          landscapeWidth={window.innerHeight}
          landscapeHeight={window.innerWidth}
        >
          <HeaderSection
            isPopupOpen={isPopupOpen}
            toggle={() => setIsPopupOpen(!isPopupOpen)}
          />

          <Body>
            <Popup
              isMenuOpen={isPopupOpen}
              setIsMenuOpen={setIsPopupOpen}
              bodyRef={ref}
            />

            <Columns>
              <Sidebar>
                <SidebarList />
              </Sidebar>

              <Content>
                <OddsList />
                <GameContainer />
                {loaded && <ActionPanel />}
              </Content>
            </Columns>
          </Body>

          {isMobile && messages.length > 0 && (
            <ErrorMessages messages={messages} />
          )}
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

export default memo(Game);
