import React, { FC } from "react";
import styled from "styled-components";
import SelectButton from "../../../../../components/SelectButton/SelectButton";
import { useBalance } from "../../../../profile/configs/store/state";
import { CurrencySymbol } from "../../../../../constants/currency";

const SelectButtonsActionRow = styled.div<{
  disabled?: boolean;
}>`
  padding: 0 14px 0 20px;
  margin: 0px -12.5px 8px;
`;

const StyledSelectButton = styled(SelectButton)``;

const SelectButtonActions: FC<{
  amount: number;
  onChange: (value: number) => void;
  disabled: boolean;
}> = ({ amount, onChange, disabled }) => {
  const freeSpinBalance = useBalance()?.FreeSpinBalance || [];
  freeSpinBalance.sort(function (a, b) {
    return a.Amount - b.Amount;
  });
  const CURRENCY = CurrencySymbol()

  return (
    <SelectButtonsActionRow disabled={disabled}>
      {freeSpinBalance.map((freeSpin, index) => {
        return (
          <StyledSelectButton
            key={index}
            text={`${
              freeSpin.Amount % 1 === 0
                ? `${freeSpin.Amount}.00`
                : freeSpin.Amount
            } ${CURRENCY}`}
            selected={freeSpin.Amount === amount}
            onClick={() => onChange(freeSpin.Amount)}
            disabled={disabled}
            count={freeSpin.Count}
          />
        );
      })}
    </SelectButtonsActionRow>
  );
};

export default SelectButtonActions;
