import React, { FC } from "react";
import { IconProps } from "./types";

export const History: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0362 0.884766C9.13913 0.893482 7.29857 1.49014 5.80309 2.58128C4.30762 3.67257 3.24169 5.19673 2.77231 6.91497L2.19799 6.10323C2.08819 5.93148 1.908 5.80908 1.69932 5.76465C1.49063 5.72008 1.27142 5.75716 1.09282 5.86728C0.914078 5.97753 0.79128 6.15112 0.752945 6.34805C0.714597 6.54484 0.763846 6.74779 0.889531 6.90983L2.61538 9.35475C2.73528 9.51165 2.91206 9.62239 3.11274 9.66646C3.31775 9.70452 3.53038 9.66634 3.70585 9.56015L6.29469 7.92991C6.47501 7.8247 6.60187 7.65541 6.64627 7.46106C6.69079 7.26672 6.64889 7.06377 6.5303 6.8989C6.41158 6.7339 6.22652 6.62095 6.01717 6.58597C5.80783 6.5511 5.5923 6.59701 5.41999 6.71328L4.24247 7.4554C4.66247 5.86175 5.7088 4.47424 7.17331 3.56894C8.63792 2.66352 10.4134 2.30664 12.1469 2.56923C13.8801 2.83171 15.4441 3.69453 16.5278 4.98602C17.6114 6.27743 18.1354 7.90288 17.9954 9.53908C17.8555 11.1753 17.0619 12.7021 15.7723 13.8162C14.4829 14.9302 12.792 15.5498 11.0361 15.5515C9.91294 15.5487 8.8071 15.2924 7.81265 14.8045C6.8182 14.3165 5.96452 13.6112 5.32414 12.7487C5.2083 12.5824 5.02522 12.4671 4.81679 12.4292C4.60837 12.3911 4.39232 12.4338 4.21816 12.5473C4.04389 12.6608 3.92634 12.8354 3.89233 13.0313C3.85831 13.2273 3.91071 13.4277 4.03758 13.5869C5.07866 14.9899 6.57363 16.0437 8.30311 16.5934C10.0324 17.1432 11.9046 17.16 13.6452 16.6412C15.3854 16.1224 16.9017 15.0955 17.9712 13.7113C19.0408 12.3272 19.6073 10.6588 19.5871 8.95147C19.5949 6.82256 18.6989 4.77776 17.0959 3.26541C15.4928 1.75329 13.3135 0.897114 11.0363 0.884825L11.0362 0.884766Z"
        fill="white"
      />
    </svg>
  );
};

export default History;
