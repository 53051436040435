/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import React, { FC, memo } from "react";
import { motion } from "framer-motion";
import GIF from "../../../assets/images/gif.gif";
import StaticRockit from "../../../assets/images/rockit/Spin_00000.png";
import { useGameConfigs } from "../../../config/store/state/app.state";

const ExplosionContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absoulte;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: ${1280}px) {
    min-width: 270px;
  }

  img {
    width: auto;
    height: auto;
    max-height: 65%;
    max-width: 270px;

    @media (max-width: ${992}px) {
      width: 30vw;
    }

    @media (min-width: ${1280}px) {
      min-width: 270px;
    }
  }
`;

const Img = styled.img``;

const ShipAnimation: FC = () => {
  const animate = useGameConfigs().animate;

  return (
    <ExplosionContainer>
      {animate ? (
        <img src={GIF} alt="rockit" />
      ) : (
        <Img src={StaticRockit} alt="static rckit" />
      )}
    </ExplosionContainer>
  );
};

export default memo(ShipAnimation);
