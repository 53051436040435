import styled from "styled-components";
import { TableHeaderCell } from "../../../../components/Table/TableHeaderCell";

export const StyledTableHeaderCell = styled(TableHeaderCell)`
  border: 0;
  border-collapse: collapse;
  padding: 0px 5px 19px 5px;
  color: rgba(255, 255, 255);
  padding-bottom: 0;
  font-weight: normal;
  font-feature-settings: "case" off;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  &.first {
    text-align: center;
    padding-left: 0;
  }

  &.last {
    // text-align: right;
    padding-left: 0;
  }

  &.data-id {
    width: 18%;
  }

  &.data-coeffc {
    width: 15%;
  }

  &.data-key {
    width: 39%;
  }

  &.data-hcode {
    width: 28%;
  }

  @media (min-width: ${1366}px) {
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 12px;

    &.first {
      padding-left: 14px;
    }
  }

  @media (min-width: ${1920}px) {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 16px;
  }
`;
