import styled from "styled-components";

export const StyledGameBoardContainer = styled.div<{
  height: number;
  landscapeHeight?: number;
}>`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  //   flex: 1 1 0%;
  overflow: hidden;
  margin-top: 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  background: #1d0030;
  // min-height: 300px;
  position: relative;
  pointer-events: none;
  height: ${(props) => `calc(${props.height}px - 458px)`};

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: ${800}px) {
    margin-top: 26px;
    overflow: hidden;
    height: ${(props) => `calc(${props.height}px - 330px)`};
  }

  @media (min-width: ${1366}px) {
    height: ${(props) => `calc(${props.height}px - 357px)`};
  }

  @media (min-width: ${1920}px) {
    height: ${(props) => `calc(${props.height}px - 457px)`};
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
    height: ${(props) => `calc(${props.landscapeHeight}px - 394px)`};
  }

  @media only screen and (max-height: 667px) and (max-width: 375px) {
    height: ${(props) => `calc(${props.height}px - 320px)`};
    min-height: 250px;
  }

  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2) {
      height: ${(props) => `calc(${props.height}px - 510px)`};
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3),
    only screen and (min--moz-device-pixel-ratio: 3),
    only screen and (-o-min-device-pixel-ratio: 3/1),
    only screen and (min-device-pixel-ratio: 3) { 
      height: ${(props) => `calc(${props.height}px - 470px)`};
  }

  /* Styles for iPhone X in portrait mode */
  @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
    /* Your CSS styles specific to iPhone X */
     height: ${(props) => `calc(${props.height}px - 510px)`};
     min-height: 260px;
  }
`;

export const PreRenderedExplosion = styled.div`
  visibility: hidden;
  overflow: hidden;
  position: absolute;

  img {
    position: absolute;
  }
`;
