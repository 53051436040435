import React, { useState } from "react";
import TableNavigation from "./components/TableNavigation";
// import CloseIcon from "../../components/Icons/Close";
import { LIST_INDEXES, SidebarNavigationItems } from "./tools";
import styled from "styled-components";
import BetsList from "./bets";
import MyBetsList from "./myBets";
import TopBetsList from "./topBets";
import { useMixpanelServices } from "../../services/mixpanel/MixpanelServices";
import { EMixpanelEvent } from "../../services/mixpanel/types";

const SidebarListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  * {
    font-variant-numeric: tabular-nums;
  }
`;

const SidebarListBody = styled.div`
  flex: 1 1 0%;
  // background: #0e0826;
  background: rgba(22, 4, 44, 0.55);
  border-radius: 8px;
  margin-top: 1px;
  overflow-y: auto;
  position: relative;

  * {
    font-variant-numeric: tabular-nums;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  @media (min-width: ${800}px) {
    margin-top: 15px;
  }

  @media (min-width: ${1366}px) {
    margin-top: 27px;
    padding-top: 18px;
  }
`;

const SidebarList = () => {
  const [active, setActive] = useState(LIST_INDEXES.BETS);
  const { track } = useMixpanelServices();
  const handleSetActive = (index: number) => {
    setActive(index);
    switch (index) {
      case LIST_INDEXES.BETS:
        track(EMixpanelEvent.switchToTheBetts);
        break;
      case LIST_INDEXES.MY_BETS:
        track(EMixpanelEvent.switchToMyBetts);
        break;
      case LIST_INDEXES.TOP:
        track(EMixpanelEvent.switchToTopBetts);
        break;
    }
  };

  return (
    <SidebarListContainer>
      {/* <Close>
        <CloseIcon className="bets-close-ic" />
      </Close> */}

      <TableNavigation
        items={SidebarNavigationItems}
        activeIndex={active}
        setActive={handleSetActive}
      />

      <SidebarListBody>
        {active === LIST_INDEXES.BETS && <BetsList />}
        {active === LIST_INDEXES.MY_BETS && <MyBetsList />}
        {active === LIST_INDEXES.TOP && <TopBetsList />}
      </SidebarListBody>
    </SidebarListContainer>
  );
};

export default SidebarList;
