import React from "react";
import { MenuIc, MenuIcLine, Close } from "./MenuIcon.styles";

export const MenuIcon = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) => {
  return (
    <MenuIc className={isOpen ? "open" : ""} onMouseDown={onClick}>
      <MenuIcLine />
      <MenuIcLine />
      <MenuIcLine />
    </MenuIc>
  );
};

export const MenuIconClose = () => {
  return <Close />;
};
