import styled from "styled-components";
// import avatar from "../../../assets/images/general/header-avatar.png";

export const StyledTr = styled.div`
  display: flex;
  align-items: center;

  &.won-highlight {
    background: linear-gradient(
        90.03deg,
        rgba(22, 67, 34, 0.77) 0.03%,
        rgba(17, 14, 34, 0.66) 99.98%
      )
      no-repeat;
  }

  &.lost-highlight {
    background: linear-gradient(
        90.03deg,
        rgba(142, 55, 55, 0.29) 0.03%,
        rgba(142, 55, 55, 0.0812) 99.98%
      )
      no-repeat;
  }

  &.gradient-fixed {
    background-attachment: fixed;
  }
`;

export const StyledTd = styled.div`
  border: 0;
  border-collapse: collapse;
  padding: 4px 15px;

  &:nth-child(1) {
    text-align: left;
  }

  &.bets-user {
    width: 34%;
    display: flex;
    align-items: center;
  }

  &.bets-bet {
    width: 26%;
  }

  &.bets-coeffc {
    width: 15%;
  }

  &.bets-profit {
    width: 25%;
  }

  @media (min-width: ${800}px) {
    padding: 4px 4px;

    &.bets-user {
      width: 26%;
    }

    &.bets-bet {
      width: 22%;
    }

    &.bets-coeffc {
      width: 27%;
    }

    &.bets-profit {
      width: 20%;
    }
  }

  @media (min-width: ${1366}px) {
    padding: 10px 4px;
    font-size: 14px;
    line-height: 17px;

    &:nth-child(1) {
      padding-left: 18px;
    }

    &:nth-child(4) {
      padding-right: 18px;
    }

    &.bets-user {
      width: 33%;
    }

    &.bets-coeffc {
      width: 25%;
    }
  }
`;

// export const Avatar = styled.span<{ avatar: string }>`
//   display: inline-block;
//   width: 24px;
//   height: 24px;
//   margin-right: 6px;
//   vertical-align: middle;
//   background: url(${(props) => props.avatar}) no-repeat center center;
//   background-size: cover;
//   border-radius: 50%;

//   @media (min-width: ${800}px) {
//     width: 16px;
//     height: 16px;
//     margin-right: 4px;
//   }

//   @media (min-width: ${1366}px) {
//     width: 38px;
//     height: 38px;
//     margin-right: 12px;
//   }
// `;

export const Coeff = styled.span`
  color: #2eb550;
`;

export const UserName = styled.div``;
