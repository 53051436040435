/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Game from './Game';
import { useGameState } from './config/store/state/app.state';
import BackgroundMusic from './assets/sounds/background.mp3';
import Loader from './features/Loader';
import styled from 'styled-components';

const StyledBackground = styled.div<{ visible: boolean }>`
    overflow: hidden;
    height: 100%;
    width: 100vw;
    max-height: ${({ visible }) => (visible ? '100vh' : '0px')};

    // @media only screen and (max-height: 668px) and (max-width: 378px) {
    //     overflow: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    // }
`;

function App() {
    const gameLoading = useGameState().loading;
    const [loaded, setLoaded] = useState<boolean>(false);
    useEffect(() => {
        if (!gameLoading && BackgroundMusic) {
            setLoaded(true);
        }
    }, [BackgroundMusic, gameLoading]);
    

    return (
        <>
            {!loaded && <Loader />}
            
            <StyledBackground visible={loaded}>
                <Game loaded={loaded} />
            </StyledBackground>
     
        </>
    );
}

export default App;
