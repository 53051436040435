/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useRef, useEffect, useState } from 'react';
import {
    useGameConfigs,
} from '../../config/store/state/app.state';
import Lines from '../../assets/images/lines.gif';
import AstroComp from './components/Astro';
import { GAME_STATE, GameStatus } from '../../constants/interfaces/Game';
import MoonsComp from './components/Moons';
import { ShipContainer } from './spaceship.styles';
import Ship from './components/Ship';
import { SoundPlayer } from '../soundPlayer/soundPlayer';

interface IFLying {
    gameState: GameStatus
}

export const Flying: FC<IFLying> = ({ gameState }) => {
    const voiceEffects = useGameConfigs().voiceEffects;
    const animate = useGameConfigs().animate;
    const [reload, setReload] = useState<boolean>(true);
    const animateRef = useRef(animate);
    const shipAnimateRef = useRef(0);
    const [crush, setCrush] = useState<boolean>(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setInterval(() => {
            setReload(false);
            setTimeout(() => {
                setReload(true);
            }, 2000);
        }, 17000);
    }, []);

    useEffect(() => {
        if (animateRef.current !== animate) {
            animateRef.current = animate;
        }
    }, [animate]);

    let crushTop = 70;
    let left = '62%';
    if (window.innerWidth >= 992) {
        left = 'calc(100% - 370px)';
        if (window.innerWidth > 1366) {
            left = '56%';
        }
        crushTop = 170;
        if (crush) {
            left = '101%'
        }
    } else if (crush) {
        left = '95%'
    }
    
    useEffect(() => {
        if (gameState.state === GAME_STATE.CRUSHED) {
           
            setCrush(true)
            setTimeout(() => {
                setCrush(false)
                setCrush(() => false)
            }, 3000)

            const sound = new SoundPlayer();
            if (!voiceEffects) {
                return;
            }
            sound.playExplosionSound();
            return () => {
                sound.stopExplosionSound();
            };
        }
    }, [gameState.state, voiceEffects])

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        timer = setTimeout(() => {
            shipAnimateRef.current = shipAnimateRef.current + 1;
        }, 8000);

        return () => {
            clearTimeout(timer);
        };
    }, []);


    return (
        <>
            {shipAnimateRef.current === 0 || crush ? (
                <ShipContainer
                    initial={{ scale: 1 }}
                    animate={{ scale: 0.7, left: left, top: crush ? -crushTop : 5 }}
                    transition={{ duration: crush ? 1 : 8 }}
                >
                    <AstroComp />
                    <div><Ship animateFire={true} /></div>
                </ShipContainer>
            ) : (
                <ShipContainer
                    initial={{
                        scale: 0.7,
                        y: 0,
                    }}
                    animate={{
                        y: [0, -20],
                    }}
                    transition={{
                        duration: crush ? 0.1 : 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                    }}
                    style={{ left: left, top: crush ? - 50 : 5 }}
                >
                     <AstroComp />
                    <div><Ship animateFire={true} /></div>
                </ShipContainer>
            )}

            {animate && <img style={{zIndex: 3}} src={Lines} alt="lines" />}

            {animate && reload && <MoonsComp />}
        </>
    );
};
