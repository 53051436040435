import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { CurrencySymbol } from "../../../../../constants/currency";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import {
  AutoLabel,
  ButtonInput,
  Column,
  Columns,
  SliderHandle,
  Tab,
} from "../../../styles";
import Range from "../../../Range";

const UnregisterPanel: FC<{
  amount: number;
  setAmount(amount: number | null): void;
  handleUnregister(): void;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  autoCashoutMinValue?: number;
  autoCashutMaxValue?: number;
  autoCashOutValue: number;
  handleAutoCashOutValueChange(amount: number | null): void;
}> = ({
  amount,
  handleUnregister,
  // disabled,
  setAmount,
  minValue,
  maxValue,
  autoCashoutMinValue,
  autoCashutMaxValue,
  autoCashOutValue,
  handleAutoCashOutValueChange,
}) => {
  const translate = useTranslate();
  const CURRENCY = CurrencySymbol()

  return (
    <Tab className="game-nums-twocolumcn-content">
      <Columns>
        <Column>
          <NumberInput
            value={amount}
            onChange={setAmount}
            minValue={minValue}
            maxValue={maxValue}
            className="two-columns first"
            disabled
          />
          <AutoLabel className="first">
            {translate(KEYWORDS.AutoBetting)}
          </AutoLabel>

          <Range
            className="game-numslider-twocolumn"
            value={amount}
            baseHalfPoint={20}
            min={minValue}
            max={maxValue}
            onChange={setAmount}
            disabled
          />
        </Column>

        <Column>
          <NumberInput
            suffix={" x"}
            value={autoCashOutValue}
            onChange={handleAutoCashOutValueChange}
            minValue={autoCashoutMinValue}
            maxValue={autoCashutMaxValue}
            className="two-columns"
            disabled
            step={0.1}
            without
          />
          <AutoLabel>{translate(KEYWORDS.AutoCashouting)}</AutoLabel>

          <SliderHandle
            type="range"
            className="game-numslider-twocolumn"
            min={autoCashoutMinValue}
            max={1001}
            value={autoCashOutValue}
            onChange={(e) => handleAutoCashOutValueChange(+e.target.value)}
            disabled
          />
        </Column>
      </Columns>

      <ButtonInput
        text={
          <>
            {translate(KEYWORDS.Unregister)}
            <span className="roboto-font"> {+roundNumber(amount)}</span>
            {CURRENCY}
          </>
        }
        onClick={handleUnregister}
        className="btn-cancel"
      />
    </Tab>
  );
};

export default UnregisterPanel;
