import styled from "styled-components";

export const StyledCurrentList = styled.div`
  font-feature-settings: "case" on;

  // padding: 0 8px;

  // @media (min-width: ${800}px) {
  //   background: #0e0826;
  //   border-radius: 8px;
  //   position: relative;
  //   margin-top: 7px;
  // }

  // @media (min-width: ${1366}px) {
  //   padding-top: 33px;
  //   margin-top: 15px;
  // }

  // @media (min-width: ${1920}px) {
  //   padding-top: 20px;
  //   margin-top: 28px;
  // }
`;
