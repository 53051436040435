import styled from "styled-components";

export const TableRow = styled.tr``;

export const StyledTd = styled.td`
  padding: 12px 0 0;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(3) {
    text-align: right;
  }

  &.rating-date {
    width: 17%;
  }

  &.rating-coeffc {
    color: #2eb550;
    width: 6%;
  }

  &.rating-pf {
    width: 19%;
  }

  @media (min-width: ${800}px) {
    padding: 7px 0 0;

    &.rating-date {
      width: 48%;
    }
  }

  @media (min-width: ${1366}px) {
    padding: 14px 0 0;

    &.rating-date {
      width: 50%;
    }

    &.rating-coeffc {
      width: 30%;
    }

    &.rating-pf {
      width: 29%;
    }
  }
`;

export const InnerSpan = styled.span`
  background: #150e28;
  width: 100%;
  padding: 6px 8px 6px 6px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.first {
    border-top-left-radius: 8px;
    -webkit-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-bottom-left-radius: 8px;
  }

  &.last {
    border-top-right-radius: 8px;
    -webkit-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-bottom-right-radius: 8px;
  }

  .table-rating-ic {
    width: 16px;
    height: 16px;
    fill: #ffffff;
    opacity: 0.5;
    margin: -4px 0px;
  }

  @media (min-width: ${1366}px) {
    padding: 10px 10px 10px 10px;
    display: inline-block;
    height: auto;
  }
`;
