const CURRENCY_SYMBOL = '___';

export const french = {
    AccountBanned: "Votre compte a été bloqué !",
    AllBets: "Tous les paris :",
    AlreadyRegistered: "Vous avez déjà placé un pari",
    Animation: "Animation",
    AutoBet: "AUTO BET",
    AutoBetting: "Auto Bet",
    AutoCashout: "AUTO CASHOUT",
    AutoCashouting: "Auto Cashout",
    Balance: "SOLDE",
    BalanceShort: "SOL",
    Banned: "BANNI",
    BaseBet: "PARI DE BASE",
    Bet: "Pari",
    BetMaximum: `Le pari maximum est de ${CURRENCY_SYMBOL}`,
    BetMustBe: `Le pari minimum est de ${CURRENCY_SYMBOL}`,
    Bets: "Paris",
    Stakes: "Paris",
    BuyIn: "PARI",
    Cashout: "Retrait",
    CashoutFromGame: "Retrait",
    CashoutMaxMustBe: "Le retrait maximum est de ",
    CashoutMustBe: "Le retrait minimum est de 1.01",
    ChangeAvatar: "Changer d’avatar",
    CheckFair: "1. Qu’est-ce que la vérification de la cote?",
    CheckHash: "3. Comment vérifier le code de crédibilité?",
    CheckResult: "Vérifier le résultat",
    Circulation: "#",
    Clear: "EFFACER",
    Crashed: "ODD",
    CurrentBets: "PARIS",
    Day: "Jour",
    DearUsers: "Désolé pour le dérangement causé",
    en: "EN",
    Error: "Erreur",
    ExampleForFair:
      "Numéro de jeu - 9088 Cotes - 1.06 Temps de génération - 12/03/2019_16:09 Numéro de jeu unique - Fv+5TVHK2621A== Par conséquent, la clé du jeu sera : 9088_1.06_Rockit_12/03/2019_16:09_Fvv+5TVHK2621A== Et le code de crédibilité généré par l’algorithme SHA256 : 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d",
    Examples: "Exemple :",
    FairGame: "Jeu équitable",
    Fill: "Remplir",
    FreeBet: "Pari Gratuit",
    GameIsStopped: "Le jeu est arrêté",
    GameKey: "2. Que signifient une clé de jeu et un code de crédibilité?",
    ServerConnectionTemp: "Connexion au serveur.",
    SameUser: "Le jeu est ouvert dans un autre onglet",
    GameRules: "Règles du jeu",
    GameStart: "Démarrage du jeu",
    GameStarted: "Le jeu a commencé - Bonne chance",
    GameStatusIsNotRegistering: "Acceptation des paris terminée",
    GameWillResume: "Temps restant avant la réprise",
    GotoHome: "HOME",
    Hash: "Code de hachage",
    History: "Histoire",
    HowToCheckFair:
      "Cette méthode nous permet de nous assurer que les cotes du jeu (le nombre sur lequel repose la conclusion du jeu) sont prédéterminées avant que les joueurs enregistrent leurs paris. Il y a 5 jeux prédéterminés à l’avance à tout moment. Ce qui suit est un code de hachage de 5 tours passés et 5 à venir.",
    HowToCheckGameKey:
      "Les cotes du jeu (le nombre sur lequel repose la conclusion du jeu) sont prédéterminées, 5 jeux à l’avance. Une clé de jeu est générée sur le serveur de jeu, qui se compose des paramètres suivants : [Numéro de jeu] _ [Cotes] _ [Bascule] _ [Temps de génération] _ [Numéro de jeu unique]",
    HowToCheckHash:
      "Une fois le jeu terminé, une clé de jeu sera disponible que vous pourrez chiffrer sur n’importe quelle calculatrice SHA256 en ligne, puis la comparer au code de crédibilité donné pour les 5 prochains jeux.",
    IncreaseBy: "AUGMENTER DE",
    Jackpot: "JACKPOT",
    ka: "KA",
    ua: "UA",
    Key: "Clé",
    LanguageChangeAcception: "Vous êtes sûr de vouloir changer de langue ?",
    LanguageChangeWarning: "Appuyer sur le bouton désactivera le jeu en cours",
    LastGames: "Derniers jeux",
    LastHand: "Dernière main",
    LastTopSpins: "LES MEILLEURS MULTIPLICATEURS EN 24 HEURES",
    LastTopWins: "LES MEILLEURS GAINS EN 24 HEURES",
    LeaveMessage: "Tapez votre message",
    Login: "LOGIN",
    LoginFailed: "Échec de connexion",
    MainPage: "Page principale",
    ManualBet: "PARI MANUEL",
    MinFreebetCashout: "Cote min. 1.5",
    Month: "Mois",
    MultiplierCashout: "Mult. Retrait",
    Music: "Musique",
    My: "Mes paris",
    No: "No",
    NotEnoughMoney: "Argent insuffisant",
    OnLoss: "PERTES",
    OnWin: "GAINS",
    OpenHere: "Ouvrir ici",
    PlaceYourBet: "PLACEZ VOS PARIS",
    Player: "Joueur",
    Rating: "TOP",
    Register: "Placer un pari",
    Registration: "PLACEZ VOS PARIS",
    Reload: "Recharger",
    Result: "Résultat",
    ResultsHash: "Résultat du code de hachage",
    ReturnToBaseBet: "RETOURNER AU PARI DE BASE",
    ReturnToCash: "RETOURNER À L'ARGENT",
    Round: "Round",
    ru: "RU",
    GameRules1: "Les joueurs peuvent placer des paris avant le début du round.",
    GameRules2: "Les cotes commencent à augmenter lorsque le jeu commence et dure jusqu’à le burnout.",
    GameRules3: "Le point de burnout est généré aléatoirement.",
    GameRules4: "Un joueur doit compléter un retrait du gain avant le burnout.",
    GameRules5: "Les paris qui ne sont pas encaissés avant le burnout seront considérés comme perdus.",
    GameRules6: "Le pari d’un joueur sera multiplié par la cote à laquelle le pari a été encaissé lors du paiement avant le burnout.",
    GameRules7: `En cas de départ du jeu, le pari sera automatiquement encaissé, en fonction du produit du pari et du coefficient de hauteur actuel. La fin d’une session de jeu pour quelque raison que ce soit est considérée comme l’arrêt du jeu.`,
    GameRules8: "Un joueur peut placer deux paris en même temps et les encaisser séparément.",
    GameRules9: `Le joueur peut faire un pari dans les conditions souhaitées, grâce aux paris automatiques. Dans la fenêtre existante, il est possible de déterminer le montant du pari, le coefficient de retrait, le montant maximum du retrait. En outre, les conditions du prochain pari peuvent être déterminées à l’avance en cas de gagner ou de perdre.`,
    GameRules10: "Les gains sont automatiquement reflétés sur le solde d’un joueur.",
    GameRules11: "Le joueur peut voir l’historique du jeu en cliquant sur le bouton 'Mes paris'.",
    GameRules12: "En cas d’erreur avérée dans le jeu, le jeu se réserve le droit de retenir ou de corriger les gains estimés du joueur.",
    GameRules16: "Limites du jeu :",
    GameRules17: `Le multiplicateur minimum du jeu est 1.`,
    GameRules18: `Le multiplicateur maximum du jeu est de 1,102,008.`,
    GameRules19: `Le RTP du jeu est de 95.05 %`,
    GameRules20: `Le multiplicateur minimum d’auto-cashout est x1.1, et le multiplicateur maximum d’auto-cashout est x1,102,008.`,
    GameRules21: `Le multiplicateur minimum du retrait est x1,01 et le multiplicateur maximum du retrait est x1,102,008.`,
    GameRules26: "Le joueur peut trouver les dernières cotes du jeu dans la section supérieure de la page.",
    GameRules27: "Le joueur peut voir le classement des joueurs en cliquant sur le bouton 'Classement'.",
    GameRules28: "Dans le cas où le coefficient de retrait et le coefficient de crash sont les mêmes, le joueur gagne.",
    GameRules29: `En cas de départ volontaire du jeu, le pari sera automatiquement encaissé, en fonction du produit du pari et du coefficient de hauteur actuel. En cas de déconnexion du réseau, un compte à rebours de 10 à 1 s’affiche, et le joueur peut choisir d’encaisser ou de continuer si la connectivité reprend. En l’absence d’action, le pari de la session de jeu en cours sera considéré perdu`,
    Save: "Sauver",
    ServerConnection: "Connexion au serveur",
    InternetConnectionLost: "Il y a eu un problème avec votre connexion internet, veuillez actualiser la page.",
    ServerError: "Erreur du serveur",
    SessionHasExpired: "La session a expiré",
    SitesUpdate: "MAINTENANCE PLANIFIÉE!",
    Sound: "Son",
    Statistics: "Statistiques",
    StopIf: "ARRÊTER SI LE PARI >",
    Submit: "Soumettre",
    SuccessfulRegistration: "Pari placé",
    TechnicalWork: "Le jeu que vous avez demandé sera bientôt de retour.",
    TicketCashoutMustBe: "Le retrait doit être d’au moins 1,5",
    Time: "Temps",
    TopSpins: "Meilleures Cotes",
    TopWins: "Gains",
    tr: "TR",
    UnknowError: "Erreur du serveur",
    Unregister: "Annuler le pari",
    UnregisterFreeBet: "Annuler Pari Gratuit",
    Unregistered: "Pari annulé",
    User: "Joueur",
    UserName: "Nom d'utilisateur",
    UserShort: "US",
    WaitingForNextRound: "Vous êtes en train d'attendre le prochain tour",
    Win: "Gains",
    WinAmount: "Profit",
    WinMultiplier: "Mult. gagné",
    Winners: "Gagnants",
    WrongAmount: "Mauvais Montant",
    WrongConfiguration: "Mauvaise Configuration",
    Year: "Année",
    Yes: "Oui",
    YouAreNotRegistered: "Vous n’êtes pas enregistré sur ce jeu",
    EnterText: "Saisir du texte",
    PlayerNotFound: "Joueur pas trouvé",
    Jan: "Jan.",
    Feb: "Fev.",
    Mar: "Mar.",
    Apr: "Avr.",
    May: "Mai",
    June: "Juin",
    July: "Juil.",
    Aug: "Août.",
    Sept: "Sep.",
    Oct: "Oct.",
    Nov: "Nov.",
    Dec: "Déc.",
    BlockedUser: "Votre compte est bloqué",
    LoadingProblem: "Impossible de charger le jeu, veuillez recharger la page.",
    January: "Janvier",
    February: "Fevrier",
    March: "Mars",
    April: "Avril",
    MayLong: "Mai",
    JuneLong: "Juin",
    JulyLong: "Juillet",
    August: "Août.",
    September: "Septembre",
    Octomber: "Octobre",
    November: "Novembre",
    December: "Décembre",
    Auto: "Auto",
    Game: "Jeu",
    BetHistory: "Historique des paris",
    ViewTable: "Voir le tableau",
    TotalProfit: "Profit total",
    WeakInternetAlert: "Connexion Internet faible",
    PageWillReload: "La page se rechargera",
    InSeconds: "Secondes",
  };
  