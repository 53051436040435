import React, { FC } from "react";
import { TableHead } from "../../../../components/Table/TableHead";
import { TableBody } from "../../../../components/Table/TableBody";
import { TableRow } from "../../../../components/Table/TableRow";
// import Table from "../../../../components/Table/Table";
import styled from "styled-components";
import {
  NeutralFirstTableCell,
  NeutralFourthTableCell,
  NeutralSecondTableCell,
  NeutralThirdTableCell,
} from "./NeutralTableCell";
import { GameHistory } from "../../../../constants/interfaces/GameHistory";
import { StyledTableHeaderCell } from "./TableHeaderCell.";
import { KEYWORDS } from "../../../../config/language/keywords";
import useTranslate from "../../../../config/language";
import { useIsMobile } from "../../../../helpers/hooks/useIsMobile";

const Container = styled.div<{ height: number }>`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(${(props) => props.height}px - 214px);
  padding-bottom: 30px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  @media (min-width: ${1366}px) {
    max-height: calc(${(props) => props.height}px - 294px);
  }

  @media (min-width: ${1920}px) {
    max-height: calc(${(props) => props.height}px - 365px);
  }
`;

const Table = styled.table`
  font-size: 12px;
  line-height: 15px;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: center;
  border: 0;
`;

const StyledTableRow = styled(TableRow)`
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  td {
  }

  &.first {
    border-top: 0;

    @media (min-width: ${1366}px) {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
`;

const ProvablyFairTable: FC<{ items: Partial<GameHistory>[] }> = ({
  items,
}) => {
  const translate = useTranslate();
  const isMobile = useIsMobile();

  return (
    <Container height={window.innerHeight}>
      <Table>
        <TableHead>
          <tr>
            <StyledTableHeaderCell className="data-id first">
              ID
            </StyledTableHeaderCell>
            <StyledTableHeaderCell className="data-coeffc">
              X
            </StyledTableHeaderCell>
            <StyledTableHeaderCell className="data-key">
              {translate(KEYWORDS.Key)}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell className="data-hcode last">
              {translate(KEYWORDS.Hash)}
            </StyledTableHeaderCell>
          </tr>
        </TableHead>

        <TableBody>
          {items.map((item: Partial<GameHistory>, index) => {
            return (
              <StyledTableRow key={index}>
                <NeutralFirstTableCell>{item.ID}</NeutralFirstTableCell>
                <NeutralSecondTableCell>
                  {item.BustedAt || "-"}
                </NeutralSecondTableCell>
                <NeutralThirdTableCell>
                  {isMobile
                    ? item.CrashSource?.slice(0, 10)
                    : item.CrashSource?.slice(0, 20) || "-"}
                </NeutralThirdTableCell>
                <NeutralFourthTableCell>
                  {isMobile
                    ? item.CrashHash?.slice(0, 10)
                    : item.CrashHash?.slice(0, 20) || "-"}
                </NeutralFourthTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ProvablyFairTable;
