export const SOCKET_URL = 'wss://dev-boom-demo-api.playtron.com/ws';
// export const SOCKET_URL = 'wss://prod-boom-eb-api.playtron.com/ws';
export const HOME_WEBSITE = 'https://europebet.com';
// @ts-ignore
export const CURRENCY_SYMBOL = window.Ccy ? window.Ccy : '$';
export const MIXPANEL_TOKEN = 'c0c52cbfea17f404859a5fe14244d314';
export const SENTRY_DSN =
    'https://7d99079bf5d143089c4993ba7f4bfebe@o1089540.ingest.sentry.io/6104736';
export const SESSION_KEY = 'sessionKey';
export const LANGUAGE_KEY = 'lang';
