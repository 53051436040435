import React, { memo, useEffect, useState } from "react";
import { useGameHistory } from "../../config/store/state/app.state";
import ResultBox from "../../components/ResultBox/ResultBox";
import { useIsMobile } from "../../helpers/hooks/useIsMobile";
import { StyledOddsList, Items, Wrap, IconsContainer } from "./styles";
import History from "../../components/Icons/History";
import HistoryArrow from "../../components/Icons/HistoyArrow";
import Undo from "../../components/Icons/Undo";
import ArrowDown from "../../components/Icons/ChevronDown";
import { GameHistory } from "../../constants/interfaces/GameHistory";

const OddsList = () => {
  const gameHistory = useGameHistory();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const [games, setGames] = useState<GameHistory[]>([]);

  useEffect(() => {
    setGames([]);
    const historyItemsToShow = gameHistory.slice(0, isMobile ? 10 : 24);
    setGames(historyItemsToShow)
  }, [gameHistory, isMobile, open])

  return (
    <StyledOddsList className={open ? "open-bar" : ""}>
      <Items>
        <Wrap className={open ? "coeffc-wrap-abs" : ""}>
          {games.map((game) => {
            return <ResultBox key={game.ID} game={game} historyOpen={open} />;
          })}
        </Wrap>
      </Items>

      <IconsContainer onClick={() => setOpen(!open)}>
        <HistoryArrow className="coeffc-ic-history-arrow" />
        <History className="coeffc-ic-history" />
        <Undo className="web-history" />

        <ArrowDown className={`coeffc-ic-arrow ${open ? "arrow-up" : ""}`} />
      </IconsContainer>
    </StyledOddsList>
  );
};

export default memo(OddsList);
