import React, { useState } from 'react';
import { KEYWORDS } from '../../../config/language/keywords';
import useTranslate from '../../../config/language';
import { GameBoard, GameTabsNav, Nav, NavItem } from '../styles';
import { ACTION_PANEL_NAVIGATION } from '../tools';
import ManualBoard from './ActionBoard/ActionBoard';
import AutoBoar from './AutoBoard/ActionBoard';

const ActionBoard = ({
    board,
    navDisable,
    delay = 0,
}: {
    board: number;
    navDisable?: boolean;
    delay?: number;
}) => {
    const translate = useTranslate();
    const [active, setActive] = useState(ACTION_PANEL_NAVIGATION.MANUAL);

    return (
        <GameBoard
            innerHeight={window.innerHeight}
        >
            <GameTabsNav>
                <Nav>
                    <NavItem
                        className={
                            active === ACTION_PANEL_NAVIGATION.MANUAL
                                ? 'active'
                                : ''
                        }
                        onClick={
                            navDisable
                                ? () => ''
                                : () =>
                                      setActive(ACTION_PANEL_NAVIGATION.MANUAL)
                        }
                    >
                        {translate(KEYWORDS.Game)}
                    </NavItem>

                    <NavItem
                        className={
                            active === ACTION_PANEL_NAVIGATION.AUTO
                                ? 'active'
                                : ''
                        }
                        onClick={
                            navDisable
                                ? () => ''
                                : () => setActive(ACTION_PANEL_NAVIGATION.AUTO)
                        }
                    >
                        {translate(KEYWORDS.Auto)}
                    </NavItem>
                </Nav>
            </GameTabsNav>

            {active === ACTION_PANEL_NAVIGATION.MANUAL && (
                <ManualBoard boardIndex={board} delay={delay} />
            )}
            {active === ACTION_PANEL_NAVIGATION.AUTO && (
                <AutoBoar boardIndex={board} delay={delay} />
            )}
        </GameBoard>
    );
};

export default ActionBoard;
