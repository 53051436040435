import React, { FC } from "react";
import { IconProps } from "./types";

export const ShieldPF: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.48289 0C9.61589 0 15.5609 2.041 16.3479 2.828C17.0039 3.484 16.9939 4.014 16.9479 6.554C16.9299 7.572 16.9049 8.959 16.9049 10.879C16.9049 17.761 9.03489 20.223 8.69989 20.324C8.62889 20.346 8.55589 20.356 8.48289 20.356C8.40989 20.356 8.33689 20.346 8.26589 20.324C7.93089 20.223 0.0608906 17.761 0.0608906 10.879C0.0608906 8.962 0.0358906 7.575 0.0178906 6.557C0.00967634 6.10289 0.00261001 5.71298 0.000284712 5.37507L0 4.99377C0.00857812 3.74112 0.126141 3.32 0.618891 2.828C1.40389 2.041 7.34889 0 8.48289 0ZM8.48289 1.5C7.63489 1.5 2.28489 3.384 1.66789 3.899C1.48589 4.082 1.47889 4.4 1.51789 6.529C1.53589 7.555 1.56089 8.949 1.56089 10.879C1.56089 16.08 7.28289 18.389 8.48189 18.814C9.67989 18.387 15.4049 16.065 15.4049 10.879C15.4049 8.947 15.4299 7.552 15.4489 6.526C15.4869 4.399 15.4799 4.081 15.2869 3.889C14.6819 3.384 9.33089 1.5 8.48289 1.5ZM12.2044 7.3395C12.4974 7.6325 12.4974 8.1075 12.2044 8.4005L8.30639 12.2995C8.19439 12.4123 8.04975 12.4854 7.89449 12.5102L7.77639 12.5195C7.57739 12.5195 7.38639 12.4405 7.24539 12.2995L5.35339 10.4055C5.06139 10.1125 5.06139 9.6365 5.35439 9.3445C5.64639 9.0515 6.12239 9.0515 6.41539 9.3445L7.77639 10.7075L11.1444 7.3395C11.4374 7.0465 11.9114 7.0465 12.2044 7.3395Z"
        fill="#6D5CCE"
      />
    </svg>
  );
};

export default ShieldPF;
