import React, { memo } from "react";
import Board from "./Boards";
import {
  RegisterState,
  useBoards,
} from "../../../config/store/state/app.state";

const BOARD_ONE_PANEL_INDEX = 0;
const BOARD_TWO_PANEL_INDEX = 1;

const Actions = () => {
  const boards = useBoards();

  const firstBoard = boards.filter(
    (board) => board.PanelIndex === BOARD_ONE_PANEL_INDEX
  )[0];
  const secondBoard = boards.filter(
    (board) => board.PanelIndex === BOARD_TWO_PANEL_INDEX
  )[0];

  const fistDisabled = boards[0].registerState !== RegisterState.UNREGISTERED;

  const secondDisabled = boards[1].registerState !== RegisterState.UNREGISTERED;

  return (
    <>
      {firstBoard && (
        <Board board={BOARD_ONE_PANEL_INDEX} navDisable={fistDisabled} />
      )}

      {secondBoard && (
        <Board
          board={BOARD_TWO_PANEL_INDEX}
          navDisable={secondDisabled}
          delay={200}
        />
      )}
    </>
  );
};

export default memo(Actions);
