import styled from "styled-components";

export const Img = styled.img`
  // width: 77px;
  height: 24px;
  box-shadow: 0px 6px 12px -3px rgba(247, 57, 47, 0.4);
  border-radius: 6px;

  @media (min-width: ${800}px) {
    // width: 127px;
    height: 46px;
    width: auto;
  }

  // @media (min-width: ${1366}px) {
  //   width: 207px;
  // }
`;

export const StyledLink = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  // a {
  //   display: block;
  // }
`;
