import styled from "styled-components";

export const Table = styled.div``;

export const TableRow = styled.div`
  display: flex;
`;

export const TableBody = styled.div``;

export const StyledCurrentList = styled.div`
  // background: #0e0826;
  // border-radius: 8px;
  // position: relative;
  // margin-top: 7px;

  // @media (min-width: ${1366}px) {
  //   padding-top: 33px;
  //   margin-top: 15px;
  // }

  // @media (min-width: ${1920}px) {
  //   padding-top: 20px;
  //   margin-top: 28px;
  // }
`;

export const TableOverflow = styled.div`
  height: calc(88vh - 249px);
  overflow-y: auto;
  position: relative;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 2px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  @media (min-width: ${800}px) {
    height: calc(100vh - 189px);
  }

  @media (min-width: ${1366}px) {
    height: calc(100vh - 238px);
  }
`;

export const TableWrap = styled.div`
  .table-bets {
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    border-spacing: 0;
    border: 0;
    border-collapse: collapse;
    text-align: center;
  }

  @media (min-width: ${800}px) {
    .table-bets {
      font-size: 8px;
      line-height: 10px;
    }
  }

  @media (min-width: ${1366}px) {
    .table-bets {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const TableHeaderCell = styled.div`
  border: 0;
  border-collapse: collapse;
  color: #ffffff;
  padding-bottom: 12px;
  font-weight: normal;
  font-feature-settings: "case" off;

  &:nth-child(1) {
    padding-left: 14px;
    text-align: left;
  }

  &:nth-child(4) {
    padding-right: 17px;
    text-align: right;
  }

  &.bets-user {
    width: 34%;
  }

  &.bets-bet {
    width: 26%;
  }

  &.bets-coeffc {
    width: 15%;
  }

  &.bets-profit {
    width: 25%;
  }

  @media (min-width: ${800}px) {
    padding-bottom: 4px;

    &:nth-child(1) {
      padding-left: 4px;

      &:after {
        display: inline-block;
        content: ".";
        vertical-align: top;
      }
    }

    &:nth-child(4) {
      padding-right: 4px;
    }

    &.bets-user {
      width: 26%;
    }

    &.bets-bet {
      width: 22%;
    }

    &.bets-coeffc {
      width: 27%;
    }

    &.bets-profit {
      width: 20%;
    }
  }

  @media (min-width: ${1366}px) {
    padding-bottom: 0;

    &:nth-child(1) {
      padding-left: 18px;

      &:after {
        display: none;
      }
    }

    &:nth-child(4) {
      padding-right: 18px;
    }

    &.bets-user {
      width: 33%;
    }

    &.bets-coeffc {
      width: 25%;
    }
  }
`;

export const InnerCell = styled.span`
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 5px 6px;
  width: 100%;

  &.first {
    padding-left: 0;
  }

  &.last {
    padding-right: 0;
  }

  @media (min-width: ${800}px) {
    display: inline-block;
    border-bottom: 0;
    padding: 0px 3px 0px;

    &.first {
      width: 23px;
      overflow: hidden;
    }
  }

  @media (min-width: ${1366}px) {
    display: block;
    padding: 0px 3px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 14px;

    &.first {
      width: auto;
      overflow: visible;
    }
  }
`;
