import React, { useEffect, useState } from "react";
import LoaderLogo from "../../assets/images/general/loader-logo.png";
import StarsContainer from "../../components/Stars";
import { useLoadGameService } from "../../config/store/services";
import { useGameState } from "../../config/store/state/app.state";
import { GAME_STATE } from "../../constants/interfaces/Game";
import GIF from "../../assets/images/gif.gif";
import {
  Container,
  LoadingContainer,
  AppLogoContainer,
  ProgressBarContainer,
  AppLogo,
  ProgressBar,
} from "./index.styled";
import Gradient from "../../assets/images/gradient.png";

const times = [900, 700, 1200, 1000];

const Loader = () => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(0);
  const [loadingDuration, setLoadingDuration] = useState<number>(0);
  const handleLoadGame = useLoadGameService();
  const gameState = useGameState();

  useEffect(() => {
    const count = setInterval(() => {
      setLoadingDuration((prevState) => prevState + 0.1);
    }, times[0]);

    return () => {
      clearInterval(count);
    };
  }, [loadingDuration, setLoadingDuration]);

  useEffect(() => {
    if (loading >= 90) {
      return;
    }

    const countdown = setInterval(() => {
      setLoading((prevState) => prevState + 10);
    }, times[1]);

    return () => {
      clearInterval(countdown);
    };
  }, [gameState.status.state, loading, setLoading]);

  useEffect(() => {
    if (loading < 90) {
      return;
    }

    const countdown = setInterval(() => {
      if (gameState.status.state !== GAME_STATE.INITIAL) {
        // sessionStorage.setItem("loaded", "1");
        setLoading(100);
      } else {
        if (loading < 99) {
          setLoading((prevState) => prevState + 0.5);
        }
      }
    }, times[2]);

    return () => {
      clearInterval(countdown);
    };
  }, [gameState.status.state, handleLoadGame, loading]);

  useEffect(() => {
    if (loading >= 100) {
      const timeout = setTimeout(() => {
        if (loaded) {
          handleLoadGame(loadingDuration);
        }
      }, times[3]);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLoadGame, loading, loaded]);

  return (
    <Container landscapeHeight={window.outerHeight}>
      <img className="full" src={Gradient} alt="Gradient" />
      <div className="full">
        <StarsContainer disabled={true} />
      </div>
      <div style={{ display: "none" }}>
        <img onLoad={() => setLoaded(true)} src={GIF} alt="rockit" />
      </div>
      <LoadingContainer>
        <AppLogoContainer>
          <AppLogo src={LoaderLogo} alt="app image" />
        </AppLogoContainer>

        <ProgressBarContainer>
          <ProgressBar width={loading} />
        </ProgressBarContainer>
      </LoadingContainer>
    </Container>
  );
};

export default Loader;
