import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { variables } from "../../constants/variables";
import { getQuery } from "../../helpers/hooks/useQuery";
const lang = getQuery(window.location.search, "lang");

const StyledButton = styled.button<{
  height?: number;
  width?: number;
  background: string;
  blocked?: boolean;
}>`
  height: 100%;
  width: 100%;
  outline: 0;
  border: none;
  border-radius: 4px;
  font-size: 22px;
  font-weight: bold;
  align-items: center;
  position: relative;
  z-index: 90;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  background: ${(props) => props.background};
  opacity: ${({ blocked }) => (blocked ? 0.5 : 1)};
  &:active {
    opacity: ${({ blocked }) => (blocked ? 0.5 : 0.8)};
  }
  &:disabled {
    // background: ${(props) => props.theme.colors.transparent3};
    color: ${(props) => props.theme.colors.whiteGrayish};
  }
  font-family: "FiraGo", sans-serif;

  &.roboto {
    font-family: "Roboto", sans-serif;
  }

  &.montserrat {
    font-family: "Montserrat", sans-serif;
  }
`;

export const ButtonNumericLabel = styled.span``;

const Button: FC<{
  text?: string | ReactNode;
  height?: number;
  width?: number;
  onClick?(): void;
  blockedAction?(): void;
  className?: string;
  background?: string;
  disabled?: boolean;
  blocked?: boolean;
}> = ({
  text,
  height,
  width,
  onClick,
  blockedAction,
  className,
  background,
  disabled,
  blocked,
}) => {
  const clickEvent = () => {
    if (blocked && blockedAction) {
      blockedAction();
    } else if (onClick) {
      onClick()
    }
  }
  return (
    <StyledButton
      // className={`${
      //   lang ? (lang === "en" ? "roboto" : "montserrat") : ""
      // } ${className}`}
      data-id={disabled}
      className={`${(lang === "ru" || lang === "uk") && "montserrat"} ${
        lang === "en" && "roboto"
      } ${(!lang || lang === "ka") && ""} ${className}`}
      height={height}
      width={width}
      onClick={() => clickEvent()}
      background={background ? background : variables.colors.vermillion}
      disabled={disabled}
      blocked={blocked}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
