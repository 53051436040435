import React, { FC, memo, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useGameStatusState } from "../../config/store/state/app.state";
import { GAME_STATE } from "../../constants/interfaces/Game";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";
import { roundNumber } from "../../helpers/functions/round-number";
import { texts } from "../../constants/variables";

const Title: FC = () => {
  const game = useGameStatusState();
  const translate = useTranslate();
  const [title, setTitle] = useState("ROCKIT! - Developed by Playtron");

  useEffect(() => {
    switch (game.state) {
      case GAME_STATE.RUNNING: {
        setTitle(
          `${(game.multiplier && roundNumber(game.multiplier)) || "1.00"}x - ${
            texts.gameTitle
          }!`
        );
        break;
      }
      case GAME_STATE.COUNTDOWN: {
        setTitle(`${translate(KEYWORDS.GameStart)} - ${texts.gameTitle}!`);
        break;
      }
      case GAME_STATE.CRUSHED: {
        setTitle(
          `${translate(KEYWORDS.Crashed)} ${game.multiplier} - ${
            texts.gameTitle
          }!`
        );
        break;
      }
      default: {
        setTitle(`ROCKIT! - Developed by Playtron`);
      }
    }
  }, [game.state, game.multiplier, translate]);

  const helmet = (
    // @ts-ignore
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );

  return <HelmetProvider>{helmet}</HelmetProvider>;
};

export default memo(Title);
