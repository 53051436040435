import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { CurrencySymbol } from "../../../../../constants/currency";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import { Tab, ButtonInput } from "../../../styles";
import Range from "../../../Range";

const UnregisterPanel: FC<{
  amount: number;
  handleUnregister(): void;
  setAmount(amount: number | null): void;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
}> = ({
  amount,
  handleUnregister,
  setAmount,
  disabled,
  minValue,
  maxValue,
}) => {
  const translate = useTranslate();
  const CURRENCY = CurrencySymbol()

  return (
    <Tab>
      <NumberInput value={amount} onChange={setAmount} disabled />

      <Range
        value={amount}
        baseHalfPoint={20}
        min={minValue}
        max={maxValue}
        onChange={setAmount}
        disabled
      />

      <ButtonInput
        text={
          <>
            {translate(KEYWORDS.Unregister)}
            <span className="roboto-font"> {+roundNumber(amount)}</span>
            {CURRENCY}
          </>
        }
        onClick={handleUnregister}
        disabled={disabled}
        className="btn-cancel"
      />
    </Tab>
  );
};

export default UnregisterPanel;
