export interface GameConfig {
  MaxBet: number;
  MinBet: number;
  Ccy?: string;
  MinFreeBetCashoutMultiplier: number;
}

export enum GAME_CONFIG {
  INITIALIZE_GAME_CONFIG = "INITIALIZE_GAME_CONFIG",
}

export const initializeMinMaxValue = (minMaxValue: GameConfig) => {
  if (minMaxValue.Ccy) {
    // @ts-ignore
    window.Ccy = minMaxValue.Ccy
  }

  return {
    type: GAME_CONFIG.INITIALIZE_GAME_CONFIG as GAME_CONFIG.INITIALIZE_GAME_CONFIG,
    minMaxValue,
  };
};
