import React, { memo, useState } from "react";
import { useTopBets } from "./configs/store/state";
import { TOP_LIST_FILTER_INDEXES, TOP_LIST_INDEXES } from "./configs/tools";
import OddsList from "./lists/Odds.list";
import WinningsList from "./lists/Winnings.list";
import MultiplierCashOutList from "./lists/MultiplierCashOut.list";
import TopBetsActions from "./components/TopBetsActions";
import { StyledCurrentList } from "./styles";

const TopBetsList = () => {
  const [active, setActive] = useState(TOP_LIST_INDEXES.TOP_ODDS);
  const [activeFilter, setActiveFilter] = useState(TOP_LIST_FILTER_INDEXES.DAY);

  const topBets = useTopBets();

  return (
    <StyledCurrentList>
      <TopBetsActions
        active={active}
        setActive={setActive}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      {active === TOP_LIST_INDEXES.TOP_ODDS && (
        <OddsList items={topBets.odds} selectedFilter={activeFilter} />
      )}
      {active === TOP_LIST_INDEXES.TOP_WINNINGS && (
        <WinningsList items={topBets.winnings} selectedFilter={activeFilter} />
      )}
      {active === TOP_LIST_INDEXES.TOP_CASH_OUT && (
        <MultiplierCashOutList
          items={topBets.multiCashOut}
          selectedFilter={activeFilter}
        />
      )}
    </StyledCurrentList>
  );
};

export default memo(TopBetsList);
