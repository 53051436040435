import React, { FC, useRef } from "react";
import Modal from "../Modal";
import Hash from "../Icons/Hash";
import Lock from "../Icons/Lock";
import Close from "../Icons/Close";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";
import {
  PopupWrap,
  Head,
  Title,
  CloseWrapper,
  Content,
  Scroll,
  Item,
  Label,
  Code,
  InfoText,
  RoundLab,
  Row,
  Key,
  Copy,
} from "./styles";
import RoundShield from "../Icons/RoundShield";

const BetsModal: FC<{
  gameId?: number;
  hashCode?: string;
  hashKey?: string;
  bustedAt?: number;
  closeModal(): void;
}> = ({ closeModal, gameId, hashCode, hashKey, bustedAt }) => {
  const translate = useTranslate();
  const KeyRef = useRef<HTMLDivElement>(null);
  const HashRef = useRef<HTMLDivElement>(null);

  return (
    <Modal closeModal={closeModal}>
      <PopupWrap className="round-popup">
        <Head>
          <Title>
            <RoundShield className="popup-raund-head-ic" />
            {translate(KEYWORDS.Round)} {gameId}
          </Title>

          <CloseWrapper onClick={closeModal}>
            <Close className="popup-close-ic" />
          </CloseWrapper>
        </Head>

        <Content>
          <Scroll>
            <div>
              <Item className="first">
                <Label>{translate(KEYWORDS.ResultsHash)}</Label>

                <Code>
                  <Hash className="info-icon" />

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <InfoText ref={HashRef}>{hashCode}</InfoText>

                    <Copy
                      onClick={() =>
                        navigator.clipboard.writeText(
                          HashRef.current?.innerText || ""
                        )
                      }
                    >
                      Copy
                    </Copy>
                  </div>
                </Code>
              </Item>

              <Item>
                <Label>{translate(KEYWORDS.CheckResult)}</Label>

                <Code>
                  <Lock className="lock" />

                  <div>
                    <Row>
                      <RoundLab>{translate(KEYWORDS.Key)}</RoundLab>
                      <RoundLab>{translate(KEYWORDS.Result)}</RoundLab>
                    </Row>

                    <Row className="bordered">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Key className="code" ref={KeyRef}>
                          {hashKey?.trim()}
                        </Key>

                        <Copy
                          onClick={() =>
                            navigator.clipboard.writeText(
                              KeyRef.current?.innerText || ""
                            )
                          }
                        >
                          Copy
                        </Copy>
                      </div>

                      <Key className="coeff">{bustedAt}x</Key>
                    </Row>
                  </div>
                </Code>
              </Item>
            </div>
          </Scroll>
        </Content>
      </PopupWrap>
    </Modal>
  );
};

export default BetsModal;
