import styled from "styled-components";

export const PopupWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
  scroll-behavior: smooth;

  // &.menu-open {
  //   background: rgba(255, 255, 255, 0.24);
  //   display: block;
  // }

  &.blur {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(14px);
  }

  &.open {
    display: flex;
    height: calc(100vh - 50px);
    top: 50px;
    align-items: center;
    justify-content: center;
  }

  &.bets {
    align-items: start;
  }

  @media (min-width: ${800}px) {
    &.bets {
      display: none;
    }
  }

  @media (min-width: ${1366}px) {
    &.blur {
      align-items: start;
    }
  }
`;

export const Blur = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
`;

export const MenuDropdown = styled.div<{ height: number }>`
  width: 300px;
  background: #000;
  padding: 47px 19px 24px 19px;
  z-index: 100;
  border-radius: 0px 0px 6px 0px;
  position: absolute;
  left: 0;
  top: 0;
  max-height: calc(${(props) => props.height}px - 90px);
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 2px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  @media (min-width: ${800}px) {
    padding: 10px 19px 15px 19px;
  }

  @media (min-width: ${1366}px) {
    left: 16px;
    top: -18px;
    border: 1px solid rgba(56, 46, 112, 0.5);
    border-radius: 0px 8px 8px 8px;
    padding: 24px 19px 15px 19px;
  }
`;

export const MenuAvatar = styled.div`
  text-align: center;
  padding-bottom: 15px;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  // margin-bottom: 44px;

  @media (min-width: ${800}px) {
    padding-bottom: 18px;
  }
`;

export const UserName = styled.div`
  font-feature-settings: "case" on;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;

  @media (min-width: ${1920}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const MainMenu = styled.ul`
  font-feature-settings: "case" on;
  text-transform: uppercase;
  list-style: none;
  font-size: 12px;
  line-height: 15px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      color: #2eb550;
    }

    &:hover .main-menu-arrow {
      fill: #6d5cce;
    }
  }

  .main-menu-ic {
    margin-right: 14px;

    width: 24px;
    height: 24px;

    &.fill {
      fill: #6d5cce;
    }

    // &.pf {
    //   width: 16px;
    //   height: 17.95px;
    // }

    // &.rules {
    //   width: 18px;
    //   height: 18px;
    // }
  }

  .main-menu-arrow {
    fill: #f1f1f1;
    width: 7px;
    height: 14px;
  }

  @media (min-width: ${800}px) {
    text-transform: none;
  }
`;

export const Item = styled.li`
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding-bottom: 16px;
  margin-top: 16px;

  font-feature-settings: "case" on;

  &:first-child {
    margin-top: 0;
  }

  @media (min-width: ${800}px) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 18px;
    margin-top: 18px;

    &.mobile {
      display: none;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  // align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  background: #0e0826;
  color: #6d5cce;
  margin-bottom: 25px;
  border-radius: 6px;
  padding: 6px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  font-feature-settings: "case" on;

  @media (min-width: ${800}px) {
    margin-bottom: 35px;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    line-height: 19px;
  }
`;
