import React, { FC } from "react";
import { Close } from "../../../../components/MenuIcon/MenuIcon.styles";
import SidebarList from "../../../topBoards";
import { Modal, Header } from "./BetsModal.styles";

const BetsModal: FC<{ close: () => void }> = ({ close }) => {
  return (
    <Modal>
      <Header>
        <Close className="close-icon" onClick={close} />
      </Header>

      <SidebarList />
    </Modal>
  );
};

export default BetsModal;
