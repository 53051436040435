const CURRENCY_SYMBOL = '___';

export const ukrainian = {
  AccountBanned: "Ваш акаунт заблоковано!",
  AllBets: "Усі ставки:",
  AlreadyRegistered: "Ви вже зробили ставку",
  Animation: "Анімація",
  AutoBet: "АВТОМАТИЧНА СТАВКА",
  AutoBetting: "Автоматична ставка",
  AutoCashout: "АВТОМАТИЧНЕ ВИВЕДЕННЯ КОШТІВ",
  AutoCashouting: "Автоматичне виведення коштів",
  Balance: "БАЛАНС",
  BalanceShort: "БАЛ",
  Banned: "ЗАБОРОНЕНО",
  BaseBet: "БАЗОВА СТАВКА",
  Bet: "Ставка",
  BetMaximum: `Сума максимальної ставки: ${CURRENCY_SYMBOL}`,
  BetMustBe: `Сума мінімальної ставки: ${CURRENCY_SYMBOL}`,
  Bets: "Ставки",
  Stakes: "Ставки",
  BuyIn: "Ставка",
  Cashout: "Виведення коштів",
  CashoutFromGame: "Виведення коштів",
  CashoutMaxMustBe: "Максимальна сума для виведення",
  CashoutMustBe: "Мінімальна сума для виведення – 1.01",
  ChangeAvatar: "Змінити аватар",
  CheckFair: "1. Що таке перевірка коефіцієнта?",
  CheckHash: "3. Як перевірити код достовірності?",
  CheckResult: "Перевірити результат",
  Circulation: "#",
  Clear: "ОЧИСТИТИ",
  Crashed: "Коэфф.",
  CurrentBets: "BETS",
  Day: "24 години",
  DearUsers: "Просимо вибачення за незручності",
  en: "EN",
  Error: "Помилка",
  ExampleForFair:
    "Номер гри – 9088 Коефіцієнти – 1.06 Час створення – 03.12.2019_16:09 Унікальний код гри Fv+5TVHK2621A== Отже, ключ до гри: 9088_1.06_Rockit_12/03/2019_16:09_Fvv+5TVHK2621A== Та код достовірності, створений за допомогою алгоритму SHA256: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d",
  Examples: "Приклад:",
  FairGame: "Чесна гра",
  Fill: "Заповнити",
  FreeBet: "Безкоштовна ставка",
  GameIsStopped: "Гру зупинено",
  GameKey: "2. 2. Що таке ключ до гри та код достовірності",
  ServerConnectionTemp: "З’єднуємося з сервером.",
  SameUser: "Гра відкрита в іншій вкладці",
  GameRules: "Правила гри",
  GameStart: "Початок гри",
  GameStarted: "Гра почалася – Успіхів ",
  GameStatusIsNotRegistering: "Прийом ставок завершено",
  GameWillResume: "Час до поновлення ",
  GotoHome: "ГОЛОВНА",
  Hash: "Хеш-код",
  History: "Історія ",
  HowToCheckFair:
    "Цей метод дозволяє переконатися, що коефіцієнти (число, після якого гра припиняється) щонайменше п’яти наступних ігор відомі ще до того, як гравці починають робити ставки. Нижче наведений хеш-код 5 минулих і 5 майбутніх раундів. ",
  HowToCheckGameKey:
    "Коефіцієнт гри (число, після якого гра припиняється) визначається завчасно, за 5 ігор. На сервері генерується код гри, який складається з наступних показників: [Game Number] _ [Odds] _ [Rockit] _ [Generation Time] _ [Unique Game Number]",
  HowToCheckHash:
    "Після завершення гри вам надається доступ до ключа, який ви можете зашифрувати за допомогою будь-якого онлайн-калькулятора SHA256, а потім порівняти з кодом достовірності для 5 наступних ігор. ",
  IncreaseBy: "ЗБІЛЬШИТИ НА",
  Jackpot: "ДЖЕКПОТ",
  ka: "KA",
  ua: "UA",
  Key: "Ключ",
  LanguageChangeAcception: "Ви дійсно хочете змінити мову?",
  LanguageChangeWarning: "Натисніть, щоб вийти з гри",
  LastGames: "Останні ігри",
  LastHand: "Остання роздача",
  LastTopSpins: "Топ-множники за 24 години",
  LastTopWins: "Топ-виграші за 24 години",
  LeaveMessage: "Введіть текст повідомлення",
  Login: "УВІЙТИ",
  LoginFailed: "Неправильний логін",
  MainPage: "Головна сторінка",
  ManualBet: "СТАВКА ВРУЧНУ",
  MinFreebetCashout: "Мін. коеф. 1.5",
  Month: "Місяць",
  MultiplierCashout: "Множ. вив.",
  Music: "Музика",
  My: "Мої ставки",
  No: "Ні",
  NotEnoughMoney: "Недостатньо коштів",
  OnLoss: "НА ПРОГРАШ",
  OnWin: "НА ПЕРЕМОГУ",
  OpenHere: "Відкрити",
  PlaceYourBet: "РОБІТЬ СТАВКИ",
  Player: "Гравець",
  Rating: "ТОП",
  Register: "Зробити ставку",
  Registration: "РОБІТЬ СТАВКИ",
  Reload: "Перезавантажити",
  Result: "Результат",
  ResultsHash: "Хеш-код результату",
  ReturnToBaseBet: "ПОВЕРНУТИСЯ ДО БАЗОВОЇ СТАВКИ",
  ReturnToCash: "Повернутися до готівки",
  Round: "Раунд",
  ru: "RU",
  GameRules1: "Гравці можуть робити ставки до початку раунду.",
  GameRules2: "З початком гри коефіцієнт зростає аж до її припинення.",
  GameRules3: "Припинення відбувається випадковим чином. ",
  GameRules4: "Гравець повинен вивести виграш раніше, ніж гра припиниться. ",
  GameRules5: "Ставки, які гравець не встиг вивести, згоряють.",
  GameRules6:
    "Ставка помножується на той коефіцієнт, із яким вона була виведена. ",
  GameRules7: `Коефіцієнти починають зростати з 1,0. У разі згоряння на 1,0 усі ставки вважаються програними. Наприклад, якщо гравець ставить 5${CURRENCY_SYMBOL} і виводить готівку на 2,0, він отримує 2х5 = 10${CURRENCY_SYMBOL}, тоді як при згорянні на рівні до 2,0, наприклад, 1,75, він втрачає всю ставку розміром 5${CURRENCY_SYMBOL}. У разі виходу з гри ставка виводиться автоматично відповідно до множника і коефіцієнтів у цей момент.`,
  GameRules8:
    "У разі виходу з гри ставка виводиться автоматично відповідно до множника ставки і висотного коефіцієнта в цей момент. Вихід вважається припиненням ігрової сесії з будь-якої причини.",
  GameRules9: "Гравець може зробити дві ставки одночасно і вивести їх окремо.",
  GameRules10:
    "Функція автоматичної ставки дозволяє зробити ставку на бажаних умовах. У віконці слід вказати суму ставки, а також коефіцієнт та максимальну суму виведення. Також можна заздалегідь визначити кількість ставок на випадок виграшу або програшу.",
  GameRules11: "Виграші автоматично додаються до балансу гравця.",
  GameRules12:
    "Переглянути історію гри можна, натиснувши на кнопку «Мої ставки».",
  GameRules13:
    "У разі доведеної помилки в грі сторона гри залишає за собою право призупинити виплату виграшу або змінити його суму.",
  GameRules16: "Ігрові ліміти:",
  GameRules17: `мінімальна ставка - 0.1${CURRENCY_SYMBOL}`,
  GameRules18: `максимальна ставка - 200${CURRENCY_SYMBOL}`,
  GameRules19: `Максимальний виграш гравця (bet*odds) - 30,000 ${CURRENCY_SYMBOL}, коли ставка, помножена на коефіцієнт, досягне 10 000 ${CURRENCY_SYMBOL}, система автоматично перерахує активну ставку.`,
  GameRules20: `Коли ставки, зроблені всіма гравцями, помножені на коефіцієнти, досягнуть 100,000 ${CURRENCY_SYMBOL} за один раунд, система автоматично виплатить усі активні ставки.`,
  GameRules21: "Мінімальна множник у грі становить 1.",
  GameRules22: "Найбільший множник у грі дорівнює 1,102,008.",
  GameRules23: "ВПГ (RTP) гри становить 95.05%.",
  GameRules24:
    "Мінімальний автоматичний кешаут складає x1,1, а максимальний автоматичний кешаут складає x1,102,008",
  GameRules25:
    "Мінімальний кешаут складає x1,01, а максимальний кешаут складає x1,102,008",
  GameRules26:
    "Гравець може знайти останні коефіцієнти гри у верхній частині сторінки.",
  GameRules27:
    "Переглянути рейтинги гравців можна, натиснувши на кнопку «Рейтинг».",
  GameRules28:
    "«Якщо коефіцієнт виведення коштів і коефіцієнт вильоту однакові, гравець виграє»",
  GameRules29:
    "Якщо проблема зі з'єднанням триває більше 10 секунд, ставка автоматично кешується. Якщо раунд завершиться раніше, ставка вважатиметься програною.",
  Save: "Зберегти",
  ServerConnection: "З’єднуємося з сервером",
  InternetConnectionLost:
    "Виникла проблема з інтернет-з’єднанням, будь ласка, оновіть сторінку.",
  ServerError: "Помилка сервера",
  SessionHasExpired: "Час сесії сплив",
  SitesUpdate: "ПЛАНОВЕ ТЕХОБСЛУГОВУВАННЯ!",
  Sound: "Звук",
  Statistics: "Статистика",
  StopIf: "ЗУПИНІТЬСЯ, ЯКЩО ЗРОБИЛИ СТАВКУ  >",
  Submit: "Готово ",
  SuccessfulRegistration: "Ставку зроблено",
  TechnicalWork: "Bаша гра скоро завантажиться.",
  TicketCashoutMustBe: "Мінімальна сума для виведення – 1.5",
  Time: "Час",
  TopSpins: "Коефіцієнти",
  TopWins: "Виграші",
  tr: "TR",
  UnknowError: "Помилка сервера",
  Unregister: "Скасувати ставку",
  UnregisterFreeBet: "Скасувати FreeBet",
  Unregistered: "Ставку скасовано",
  User: "Гравець",
  UserName: "Ім’я Гравеца",
  UserShort: "US",
  WaitingForNextRound: "В очікуванні наступного раунду",
  Win: "ВИГРАШ",
  WinAmount: "Віграш",
  WinMultiplier: "Виграно множ.",
  Winners: "Переможці",
  WrongAmount: "Неправильна кількість",
  WrongConfiguration: "Неправильна конфігурація",
  Year: "рік",
  Yes: "Так",
  YouAreNotRegistered: "Ви не зареєстровані в цій грі",
  EnterText: "Введіть текст",
  PlayerNotFound: "Гравець не знайдений",
  Jan: "Січ.",
  Feb: "Лют.",
  Mar: "Бер.",
  Apr: "Квіт.",
  May: "Трав",
  June: "Черв",
  July: "Лип",
  Aug: "Серп.",
  Sept: "Вер.",
  Oct: "Жовт.",
  Nov: "Лист.",
  Dec: "Груд.",
  BlockedUser: "Ваш акаунт заблокований",
  LoadingProblem: "Не вдалося завантажити гру, оновіть сторінку.",
  January: "Січень",
  February: "Лютий",
  March: "Березень",
  April: "Квітень",
  MayLong: "Травень",
  JuneLong: "Червень",
  JulyLong: "Липень",
  August: "Серпень",
  September: "Вересень",
  Octomber: "Жовтень",
  November: "Листопад",
  December: "Грудень",
  Auto: "Авто",
  Game: "Ставка",
  BetHistory: "Історія ставок",
  ViewTable: "Переглянути таблицю",
  TotalProfit: "Biграш",
  // TotalProfit: "Загальний вiграш",
  WeakInternetAlert: "Слабке підключення до Інтернету",
  PageWillReload: "Сторінка перезавантажиться",
  InSeconds: "Секунд",
};
