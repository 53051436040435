import React, { FC, useState } from "react";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";
import WebProfileSection from "../../features/profile/WebProfileSection";
import { MenuIcon } from "../../components/MenuIcon/MenuIcon";
import AppLogo from "../../components/AppLogo/AppLogo";
import {
  StyledHeader,
  HeaderLeft,
  Bets,
  HeaderRight,
  InfoSection,
} from "./Header.styles";
import { useCurrentBets } from "../../config/store/state/app.state";
import { useIsMobile } from "../../helpers/hooks/useIsMobile";
import ClickAwayListener from "../../helpers/hooks/clickawayListener";
import BetsModal from "../../features/profile/components/BetsModal/BetsModal";
import { PopupWrap, Blur } from "../../components/Popup/Popup.styles";
import FreeBetsContainer from "../../features/freeBets";

const Header: FC<{
  isOpen: boolean;
  toggle: () => void;
}> = ({ isOpen, toggle }) => {
  const translate = useTranslate();
  const currentBets = useCurrentBets();
  const isMobile = useIsMobile();

  const [modalOpen, setModalOpen] = useState({
    ProvablyFair: false,
    ProvablyFairData: false,
    GameRules: false,
    BetHistory: false,
  });

  const showHistory = () => {
    if (isMobile) {
      setModalOpen(() => ({
        ProvablyFair: false,
        GameRules: false,
        ProvablyFairData: false,
        BetHistory: true,
      }));
    }
  };

  return (
    <StyledHeader>
      <HeaderLeft>
        <MenuIcon isOpen={isOpen} onClick={toggle} />

        <InfoSection className={isOpen ? "disappear" : ""}>
          <Bets onClick={() => showHistory()}>{translate(KEYWORDS.Bets)}</Bets>
          
          {currentBets.length}
        </InfoSection>
      </HeaderLeft>

      <AppLogo />

      <PopupWrap
        className={`${
          Object.values(modalOpen).some((value) => value === true) ? "blur" : ""
        } ${modalOpen.BetHistory ? "bets" : ""}`}
      >
        <Blur
          onClick={() => {
            setModalOpen((state) => ({
              ...state,
              BetHistory: false,
            }));
          }}
        />
        {modalOpen.BetHistory && (
          <ClickAwayListener
            onClickAway={() =>
              setModalOpen((state) => ({
                ...state,
                BetHistory: false,
              }))
            }
          >
            <BetsModal
              close={() =>
                setModalOpen((state) => ({
                  ...state,
                  BetHistory: false,
                }))
              }
            />
          </ClickAwayListener>
        )}
      </PopupWrap>

      <HeaderRight>
        {!isMobile && <FreeBetsContainer />}
        <WebProfileSection />
      </HeaderRight>
    </StyledHeader>
  );
};

export default Header;
