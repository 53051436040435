import React, { memo } from 'react';
import moonX1 from '../../../assets/images/planets/x1.png';
import moonX2 from '../../../assets/images/planets/x2.png';
import moonX3 from '../../../assets/images/planets/x3.png';
import moonX4 from '../../../assets/images/planets/x4.png';
import moonX5 from '../../../assets/images/planets/x5.png';
import { SateliteSmall, X1 } from '../spaceship.styles';
import { useIsMobile } from '../../../helpers/hooks/useIsMobile';
import SateliteImage from '../../../assets/images/general/satelite.png';

const sw = window.innerWidth;
const sh = window.innerHeight;

const MoonsComp = () => {
    const isMobile = useIsMobile();
    const initialSmall = isMobile
        ? { scale: 0.5, x: sw / 0.8, y: -(sh / 3) } // { scale: 0.5, x: 120, y: -150 }
        : { scale: 0.5, x: sw / 2, y: -(sh / 2.8) };

    return (
        <>
            <X1
                initial={initialSmall}
                animate={{ scale: 1, x: -1600, y: 1000 }}
                transition={{ duration: 3 }}
            >
                <img src={moonX1} alt="moonx1" />
            </X1>

            <X1
                initial={{ scale: 0.5, x: sw / 2, y: -(sh / 1.6) }}
                animate={{ scale: 1, x: -1200, y: 1000 }}
                transition={{ duration: 4, delay: 6 }}
            >
                <img src={moonX2} alt="moonx2" />
            </X1>

            <X1
                initial={{ scale: 0.5, x: sw / 2, y: -(sh / 0.9) }}
                animate={{ scale: 1, x: -1700, y: 800 }}
                transition={{ duration: 4, delay: 9 }}
            >
                <img src={moonX3} alt="moonx3" />
            </X1>

            <X1
                initial={initialSmall}
                animate={{ scale: 1, x: -1600, y: 1200 }}
                transition={{
                    duration: 4,
                    delay: 11,
                }}
            >
                <img src={moonX4} alt="moonx4" />
            </X1>

            <X1
                initial={initialSmall}
                animate={{ scale: 1, x: -1800, y: 1000 }}
                transition={{
                    repeat: Infinity,
                    duration: 12,
                    delay: 5,
                }}
            >
                <img src={moonX5} alt="moonx5" />
            </X1>

            <SateliteSmall
                initial={initialSmall}
                animate={{ scale: 1, x: -1800, y: 1000 }}
                transition={{ duration: 3, delay: 5 }}
            >
                <img src={SateliteImage} alt="satelite" />
            </SateliteSmall>
        </>
    );
};

export default memo(MoonsComp);
