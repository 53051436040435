import React, { FC } from "react";
import { IconProps } from "./types";

export const Undo: FC<IconProps> = ({ className }) => {
  return (
    <svg
      //   width="24"
      //   height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7V9ZM5 8H4C4 8.55228 4.44772 9 5 9V8ZM6 3C6 2.44772 5.55228 2 5 2C4.44772 2 4 2.44772 4 3H6ZM10 7H5V9H10V7ZM6 8V3H4V8H6Z"
        fill="white"
      />
      <path
        d="M5.29102 16.3571C6.22284 17.792 7.59014 18.8904 9.19218 19.4909C10.7942 20.0915 12.5466 20.1627 14.1921 19.6939C15.8375 19.2252 17.2893 18.2413 18.3344 16.8867C19.3795 15.5321 19.9628 13.8781 19.9986 12.1675C20.0345 10.457 19.521 8.78004 18.5335 7.38284C17.5461 5.98564 16.1367 4.94181 14.5123 4.4046C12.8879 3.86738 11.1341 3.86509 9.50833 4.39805C7.88255 4.93101 6.47045 5.97114 5.47936 7.36575"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Undo;
