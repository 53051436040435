import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Avatar01 from "../../../../assets/images/avatars/avatar-1.png";
import Avatar02 from "../../../../assets/images/avatars/avatar-2.png";
import Avatar03 from "../../../../assets/images/avatars/avatar-3.png";
import Avatar04 from "../../../../assets/images/avatars/avatar-4.png";
import Avatar05 from "../../../../assets/images/avatars/avatar-5.png";
import Avatar06 from "../../../../assets/images/avatars/avatar-6.png";
import Avatar07 from "../../../../assets/images/avatars/avatar-7.png";
import Avatar08 from "../../../../assets/images/avatars/avatar-8.png";

const avatarsList = [
  { path: Avatar01, id: 0 },
  { path: Avatar02, id: 1 },
  { path: Avatar03, id: 2 },
  { path: Avatar04, id: 3 },
  { path: Avatar05, id: 4 },
  { path: Avatar06, id: 5 },
  { path: Avatar07, id: 6 },
  { path: Avatar08, id: 7 },
];

const AvatarContainer = styled.div<{ src: string }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  vertical-align: middle;
  background: url(${(props) => props.src}) no-repeat center center;
  background-size: cover;
  border-radius: 50%;

  @media (min-width: ${800}px) {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  @media (min-width: ${1366}px) {
    width: 38px;
    height: 38px;
    margin-right: 12px;
  }
`;

const Avatar: FC<{ avatarId?: string | number }> = ({ avatarId = 0 }) => {
  const [path, setPath] = useState(avatarsList[0].path);

  useEffect(() => {
    avatarsList.forEach((avatar) => {
      if (+avatarId === avatar.id) {
        setPath(avatar.path);
      }
    });
  }, [avatarId, path]);

  return <AvatarContainer src={path} />;
};

export default Avatar;
