import React, { FC } from "react";
import styled from "styled-components";

const StyledSidebar = styled.div`
  display: none;
  height: 100%;
  width: 189px;

  @media (min-width: ${800}px) {
    display: block;
    overflow-y: hidden;
  }

  @media (min-width: ${1366}px) {
    width: 386px;
  }

  @media (min-width: ${1920}px) {
    width: 454px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
    display: none;
  }
`;

const Sidebar: FC = ({ children }) => {
  return <StyledSidebar>{children}</StyledSidebar>;
};

export default Sidebar;
