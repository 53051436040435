import React, { FC } from "react";
import styled from "styled-components";
import useTranslate from "../../config/language";

const StyledNavigationItem = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  // height: 45px;
  // flex: 1;
  //   color: ${(props) => props.theme.colors.white};
  // opacity: ${(props) => (props.active ? 1 : 0.5)};
  // letter-spacing: 0;
  // font-size: 16px;
  // cursor: pointer;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // font-weight: bold;
  // position: relative;
  // font-family: ${(props) => props.theme.fonts.mediatorNarrowCaps};
  ${(props) => props.disabled && "pointer-events: none"};
`;

const Inner = styled.div``;

const NavigationItem: FC<{
  text: string;
  index: number;
  active: boolean;
  onClick(index: number): void;
  className?: string;
  innerStyle?: string;
  disabled?: boolean;
}> = ({ text, active, onClick, index, className, innerStyle, disabled }) => {
  const translate = useTranslate();

  return (
    <StyledNavigationItem
      className={className}
      active={active}
      onClick={() => !disabled && onClick(index)}
      disabled={disabled}
    >
      <Inner className={innerStyle}>{translate(text)}</Inner>
    </StyledNavigationItem>
  );
};

export default NavigationItem;
