import React, { FC } from "react";
import { IconProps } from "./types";

export const ChevronDown: FC<IconProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.62695 3.75L6.30137 6.25L8.97579 3.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronDown;
