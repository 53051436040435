import React, { FC } from 'react';
import styled from 'styled-components';
// import "./styles.css";

import { useGameConfigs } from '../../config/store/state/app.state';
import { StarsOne } from './Stars';

const StyledStarsContainer = styled.div<{ game?: boolean }>`
    ${props =>
        props.game
            ? `
    position: absolute;
  top: -100%;
  bottom: 0;
  left: -100%;
  overflow: hidden;
  transform: rotate(30deg);
  width: 300%;
  height: 300%;

  .moving-stars {
    position: relative;
  }

  @media (min-width: ${800}px) {
    right: 15px;
    left: -50%;
    width: 200%;
    top: -10%;
  }

    @media (min-width: ${1080}px) {
      left: -30%;
    }

    @media (min-width: ${1600}px) {
       left: -20%;
    }

  @media (min-width: ${1920}px) {
    left: -20%;

     .moving-stars.second {
       left: 40%;
     }
  }

  `
            : ''};
`;

const StarsContainer: FC<{ disabled?: boolean; game?: boolean }> = ({
    disabled,
    game,
}) => {
    const animate = useGameConfigs().animate;

    return (
        <StyledStarsContainer game={game}>
            {!disabled && animate ? (
                <div className="moving-stars">
                    <StarsOne animate />
                </div>
            ) : (
                <>
                    <StarsOne />
                </>
            )}
        </StyledStarsContainer>
    );
};

export default StarsContainer;
