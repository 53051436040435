import React, { FC } from "react";
import { TopMultipliers } from "../../../../constants/interfaces/TopMultipliers";
import { TOP_LIST_FILTER_INDEXES } from "../configs/tools";
import {
  formatTime,
  useFormatDate,
} from "../../../../helpers/functions/format-date";
import CheckGameHistory from "./CheckGameHistory";
import { TableRow, StyledTd, InnerSpan } from "./RenderOddsTableRows.styles";

const RenderOddsTableRows: FC<{
  items: TopMultipliers;
  time: TOP_LIST_FILTER_INDEXES;
}> = ({ items, time }): any => {
  const formatDate = useFormatDate();

  return (
    items &&
    items[time].map((game, index) => {
      return (
        <TableRow key={JSON.stringify(game + `${index}`)}>
          <StyledTd className="rating-date">
            <InnerSpan className="first">{`${formatDate(
              game.TimeStamp,
              true
            )} ${formatTime(game.TimeStamp)}`}</InnerSpan>
          </StyledTd>

          <StyledTd className="rating-coeffc">
            <InnerSpan>{game.BustedAt}x</InnerSpan>
          </StyledTd>

          <StyledTd className="rating-pf">
            <InnerSpan className="last">
              <CheckGameHistory game={game} />
            </InnerSpan>
          </StyledTd>
        </TableRow>
      );
    })
  );
};

export default RenderOddsTableRows;
