import styled from "styled-components";

export const Scroll = styled.div`
  padding-bottom: 15px;
  padding-right: 0;
  max-height: calc(88vh - 218px);
  overflow-y: auto;

  @media (min-width: ${1366}px) {
    padding-right: 0;
    padding-bottom: 30px;
  }
`;

export const Item = styled.div`
  margin-top: 28px;

  &.first {
    margin-top: 0;
  }

  .lock {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @media (min-width: ${1366}px) {
    margin-top: 48px;
  }
`;

export const Label = styled.div`
  color: #ffffff;
  margin-bottom: 13px;
  display: block;
  font-size: 12px;

  @media (min-width: ${1366}px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 18px;
  }
`;

export const Code = styled.div`
  position: relative;
  padding-left: 30px;

  .info-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .info-icon path {
    stroke: #6d5cce;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  @media (min-width: ${1366}px) {
    padding-left: 50px;
  }
`;

export const InfoText = styled.div`
  font-feature-settings: "case" off;
  font-size: 12px;
  line-height: 24px;
  background: transparent;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 0.3);
  width: 100%;

  @media (min-width: ${800}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const RoundLab = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  line-height: 12px;

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  &.bordered {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 12px;
    margin-top: 12px;
  }
`;

export const Key = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.3);

  &.coeff {
    color: #2eb550;
    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;
