import styled from "styled-components";

export const Info = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
`;

export const Text = styled.div`
  text-align: right;
  font-size: 10px;
  line-height: 12px;
  color: #2eb550;

  @media (min-width: ${800}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Balance = styled.div`
  color: #fff;
  font-feature-settings: "case" on;
`;

export const AvatarContainer = styled.div`
  cursor: pointer;
`;
