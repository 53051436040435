import styled from "styled-components";

export const StyledOddsList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-right: 59px;
  position: relative;

  // &.open-bar {
  //   padding-right: 46px;
  // }

  // @media (min-width: ${800}px) {
  //   &.open-bar {
  //     padding-right: 44px;
  //   }
  // }

  // @media (min-width: ${1366}px) {
  //   padding-right: 77px;

  //   &.open-bar {
  //     padding-right: 64px;
  //   }
  // }

  // @media (min-width: ${1920}px) {
  //   &.open-bar {
  //     padding-right: 61px;
  //   }
  // }
`;

export const Items = styled.div`
  min-height: 24px;
  width: 100%;
  position: relative;
  // text-align: justify;

  @media (min-width: ${1366}px) {
    min-height: 35px;
  }
`;

export const Wrap = styled.div`
  height: 25px;
  overflow: hidden;
  font-size: 0;
  // margin: 0 -3px;
  // border: 1px solid transparent;

  &.coeffc-wrap-abs {
    // padding: 0px 2px 5px;
    margin: 0;
    border: 1px solid #110e22;
    border-radius: 11px 0px 11px 11px;
    position: absolute;
    top: 0px;
    // right: 8px;
    background: #000000;
    z-index: 23;
    height: auto;
    overflow: visible;
    width: 100%;
    text-align: justify;
    min-height: 39px;
    padding-top: 8px;
  }

  @media (min-width: ${1366}px) {
    height: 35px;

    &.coeffc-wrap-abs {
      // padding: 0px 6px 12px;
      height: auto;
      padding-top: 12px;
    }
  }

  @media (min-width: ${1920}px) {
    &.coeffc-wrap-abs {
      // padding: 0px 8px 10px;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 46px;
  height: 26px;
  // position: absolute;
  // right: 0;
  // top: 0px;
  border: 1px solid #4c4c4c;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  padding: 0 6px;
  // text-align: right;
  cursor: pointer;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 51px;
  position: relative;

  &:hover {
    background: #17ae43;
  }

  .coeffc-ic-history-arrow {
    width: 5px;
    height: 8px;
    fill: #ffffff;
    position: absolute;
    left: 16px;
    top: 6px;
  }

  .coeffc-ic-history {
    width: 20px;
    height: 18px;
    fill: #ffffff;
  }

  .web-history {
    display: none;
  }

  .coeffc-ic-arrow {
    width: 12px;
    height: 12px;
    // position: absolute;
    // right: 4px;
    // top: 3px;

    // &.arrow-up {
    //   transform: rotate(180deg);
    //   top: 6px;
    //   right: 0;
    // }

    &.arrow-up {
      transform: rotate(180deg);
    }
  }

  @media (min-width: ${800}px) {
    // padding-right: 26px;
    // padding-top: 3px;

    .coeffc-ic-history-arrow {
      display: none;
    }

    .coeffc-ic-history {
      width: 13px;
      height: 13px;
      display: none;
    }

    .web-history {
      display: block;
      width: 13px;
      height: 13px;
    }

    // .coeffc-ic-arrow {
    //   width: 14px;
    //   height: 14px;
    //   // top: 3px;
    // }
  }

  @media (min-width: ${1366}px) {
    height: 35px;
    min-width: 70px;
    padding: 0 10px;

    // padding-right: 36px;
    // padding-left: 2px;
    // padding-top: 5px;

    .web-history {
      width: 22px;
      height: 22px;
    }

    // .coeffc-ic-history {
    //   width: 22px;
    //   height: 22px;
    // }

    .coeffc-ic-arrow {
      width: 24px;
      height: 24px;
      // top: 1px;
    }
  }

  @media (min-width: ${1920}px) {
    .web-history {
      width: 24px;
      height: 24px;
    }
  }
`;
