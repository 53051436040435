import styled from "styled-components";

export const StyledCurrentList = styled.div`
  // background: #0e0826;
  // border-radius: 8px;
  // position: relative;
  // margin-top: 7px;

  // @media (min-width: ${1366}px) {
  //   padding-top: 33px;
  //   margin-top: 15px;
  // }

  // @media (min-width: ${1920}px) {
  //   padding-top: 20px;
  //   margin-top: 28px;
  // }
`;

export const StyledScrollableTable = styled.div`
  max-height: calc(88vh - 198px);
  overflow-y: auto;
  position: relative;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 2px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  @media (min-width: ${800}px) {
    height: calc(100vh - 140px);
    max-height: none;
  }

  @media (min-width: ${1366}px) {
    height: calc(100vh - 200px);
  }
`;
