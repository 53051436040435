import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  color: white;
  font-family: "FiraGo", sans-serif;

  &.roboto {
    font-family: "Roboto", sans-serif;
  }

  &.montserrat {
    font-family: "Montserrat", sans-serif;
  }

  * {
    font-variant-numeric: tabular-nums;
  }

  @media (max-width: ${1366}px) {
    input[type=range]::-webkit-slider-thumb {
      width: 22px !important;
      height: 22px !important;
    }
  }
`;

export const InnerWrapper = styled.div<{
  landscapeWidth?: number;
  landscapeHeight?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;

  @media only screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
    width: ${(props) => `${props.landscapeWidth}px`};
    height: ${(props) => `${props.landscapeHeight}px`};
  }
`;

export const Body = styled.div`
  position: relative;
  padding: 0 8px 8px 8px;
  display: flex;
  flex: 1 1 0%;

  @media (min-width: ${800}px) {
    padding: 0 24px 24px 24px;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex: 1 1 0%;
  z-index: 2;
`;

export const LandscapeIconWrapper = styled.div`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(28px);
  width: 100%;
  height: 100%;
  z-index: 99;

  svg {
    position: absolute;
    transform: rotate(90deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  // tablet
  @media only screen and (min-device-width: 768px) and (orientation: portrait) {
    visibility: visible;
  }

  // mobile
  @media only screen and (min-device-width: 320px) and (max-device-width: 768px) and (orientation: landscape) {
    visibility: visible;
  }

  // Ipad air 5th 
  @media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {
    visibility: visible;
  }

  @media only screen 
  and (min-device-width: 819px) 
  and (max-device-width: 1112px) 
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {
    visibility: hidden;
  }
`;

export const EuropeBet = styled.div`
  height: 60px;
  background: #3f1d1d;
  text-align: center;
  color: red;
  line-height: 56px;

  @media (max-width: ${992}px) {
    height: 56px;
  }
`