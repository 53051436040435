import React, { FC } from "react";
import { Winnings } from "../../../../constants/interfaces/TopWinnings";
import TableAvatar from "../../components/TableAvatar";
import { formatUsername } from "../../../../helpers/functions/format-username";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
import { roundNumber } from "../../../../helpers/functions/round-number"
import { CurrencySymbol } from '../../../../constants/currency';

import { useProfile } from "../../../profile/configs/store/state";
import {
  StyledTopBoardActions,
  Left,
  AvatarContainer,
  Name,
  Center,
  InfoItem,
  Label,
  Value,
} from "./WinningTableItem.styles";

const WinningTableItem: FC<{
  game: Winnings;
}> = ({ game }) => {
  const translate = useTranslate();
  const { Player } = useProfile();

  const CURRENCY = CurrencySymbol()

  return (
    <StyledTopBoardActions>
      <Left>
        <AvatarContainer>
          <TableAvatar avatarId={game.Player.A} />
          <Name>
            {game.Player.I === Player.I
              ? game.Player.N
              : formatUsername(game.Player.N)}
          </Name>
        </AvatarContainer>
      </Left>

      <Center>
        <div>
          <InfoItem>
            <Label>{translate(KEYWORDS.Bet)}:</Label>
            <Value>
              {game.BuyIn}
              {CURRENCY}
            </Value>
          </InfoItem>

          <InfoItem>
            <Label>{translate(KEYWORDS.WinAmount)}:</Label>
            <Value className="profit-summary">
              {roundNumber(game.BuyIn * game.Multiplier)}
              {CURRENCY}
            </Value>
          </InfoItem>

          <InfoItem>
            <Label className="profit-green">X:</Label>
            <Value className="profit-green">{game.Multiplier}x</Value>
          </InfoItem>
        </div>
      </Center>

      {/* <Right>
        <Icon>
          <Security className="rating-profit-shield-check-ic" />
        </Icon>
        <Icon>
          <Warning className="rating-profit-warning-ic" />
        </Icon>
      </Right> */}
    </StyledTopBoardActions>
  );
};

export default WinningTableItem;
