import React from "react";
// import { Link } from "react-router-dom";
// import mobLogo from "../../assets/images/general/main-logo-mob.png";
import logo from "../../assets/images/general/logo.png";
import { StyledLink, Img } from "./AppLogo.styles";

const AppLogo = () => {
  return (
    <StyledLink>
      {/* <Link to={""} className="mob">
        <Img src={mobLogo} alt="mob-rokit" />
      </Link> */}

      {/* <Link to={"/"}> */}
      <Img src={logo} alt="rokit" />
      {/* </Link> */}
    </StyledLink>
  );
};

export default AppLogo;
