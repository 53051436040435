const CURRENCY_SYMBOL = '___';

export const georgian = {
  AccountBanned: "თქვენი ანგარიში დაბლოკილია!",
  AllBets: "ყველა ფსონი:",
  AlreadyRegistered: "თქვენ უკვე დადებული გაქვთ ფსონი",
  Animation: "ანიმაცია",
  AutoBet: "ავტომატური",
  AutoBetting: "ავტო ფსონი",
  AutoCashout: "ავტო ქეშაუთი",
  AutoCashouting: "ავტო განაღდება",
  Balance: "ბალანსი",
  BalanceShort: "ბალ",
  Banned: "თქვენ დაბლოკილი ხართ",
  BaseBet: "საწყისი ფსონი",
  Bet: "ფსონი",
  BetMaximum: `ბეთი უნდა იყოს მაქსიმუმ ${CURRENCY_SYMBOL}`,
  BetMustBe: "ბეთი უნდა იყოს მინიმუმ ",
  Bets: "ფსონები",
  Stakes: "ფსონები",
  BuyIn: "ფსონი",
  Cashout: "ქეშაუთი",
  CashoutFromGame: "ქეშაუთი",
  CashoutMaxMustBe: "ქეშაუთი უნდა იყოს მაქსიმუმ",
  CashoutMustBe: "ქეშაუთი უნდა იყოს მინუმუმ 1.01",
  ChangeAvatar: "ავატარის შეცვლა",
  CheckFair: "1. რა არის კოეფიციენტის გადამოწმება?",
  CheckHash: "3. როგორ გადავამოწმოთ სანდოობის კოდი?",
  CheckResult: "შედეგის შემოწმება",
  Circulation: "#",
  Clear: "გასუფთავება",
  Crashed: "კოეფ.",
  CurrentBets: "ფსონები",
  Day: "დღე",
  DearUsers: "ბოდიშს გიხდით შეფერხებისთვის",
  Error: "შეცდომა",
  ExampleForFair:
    "თამაშის ნომერი - 9088 თამაშის კოეფიციენტი - 1.06 გენერირების დრო - 12/03/2019_16:09 თამაშის უნიკალური ნომერი - Fvv+5TVHK2621A== შედეგად, თამაშის გასაღები იქნება: 9088_1.06_Rockit_12/03/2019_16:09_Fvv+5TVHK2621A== ხოლო მისგან SHA256 ალგორითმით გენერირებული სანდოობის კოდი: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d",
  Examples: "მაგალითად:",
  FairGame: "სამართლიანი თამაში",
  Fill: "შევსება",
  FreeBet: "ფრიბეთი",
  GameIsStopped: "თამაში შეჩერებულია",
  GameKey: "2. რას ნიშნავს სანდოობის კოდი ?",
  ServerConnectionTemp: "სერვერთან კავშირის აღდგენა",
  SameUser: "თამაში გახსნილია სხვა ტაბში",
  GameRules: "თამაშის წესები",
  GameStart: "თამაში იწყება",
  GameStarted: "თამაში დაიწყო, წარმატებები",
  GameStatusIsNotRegistering: "ფსონების მიღება დასრულებულია",
  GameWillResume: "თამაშის განახლებამდე დარჩა",
  GotoHome: "HOME",
  Hash: "Hash კოდი",
  History: "ისტორია",
  HowToCheckFair:
    "ეს მეთოდი საშუალებას გვაძლევს დავრწმუნდეთ, რომ თამაშის კოეფიციენტი (რიცხვი, რომელზეც 'ჩაიწვება' თამაში) განსაზღვრულია წინასწარ, სანამ მოთამაშეები დარეგისტრირდებიან. ნებისმიერ მომენტში წინასწარ განსაზღვრულია 5 თამაში. ქვემოთ მოყვანილია 5 გასული და 5 მომავალი თამაშის სანდოობის Hash კოდი.",
  HowToCheckGameKey:
    "თამაშების კოეფიციენტი (რიცხვი, რომელზეც 'ჩაიწვება' თამაში) განისაზღვრება წინასწარ, 5 თამაშით ადრე. თამაშის სერვერზე გენერირდება თამაშის გასაღები, რომელიც შედგება შემდეგი პარამეტრებისგან: [თამაშის ნომერი]_[თამაშის კოეფიციენტი]_[Rockit]_[გენერირების დრო]_[თამაშის უნიკალური ნომერი]",
  HowToCheckHash:
    "თამაშის დამთავრების შემდეგ ქვემოთ გამოჩნდება თამაშის გასაღები რომელიც შეგიძლიათ დაშიფროთ ნებისმიერ ონლაინ SHA256 კალკულატორზე და შემდეგ შეადაროთ სანდოობის კოდს, რომელიც წინასწარ არის მოცემული მომდევნო 5 თამაშზე.",
  IncreaseBy: "გაზარდე",
  Jackpot: "ჯეკპოტი",
  Key: "გასაღები",
  LanguageChangeAcception: "ნამდვილად გსურთ ენის შეცვლა?",
  LanguageChangeWarning: "ღილაკზე დაჭერით გაგეთიშებათ მიმდინარე თამაში",
  LastGames: "ბოლო თამაშები",
  LastHand: "წინა ხელი",
  LastTopSpins: "ბოლო 24 საათის ტოპ კოეფიციენტები",
  LastTopWins: "ბოლო 24 საათის ტოპ მოგებები",
  LeaveMessage: "დაწერეთ კომენტარი",
  Login: "შესვლა",
  LoginFailed: "შესვლისას დაფიქსირდა შეცდომა",
  Failed: "დაფიქსირდა შეცდომა",
  MainPage: "მთავარი გვერდი",
  ManualBet: "ძირითადი",
  MinFreebetCashout: "მინ. კოეფიციენტი 1.5",
  Month: "თვე",
  MultiplierCashout: "კოეფ. მოგება",
  Music: "მუსიკა",
  My: "ჩემი",
  No: "არა",
  NotEnoughMoney: "არასაკმარისი თანხა",
  OnLoss: "წაგებისას",
  OnWin: "მოგებისას",
  OpenHere: "გახსენი აქ",
  PlaceYourBet: "მიმდინარეობს ფსონების მიღება",
  Player: "მოთამაშე",
  Rating: "რეიტინგი",
  Register: "ფსონის დადება",
  Registration: "მიმდინარეობს ფსონის მიღება",
  Reload: "განახლება",
  Result: "შედეგი",
  ResultsHash: "შედეგის ჰეშ კოდი",
  ReturnToBaseBet: "დაბრუნდი საწყის ფსონზე",
  ReturnToCash: "Cash-ზე დაბრუნება",
  Round: "რაუნდი",
  GameRules1:
    "თამაშში მოთამაშეებს აქვთ საშუალება განათავსონ ფსონი რაუნდის დაწყებამდე.",
  GameRules2:
    "თამაშის დაწყებისას კოეფიცინეტი იწყებს ზრდას, მანამ სანამ არ ჩაიწვება.",
  GameRules3: "ჩაწვის წერტილი გენერირდება შემთხვევითობის პრინციპით.",
  GameRules4: "მოთამაშემ უნდა მოასწროს საკუთარი მოგების განაღდება ჩაწვამდე.",
  GameRules5: "ჩაწვის მომენტში გაუნაღდებელი ფსონები ითვლება წაგებულად",
  GameRules6:
    "ჩაწვამდე განაღდებისას, მოთამაშის განთავსებული ფსონი მრავლდება იმ კოეფიციენტზე რა მომენტშიც მოხდა ფსონის განაღდება.",
  GameRules7: `კოეფიციიენტის ზრდა იწყება 1.0-დან. 1.0-ზე ჩაწვის შემთხვევაში ყველა ფსონი ითვლება წაგებულად. მაგ. მოთამაშემ განათავსა ფსონი 5${CURRENCY_SYMBOL} და 2.0 კოეფიციენტზე თანხა გაანაღდა, მას დაერიცხება 2*5=10${CURRENCY_SYMBOL}. თუ კოეფიციენტი ჩაიწვება 2.0-ზე ადრე, მაგალითად 1.75-ზე, ამ შემთხვევაში მოთამაშე წააგებს მთლიან ბეთს 5${CURRENCY_SYMBOL}-ს.`,
  GameRules8:
    "თამაშის დატოვების შემთხვევაში, მოხდება ფსონის ავტომატური დაქეშაუთება, ფსონის და იმ მომენტში არსებული სიმაღლის კოეფიციენტის ნამრავლის მიხედვით. თამაშის დატოვებად ჩაითვლება ნებისმიერი მიზეზით თამაშის სესიის შეწყვეტა.",
  GameRules9:
    "მოთამაშეს შეუძლია ერთდროულად ორი ფსონის დადება და მათი ცალ-ცალკე დაქეშაუთება.",
  GameRules10:
    "მოთამაშეს შეუძლია დადოს ფსონი სასურველი პირობებით, ავტომატური ფსონის დადების საშუალებით. არსებულ ფანჯარაში შესაძლებელია ფსონის ოდენობის, განაღდების კოეფიციენტის, განაღდების მაქსიმალური ოდენობის განსაზღვრა. ასევე შემდეგი ფსონის პირობების წინასწარი განსაზღვრა მოგების ან წაგების შემთხვევაში.",
  GameRules11: "მოგება ავტომატურად აისახება მოთამაშის ბალანსზე.",
  GameRules12:
    "მოთამაშეს შეუძლია ნახოს თავისი თამაშის ისტორია ღილაკზე „ჩემი“ დაკლიკებით.",
  GameRules13:
    "თამაშში დადასტურებული ხარვეზის აღმოჩენის შემთხვევაში, თამაშის ორგანიზატორი უფლებას იტოვებს, მომხმარებელს დაუკავოს ან დაუკორექტიროს სავარაუდო მოგება.",
  GameRules16: "თამაშის ლიმიტები:",
  GameRules17: `მინიმალური ფსონი - 0.10${CURRENCY_SYMBOL}`,
  GameRules18: `მაქსიმალური ფსონი - 200${CURRENCY_SYMBOL}`,
  GameRules19: `მოთამაშის მაქსიმალური მოგება (ფსონი*კოეფიციენტი) – 30,000${CURRENCY_SYMBOL}, როდესაც ფსონი*კოეფიციენტი მიაღწევს 30,000${CURRENCY_SYMBOL}-ს, სისტემა ავტომატურად გაანაღდებს აქტიურ ფსონს.`,
  GameRules20: `ყველა მოთმაშის ჯამური მაქსიმალური მოგება რაუნდზე - 100,000${CURRENCY_SYMBOL}. როდესაც ერთ რაუნდზე, ყველა მოთამაშის განთავსებული ფსონი*კოეფიციენტი მიაღწევს 100,000${CURRENCY_SYMBOL}-ს, სისტემა ატომატურად გაანაღდებს ყველა აქტიურ ფსონს.`,
  GameRules21: "თამაშის მინიმალური მულტიმოთამაშე არის 1.",
  GameRules22: "თამაშის მაქსიმალური კოეფიციენტი არის 1,102,008.",
  GameRules23: "თამაშის RTP არის 95.05%",
  GameRules24:
    "ავტომატური განაღდების მინიმალური მულტიპლიკატორი არის x1.1, ხოლო ავტომატური განაღდების მაქსიმალური მულტიპლიკატორი არის x1,102,008.",
  GameRules25:
    "განაღდების მინიმალური მულტიპლიკატორი არის x1.01, ხოლო განაღდების მაქსიმალური მულტიპლიკატორი არის x1,102,008.",
  GameRules26:
    "მოთამაშეს შეუძლია ნახოს თამაშის ბოლო კოეფიციენტები თამაშის ფანჯრის ზედა სექციაში.",
  GameRules27:
    "მოთამაშეს შეუძლია ნახოს რეიტინგის ინფორმაცია, ღილაკზე „რეიტინგი“ დაკლიკებით.",
  GameRules28:
    "იმ შემთხვევაში, თუ გამოტანისა და შეჯახების კოეფიციენტები ტოლია, მოთამაშე იგებს.",
  GameRules29:
    "იმ შემთხვევაში თუ მომხმარებლის მხარეს, 10 წამზე მეტი ხნის განმავლობაშ, დაფიქსრიდა ინტერნეტის წყვეტის პრობლემა, ფსონი ავტომატურად დაქეშაუთდება. თუ ამ დროის განმავლობაში რაუნდი დასრულდება, ფსონი ჩაითვლება წაგებულად.",
  Save: "შენახვა",
  ServerConnection: "სერვერთან კავშირის აღდგენა",
  InternetConnectionLost:
    "დაფიქსირდა ინტერნეტთან კავშირის პრობლება, გთხოვთ გადატვირთეთ გვერდი.",
  ServerError: "სერვერთან დაკავშირებისას მოხდა შეცდომა",
  SessionHasExpired: "სესიას ვადა გაუვიდა",
  SitesUpdate: "მიმდინარეობს ტექნიკური სამუშაოები!",
  Sound: "ხმა",
  Statistics: "სტატისტიკა",
  StopIf: "გაჩერდი თუ ფსონი >",
  Submit: "გაგზავნა",
  SuccessfulRegistration: "ფსონი მიღებულია",
  TechnicalWork: "თამაშის უწყვეტი ფუნქციონირება მალე აღდგება.",
  TicketCashoutMustBe: "ქეშაუთი უნდა იყოს მინუმუმ 1.5",
  Time: "დრო",
  TopSpins: "ტოპ X",
  TopWins: "მოგებები",
  UnknowError: "სერვერის შეცდომა",
  Unregister: "ფსონის გაუქმება",
  UnregisterFreeBet: "ფრიბეთის გაუქმება",
  Unregistered: "ფსონი გაუქმებულია",
  User: "მომხმარებელი",
  UserName: "სახელი",
  UserShort: "მომ",
  WaitingForNextRound: "ველოდებით შემდეგ რაუნდს",
  Win: "მოგება",
  WinAmount: "მოგება",
  WinMultiplier: "მოგ. კოეფ.",
  Winners: "გამარჯვებულები",
  WrongAmount: "არასწორი თანხა",
  WrongConfiguration: "არასწორი კონფიგურაცია",
  Year: "წელი",
  Yes: "კი",
  YouAreNotRegistered: "თქვენ არ ხართ დარეგისტრირებული",
  EnterText: "შეიყვანეთ ტექსტი",
  PlayerNotFound: "მოთამაშე არ მოიძებნა",
  Jan: "იან.",
  Feb: "თებ.",
  Mar: "მარ.",
  Apr: "აპრ.",
  May: "მაის.",
  June: "ივნ.",
  July: "ივლ.",
  Aug: "აგვ.",
  Sept: "სექ.",
  Oct: "ოქტ.",
  Nov: "ნოე.",
  Dec: "დეკ.",
  BlockedUser: "თქვენი მომხმარებელი ბლოკირებულია",
  LoadingProblem: "თამაში ვერ ჩაიტვირთა, გთხოვთ გადავირთოთ გვერდი.",
  January: "იანვარი",
  February: "თებერვალი",
  March: "მარტი",
  April: "აპრილი",
  MayLong: "მაისი",
  JuneLong: "ივნისი",
  JulyLong: "ივლისი",
  August: "აგვისტო",
  September: "სექტემბერი",
  Octomber: "ოქტომბერი",
  November: "ნოემბერი",
  December: "დეკემბერი",
  Auto: "ავტო",
  Game: "თამაში",
  BetHistory: "ფსონების ისტორია",
  ViewTable: "ცხრილის ნახვა",
  TotalProfit: "ჯამური მოგება",
  Table: "ცხრილი",
  WeakInternetAlert: "სუსტი ინტერნეტის კავშირი",
  PageWillReload: "გვერდი გადაიტვირთება",
  InSeconds: "წამში",
  en: "EN",
  ka: "KA",
  ua: "UA",
  tr: "TR",
};
