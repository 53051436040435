import styled from "styled-components";
import NavigationItem from "../../../components/Navigation/NavigationItem";
import Navigation from "../../../components/Navigation/Navigation";

export const StyledNavigation = styled(Navigation)`
  font-size: 0;
  list-style: none;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  // font-family: "helvetica-neue-lt-geo-roman";
  font-feature-settings: "case" on;

  @media (min-width: ${800}px) {
    margin: 6px 5px 0 -4px;
  }

  @media (min-width: ${1366}px) {
    // border-bottom: 2px solid #1b1e1e;
  }
`;

export const StyledNavigationItem = styled(NavigationItem)<{ active: boolean }>`
  display: inline-block;
  width: 33.3%;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  opacity: 0.5;
  margin-bottom: -1px;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-feature-settings: "case" on;
  text-transform: uppercase;

  &:hover {
    opacity: 1;
    cursor: pointer;
    font-weight: 500;
  }

  .bets-nav-link {
    display: block;
    padding: 7px;
    color: #ffffff;
  }

  @media (min-width: ${800}px) {
    font-size: 10px;
    line-height: 12px;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    .bets-nav-link {
      display: inline-block;
      padding: 2px;
    }
  }

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 18px;
    border-bottom: 2px solid #1b1e1e;

    .bets-nav-link {
      display: block;
      padding: 2px 4px 9px 4px;
    }
  }

  ${(props) =>
    props.active &&
    `
    opacity: 1;
	border-bottom: 1px solid rgba(109, 92, 206, 1);
  font-weight: 500;

  @media (min-width: ${800}px) {
  border-bottom: 0px solid rgba(109, 92, 206, 1);

  // .bets-nav-link {
	// 	border-bottom: 1px solid rgba(109, 92, 206, 1);
	// }
  }

  @media (min-width: ${1366}px) {
   border-bottom: 2px solid #6D5CCE;

    // .bets-nav-link {
    //   border-bottom: 0;
    // }
  }
    `};
`;
