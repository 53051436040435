import React, { FC } from 'react';
import Close from '../Icons/Close';
import {
    PopupWrap,
    Head,
    Title,
    CloseWrapper,
    Content,
} from './PagePopup.styles';

interface Props {
    title: string;
    icon?: JSX.Element;
    back?: JSX.Element;
    round?: boolean;
    pf?: boolean;
    close: () => void;
}

const PagePopup: FC<Props> = ({
    title,
    icon,
    back,
    round,
    pf,
    close,
    children,
}) => {
    return (
        <PopupWrap
            className={`${round ? 'round-popup' : ''} ${pf ? 'pf' : ''}`}
            height={window.innerHeight}
        >
            <Head className={`${round ? 'round-head' : ''} ${pf ? 'pf' : ''}`}>
                {back && back}
                {icon && icon}

                <Title className={round ? 'round-title' : ''}>{title}</Title>
                <CloseWrapper onClick={close}>
                    <Close className="popup-close-ic" />
                </CloseWrapper>
            </Head>

            <Content>{children}</Content>
        </PopupWrap>
    );
};

export default PagePopup;
