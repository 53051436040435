import { InitializeBetsParams } from "./store/actions";
import { ListStatus } from "../../../../constants/interfaces/List";
import { CashedOutPlayerParams } from "../../../../config/store/actions/app.actions";
import { roundNumber } from "../../../../helpers/functions/round-number";

export const mergeBets = (
  lastBets: InitializeBetsParams[],
  lastBetsCashedOut: InitializeBetsParams[]
) => {
  return lastBets
    .map(
      (bets) =>
        lastBetsCashedOut.find(
          (coBets) =>
            coBets.PanelIndex === bets.PanelIndex &&
            coBets.Player.I === bets.Player.I
        ) || bets
    )
    .concat(
      lastBetsCashedOut.filter(
        (coBets) =>
          !lastBets.find(
            (bets) =>
              coBets.PanelIndex === bets.PanelIndex &&
              coBets.Player.I === bets.Player.I
          )
      )
    );
};

export const mapCurrentBetsValues = (
  lastBets: InitializeBetsParams[],
  lastBetsCashedOut: InitializeBetsParams[]
) => {
  const lastGamePlayers = mergeBets(lastBets, lastBetsCashedOut);

  return lastGamePlayers.map((bet) => {
    return {
      username: bet.Player.N,
      avatar: bet.Player.A,
      id: bet.Player.I,
      bet: bet.BuyIn,
      status: bet.Multiplier ? ListStatus.WON : ListStatus.DEFAULT,
      odds: bet.Multiplier,
      profit: bet.Multiplier && roundNumber(bet.Multiplier * bet.BuyIn),
      PanelIndex: bet.PanelIndex,
    };
  });
};

export const mapLastBetsValues = (
  lastBets: InitializeBetsParams[],
  lastBetsCashedOut: InitializeBetsParams[]
) => {
  const lastGamePlayers = mergeBets(lastBets, lastBetsCashedOut);

  return lastGamePlayers.map((bet) => {
    return {
      username: bet.Player.N,
      avatar: bet.Player.A,
      id: bet.Player.I,
      bet: bet.BuyIn,
      status: bet.Multiplier ? ListStatus.WON : ListStatus.LOST,
      odds: bet.Multiplier,
      profit: bet.Multiplier && Number(roundNumber(bet.Multiplier * bet.BuyIn)),
      PanelIndex: bet.PanelIndex,
    };
  });
};

export const calculateCashedOutAmount = (player: CashedOutPlayerParams) => {
  return player.CashOut || roundNumber(player.Multiplier * player.BuyIn);
};
