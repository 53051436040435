import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Plus from '../../../../components/Icons/Plus';
import Minus from '../../../../components/Icons/Minus';
import {
    useCashOutService,
    usePendingService,
    useRegisterService,
    useUnregisterLocalService,
    useUnregisterService,
} from '../../../../config/store/services';
import {
    RegisterState,
    useBoards,
    useErrorsState,
    useGameStatusState,
    usePanelState,
} from '../../../../config/store/state/app.state';
import { GAME_STATE } from '../../../../constants/interfaces/Game';
import RegisterPanel from './panels/RegisterPanel';
import UnregisterPanel from './panels/UnregisterPanel';
import CashOutPanel from './panels/CashOutPanel';
import AutoBetPanel from './AutobetPanel';
import SelectButtonActions from './components/SelectButtonActions';
import { useBalance } from '../../../profile/configs/store/state';
import { useMessengerState } from '../../../chat/store/state';
import {
    calculateMultiplierGrow,
    getFinalMultiplier,
    MULTIPLIER_DELAY,
} from '../../../game/components/GameMultiplier';
import { KEYWORDS } from '../../../../config/language/keywords';
import { useShowMessage } from '../../../errorMessages/store/services';
import { useMixpanelServices } from '../../../../services/mixpanel/MixpanelServices';
import { EMixpanelEvent } from '../../../../services/mixpanel/types';

const StyledActionBoard = styled.div<{ isChatOpen: boolean }>`
    padding: 0 16px;
    max-width: 50%;
    flex: 1;
    box-sizing: border-box;
    margin-bottom: 8px;
    &:nth-child(2) {
        border-left: 1px solid #ffffff30;
    }

    @media (max-height: ${850}px) {
        max-width: 50%;
    }

    @media (max-width: ${1500}px) {
        max-width: 60%;
    }

    @media (max-height: ${500}px), (max-width: ${1000}px) {
        max-width: 80%;
    }

    @media (max-width: ${700}px) {
        max-width: 100%;
        padding: 0;
        &:nth-child(2) {
            border-left: none;
        }
    }

    ${({ isChatOpen, theme }) =>
        isChatOpen &&
        `
    @media only screen and (max-width: ${900}px) and (min-width: ${
            theme.breaks.mobile
        }px) {
            max-width: 100%;
            padding: 0;
            &:nth-child(2) {
                border-left: none;
            }
        }

    @media only screen and (max-width: ${1220}px) and (min-width: ${1000}px) {
        max-width: 100%;
        padding: 0;
        &:nth-child(2) {
            border-left: none;
        }
    }
  `}
`;

const SecondRow = styled.div`
    display: flex;
    margin-bottom: 10px;
    height: 68px;
    @media (max-height: ${550}px) {
        height: 55px;
        margin-bottom: 6px;
    }
`;

const ThirdRow = styled.div`
    display: flex;
    height: 54px;
    @media (max-height: ${550}px), (max-width: ${1100}px) {
        height: 45px;
    }
`;

const MIN_VALUE = 0.1;
const MAX_VALUE = 200;

const MIN_AUTO_CASH_OUT_VALUE = 1.5;
const MAX_AUTO_CASH_OUT_VALUE = 50000;

const ActionBoard: FC<{
    activeBoards: number;
    boardIndex: number;
    BalanceType: number;
    addBoard(): void;
    removeBoard(): void;
    delay?: number;
}> = ({
    activeBoards,
    boardIndex,
    BalanceType,
    addBoard,
    removeBoard,
    delay = 0,
}) => {
    const displayAdd = activeBoards < 2;
    const state = usePanelState().filter(
        board => board.PanelIndex === boardIndex
    )[0];
    const gameStatus = useGameStatusState();
    const multiplier = gameStatus ? gameStatus.multiplier || 1 : 1;
    const registerService = useRegisterService();
    const pendingService = usePendingService();
    const unregisterService = useUnregisterService();
    const unregisterLocalService = useUnregisterLocalService();
    const cashOutService = useCashOutService();
    const registerState = state && state.registerState;

    const [amount, setAmount] = useState<number>(1);
    const [autoBet, setAutoBet] = useState(false);
    const [autoBetEnabled, setAutoBetEnabled] = useState(false);

    const [enableAutoCashOut, setEnableAutoCashOut] = useState(false);
    const [autoCashOut, setAutoCashOut] = useState(2);

    const showMessage = useShowMessage();
    const balance = useBalance();

    const handleRegister = useCallback(() => {
        registerService({
            BuyIn: amount,
            AutoCashoutOn: enableAutoCashOut ? autoCashOut : null,
            IsAutoBet: autoBet,
            PanelIndex: boardIndex,
            BalanceType: BalanceType,
        });
    }, [
        registerService,
        amount,
        enableAutoCashOut,
        autoCashOut,
        autoBet,
        boardIndex,
        BalanceType,
    ]);

    const handlePending = useCallback(() => {
        pendingService({
            BuyIn: amount,
            AutoCashoutOn: enableAutoCashOut ? autoCashOut : null,
            IsAutoBet: autoBet,
            PanelIndex: boardIndex,
            BalanceType: BalanceType,
        });
    }, [
        amount,
        enableAutoCashOut,
        autoCashOut,
        autoBet,
        boardIndex,
        BalanceType,
        pendingService,
    ]);

    const handleInitRegister = useCallback(() => {
        if (!balance.FreeSpinBalance || balance.FreeSpinBalance.length < 1) {
            showMessage(KEYWORDS.NotEnoughMoney, 'error');
            if (autoBet) {
                setAutoBetEnabled(false);
            }
            return;
        }
        if (gameStatus && gameStatus.state !== GAME_STATE.COUNTDOWN) {
            handlePending();
        } else {
            handleRegister();
        }
    }, [
        autoBet,
        balance.FreeSpinBalance,
        gameStatus,
        handlePending,
        handleRegister,
        showMessage,
    ]);

    const handleClickRegister = useCallback(() => {
        if (autoBet) {
            setAutoBetEnabled(true);
        }

        handleInitRegister();
    }, [autoBet, setAutoBetEnabled, handleInitRegister]);

    const handleClickUnregister = useCallback(() => {
        if (autoBetEnabled) {
            setAutoBetEnabled(false);
        }
        if (registerState === RegisterState.PENDING) {
            unregisterLocalService({
                PanelIndex: boardIndex,
            });
        } else {
            unregisterService({
                PanelIndex: boardIndex,
            });
        }
    }, [
        boardIndex,
        autoBetEnabled,
        setAutoBetEnabled,
        registerState,
        unregisterLocalService,
        unregisterService,
    ]);

    const { track } = useMixpanelServices();

    const handleClickCashOut = useCallback(() => {
        track(EMixpanelEvent.cashout, {
            Board: 'FreeBet',
            Multiplier: multiplier,
            BetAmount: amount,
            Won: amount * multiplier,
        });
        cashOutService({
            PanelIndex: boardIndex,
            Won: amount * multiplier,
            Multiplier: multiplier,
        });
    }, [track, multiplier, amount, cashOutService, boardIndex]);

    const handleValueChange = useCallback(
        (value: number | null) => {
            if (!value) return;
            if (value < MIN_VALUE) {
                setAmount(MIN_VALUE);
                return;
            }
            if (value > MAX_VALUE) {
                setAmount(MAX_VALUE);
                return;
            }
            setAmount(value);
        },
        [setAmount]
    );

    const handleAutoCashOutValueChange = useCallback(
        (value: number | null) => {
            if (!value) return;
            if (value < MIN_AUTO_CASH_OUT_VALUE) {
                setAutoCashOut(MIN_AUTO_CASH_OUT_VALUE);
                return;
            }
            if (value > MAX_AUTO_CASH_OUT_VALUE) {
                setAutoCashOut(MAX_AUTO_CASH_OUT_VALUE);
                return;
            }
            setAutoCashOut(value);
        },
        [setAutoCashOut]
    );

    const boards = useBoards();

    // Reset amount to next possible value if current
    // amount of free bet balance is unavailable
    useEffect(() => {
        const boardRegisterState = boards.filter(
            board => board.PanelIndex === boardIndex
        )[0].registerState;
        if (boardRegisterState === RegisterState.UNREGISTERED) {
            if (
                balance.FreeSpinBalance &&
                balance.FreeSpinBalance.filter(
                    freeBet => freeBet.Amount === amount
                ).length < 1 &&
                balance.FreeSpinBalance.length > 0
            ) {
                setAmount(balance.FreeSpinBalance[0].Amount);
            }
        }
    }, [amount, balance.FreeSpinBalance, boardIndex, boards]);

    // Handle auto bet
    useEffect(() => {
        let timer: any;
        if (state.registerState && gameStatus.state) {
            if (
                state.registerState === RegisterState.PENDING &&
                gameStatus.state === GAME_STATE.COUNTDOWN
            ) {
                timer = setTimeout(() => {
                    handleRegister();
                }, delay);
            }
            if (
                state.registerState === RegisterState.UNREGISTERED &&
                autoBetEnabled
            ) {
                if (
                    balance.FreeSpinBalance &&
                    balance.FreeSpinBalance.filter(
                        freeBet => freeBet.Amount === amount
                    ).length < 1 &&
                    balance.FreeSpinBalance.length > 0
                ) {
                    setAmount(balance.FreeSpinBalance[0].Amount);
                    setAutoBet(false);
                    setAutoBetEnabled(false);
                } else {
                    handleInitRegister();
                }
            }
        }

        return () => {
            clearTimeout(timer);
        };
    }, [
        handleInitRegister,
        state.registerState,
        gameStatus.state,
        handleRegister,
        autoBetEnabled,
        balance,
        amount,
        boardIndex,
        delay,
    ]);

    // BEGIN TEMP LOCAL CALCULATIONS
    const gameError = useErrorsState().isError;
    const gameState = useGameStatusState().state;
    const gameCrushed = gameState === GAME_STATE.CRUSHED;

    const [localMultiplier, setLocalMultiplier] = useState(1);

    useEffect(() => {
        setLocalMultiplier(multiplier || 1);
    }, [multiplier]);

    useEffect(() => {
        if (gameState === GAME_STATE.RUNNING) {
            const interval = setInterval(() => {
                setLocalMultiplier(prevValue =>
                    calculateMultiplierGrow(prevValue)
                );
            }, MULTIPLIER_DELAY);

            return () => {
                clearInterval(interval);
            };
        }
    }, [multiplier, gameState, setLocalMultiplier]);

    let finalMultiplayer = getFinalMultiplier(
        gameCrushed,
        gameError,
        localMultiplier,
        multiplier
    );
    // END TEMP LOCAL CALCULATIONS

    const handleRegisterStateView = () => {
        switch (registerState) {
            case RegisterState.UNREGISTERED: {
                return (
                    <RegisterPanel
                        amount={amount}
                        setAmount={handleValueChange}
                        toggleBoard={displayAdd ? addBoard : removeBoard}
                        handleRegister={handleClickRegister}
                        icon={displayAdd ? <Plus /> : <Minus />}
                        minValue={MIN_VALUE}
                        maxValue={MAX_VALUE}
                    />
                );
            }
            case RegisterState.REGISTER_INITIATED:
            case RegisterState.CASH_OUT_INITIATED:
            case RegisterState.REGISTERED: {
                if (gameStatus.state === GAME_STATE.COUNTDOWN) {
                    return (
                        <UnregisterPanel
                            amount={amount}
                            disabled={
                                registerState ===
                                RegisterState.REGISTER_INITIATED
                            }
                            handleUnregister={handleClickUnregister}
                        />
                    );
                } else {
                    return (
                        <CashOutPanel
                            amount={amount * finalMultiplayer}
                            multiplier={multiplier}
                            disabled={
                                registerState ===
                                RegisterState.CASH_OUT_INITIATED
                            }
                            handleCashOut={handleClickCashOut}
                        />
                    );
                }
            }
            case RegisterState.UNREGISTER_INITIATED:
            case RegisterState.PENDING: {
                return (
                    <UnregisterPanel
                        amount={amount}
                        disabled={
                            registerState === RegisterState.UNREGISTER_INITIATED
                        }
                        handleUnregister={handleClickUnregister}
                    />
                );
            }
            default: {
                return (
                    <RegisterPanel
                        amount={amount}
                        setAmount={handleValueChange}
                        toggleBoard={displayAdd ? addBoard : removeBoard}
                        handleRegister={handleClickRegister}
                        icon={displayAdd ? <Plus /> : <Minus />}
                    />
                );
            }
        }
    };

    const disableActions = registerState !== RegisterState.UNREGISTERED;
    const isChatOpen = useMessengerState().open;

    return (
        <StyledActionBoard isChatOpen={isChatOpen}>
            <SelectButtonActions
                disabled={disableActions}
                amount={amount}
                onChange={handleValueChange}
            />
            <SecondRow>{handleRegisterStateView()}</SecondRow>
            <ThirdRow>
                <AutoBetPanel
                    autoBet={autoBet}
                    setAutoBet={setAutoBet}
                    enableAutoCashOut={enableAutoCashOut}
                    setEnableAutoCashOut={setEnableAutoCashOut}
                    autoCashOut={autoCashOut}
                    setAutoCashOut={handleAutoCashOutValueChange}
                    minValue={MIN_AUTO_CASH_OUT_VALUE}
                    maxValue={MAX_AUTO_CASH_OUT_VALUE}
                    panelDisabled={disableActions}
                />
            </ThirdRow>
        </StyledActionBoard>
    );
};

export default ActionBoard;
