import styled from "styled-components";

export const Container = styled.div`
  margin-top: 32px;

  @media (min-width: ${800}px) {
    margin-top: 12px;
  }

  @media (min-width: ${1366}px) {
    margin-top: 24px;
  }
`;

export const Head = styled.div`
  padding: 0 15px;
  margin: 15px 0 6px 0;
  display: flex;
  justify-content: space-between;
  font-feature-settings: "case" off;

  @media (min-width: ${800}px) {
    margin-bottom: 9px;
    padding: 4px 6px 0;
  }

  @media (min-width: ${1366}px) {
    padding: 0px 17px 0;

    margin-bottom: 21px;
    padding-left: 23px;
  }

  @media (min-width: ${1920}px) {
    margin-bottom: 33px;
  }
`;

export const StyledDate = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-top: 0 !important;

  @media (min-width: ${800}px) {
    font-size: 8px;
    line-height: 10px;
    padding-top: 3px;
  }

  @media (min-width: ${1366}px) {
    font-size: 18px;
    line-height: 21px;
    padding-top: 0;
  }
`;

export const Summery = styled.div`
  padding-top: 3px;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  text-shadow: 0px 4px 4px rgba(46, 181, 80, 0.34);

  @media (min-width: ${800}px) {
    font-size: 8px;
    line-height: 10px;
  }

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 17px;
    text-shadow: 0px 4px 4px rgba(46, 181, 80, 0.34);
    padding-top: 0;
  }
`;

export const Currency = styled.div`
  color: #2eb550;
  margin-top: 0 !important;
`;

export const TableWrap = styled.div`
  .table-bets {
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    border-spacing: 0;
    border: 0;
    border-collapse: collapse;
    text-align: center;
  }

  @media (min-width: ${800}px) {
    .table-bets {
      font-size: 8px;
      line-height: 10px;
    }
  }

  @media (min-width: ${1366}px) {
    .table-bets {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const Table = styled.div``;

export const TableHead = styled.div``;

export const TableHeaderRow = styled.div`
  display: flex;
`;

export const TableHeaderCell = styled.div`
  border: 0;
  border-collapse: collapse;
  color: #ffffff;
  padding-bottom: 12px;
  font-weight: normal;
  font-feature-settings: "case" off;
  margin-top: 0 !important;

  &:nth-child(1) {
    padding-left: 14px;
    text-align: left;
  }

  &:nth-child(4) {
    padding-right: 17px;
    text-align: right;
  }

  &.bets-my-date {
    width: 25%;
  }

  &.bets-my-bet {
    width: 27%;
  }

  &.bets-my-coeffc {
    width: 24%;
  }

  &.bets-my-profit {
    width: 22%;
  }

  @media (min-width: ${800}px) {
    padding-bottom: 4px;

    &:nth-child(1) {
      padding-left: 4px;

      &:after {
        display: inline-block;
        content: ".";
        vertical-align: top;
      }
    }

    &:nth-child(4) {
      padding-right: 4px;
    }
  }

  @media (min-width: ${1366}px) {
    padding-bottom: 0;

    &:nth-child(1) {
      padding-left: 17px;

      &:after {
        display: none;
      }
    }

    &:nth-child(4) {
      padding-right: 15px;
    }

    &.bets-my-date {
      padding-left: 19px;
    }

    &.bets-my-profit {
      padding-right: 19px;
    }
  }
`;

export const InnerCell = styled.span`
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 5px 6px;
  width: 100%;

  &.first {
    padding-left: 0;
  }

  &.last {
    padding-right: 0;
  }

  @media (min-width: ${800}px) {
    display: inline-block;
    border-bottom: 0;
    padding: 0px 3px 0px;

    &.first {
      width: 23px;
      overflow: hidden;
    }
  }

  @media (min-width: ${1366}px) {
    display: block;
    padding: 0px 3px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 14px;

    &.first {
      width: auto;
      overflow: visible;
    }
  }
`;

export const TableBody = styled.div``;
