import { Howl } from "howler";
// import TakeOff from "../../assets/sounds/takeoff.mp3";
import ExplosionSound from "../../assets/sounds/fly-lose.wav";
import BetSound from "../../assets/sounds/betting.mp3";
import WinSound from "../../assets/sounds/cashout.mp3";
import BackgroundMusic from "../../assets/sounds/background.mp3";

export class SoundPlayer {
  //   private flyingSound: Howl;
  private explosionSound: Howl;
  private betSound: Howl;
  private winSound: Howl;
  private backgroundMusic: Howl;
  constructor() {
    // this.flyingSound = new Howl({
    //   src: [TakeOff],
    //   volume: 0.05,
    //   loop: true,
    //   onloaderror(id, err) {
    //     console.warn("failed to load sound file:", { id, err });
    //   },
    //   onplayerror() {
    //     console.warn("Failed to start the audio device");
    //   },
    // });
    this.explosionSound = new Howl({
      src: [ExplosionSound],
      volume: 0.7,
      onloaderror(id, err) {
        console.warn("failed to load sound file:", { id, err });
      },
      onplayerror() {
        console.warn("Failed to start the audio device");
      },
    });
    this.betSound = new Howl({
      src: [BetSound],
      volume: 0.9,
      onloaderror(id, err) {
        console.warn("failed to load sound file:", { id, err });
      },
      onplayerror() {
        console.warn("Failed to start the audio device");
      },
    });
    this.winSound = new Howl({
      src: [WinSound],
      volume: 0.9,
      loop: true,
      onloaderror(id, err) {
        console.warn("failed to load sound file:", { id, err });
      },
      onplayerror() {
        console.warn("Failed to start the audio device");
      },
    });
    this.backgroundMusic = new Howl({
      src: [BackgroundMusic],
      volume: 0.7,
      loop: true,
      onloaderror(id, err) {
        console.warn("failed to load sound file:", { id, err });
      },
      onplayerror() {
        console.warn("Failed to start the audio device");
      },
    });
  }

  //   playFlyingSound() {
  //     this.flyingSound.play();
  //   }

  //   stopFlyingSound() {
  //     this.flyingSound.stop();
  //   }

  playExplosionSound() {
    this.explosionSound.play();
  }

  stopExplosionSound() {
    this.explosionSound.stop();
  }

  playButtonSound() {
    // this.stopButtonSound();
    this.betSound.play();
  }

  stopButtonSound() {
    this.betSound.stop();
  }

  playBetSound() {
    // this.stopBetSound();
    // this.betSound.play();
  }

  stopBetSound() {
    // this.betSound.stop();
  }

  playWinSound() {
    this.stopWinSound();
    this.winSound.play();
  }

  stopWinSound() {
    this.winSound.stop();
  }

  playBackgroundMusic() {
    this.stopBackgroundMusic();
    this.backgroundMusic.play();
  }

  stopBackgroundMusic() {
    this.backgroundMusic.stop();
  }
}
