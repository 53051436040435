import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { CurrencySymbol } from "../../../../../constants/currency";
import { ButtonInput } from "../../../styles";

const RegisterPanel: FC<{
  amount: number;
  setAmount(amount: number | null): void;
  handleRegister(): void;
  toggleBoard(): void;
  icon: any;
  minValue?: number;
  maxValue?: number;
}> = ({ amount, handleRegister, toggleBoard, icon }) => {
  const translate = useTranslate();
  const CURRENCY = CurrencySymbol()

  return (
    <ButtonInput
      text={`${translate(KEYWORDS.FreeBet)} ${roundNumber(
        amount
      )}${CURRENCY}`}
      onClick={handleRegister}
      className="register-bet"
    />
  );
};

export default RegisterPanel;
