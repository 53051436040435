import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { GAME_STATE } from "../../../../../constants/interfaces/Game";
import { useGameStatusState } from "../../../../../config/store/state/app.state";
import { CurrencySymbol } from "../../../../../constants/currency";
import {
  AutoLabel,
  SliderHandle,
  ButtonInput,
  Column,
  Columns,
  Tab,
} from "../../../styles";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import Range from "../../../Range";

const CashOutPanel: FC<{
  amount: number;
  baseAmount: number;
  handleCashOut(): void;
  handleUnregister(): void;
  disabled?: boolean;
  setAmount(amount: number | null): void;
  minValue: number;
  maxValue: number;
  autoCashoutMinValue?: number;
  autoCashutMaxValue?: number;
  autoCashOutValue: number;
  handleAutoCashOutValueChange(amount: number | null): void;
}> = ({
  amount,
  baseAmount,
  handleCashOut,
  handleUnregister,
  setAmount,
  minValue,
  maxValue,
  autoCashoutMinValue,
  autoCashutMaxValue,
  autoCashOutValue,
  handleAutoCashOutValueChange,
}) => {
  const translate = useTranslate();
  const gameState = useGameStatusState().state;
  // const game = useGameStatusState();
  const CURRENCY = CurrencySymbol()

  return (
    <Tab className="game-nums-twocolumcn-content">
      <Columns>
        <Column>
          <NumberInput
            value={baseAmount}
            onChange={setAmount}
            minValue={minValue}
            maxValue={maxValue}
            className="two-columns first"
            disabled
          />
          <AutoLabel className="first">
            {translate(KEYWORDS.AutoBetting)}
          </AutoLabel>

          <Range
            className="game-numslider-twocolumn"
            value={baseAmount}
            // baseHalfPoint={20}
            min={minValue}
            max={maxValue}
            onChange={setAmount}
            disabled
          />
        </Column>

        <Column>
          <NumberInput
            suffix={" x"}
            value={autoCashOutValue}
            onChange={handleAutoCashOutValueChange}
            minValue={autoCashoutMinValue}
            maxValue={autoCashutMaxValue}
            className="two-columns"
            disabled
            step={0.1}
            without
          />
          <AutoLabel>{translate(KEYWORDS.AutoCashouting)}</AutoLabel>

          <SliderHandle
            type="range"
            className="game-numslider-twocolumn"
            min={autoCashoutMinValue}
            max={1001}
            value={autoCashOutValue}
            onChange={(e) =>
              handleAutoCashOutValueChange(Math.round(+e.target.value))
            }
            disabled
          />
        </Column>
      </Columns>

      {gameState === GAME_STATE.RUNNING ? (
        <ButtonInput
          text={
            <>
              {translate(KEYWORDS.Cashout)}
              <span className="roboto-font"> {roundNumber(amount)}</span>
              {CURRENCY}
            </>
          }
          // disabled={disabled}
          onClick={handleCashOut}
          // className="cashout"
        />
      ) : (
        <ButtonInput
          text={
            <>
              {translate(KEYWORDS.Unregister)}
              <span className="roboto-font"> {+roundNumber(amount)}</span>
              {CURRENCY}
            </>
          }
          onClick={handleUnregister}
          className="btn-cancel"
        />
      )}
    </Tab>
  );
};

export default CashOutPanel;
