import React, { FC } from "react";
import { IconProps } from "./types";

export const ArrowRight: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className ? className : "main-menu-arrow"}
      viewBox="0 0 9 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM8 8L8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L8 8ZM0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683417 16.0976 1.31658 16.0976 1.70711 15.7071L0.292893 14.2929ZM0.292893 1.70711L7.29289 8.70711L8.70711 7.29289L1.70711 0.292893L0.292893 1.70711ZM7.29289 7.29289L0.292893 14.2929L1.70711 15.7071L8.70711 8.70711L7.29289 7.29289Z" />
    </svg>
  );
};

export default ArrowRight;
