import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { CurrencySymbol } from "../../../../../constants/currency";
import { ButtonInput } from "../../../styles";

const UnregisterPanel: FC<{
  amount: number;
  handleUnregister(): void;
  disabled?: boolean;
}> = ({ amount, handleUnregister, disabled }) => {
  const translate = useTranslate();
  const CURRENCY = CurrencySymbol()

  return (
    <ButtonInput
      text={
        <>
          {translate(KEYWORDS.UnregisterFreeBet)}
          <span className="roboto-font"> {roundNumber(amount)}</span>
          {CURRENCY}
        </>
      }
      onClick={handleUnregister}
      disabled={disabled}
      className="btn-cancel"
    />
  );
};

export default UnregisterPanel;
