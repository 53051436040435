import React, { FC, useState } from "react";
import styled from "styled-components";
import BetsModal from "../BetsModal";
import { GameHistory } from "../../constants/interfaces/GameHistory";
import { getMultiplierRGBColor } from "../../features/betColors";
import { roundNumber } from "../../helpers/functions/round-number";

const color = (n: Number) => {
  if (n < 1.2) {
    return {
      background: "#141b0f",
    };
  } else if (n >= 1.2 && n < 1.3) {
    return {
      background: "#202c17",
    };
  } else if (n >= 1.3 && n < 1.4) {
    return {
      background: "#233317",
    };
  } else if (n >= 1.4 && n < 1.5) {
    return {
      background: "#294217",
    };
  } else if (n >= 1.5 && n < 2) {
    return {
      background: "#1f3d0a",
    };
  } else if (n >= 2 && n < 5) {
    return {
      background: "#1d0c0c",
    };
  } else if (n >= 5 && n < 8) {
    return {
      background: "#271111",
    };
  } else if (n >= 8 && n < 10) {
    return {
      background: "#360d0d",
    };
  } else if (n >= 10 && n < 11) {
    return {
      background: "#491111",
    };
  } else {
    return {
      background: "#480d0d",
    };
  }
};

const StyledResultBox = styled.div<{ color?: string }>`
  display: inline-block;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  padding: 6px 5.5px;
  font-size: 10px;
  line-height: 10px;
  min-height: 24px;
  margin: 0px 3px 0;
  border: 1px solid;

  color: #2eb550;
  border-color: rgba(104, 138, 78, 0.1);

  font-variant-numeric: tabular-nums;

  * {
    font-variant-numeric: tabular-nums;
  }

  &.red {
    color: #ca0505;
    border-color: #8e37371a;
  }

  &.btn-green-coeffc {
    background: linear-gradient(
      180deg,
      rgba(104, 138, 78, 0.4) 0%,
      rgba(104, 138, 78, 0.112) 100%
    );
    border: 1px solid #25311c;
    color: #2eb550;

    &:hover {
      cursor: pointer;
      border: 1px solid #2eb550;
      color: #2eb550;
    }
  }

  &.btn-red-coeffc {
    color: #ca0505;
    border: 1px solid #321313;
    background: linear-gradient(
      180deg,
      rgba(142, 55, 55, 0.29) 0%,
      rgba(142, 55, 55, 0.0812) 100%
    );

    &:hover {
      cursor: pointer;
      color: #ca0505;
      border: 1px solid #ca0505;
    }
  }

  @media (min-width: ${800}px) {
    margin: 0 2px 1px 2px;
  }

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
    min-height: 35px;
    padding: 7px;
    margin: 0px 5px 1px 5px;
  }

  @media (min-width: ${1920}px) {
    margin: 0px 7px 1px 7px;
  }
`;

const CustomResultBox: FC<{ game: GameHistory }> = ({ game }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {open && (
        <BetsModal
          closeModal={() => setOpen(false)}
          bustedAt={game?.BustedAt}
          gameId={game.ID}
          hashCode={game.CrashHash}
          hashKey={game.CrashSource}
        />
      )}
      <StyledResultBox
        color={getMultiplierRGBColor(game?.BustedAt)}
        onClick={() => setOpen(true)}
        style={color(game.BustedAt)}
        className={game.BustedAt >= 11 ? "red" : ""}
      >
        {roundNumber(game?.BustedAt)}x
      </StyledResultBox>
    </div>
  );
};

export default CustomResultBox;
