import React, { FC } from "react";
import { IconProps } from "./types";

export const Music: FC<IconProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.28 22C8.00313 22 9.4 20.6031 9.4 18.88C9.4 17.1569 8.00313 15.76 6.28 15.76C4.55688 15.76 3.16 17.1569 3.16 18.88C3.16 20.6031 4.55688 22 6.28 22Z"
        stroke="#6D5CCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.84 16.8V4.59997C20.84 1.99997 19.21 1.63997 17.56 2.08997L11.32 3.78997C10.18 4.09997 9.39999 4.99997 9.39999 6.29997V8.46997V9.92997V18.87"
        stroke="#6D5CCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.72 19.9201C19.4431 19.9201 20.84 18.5232 20.84 16.8001C20.84 15.0769 19.4431 13.6801 17.72 13.6801C15.9969 13.6801 14.6 15.0769 14.6 16.8001C14.6 18.5232 15.9969 19.9201 17.72 19.9201Z"
        stroke="#6D5CCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39999 9.52002L20.84 6.40002"
        stroke="#6D5CCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Music;
