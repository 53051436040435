import styled from 'styled-components';

export const Modal = styled.div`
    width: 335px;
    max-height: 70vh;
    max-width: 90vw;
    height: 100%;
    background: #0e0826;
    border-radius: 6px;
    margin-top: 70px;
    margin-bottom: 30px;
    position: relative;

    @media (min-width: ${800}px) {
        display: none;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 15px;

    .close-icon {
        width: 24px;
        height: 24px;
    }
`;
