/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from 'react';
import astro1 from '../../../assets/images/astro-v1.gif';
import astro2 from '../../../assets/images/astro-v2.gif';
import astro3 from '../../../assets/images/astro-v3.gif';
import astroMy from '../../../assets/images/astro-you.gif';
import { Astronaut } from '../spaceship.styles';
import { ListUser } from '../../../constants/interfaces/List';
import { useCurrentBets } from '../../../config/store/state/app.state';
import { useProfile } from '../../profile/configs/store/state';
import { useErrorMessagesState } from '../../errorMessages/store/state';

const AstroComp = () => {
    const { Player: currentPlayer } = useProfile();
    const currentBets = useCurrentBets();
    const [astroImg, setAstroImg] = useState<number>(0);
    const [astros, setAstros] = useState<JSX.Element[]>([]);
    const [myAstro, setMyAstro] = useState<JSX.Element | null>(null);
    const [bets, setBets] = useState(currentBets);
    const astrosArr = [astro1, astro2, astro3];
    const messages = useErrorMessagesState();
    const wins = messages.filter(i => i.keyword === "Win");
    const [number, setNumber] = useState<number>(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function objectsAreSame(x: ListUser[], y: ListUser[]) {
        var objectsAreSame = true;
        for (var propertyName in x) {
            if (
                x &&
                x[propertyName] &&
                x[propertyName].status &&
                y &&
                y[propertyName] &&
                y[propertyName].status &&
                x[propertyName].status !== y[propertyName].status
            ) {
                objectsAreSame = false;

                if (y[propertyName].id === currentPlayer.I) {
                    objectsAreSame = true;
                }
                break;
            }
        }
        return objectsAreSame;
    }

    const showAstros = () => {
        const randAstro = Math.floor(Math.random() * 3);
        setAstros(state => [
            ...state,
            <Astronaut random={randAstro}>
                <img src={astrosArr[astroImg]} alt="astro" />
            </Astronaut>,
        ]);

        setTimeout(() => {
            setAstros(state => state.filter((_, i) => i !== 0));
        }, 800);
    };

    useEffect(() => {
        setBets(state => {
            if (!objectsAreSame(state, currentBets)) {
                const randAstro = Math.floor(Math.random() * 3);
                setAstroImg(randAstro);

                showAstros();
            }
            return currentBets;
        });
    }, [bets, currentBets, showAstros, objectsAreSame]);

    useEffect(() => {
        if (wins.length > 0 && number === 1) {
            setMyAstro(
                <Astronaut>
                    <img src={astroMy} alt="astro" />
                </Astronaut>
            );
            setNumber(2);

            setTimeout(() => {
                setMyAstro(null);
            }, 1000);

            setTimeout(() => {
                setNumber(1);
            }, 2000)
        }
    }, [wins, number]);

    return (
        <>
            {!!astros?.length && astros.map(item => item)}

            {!!myAstro && myAstro}
        </>
    );
};

export default memo(AstroComp);
