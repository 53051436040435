import React, { FC } from "react";
import { IconProps } from "./types";

export const Security: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.2902 20.8968L12.6452 21.8317L12.6452 21.8317L12.2902 20.8968ZM11.7098 20.8968L11.3548 21.8317L11.3548 21.8317L11.7098 20.8968ZM5 4H19V2H5V4ZM19 4V10.1649H21V4H19ZM5 10.1649V4H3V10.1649H5ZM19 10.1649C19 16.8586 13.3228 19.435 11.9351 19.962L12.6452 21.8317C14.1289 21.2682 21 18.2341 21 10.1649H19ZM3 10.1649C3 18.2341 9.87108 21.2682 11.3548 21.8317L12.0649 19.962C10.6772 19.435 5 16.8586 5 10.1649H3ZM11.9351 19.962C11.95 19.9564 11.9725 19.951 12 19.951C12.0275 19.951 12.05 19.9564 12.0649 19.962L11.3548 21.8317C11.7737 21.9908 12.2263 21.9908 12.6452 21.8317L11.9351 19.962ZM19 4V4H21C21 2.89543 20.1046 2 19 2V4ZM5 2C3.89543 2 3 2.89543 3 4H5V4V2Z"></path>
      <path d="M15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L15.7071 9.70711ZM11 13L10.2929 13.7071C10.6834 14.0976 11.3166 14.0976 11.7071 13.7071L11 13ZM9.70711 10.2929C9.31658 9.90237 8.68342 9.90237 8.29289 10.2929C7.90237 10.6834 7.90237 11.3166 8.29289 11.7071L9.70711 10.2929ZM14.2929 8.29289L10.2929 12.2929L11.7071 13.7071L15.7071 9.70711L14.2929 8.29289ZM11.7071 12.2929L9.70711 10.2929L8.29289 11.7071L10.2929 13.7071L11.7071 12.2929Z"></path>
    </svg>
  );
};

export default Security;
