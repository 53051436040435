import React, { FC, useState } from "react";
import BetsModal from "../../../../components/BetsModal";
import Security from "../../../../components/Icons/Security";
import styled from "styled-components";
import { TopMultipliersItem } from "../../../../constants/interfaces/TopMultipliers";

const IconContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const CheckGameHistory: FC<{ game: TopMultipliersItem }> = ({ game }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <BetsModal
          closeModal={() => setOpen(false)}
          bustedAt={game.BustedAt}
          gameId={game.ID}
          hashCode={game.CrashHash}
          hashKey={game.CrashSource}
        />
      )}
      <IconContainer onClick={() => setOpen(true)}>
        <Security className="table-rating-ic" />
      </IconContainer>
    </>
  );
};

export default CheckGameHistory;
