import styled from "styled-components";

export const Terms = styled.div<{ height: number }>`
  max-height: calc(${(props) => props.height}px - 254px);
  overflow-y: auto;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-left: 15px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  @media (min-width: ${1366}px) {
    max-height: calc(${(props) => props.height}px - 315px);
  }

  @media (min-width: ${1920}px) {
    max-height: calc(${(props) => props.height}px - 365px);
  }
`;

export const List = styled.ul`
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "case" off;
  font-weight: 500;

  @media (min-width: ${1366}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Item = styled.li`
  padding-left: 17px;
  margin-bottom: 14px;
  position: relative;

  &.popup-list-item-first {
    margin-top: 0px;
  }

  &::before {
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    position: absolute;
    top: 4px;
    left: 0;
    background: #6d5cce;
  }

  &.inner {
    &::before {
      display: none;
    }

    ul {
      list-style-type: none;
    }
  }

  @media (min-width: ${800}px) {
    padding-left: 25px;
  }

  @media (min-width: ${1366}px) {
    margin-bottom: 25px;

    &:before {
      top: 6px;
      background: #fff;
    }
  }
`;
