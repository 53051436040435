import React, { useEffect, useState, FC } from "react";
import styled from "styled-components";
import RedCircleWarning from "../Icons/RedCircleWarning";
import { Close } from "../MenuIcon/MenuIcon.styles";
import Modal from "../Modal";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";

const Container = styled.div`
  position: relative;
  outline: 0;
  padding: 40px 18px;
  border-radius: 6px;
  background: #020606;
  box-shadow: none;
  width: 314px;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;

  .warning-icon {
    margin-right: 4px;
  }

  @media (min-width: ${800}px) {
    width: 715px;
    padding: 54px 20px;
    border-radius: 8px;
    background: #000000;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 10px 15px 10px;

  .close-icon {
    width: 20px;
    height: 20px;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-transform: uppercase;

  font-size: 14px;
  line-height: 19px;
  margin-bottom: 35px;

  @media (min-width: ${800}px) {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 40px;
  }
`;

const InnerText = styled.div`
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 35px;

  @media (min-width: ${800}px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  }
`;

const CountdownContainer = styled.div`
  color: #38cc03;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${800}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ConnectionModal: FC<{ close?: () => void }> = ({ close }) => {
  const translate = useTranslate();
  const [second, setSecond] = useState(10);

  useEffect(() => {
    if (second === 0) {
      window.location.reload();

      return;
    }

    const interval = setInterval(() => setSecond(second - 1), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [second]);

  return (
    <Modal>
      <Container>
        <Header>
          <Close className="close-icon" onClick={close} />
        </Header>
        <Text>
          <RedCircleWarning className="warning-icon" />
          {translate(KEYWORDS.WeakInternetAlert)}
        </Text>

        <InnerText>{translate(KEYWORDS.PageWillReload)}</InnerText>

        <CountdownContainer>
          {second} {translate(KEYWORDS.InSeconds)}
        </CountdownContainer>
      </Container>
    </Modal>
  );
};

export default ConnectionModal;
