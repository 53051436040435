import React, { FC, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import BetsModal from "../BetsModal";
import { GameHistory } from "../../constants/interfaces/GameHistory";
import { roundNumber } from "../../helpers/functions/round-number";

const color = (n: Number) => {
  if (n < 2) {
    return {
      background: "#17AE46",
    };
  } else if (n >= 2 && n < 10) {
    return {
      background: "#e74e0f",
    };
  } else {
    return {
      background: "rgb(166, 40, 40)",
    };
  }
};

const StyledResultBox = styled(motion.div)<{ color?: string }>`
  display: inline-block;
  border: 1px solid;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  padding: 2px 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  min-height: 24px;
  margin: 0px 3px 0;
  min-width: 45px;
  width: auto;
  height: 26px;
  text-align: center;
  cursor: pointer;

  color: #fff;

  &.coeffc-btn-abs {
    margin-bottom: 8px;
  }

  // color: #2eb550;
  border-color: rgba(104, 138, 78, 0.1);

  &.red {
    // color: #ca0505;
    border-color: #8e37371a;
  }

  // &.green-light {
  //   color: #D000CB;
  // }

  @media (min-width: ${800}px) {
    margin: 0 2px;
  }

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
    min-height: 35px;
    padding: 7px;
    margin: 0px 5px 0;
    width: auto;
    min-width: 70px;
    text-align: center;

    &.coeffc-btn-abs {
      margin-bottom: 12px;
    }
  }

  @media (min-width: ${1920}px) {
    margin: 0px 7px 0;
  }
`;

const Text = styled.div`
  font-variant-numeric: tabular-nums;

  * {
    font-variant-numeric: tabular-nums;
  }
`;

const ResultBox: FC<{ game: GameHistory; historyOpen: boolean }> = ({
  game,
  historyOpen,
}) => {
  const [open, setOpen] = useState(false);
  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <>
      {open && (
        <BetsModal
          closeModal={() => setOpen(false)}
          bustedAt={game.BustedAt}
          gameId={game.ID}
          hashCode={game.CrashHash}
          hashKey={game.CrashSource}
        />
      )}

      <StyledResultBox
        initial={{ y: -10 }}
        animate={{ x: 0, y: 0 }}
        onClick={() => setOpen(true)}
        className={`${historyOpen ? "coeffc-btn-abs" : ""} ${
          game.BustedAt >= 2 ? (game.BustedAt > 10 ? "red" : "green-light") : ""
        }`}
        style={color(game.BustedAt)}
      >
        <Text>
          {+roundNumber(game.BustedAt) % 1 === 0
            ? `${numberFormatter.format(+roundNumber(game.BustedAt))}.00x`
            : `${numberFormatter
                .format(+roundNumber(game.BustedAt))
                .toString()}x`}
        </Text>
      </StyledResultBox>
    </>
  );
};

export default ResultBox;
