import { FC, useCallback, useContext } from 'react';
import { LanguageContext } from './provider';
// import {CurrencySymbol} from '../../constants/currency'
import { CurrencySymbol } from '../../constants/currency';
import { KEYWORDS } from './keywords';

const useTranslate = () => {
    const languageContext = useContext(LanguageContext);
    const Currency = CurrencySymbol();

    return useCallback(
        (keyword?: KEYWORDS | string) => {
            if (!keyword) return '';
            if (
                keyword === 'GameRules7' ||
                keyword === 'BetMaximum' ||
                keyword === 'GameRules17' ||
                keyword === 'GameRules18' ||
                keyword === 'GameRules19' ||
                keyword === 'GameRules20'
            ) {
                const translatedText = languageContext.dictionary[
                    keyword as KEYWORDS
                ].replace(/___/g, Currency);
                return translatedText;
            } else {
                return (
                    languageContext.dictionary[keyword as KEYWORDS] || keyword
                );
            }
        },
        [languageContext, Currency]
    );
};

export default useTranslate;

export const Translate: FC<{ keyword: any }> = keyword => {
    const languageContext = useContext(LanguageContext);
    if (!keyword) return '';
    return languageContext.dictionary[keyword.keyword] || keyword;
};
