import React, { FC } from "react";
// import Back from "../../../../components/Icons/Back";
import ToggleInput from "../../../../components/ToggleInput/ToggleInput";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
import { useGameHistory } from "../../../../config/store/state/app.state";
import CustomResultBox from "../../../../components/ResultBox/CustomResultBox";
import {
  StyledInfoPanel,
  Left,
  // Refresh,
  SwitchContainer,
  SwitchLabel,
  // Text,
  // Count,
} from "./BetsInfoPanel.styles";

const BetsInfoPanel: FC<{
  showLastBets: boolean;
  setShowLastBets(setShow: boolean): void;
  // totalBets: number;
}> = ({ showLastBets, setShowLastBets }): any => {
  const translate = useTranslate();
  const lastGameStats = useGameHistory()[0];

  return (
    <StyledInfoPanel>
      <Left>
        {/* <Refresh>
          <Back className="bets-refresh-ic" />
        </Refresh> */}

        <SwitchContainer className="bets-switch">
          <SwitchLabel className="bets-switch-lab">
            {translate(KEYWORDS.LastHand)}
          </SwitchLabel>

          <ToggleInput
            active={showLastBets}
            onChange={() => setShowLastBets(!showLastBets)}
          />
        </SwitchContainer>

        {lastGameStats && showLastBets && (
          <CustomResultBox game={lastGameStats} />
        )}
      </Left>

      {/* <Text>
        {translate(KEYWORDS.Bets)} <Count>{totalBets}</Count>
      </Text> */}
    </StyledInfoPanel>
  );
};

export default BetsInfoPanel;
