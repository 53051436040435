import styled from "styled-components";

export const PopupWrap = styled.div`
  margin: 5vh auto 30px;
  position: relative;
  top: auto;
  background: #020606;
  left: 0;
  width: 90%;
  min-width: 237px;
  padding: 0 15px;
  right: 0;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  max-height: 70vh;
  overflow: auto;

  @media (min-width: ${800}px) {
    width: 100%;
  }

  @media (min-width: ${1366}px) {
    width: 866px;
    margin-top: 44px;
    margin-bottom: 46px;

    &.round-popup {
      width: 813px;
      margin-top: 12vh;
    }
  }

  @media (min-width: ${1920}px) {
    padding: 0 32px;
  }
`;

export const Head = styled.div`
  text-align: center;
  font-size: 12px;
  font-feature-settings: "case" off;
  padding: 15px 20px 8px 15px;
  position: relative;
  min-height: 24px;

  .popup-raund-head-ic {
    fill: #ffffff;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    display: inline-block;
    vertical-align: middle;
  }

  @media (min-width: ${1366}px) {
    font-size: 22px;
    line-height: 26px;
    padding: 31px 20px 30px 15px;

    .popup-raund-head-ic {
      display: none;
    }
  }
`;

export const Title = styled.div`
  color: #2eb550;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: -4px;
  top: 10px;
  display: block;
  padding: 10px;

  .popup-close-ic {
    width: 12px;
    height: 12px;
    fill: #ffffff;
    vertical-align: top;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      fill: #ca0505;
    }
  }

  @media (min-width: ${1366}px) {
    // display: none;
    right: 10px;
    top: 27px;

    .popup-close-ic {
      width: 24px;
      height: 24px;
    }
  }

  @media (min-width: ${1920}px) {
    right: -6px;
    top: 25px;

    .popup-close-ic {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Content = styled.div`
  padding-top: 13px;
`;

export const Scroll = styled.div`
  padding-bottom: 15px;
  padding-right: 0;
  max-height: calc(88vh - 218px);
  overflow-y: auto;
  // overflow-x: hidden;

  @media (min-width: ${1366}px) {
    padding-right: 0;
    padding-bottom: 31px;
  }
`;

export const Item = styled.div`
  margin-top: 28px;
  text-align: start;

  &.first {
    margin: 0;
  }

  @media (min-width: ${1366}px) {
    margin-top: 46px;

    &.first {
      margin: 0;
    }
  }
`;

export const Label = styled.div`
  color: #ffffff;
  margin-bottom: 13px;
  display: block;
  font-size: 12px;

  @media (min-width: ${1366}px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
  }
`;

export const Code = styled.div`
  position: relative;
  padding-left: 30px;

  .lock {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .info-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .info-icon path {
    stroke: #6d5cce;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  @media (min-width: ${1366}px) {
    padding-left: 50px;
  }
`;

export const InfoText = styled.div`
  font-feature-settings: "case" off;
  font-size: 12px;
  line-height: 24px;
  background: transparent;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 0.3);
  width: 100%;

  background: #0b0216;
  border-radius: 8px;
  padding: 9px;
  max-width: 65vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 19px;
    max-width: 551px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.bordered {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 12px;
    margin-top: 12px;
  }
`;

export const RoundLab = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  line-height: 12px;

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Key = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.3);

  &.code {
    background: #0b0216;
    border-radius: 8px;
    padding: 9px;
    max-width: 65vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    
    @media (min-width: ${1366}px) {
      max-width: 551px;
    }
  }

  &.coeff {
    color: #2eb550;

    @media (max-width: ${800}px) {
      display: none;
    }
  }

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Copy = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 12px;
  cursor: pointer;
  transition: color 0.3s ease; 

  &:active {
    color: #fff;
  }
`;
