import React, { FC } from "react";
import styled from "styled-components";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;

  @media (min-width: ${800}px) {
    padding-left: 20px;
  }
`;

const Content: FC = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

export default Content;
