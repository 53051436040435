import React, { FC, memo, useCallback, useEffect, useState } from "react";
import {
  RegisterState,
  useErrorsState,
  useGameConfigs,
  useGameStatusState,
  usePanelState,
} from "../../../../config/store/state/app.state";
import {
  useCashOutService,
  usePendingService,
  useRegisterService,
  useUnregisterLocalService,
  useUnregisterService,
} from "../../../../config/store/services";
import { GAME_STATE } from "../../../../constants/interfaces/Game";
import RegisterPanel from "./panels/RegisterPanel";
import CashOutPanel from "./panels/CashOutPanel";
import UnregisterPanel from "./panels/UnregisterPanel";
import { KEYWORDS } from "../../../../config/language/keywords";
import { useShowMessage } from "../../../errorMessages/store/services";
import { useBalance } from "../../../profile/configs/store/state";
import {
  calculateMultiplierGrow,
  getFinalMultiplier,
  MULTIPLIER_DELAY,
} from "../../../game/components/GameMultiplier";
import { useMixpanelServices } from "../../../../services/mixpanel/MixpanelServices";
import { EMixpanelEvent } from "../../../../services/mixpanel/types";
import {
  usePlayButtonSoundService,
  useWinSoundService,
} from "../../../audioPlayer/store/services";
import { useMaxMinConfig } from "../../configs/state";

const AutoBoardContainer: FC<{
  boardIndex: number;
  delay?: number;
}> = ({ boardIndex, delay }) => {
  const configs = useGameConfigs();
  const state = usePanelState().filter(
    (board) => board.PanelIndex === boardIndex
  )[0];
  const gameStatus = useGameStatusState();
  const multiplier = gameStatus ? gameStatus.multiplier || 1 : 1;
  const registerService = useRegisterService();
  const pendingService = usePendingService();
  const unregisterService = useUnregisterService();
  const unregisterLocalService = useUnregisterLocalService();
  const cashOutService = useCashOutService();
  const registerState = state && state.registerState;

  const showMessage = useShowMessage();
  const balance = useBalance();

  // const disableActions = registerState !== RegisterState.UNREGISTERED;
  const [autoBetEnabled, setAutoBetEnabled] = useState(false);

  const [amount, setAmount] = useState(1);
  const [baseAmount, setBaseAmount] = useState<number>(1);

  const sound = usePlayButtonSoundService();
  const winSound = useWinSoundService();

  const minMaxValue = useMaxMinConfig();
  const BASE_AMOUNT_MIN_VALUE = minMaxValue.MinBet;
  const BASE_AMOUNT_MAX_VALUE = minMaxValue.MaxBet;
  const AUTO_CASHOUT_MIN_VALUE = 1.1;
  const AUTO_CASHOUT_MAX_VALUE = 1102008;

  const handleCheckBalance = useCallback(
    (value: number) => {
      if (value > balance.WalletBalance) {
        return balance.WalletBalance;
      } else {
        return value;
      }
    },
    [balance.WalletBalance]
  );

  const handleBaseAmountValueChange = useCallback(
    (value: number | null) => {
      if (!value) return;
      value = Math.round(value * 100) / 100;
      value = handleCheckBalance(value);

      if (value < BASE_AMOUNT_MIN_VALUE) {
        setBaseAmount(BASE_AMOUNT_MIN_VALUE);
        setAmount(BASE_AMOUNT_MIN_VALUE);
        return;
      }
      if (value > BASE_AMOUNT_MAX_VALUE) {
        setBaseAmount(BASE_AMOUNT_MAX_VALUE);
        setAmount(BASE_AMOUNT_MAX_VALUE);
        return;
      }
      setBaseAmount(value);
      setAmount(value);
    },
    [
      // disableActions,
      handleCheckBalance,
      BASE_AMOUNT_MIN_VALUE,
      BASE_AMOUNT_MAX_VALUE,
    ]
  );

  const [autoCashOutValue, setAutoCashOutValue] = useState<number>(2);
  const handleAutoCashOutValueChange = useCallback(
    (value: number | null) => {
      if (value === null) return;
      if (value < AUTO_CASHOUT_MIN_VALUE) {
        setAutoCashOutValue(AUTO_CASHOUT_MIN_VALUE);
        return;
      }
      if (value > AUTO_CASHOUT_MAX_VALUE) {
        setAutoCashOutValue(AUTO_CASHOUT_MAX_VALUE);
        return;
      }
      if (value > Number.MAX_SAFE_INTEGER) {
        return;
      }
      setAutoCashOutValue(value);
    },
    [setAutoCashOutValue]
  );

  const handleOnWinCase = useCallback(() => {
    if (configs.voiceEffects) {
      winSound();
    }
    setAmount(baseAmount);
  }, [configs.voiceEffects, baseAmount, winSound]);

  const handleOnLossCase = useCallback(() => {
    setAmount(baseAmount);
  }, [setAmount, baseAmount]);

  const handleRegister = useCallback(() => {
    showMessage(KEYWORDS.SuccessfulRegistration, "success");

    registerService({
      BuyIn: amount,
      AutoCashoutOn: autoCashOutValue,
      IsAutoBet: true,
      PanelIndex: boardIndex,
      BalanceType: 1,
    });
  }, [showMessage, registerService, amount, autoCashOutValue, boardIndex]);

  const handlePending = useCallback(() => {
    showMessage(KEYWORDS.WaitingForNextRound, "success");

    pendingService({
      BuyIn: amount,
      AutoCashoutOn: autoCashOutValue,
      IsAutoBet: true,
      PanelIndex: boardIndex,
      BalanceType: 1,
    });
  }, [amount, autoCashOutValue, boardIndex, pendingService, showMessage]);

  const handleInitRegister = useCallback(() => {
    if (amount > balance.WalletBalance) {
      showMessage(KEYWORDS.NotEnoughMoney, "error");
      setAutoBetEnabled(false);
      return;
    }
    if (gameStatus && gameStatus.state !== GAME_STATE.COUNTDOWN) {
      handlePending();
    } else {
      handleRegister();
    }
  }, [
    gameStatus,
    handlePending,
    handleRegister,
    showMessage,
    amount,
    balance.WalletBalance,
  ]);

  // const handleClickRegister = () => {
  //   setAutoBetEnabled(true);
  //   handleInitRegister();
  // };

  const handleClickRegister = useCallback(() => {
    if (configs.voiceEffects) {
      sound();
    }

    setAutoBetEnabled(true);
    handleInitRegister();
  }, [configs.voiceEffects, handleInitRegister, sound]);

  const handleClickUnregister = useCallback(() => {
    if (configs.voiceEffects) {
      sound();
    }

    setAutoBetEnabled(false);
    setAmount(baseAmount);
    if (registerState === RegisterState.PENDING) {
      showMessage(KEYWORDS.Unregistered, "success");
      unregisterLocalService({
        PanelIndex: boardIndex,
      });
    } else {
      unregisterService({
        PanelIndex: boardIndex,
      });
    }
  }, [
    configs.voiceEffects,
    baseAmount,
    registerState,
    sound,
    showMessage,
    unregisterLocalService,
    boardIndex,
    unregisterService,
  ]);

  const { track } = useMixpanelServices();

  const handleClickCashOut = () => {
    track(EMixpanelEvent.cashout, {
      Board: "AutoBetting",
      Multiplier: multiplier,
      BetAmount: amount,
      Won:
        amount * multiplier > 9900 && amount * multiplier < 11000
          ? 10000
          : amount * multiplier,
    });
    cashOutService({
      PanelIndex: boardIndex,
      Won:
        amount * multiplier > 9900 && amount * multiplier < 11000
          ? 10000
          : amount * multiplier,
      Multiplier: multiplier,
    });
  };

  useEffect(() => {
    let timer: any;
    if (state.registerState && gameStatus.state) {
      if (
        state.registerState === RegisterState.PENDING &&
        gameStatus.state === GAME_STATE.COUNTDOWN
      ) {
        if (amount > 500) {
          setAutoBetEnabled(false);
          handleClickUnregister();
        }
        timer = setTimeout(() => {
          handleRegister();
        }, delay);
      }
      if (
        state.registerState === RegisterState.UNREGISTERED &&
        autoBetEnabled
      ) {
        if (amount > 500) {
          setAutoBetEnabled(false);
        } else {
          if (state.wonLastGame) {
            handleOnWinCase();
          } else {
            handleOnLossCase();
          }
          handleInitRegister();
        }
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    state.registerState,
    state.wonLastGame,
    gameStatus.state,
    handleRegister,
    autoBetEnabled,
    setAutoBetEnabled,
    handleInitRegister,
    handleOnWinCase,
    handleOnLossCase,
    handleClickUnregister,
    amount,
    delay,
  ]);

  // BEGIN TEMP LOCAL CALCULATIONS
  const gameError = useErrorsState().isError;
  const gameState = useGameStatusState().state;
  const gameCrushed = gameState === GAME_STATE.CRUSHED;

  const [localMultiplier, setLocalMultiplier] = useState(1);

  useEffect(() => {
    setLocalMultiplier(multiplier || 1);
  }, [multiplier]);

  useEffect(() => {
    if (gameState === GAME_STATE.RUNNING) {
      const interval = setInterval(() => {
        setLocalMultiplier((prevValue) => calculateMultiplierGrow(prevValue));
      }, MULTIPLIER_DELAY);

      return () => {
        clearInterval(interval);
      };
    }
  }, [multiplier, gameState, setLocalMultiplier]);

  let finalMultiplayer = getFinalMultiplier(
    gameCrushed,
    gameError,
    localMultiplier,
    multiplier
  );
  // END TEMP LOCAL CALCULATIONS

  const handleRegisterStateView = () => {
    switch (registerState) {
      case RegisterState.UNREGISTERED: {
        return (
          <RegisterPanel
            amount={baseAmount}
            minValue={BASE_AMOUNT_MIN_VALUE}
            maxValue={BASE_AMOUNT_MAX_VALUE}
            autoCashoutMinValue={AUTO_CASHOUT_MIN_VALUE}
            autoCashutMaxValue={AUTO_CASHOUT_MAX_VALUE}
            autoCashOutValue={autoCashOutValue}
            setAmount={handleBaseAmountValueChange}
            // disabled={disableActions}
            handleRegister={handleClickRegister}
            handleAutoCashOutValueChange={handleAutoCashOutValueChange}
          />
        );
      }
      case RegisterState.REGISTER_INITIATED:
      case RegisterState.CASH_OUT_INITIATED:
      case RegisterState.REGISTERED: {
        if (gameStatus.state === GAME_STATE.COUNTDOWN) {
          return (
            <UnregisterPanel
              amount={amount}
              minValue={BASE_AMOUNT_MIN_VALUE}
              maxValue={BASE_AMOUNT_MAX_VALUE}
              autoCashoutMinValue={AUTO_CASHOUT_MIN_VALUE}
              autoCashutMaxValue={AUTO_CASHOUT_MAX_VALUE}
              autoCashOutValue={autoCashOutValue}
              disabled={registerState === RegisterState.REGISTER_INITIATED}
              handleUnregister={handleClickUnregister}
              setAmount={handleBaseAmountValueChange}
              handleAutoCashOutValueChange={handleAutoCashOutValueChange}
            />
          );
        } else {
          return (
            <CashOutPanel
              amount={amount * finalMultiplayer}
              baseAmount={baseAmount}
              disabled={registerState === RegisterState.CASH_OUT_INITIATED}
              handleCashOut={handleClickCashOut}
              handleUnregister={handleClickUnregister}
              setAmount={handleBaseAmountValueChange}
              minValue={BASE_AMOUNT_MIN_VALUE}
              maxValue={BASE_AMOUNT_MAX_VALUE}
              autoCashoutMinValue={AUTO_CASHOUT_MIN_VALUE}
              autoCashutMaxValue={AUTO_CASHOUT_MAX_VALUE}
              autoCashOutValue={autoCashOutValue}
              handleAutoCashOutValueChange={handleAutoCashOutValueChange}
            />
          );
        }
      }
      case RegisterState.UNREGISTER_INITIATED:
      case RegisterState.PENDING: {
        return (
          <UnregisterPanel
            amount={amount}
            minValue={BASE_AMOUNT_MIN_VALUE}
            maxValue={BASE_AMOUNT_MAX_VALUE}
            autoCashoutMinValue={AUTO_CASHOUT_MIN_VALUE}
            autoCashutMaxValue={AUTO_CASHOUT_MAX_VALUE}
            autoCashOutValue={autoCashOutValue}
            disabled={registerState === RegisterState.UNREGISTER_INITIATED}
            handleUnregister={handleClickUnregister}
            setAmount={handleBaseAmountValueChange}
            handleAutoCashOutValueChange={handleAutoCashOutValueChange}
          />
        );
      }
      default: {
        return (
          <RegisterPanel
            amount={baseAmount}
            minValue={BASE_AMOUNT_MIN_VALUE}
            maxValue={BASE_AMOUNT_MAX_VALUE}
            autoCashoutMinValue={AUTO_CASHOUT_MIN_VALUE}
            autoCashutMaxValue={AUTO_CASHOUT_MAX_VALUE}
            autoCashOutValue={autoCashOutValue}
            setAmount={handleBaseAmountValueChange}
            // disabled={disableActions}
            handleRegister={handleClickRegister}
            handleAutoCashOutValueChange={handleAutoCashOutValueChange}
          />
        );
      }
    }
  };

  return <>{handleRegisterStateView()}</>;
};

export default memo(AutoBoardContainer);
