import styled from "styled-components";
import { motion } from "framer-motion";

interface IStyled {
  crushed?: boolean
}

export const MultiplierContainer = styled(motion.div)`
  position: absolute;
  right: 50%;
  z-index: 3;
  transform: translateX(62px) !important;

  @media (min-width: ${1366}px) {
    transform: translateX(135px) !important;
  }

  @media (max-width: 992px) {
    width: 100vw;
    display: flex;
    right: 0;
    transform: none !important;
    justify-content: center;
    text-align: center;
    left: 0;
  }
`;

export const StyledMultiplier = styled(motion.div)<IStyled>`
  background-size: contain;
  position: relative;
  height: 40px;
  font-family: "drukwideweb";
  color: ${(props) => props.crushed ? '#cf0505' : '#ffffff'};
  // -webkit-text-stroke: 1px ${(props) => props.crushed ? '#cf0505' : '#ffffff'};
  padding-top: 6px;
  border-radius: 18px;
  border: 2px solid transparent;
  padding-left: 8px;
  display: flex;
  align-items: end;
  min-width: 80px;

  &:before {
    display: block;
    content: "X";
    color: ${(props) => props.crushed ? '#ffffff' : '#ffffff'};
    
    position: absolute;
    top: 2px;
    right: 8px;
    opacity: 0.3;
    font-size: 40px;
    transform: skew(-0.04turn, 0deg);
    // font-size: 26px;
  }

  @media (min-width: ${1366}px) {
    padding-left: 40px;
    border-radius: 36px;
    padding-top: 6px;
    height: 110px;
    padding-right: 30px;
    margin-top: -20px;

    &:before {
      font-size: 120px;
      right: 27px;
      top: 13px;
    }
  }

  .game-counter-sec {
    font-size: 36px;
    line-height: 26px;
    -webkit-text-stroke: 1px ${(props) => props.crushed ? '#fff' : '#fff'};
    @media (min-width: ${1366}px) {
      font-size: 110px;
      line-height: unset;
    }
  }

  .game-counter-mlsec {
    font-size: 24px;
    text-align: left;
    position: relative;
    line-height: 20px;
    margin-left: 3px;
    width: 69px;
    -webkit-text-stroke: 0.05rem ${(props) => props.crushed ? '#fff' : 'transparent'};

    &:before {
      display: inline-block;
      content: ".";
      margin-right: -0px;
    }

    @media (min-width: ${1366}px) {
      -webkit-text-stroke: 1px ${(props) => props.crushed ? '#fff' : '#fff'};
      width: 45px;
      font-size: 53px;
      width: 150px;
      line-height: 40px;
      margin-left: 5px;
      position: relative;
      top: -15px;
    }
  }
`;
