import styled from "styled-components";

export const StyledInfoPanel = styled.div`
  padding: 0 15px;
  margin: 15px 0 6px 0;
  display: flex;
  justify-content: space-between;
  font-feature-settings: "case" off;
  display: flex;
  align-items: center;
  height: 35px;

  @media (min-width: ${800}px) {
    margin-top: 0px;
    margin-bottom: 9px;
    padding: 4px 6px 0;
  }

  @media (min-width: ${1366}px) {
    padding: 0px 17px 0;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  @media (min-width: ${1920}px) {
    margin-bottom: 33px;
  }
`;

export const Left = styled.div`
  font-size: 0;
  display: flex;
  align-items: center;
`;

// export const Refresh = styled.div`
//   display: none;

//   @media (min-width: ${800}px) {
//     display: inline-block;
//     margin-right: 6px;

//     .bets-refresh-ic {
//       width: 14px;
//       height: 14px;
//     }
//   }

//   @media (min-width: ${1366}px) {
//     .bets-refresh-ic {
//       width: 22px;
//       height: 22px;
//     }
//   }

//   @media (min-width: ${1920}px) {
//     display: none;
//   }
// `;

// export const Text = styled.div`
//   display: none;
//   font-size: 8px;
//   line-height: 10px;
//   padding-top: 4px;

//   @media (min-width: ${800}px) {
//     display: block;
//   }

//   @media (min-width: ${1366}px) {
//     font-size: 15px;
//     line-height: 18px;
//     padding-top: 2px;
//   }
// `;

// export const Count = styled.span`
//   color: #2eb550;
// `;

export const SwitchContainer = styled.div`
  position: absolute;
  top: -16px;
  left: -1px;

  &.bets-switch {
    position: relative;
    top: auto;
    left: 0;
  }

  @media (min-width: ${800}px) {
    &.bets-switch {
      display: inline-block;
      vertical-align: text-bottom;
    }
  }
`;

export const SwitchLabel = styled.div`
  font-size: 8px;
  line-height: 10px;
  color: #ffffff;

  font-feature-settings: "case" on;
  display: inline-block;
  margin-right: 9px;
  vertical-align: middle;

  &.bets-switch-lab {
    font-size: 12px;
    line-height: 14px;
    font-feature-settings: "case" off;
  }

  @media (min-width: ${800}px) {
    &.bets-switch-lab {
      font-size: 8px;
      line-height: 10px;
    }
  }

  @media (min-width: ${1366}px) {
    &.bets-switch-lab {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;
