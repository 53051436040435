import styled from "styled-components";
import { TableCell } from "../../../../components/Table/TableCell";

export const NeutralFirstTableCell = styled(TableCell)`
  border: 0;
  border-collapse: collapse;
  padding: 18px 5px 17px 5px;
  width: 18%;
  text-align: center;

  // text-align: left;
  padding-left: 0;

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: ${1920}px) {
    font-size: 20px;
    line-height: 24px;
    padding: 25px 5px 24px 5px;
  }
`;

export const NeutralSecondTableCell = styled(TableCell)`
  border: 0;
  border-collapse: collapse;
  padding: 18px 5px 17px 5px;
  width: 15%;
  text-align: center;

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: ${1920}px) {
    font-size: 20px;
    line-height: 24px;
    padding: 25px 5px 24px 5px;
  }
`;

export const NeutralThirdTableCell = styled(TableCell)`
  border: 0;
  border-collapse: collapse;
  padding: 18px 5px 17px 5px;
  width: 39%;
  text-align: center;

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: ${1920}px) {
    font-size: 20px;
    line-height: 24px;
    padding: 25px 5px 24px 5px;
  }
`;

export const NeutralFourthTableCell = styled(TableCell)`
  border: 0;
  border-collapse: collapse;
  padding: 18px 5px 17px 5px;
  width: 28%;
  text-align: center;

  // text-align: right;
  padding-left: 0;

  @media (min-width: ${1366}px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: ${1920}px) {
    font-size: 20px;
    line-height: 24px;
    padding: 25px 5px 24px 5px;
  }
`;
