import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
import { useGameStatusState } from "../../../../../config/store/state/app.state";
import { GAME_STATE } from "../../../../../constants/interfaces/Game";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import { CurrencySymbol } from "../../../../../constants/currency";
import { Tab, ButtonInput } from "../../../styles";
import Range from "../../../Range";

const CashOutPanel: FC<{
  initialAmount: number;
  amount: number;
  handleCashOut(): void;
  handleUnregister(): void;
  setAmount(amount: number | null): void;
  disabled?: boolean;
  minValue: number;
  maxValue: number;
}> = ({
  initialAmount,
  amount,
  handleCashOut,
  handleUnregister,
  setAmount,
  disabled,
  minValue,
  maxValue,
}) => {
  const translate = useTranslate();
  const gameState = useGameStatusState().state;
  // const game = useGameStatusState();
  const CURRENCY = CurrencySymbol()

  return (
    <Tab>
      <NumberInput value={initialAmount} onChange={setAmount} disabled />

      <Range
        value={initialAmount}
        baseHalfPoint={20}
        min={minValue}
        max={maxValue}
        onChange={setAmount}
        disabled
      />

      {gameState === GAME_STATE.RUNNING ? (
        <ButtonInput
          text={
            <>
              {translate(KEYWORDS.Cashout)}
              <span className="roboto-font"> {roundNumber(amount)}</span>
              {CURRENCY}
            </>
          }
          disabled={disabled}
          onClick={handleCashOut}
          // className="cashout"
        />
      ) : (
        <ButtonInput
          text={
            <>
              {translate(KEYWORDS.Unregister)}
              <span className="roboto-font"> {+roundNumber(amount)}</span>
              {CURRENCY}
            </>
          }
          onClick={handleUnregister}
          // disabled={disabled}
          className="btn-cancel"
        />
      )}
    </Tab>
  );
};

export default CashOutPanel;
