import styled from "styled-components";

export const Container = styled.div<{ landscapeHeight?: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: #1d0030;
  z-index: 99990;
  width: 100vw;
  height: 100vh;

  @media (min-device-width: 320px) and (max-device-swidth: 992px) and (orientation: landscape) {
    height: ${window.outerWidth};
    width: ${(props) => `${props.landscapeHeight}px`};
  }

  .full {
    position: absolute;
    left: 0;
    top: 0;
    // width: 100vw;
    // height: 100vh;
    z-index: 999999;
    height: 100%;
    width: 100%;
  }
`;

export const LoadingContainer = styled.div`
  z-index: 1;
`;

export const AppLogoContainer = styled.div`
  width: 50%;
  max-width: 404px;
  display: flex;
  max-height: 80px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transition: all 0.5s;
`;

export const AppLogo = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: contain;
`;

export const ProgressBarContainer = styled.div`
  background-color: rgba(46, 181, 80, 0.2);
  border-radius: 18px;
  width: 80%;
  max-width: 488px;
  height: 16px;
  margin: 60px auto;
  overflow: hidden;
  transition: all 0.5s;
`;

export const ProgressBar = styled.div<{ width: number }>`
  background-color: #2eb550;
  width: ${({ width }) => width}%;
  height: 100%;
  border-radius: 18px;
  transition: all 1s;
`;
