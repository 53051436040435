import React, { FC, useEffect } from "react";
// import { useErrorMessagesState } from "./store/state";
import { InfoSnackbar } from "../../components/InfoSnackbar/InfoSnackbar";
import { useHideMessageService } from "./store/services";
import styled from "styled-components";
import { ErrorMessagesState } from "./store/state";

const SnackbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: absolute;
  width: 100%;
  height: 95%;
  // z-index: 1;
  pointer-events: none;

  @media (max-width: ${700}px) {
    justify-content: center;
  }
`;

const HIDE_DURATION = 1000;

export interface IMSG {
  status: "success" | "error" | "info";
  message: string;
  keyword: string;
}

interface IMessages {
  messages: IMSG[] | ErrorMessagesState[]
}

const ErrorMessages: FC<IMessages> = ({ messages }) => {
  const hideMessage = useHideMessageService();

  useEffect(() => {
    const timer = setTimeout(() => {
      hideMessage();
    }, HIDE_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [hideMessage, messages]);

  return (
    <SnackbarContainer>
      {messages && messages.length > 0 && (
        messages.map((message, index) => {
          return (
            <InfoSnackbar
              key={index}
              open={!!message.status}
              variant={message.status ? message.status : "error"}
              message={message.message}
              keyword={message.keyword}
              handleClose={() => hideMessage()}
            />
          );
        })
      )}
    </SnackbarContainer>
  );
};

export default ErrorMessages;
