import styled from "styled-components";
import MaskedInput from "react-text-mask";

export const StyledNumberInput = styled.div<{
  fontSize: number;
  disabled?: boolean;
}>`
  max-width: 205.44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
  background: #160e29;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  padding: 4px;
  height: 38px;
  position: relative;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'all'};


  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #ff4500;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }

  &:disabled {
    opacity: 0.5;
    font-size: ${props => props.fontSize}px;
    color: ${props => props.theme.colors.whiteGrayish} !important;
    text-shadow: none;
}

  &.two-columns {
    max-width: 100%;
    min-width: 100%;
    height: 32px;
  }

  @media (min-width: ${1366}px) {
    max-width: 210px;
    // font-size: 12px;
    padding: 5px;
    height: 34px;

    &.two-columns {
      max-width: 100%;
      min-width: 100%;
      height: 34px;
    }

    &.first {
      left: 0;
    }
  }

  @media (min-width: ${1920}px) {
    max-width: 298px;
    font-size: 8px;
    padding: 9px;
    height: 60px;
    border-radius: 8px;
    -webkit-border-radius: 8px;

    &.two-columns {
      max-width: 100%;
      min-width: 100%;
      height: 60px;
    }

    &.first {
      left: 0;
    }
  }
`;

export const Input = styled(MaskedInput)`
  width: 100%;
  text-align: center;
  border: 0;
  background-color: transparent;
  height: 36px;
  font-size: 14px;
  color: #ffffff;

  // @media (min-width: ${1366}px) {
  //   height: 42px;
  //   font-size: 18px;
  // }

  @media (min-width: ${1920}px) {
    height: 42px;
    font-size: 18px;
  }
`;

export const Button = styled.button`
  position: relative;
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  cursor: pointer;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #201739;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #ffffff;
    opacity: 0.8;
  }

  &.quantity-minus {
    background: #cf0505;

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: #860707;
    }
  }

  &.quantity-plus {
    background: #2eb550;

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: #208b44;
    }
  }

  // @media (min-width: ${1366}px) {
  //   min-width: 42px;
  //   height: 42px;

  //   &.quantity-minus svg {
  //     width: 24px;
  //     height: 24px;
  //   }

  //   &.quantity-plus svg {
  //     width: 24px;
  //     height: 24px;
  //   }
  // }

  @media (min-width: ${1920}px) {
    min-width: 42px;
    width: 42px;
    height: 42px;

    &.quantity-minus svg {
      width: 24px;
      height: 24px;
    }

    &.quantity-plus svg {
      width: 24px;
      height: 24px;
    }
  }
`;
