import React from "react";
import { SliderHandle } from "./styles";
import { useProfile } from "../../features/profile/configs/store/state";

function Range({
  min,
  max = 0,
  baseHalfPoint = 0,
  onChange,
  value,
  disabled = false,
  className,
}: {
  min?: number;
  max?: number;
  baseHalfPoint?: number;
  onChange: (val: number) => void;
  value: number;
  disabled?: boolean;
  className?: string;
}) {
  const profile = useProfile();
  // const calculatedMax = baseHalfPoint ? (max - baseHalfPoint) * 2 : max;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = +e.target.value;
    onChange(val > 1 ? Math.floor(val) : val);
    // const half = calculatedMax / 2;
    // let calculated = val;

    // if (baseHalfPoint) {
    //   if (val <= half) {
    //     calculated = Math.round((baseHalfPoint / half) * val);
    //   } else {
    //     calculated = baseHalfPoint + calculated - half;
    //   }
    // }

    // onChange(Math.ceil(calculated));
  };

  const balance = profile.B.WalletBalance

  if (max > balance) {
    max = balance;
  }

  return (
    <SliderHandle
      className={`${className} ${+value === max ? "max" : ""}`}
      width={max + 0.9}
      type="range"
      value={value > 1 ? Math.floor(value) : value}
      // value={
      //   value <= 20
      //     ? (value * (max - 20)) / 20 <= 4
      //       ? 0
      //       : (value * (max - 20)) / 20
      //     : max - 20 + value - 20
      // }
      min={min}
      max={max + 0.9}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

export default Range;
