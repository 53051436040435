import React, { FC, memo, useRef } from "react";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { CurrencySymbol } from "../../constants/currency";
import Minus from "../Icons/Minus";
import { Plus } from "../Icons/Plus";
import { StyledNumberInput, Input, Button } from "./NumberInput.styles";
import { debounce } from "lodash"

function formatDecimalsNumber(value: number | null) {
  if (!value) return 0;
  return Number(Math.round(parseFloat(value + "e" + 2)) + "e-" + 2);
}

const NumberInput: FC<{
  suffix?: string;
  prefix?: string;
  value: number;
  onChange: (value: number) => void;
  step?: number;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  maxLength?: number;
  fontSize?: number;
  without?: boolean;
  className?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}> = ({
  value,
  onChange,
  step = 1,
  disabled,
  fontSize = 24,
  className,
  without
}) => {
  const inputRef = useRef<any>(null);
  const Currency = CurrencySymbol();



  const focusedMask = createNumberMask({
    prefix: without ? '' : Currency,
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: without ? 6 : 5, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  const callDebounce = debounce((val, action) => {
      const updatedVal = val > 300000 ? '300000' : val;
      let updatedValue = updatedVal.replace(Currency, "");
      updatedValue = updatedValue.replace(',','.')
      if (!action) {
        updatedValue = formatValue(Number(updatedValue))
      }
      onChange(updatedValue);
  }, 1000);

  const handleInputChange = (val: string, action: boolean = false) => {
    callDebounce(val, action)
  };

  const handleIncrement = () => {
    if (value < 1) {
      step = 0.1;
    }
    onChange(formatDecimalsNumber(Number(value + step)));
  };

  const handleDecrement = () => {
    if (value <= 1) {
      step = 0.1;
    }

    onChange(formatDecimalsNumber(Number(value - step)));
  };

  const formatValue = (num: number) => {
    const decimal = Number(num).toFixed(2);
    return decimal.replace('.', ',')
  }

  return (
    <StyledNumberInput
      disabled={disabled}
      fontSize={fontSize}
      className={className}
    >
      <Button
        className="quantity-minus"
        onClick={!disabled ? handleDecrement : undefined}
      >
        <Minus />
      </Button>
      
      <Input
        ref={inputRef}
        mask={focusedMask}
        value={formatValue(value)}
        onBlur={(e) => {
          if ( e.target.value === '') {
            let val = '' + value;
            let updatedValue = val.replace(Currency, "");
            updatedValue = updatedValue.replace(',', '.')
            onChange(Number(updatedValue));
            e.target.value = (without ? '' : Currency) + formatValue(value);
          }
        }}
        onFocus={(e) => {
          e.target.value = '';
        }}
        onChange={(event) => {
          handleInputChange(event.target.value, true);
        }}
        disabled={disabled}
        inputMode="decimal"
      />

      <Button
        className="quantity-plus"
        onClick={!disabled ? handleIncrement : undefined}
      >
        <Plus />
      </Button>
    </StyledNumberInput>
  );
};

export default memo(NumberInput);
