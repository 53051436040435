import styled from "styled-components";
import { getQuery } from "../../../../helpers/hooks/useQuery";
const lang = getQuery(window.location.search, "lang");

export const StyledTopBoardActions = styled.div`
  background: #150e28;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  margin-top: 14px;
  font-size: 0;
  padding: 8px 10px 6px;
  min-height: 66px;

  &:nth-child(1) {
    margin-top: 0px;
  }

  @media (min-width: ${800}px) {
    padding: 6px 5px 4px;
    min-height: 48px;
    margin-top: 7px;

    &:nth-child(1) {
      margin-top: 0px;
    }
  }

  @media (min-width: ${1366}px) {
    padding: 8px 10px 8px 14px;
    margin-top: 21px;

    &:nth-child(1) {
      margin-top: 0px;
    }
  }
`;

export const Left = styled.div`
  display: inline-block;
  width: 37%;
  font-size: 12px;
  text-align: left;
  vertical-align: top;
  margin-top: 2px;

  @media (min-width: ${800}px) {
    width: 32%;
  }

  @media (min-width: ${1366}px) {
    width: 34%;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const AvatarContainer = styled.div`
  display: inline-block;
  text-align: center;
`;

export const Name = styled.div`
  text-transform: uppercase;
  font-feature-settings: "case" on;

  @media (min-width: ${800}px) {
    font-size: 8px;
    line-height: 10px;
  }

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Center = styled.div`
  display: inline-block;
  width: 53%;
  font-size: 12px;
  vertical-align: top;

  @media (min-width: ${800}px) {
    width: 58%;
  }

  @media (min-width: ${1366}px) {
    width: 56%;
  }
`;

export const InfoItem = styled.div`
  font-feature-settings: "case" off;
  font-size: 0;
  margin-top: 4px;

  &:nth-child(1) {
    margin-top: 0;
  }

  @media (min-width: ${1366}px) {
    margin-top: 6px;

    &:nth-child(1) {
      margin-top: 6px;
    }
  }
`;

export const Label = styled.div`
  display: inline-block;
  width: 46px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);

  &.profit-green {
    color: #2eb550;
  }

  @media (min-width: ${800}px) {
    width: 35px;
    font-size: 8px;
    line-height: 10px;
  }

  @media (min-width: ${1366}px) {
    width: 56px;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Value = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  padding-left: ${lang === "ru" ? "20px" : "9px"};

  &.profit-summary {
    text-shadow: 0px 4px 4px rgba(46, 181, 80, 0.32);
    font-weight: 600;
  }

  &.profit-green {
    color: #2eb550;
  }

  @media (min-width: ${800}px) {
    font-size: 8px;
    line-height: 10px;
    padding-left: ${lang === "ru" ? "8px" : "3px"};
  }

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 17px;
    padding-left: ${lang === "ru" ? "30px" : "15px"};
  }
`;

// export const Right = styled.div`
//   display: inline-block;
//   width: 10%;
//   font-size: 12px;
//   vertical-align: top;
//   text-align: right;
// `;

// export const Icon = styled.div`
//   margin-top: 16px;

//   &:nth-child(1) {
//     margin-top: 0;
//   }

//   .rating-profit-shield-check-ic {
//     opacity: 0.5;
//     fill: #ffffff;
//     width: 16px;
//     height: 16px;
//   }

//   .rating-profit-warning-ic {
//     opacity: 0.5;
//     fill: #ffffff;
//     width: 16px;
//     height: 14px;
//   }

//   @media (min-width: ${800}px) {
//     margin-top: 7px;

//     &:nth-child(1) {
//       margin-top: 0;
//     }

//     .rating-profit-shield-check-ic {
//       width: 14px;
//       height: 14px;
//     }

//     .rating-profit-warning-ic {
//       width: 14px;
//       height: 11px;
//     }
//   }

//   @media (min-width: ${1366}px) {
//     margin-top: 17px;

//     &:nth-child(1) {
//       margin-top: 2px;
//     }

//     .rating-profit-shield-check-ic {
//       width: 25px;
//       height: 25px;
//     }

//     .rating-profit-warning-ic {
//       width: 25px;
//       height: 21px;
//     }
//   }
// `;
