import styled from "styled-components";

export const StyledHeader = styled.div`
  height: 50px;
  padding: 10px 8px 10px 14px;
  position: relative;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  // z-index: 3;

  @media (min-width: ${800}px) {
    padding: 16px 24px;
    height: 82px;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  // @media (min-width: ${1366}px) {
  //   margin-top: 12px;
  // }
`;

export const InfoSection = styled.div`
  font-size: 10px;
  color: #2eb550;
  line-height: 12px;
  text-overflow: ellipsis;
  font-feature-settings: "cpsp" on, "case" on;
  margin-left: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: ${800}px) {
    font-size: 16px;
    line-height: 19px;
    margin-left: 20px;
  }

  &.disappear {
    display: none;
  }
`;

export const Bets = styled.div`
  color: #fff;
  text-transform: uppercase;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
  margin-left: auto;
`;
