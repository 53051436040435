import React, { FC } from "react";
import { IconProps } from "./types";

export const HistoryArrow: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.956323 0.515625C0.748291 0.515625 0.5488 0.592967 0.401707 0.730467C0.254483 0.867967 0.171875 1.05446 0.171875 1.24904V4.95227C0.175158 5.14612 0.257374 5.33148 0.401707 5.47071L2.7552 7.69048C2.90295 7.82638 3.10153 7.90299 3.30877 7.90397C3.51602 7.90496 3.71539 7.83019 3.86443 7.69564C4.01218 7.55875 4.09571 7.37252 4.09649 7.17817C4.09741 6.9837 4.01559 6.79684 3.86916 6.65875L1.7409 4.65087V1.24904C1.7409 1.05446 1.65816 0.867971 1.51106 0.730471C1.36397 0.592971 1.16446 0.515628 0.956313 0.515628L0.956323 0.515625Z"
        fill="white"
      />
    </svg>
  );
};

export default HistoryArrow;
