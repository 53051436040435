import React, { createContext, FC } from 'react';
import { useGameStatusState } from '../../config/store/state/app.state';
import { GAME_STATE, GameStatus } from '../../constants/interfaces/Game';
import GameMultiplier from './components/GameMultiplier';
import useWindowDimensions from '../../helpers/hooks/windowDimentions';
import { calculateGameBoardDimensions } from './tools';
import GameCoolDown from './state/Cooldown';
import StarsContainer from '../../components/Stars';
import { StyledGameBoardContainer } from './style';
import { useIsMobile } from '../../helpers/hooks/useIsMobile';
import { useErrorMessagesState } from '../errorMessages/store/state';
import ErrorMessages from '../errorMessages/ErrorMessages';
import StarsBackground from '../../assets/images/gradient.png';
import Clouds from '../spaceship/components/Clouds';
import { Flying } from '../spaceship/spaceship';

export const GameContext = createContext({ gameWidth: 300, gameHeight: 400 });

const GameBoard: FC<{ game: GameStatus }> = ({ game }) => {
    const { width, height } = useWindowDimensions();
    const isMobile = useIsMobile();
    const gameDimensions = calculateGameBoardDimensions(width, height);
    const messages = useErrorMessagesState();

    const renderGame = () => {
        switch (game.state) {
            case GAME_STATE.LAUNCHING: {
                return <Flying gameState={game} />;
            }
            case GAME_STATE.RUNNING: {
                return <Flying gameState={game} />;
            }
            case GAME_STATE.CRUSHED: {
                return <Flying gameState={game} />;
            }
            case GAME_STATE.COUNTDOWN: {
                return <GameCoolDown />;
            }
            default:
                return <div />;
        }
    };

    return (
        <StyledGameBoardContainer
            height={window.innerHeight}
            landscapeHeight={window.innerWidth}
        >
            <img
                src={StarsBackground}
                alt="stars-background"
                style={{ opacity: 0.6, zIndex: 22 }}
            />
            <StarsContainer game={true} />
            <GameContext.Provider
                value={{
                    gameWidth: gameDimensions.width,
                    gameHeight: gameDimensions.height,
                }}
            >
                {renderGame()}

                {(game.state === GAME_STATE.COUNTDOWN ||
                    game.state === GAME_STATE.LAUNCHING ||
                    (game.state === GAME_STATE.RUNNING &&
                        game.multiplier &&
                        game.multiplier < 1.9)) && <Clouds />}
            </GameContext.Provider>
            {!!game.multiplier && (
                <GameMultiplier
                    multiplier={game.multiplier}
                    gameState={game.state}
                />
            )}
            {/* @ts-ignore */}
            {!isMobile && messages.length > 0 && <ErrorMessages messages={messages} />}
        </StyledGameBoardContainer>
    );
};

const GameBoardContainer = () => {
    const gameState = useGameStatusState();
    if (!gameState) return <div />;
    return <GameBoard game={gameState} />;
};
export default GameBoardContainer;
