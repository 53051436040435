import React, { memo } from "react";
import styled from "styled-components";
import {
  RegisterState,
  useBoards,
  useFreeBetsState,
} from "../../config/store/state/app.state";
import { useToggleEnableFreeBetsService } from "../../config/store/services";
import ToggleInput from "../../components/ToggleInput/ToggleInput";
import { useBalance } from "../profile/configs/store/state";

const SwitchSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 2;
  position: relative;
`;

// const RemainingBets = styled.div`
//   color: ${({ theme }) => theme.colors.frogGreen2};
//   font-size: 12px;
//   font-weight: bold;
//   margin-right: 4px;
//   position: absolute;
//   left: -25px;
// `;

// export const CustomSwitchInput = styled(ToggleInput)`
//   & > div {
//     .switch-stick-active {
//       background: ${({ theme }) => theme.colors.frogGreen2}3d;
//     }
//     .switch-bullet-active {
//       background: ${({ theme }) => theme.colors.frogGreen2};
//     }
//   }
// `;

export const SwitchContainer = styled.div`
  position: absolute;
  top: -16px;
  left: -1px;

  &.bets-switch {
    position: relative;
    top: auto;
    left: 0;
  }

  @media (min-width: ${800}px) {
    &.bets-switch {
      display: inline-block;
      vertical-align: text-bottom;
    }
  }
`;

const FreeBetsToggler = () => {
  const usFreeBetsEnabled = useFreeBetsState();
  const freeBetsBalance = useBalance().FreeSpinBalance || [];
  const freeSpinTotalCount = freeBetsBalance
    .map((freeSpin) => freeSpin.Count)
    .reduce((a, b) => a + b, 0);
  const isFreeBetsBalanceEmpty =
    freeBetsBalance.length < 1 || freeSpinTotalCount < 1;
  const handleToggleFreeBets = useToggleEnableFreeBetsService();

  const boards = useBoards();
  const disableToggle =
    boards.filter((board) => board.registerState !== RegisterState.UNREGISTERED)
      .length > 0;

  if (isFreeBetsBalanceEmpty) {
    return <span />;
  }

  return (
    <SwitchSection>
      {/* <RemainingBets>{freeSpinTotalCount}x</RemainingBets> */}
      {/* <CustomSwitchInput
        active={usFreeBetsEnabled}
        disabled={disableToggle}
        onChange={() => handleToggleFreeBets(!usFreeBetsEnabled)}
      /> */}
      <SwitchContainer className="bets-switch">
        <ToggleInput
          active={usFreeBetsEnabled}
          disabled={disableToggle}
          onChange={() => handleToggleFreeBets(!usFreeBetsEnabled)}
        />
      </SwitchContainer>
    </SwitchSection>
  );
};

export default memo(FreeBetsToggler);
