import { KEYWORDS } from "../../config/language/keywords";

export enum ErrorListNames {
  Disconnect = "Disconnect",
  ServerConnection = "ServerConnection",
  ServerError = "ServerError",
  SessionHasExpired = "SessionHasExpired",
  TechnicalWork = "TechnicalWork",
  SitesUpdate = "SitesUpdate",
  InternetConnectionLost = "InternetConnectionLost",
  BlockedUser = "BlockedUser",
  LoadingProblem = "LoadingProblem",
}

export const ErrorList = {
  [ErrorListNames.Disconnect]: {
    keyword: KEYWORDS.SameUser,
    action: KEYWORDS.Reload,
  },
  [ErrorListNames.ServerConnection]: {
    keyword: KEYWORDS.ServerConnection,
    action: KEYWORDS.Reload,
  },
  [ErrorListNames.ServerError]: {
    keyword: KEYWORDS.ServerError,
    action: KEYWORDS.Reload,
  },
  [ErrorListNames.SessionHasExpired]: {
    keyword: KEYWORDS.SessionHasExpired,
    action: KEYWORDS.Reload,
  },
  [ErrorListNames.TechnicalWork]: {
    keyword: KEYWORDS.TechnicalWork,
    action: KEYWORDS.Reload,
  },
  [ErrorListNames.TechnicalWork]: {
    keyword: KEYWORDS.TechnicalWork,
    action: KEYWORDS.Reload,
  },
  [ErrorListNames.SitesUpdate]: {
    keyword: KEYWORDS.SitesUpdate,
    action: KEYWORDS.Reload,
  },
  [ErrorListNames.InternetConnectionLost]: {
    keyword: KEYWORDS.InternetConnectionLost,
    action: KEYWORDS.Reload,
  },
  [ErrorListNames.BlockedUser]: {
    keyword: KEYWORDS.BlockedUser,
    action: undefined,
  },
  [ErrorListNames.LoadingProblem]: {
    keyword: KEYWORDS.LoadingProblem,
    action: KEYWORDS.Reload,
  },
  // [ErrorListNames.SameUser]: {
  //   keyword: KEYWORDS.SameUser,
  //   action: KEYWORDS.Reload,
  // },
};
