import { georgian as ka } from './dictionary/georgian';
import { english as en } from './dictionary/english';
import { turkish as tr } from './dictionary/turkish';
import { russian as ru } from './dictionary/russian';
import { ukrainian as uk } from './dictionary/ukrainian';
import { spain as es } from './dictionary/spain';
import { french as fr } from './dictionary/french';
import { portugal as pt } from './dictionary/portugal';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';

export enum LANGUAGES {
    ka = 'ka',
    en = 'en',
    tr = 'tr',
    ru = 'ru',
    uk = 'uk',
    pt = 'pt',
    es = 'es',
    fr = 'fr',
}

export const dictionaryList: any = { ka, en, tr, ru, uk, pt, es, fr };

export const useGetLanguage = () => {
    const pathname = useLocation().pathname;

    return useCallback((): LANGUAGES => {
        const splitUrl = pathname.split('/');
        return splitUrl[1] as LANGUAGES;
    }, [pathname]);
};
