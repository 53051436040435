const CURRENCY_SYMBOL = '___';

export const spain = {
    AccountBanned: "¡Tu cuenta ha sido bloqueada!",
    AllBets: "Todas las apuestas:",
    AlreadyRegistered: "Ya has hecho una apuesta",
    Animation: "Animación",
    AutoBet: "APUESTA AUTOMÁTICA",
    AutoBetting: "Apuesta Automática",
    AutoCashout: "RETIRO AUTOMÁTICO",
    AutoCashouting: "Retiro Automático",
    Balance: "BALANCE",
    BalanceShort: "BAL",
    Banned: "PROHIBIDO",
    BaseBet: "APUESTA BASE",
    Bet: "Apuesta",
    BetMaximum: `La apuesta debe ser máxima ${CURRENCY_SYMBOL}`,
    BetMustBe: `La apuesta debe ser mínima ${CURRENCY_SYMBOL}`,
    Bets: "Apuestas",
    Stakes: "Apuestas",
    BuyIn: "APUESTA",
    Cashout: "Retiro",
    CashoutFromGame: "Retiro",
    CashoutMaxMustBe: "El retiro debe ser máximo ",
    CashoutMustBe: "El retiro debe ser mínimo 1.01",
    ChangeAvatar: "Cambiar avatar",
    CheckFair: "1. ¿Qué es marcar los impares?",
    CheckHash: "3. ¿Cómo comprobar el código de credibilidad?",
    CheckResult: "Comprobar Resultado",
    Circulation: "#",
    Clear: "CLARO",
    Crashed: "EXTRAÑO",
    CurrentBets: "APUESTAS",
    Day: "Día",
    DearUsers: "Disculpe las molestias",
    en: "EN",
    Error: "Error",
    ExampleForFair:
      "Número de juego - 9088 Cuotas - 1.06 Tiempo de generación - 03/12/2019_16:09 Número de juego único - Fv+5TVHK2621A== Como resultado, la clave del juego será: 9088_1.06_Rockit_12/03/2019_16:09_Fvv+5TVHK2621A=  = Y el código de credibilidad generado por el algoritmo SHA256: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d",
    Examples: "Ejemplo:",
    FairGame: "Juego Justo",
    Fill: "Llenar",
    FreeBet: "Apuesta Gratuita",
    GameIsStopped: "El Juego Está Detenido",
    GameKey: "2. ¿Qué significa una clave de juego y un código de credibilidad?",
    ServerConnectionTemp: "Conectando al servidor.",
    SameUser: "El juego está abierto en otra pestaña",
    GameRules: "Reglas del Juego",
    GameStart: "Empieza el juego",
    GameStarted: "El juego empezó - Buena suerte",
    GameStatusIsNotRegistering: "Aceptación de apuestas finalizada.",
    GameWillResume: "Tiempo hasta reanudar",
    GotoHome: "HOGAR",
    Hash: "Código hash",
    History: "Historia",
    HowToCheckFair:
      "Este método nos permite asegurarnos de que las probabilidades del juego (el número en el que termina el juego) estén predeterminadas antes de que los jugadores registren sus apuestas. Hay 5 juegos predeterminados de antemano en un momento dado. El siguiente es un código Hash de 5 rondas pasadas y 5 rondas futuras.",
    HowToCheckGameKey:
      "Las probabilidades del juego (el número en el que terminará el juego) están predeterminadas con 5 juegos de anticipación. Se genera una clave de juego en el servidor del juego, que consta de los siguientes parámetros: [Número de juego] _ [Probabilidades] _ [Rockit] _ [Tiempo de generación] _ [Número de juego único]",
    HowToCheckHash:
      "Una vez finalizado el juego, estará disponible una clave del juego que podrá cifrar en cualquier calculadora SHA256 en línea y luego compararla con el código de credibilidad proporcionado para los próximos 5 juegos.",
    IncreaseBy: "AUMENTADO POR",
    Jackpot: "JACKPOT",
    ka: "KA",
    ua: "UA",
    Key: "Llave",
    LanguageChangeAcception: "¿Estás seguro de que quieres cambiar el idioma?",
    LanguageChangeWarning: "Al presionar el botón se apagará el juego actual",
    LastGames: "Últimos juegos",
    LastHand: "Última mano",
    LastTopSpins: "TOP MULTIPLIERS EN 24 HORAS",
    LastTopWins: "MEJORES GANANCIAS EN 24 HORAS",
    LeaveMessage: "Escribe tu mensaje",
    Login: "ACCESO",
    LoginFailed: "Error de inicio de sesion",
    MainPage: "Pagina principal",
    ManualBet: "MANUAL DE APUESTA",
    MinFreebetCashout: "Mín.  impar 1,5",
    Month: "Mes",
    MultiplierCashout: "Mult.  Retiro",
    Music: "Musica",
    My: "Mis Apuestas",
    No: "No",
    NotEnoughMoney: "Dinero insuficiente",
    OnLoss: "EN PÉRDIDA",
    OnWin: "EN GANAR",
    OpenHere: "Abierto aquí",
    PlaceYourBet: "HAGA SUS APUESTAS",
    Player: "Jugador",
    Rating: "TOP",
    Register: "HAGA SUS APUESTAS",
    Registration: "Recargar",
    Reload: "Recargar",
    Result: "Resultado",
    ResultsHash: "Código hash del resultado",
    ReturnToBaseBet: "VOLVER A LA APUESTA BASE",
    ReturnToCash: "Volver al efectivo",
    Round: "Redondo",
    ru: "RU",
    GameRules1: "Los jugadores pueden hacer apuestas antes de que comience la ronda.",
    GameRules2:
      "Las probabilidades comienzan a aumentar cuando comienza el juego y duran hasta que se agota.",
    GameRules3: "El punto de agotamiento se genera aleatoriamente.",
    GameRules4:
      "Un jugador debe completar el retiro de las ganancias antes del agotamiento.",
    GameRules5:
      "Las apuestas que no se retiren antes del agotamiento se considerarán perdidas.",
    GameRules6:
      "La apuesta de un jugador se multiplicará por las probabilidades con las que se cobró la apuesta antes del agotamiento.",
    GameRules7: `Las probabilidades comienzan a aumentar desde 1.0. En el caso de agotamiento en 1.0, todas las apuestas se consideran perdidas. Por ejemplo, un jugador hace una apuesta de 5${CURRENCY_SYMBOL} y retira el efectivo a 2.0, recibirá 2*5=10${CURRENCY_SYMBOL}. Si las probabilidades se agotan en menos de 2,0, por ejemplo en 1,75, en este caso, un jugador pierde toda la apuesta de 5${CURRENCY_SYMBOL}. En caso de abandonar el juego, la apuesta se cobrará automáticamente según la multiplicación de la apuesta y la altura de las cuotas en ese momento`,
    GameRules8:
      "En caso de abandonar el juego, la apuesta se cobrará automáticamente, en función del producto de la apuesta y el coeficiente de altura actual. La terminación de una sesión de juego por cualquier motivo se considerará como abandono del juego.",
    GameRules9:
      "Un jugador puede realizar dos apuestas al mismo tiempo y retirarlas por separado.",
    GameRules10:
      "El jugador puede realizar una apuesta en las condiciones deseadas, mediante apuestas automáticas. En la ventana existente, es posible determinar el monto de la apuesta, el coeficiente de retiro y el monto máximo de retiro. Además, las condiciones de la siguiente apuesta se pueden determinar de antemano en caso de ganar o perder.",
    GameRules11: "Las ganancias se reflejan automáticamente en el saldo del jugador.",
    GameRules12:
      "El jugador puede ver el historial del juego haciendo clic en el botón 'Mis apuestas'.",
    GameRules13:
      "En caso de un error comprobado en el juego, la parte del juego se reserva el derecho de retener o corregir las ganancias estimadas del jugador.",
    GameRules16: "Límites del juego:",
    GameRules17: `apuesta mínima - 0.10 ${CURRENCY_SYMBOL}`,
    GameRules18: `apuesta máxima - 200 ${CURRENCY_SYMBOL}`,
    GameRules19: `Ganancia máxima de un jugador (apuesta*probabilidades) - 30 000${CURRENCY_SYMBOL}, cuando la apuesta multiplicada por las probabilidades alcanza 30 000 ₾${CURRENCY_SYMBOL}, el sistema cobrará automáticamente la apuesta activa`,
    GameRules20: `Cuando las apuestas realizadas por más de dos jugadores multiplicadas por las probabilidades alcancen los ${CURRENCY_SYMBOL}100 000 en una ronda, el sistema pagará automáticamente todas las apuestas activas.`,
    GameRules21: "El multiplier mínimo del juego es 1.",
    GameRules22: "El multiplier máximo del juego es 1.102.008.",
    GameRules23: "El RTP del juego es 95,05%",
    GameRules24:
      "El multiplier mínimo del retiro automático es x1.1, y el multiplicador máximo del retiro automático es x1,102,008.",
    GameRules25:
      "El multiplicador mínimo de retiro es x1.01, y el multiplier máximo de retiro es x1,102,008.",
    GameRules26:
      "El jugador puede encontrar las últimas probabilidades del juego en la sección superior de la página.",
    GameRules27:
      "El jugador puede ver la calificación de los jugadores haciendo clic en el botón 'Calificación'.",
    GameRules28:
      "En caso de que el coeficiente de retiro y el coeficiente de colisión sean iguales, el jugador gana.",
    GameRules29:
      "En caso de abandonar el juego voluntariamente, la apuesta se cobrará automáticamente, en función del producto de la apuesta y el coeficiente de altura actual. En caso de una desconexión de la red, se mostrará una cuenta regresiva de 10 a 1 y el jugador puede optar por retirar dinero o continuar si se reanuda la conectividad. En caso de no acción, se considerará que el juego pierde la apuesta.",
    Save: "Guardar",
    ServerConnection: "Conectando al servidor",
    InternetConnectionLost:
      "Hubo un problema con su conexión a Internet, actualice la página.",
    ServerError: "Error del Servidor",
    SessionHasExpired: "La sesión ha caducado",
    SitesUpdate: "¡MANTENIMIENTO PROGRAMADO!",
    Sound: "Sonido",
    Statistics: "Estadísticas",
    StopIf: "DETENER SI APUESTA >",
    Submit: "Entregar",
    SuccessfulRegistration: "Apuesta realizada",
    TechnicalWork: "El juego que solicitaste volverá pronto.",
    TicketCashoutMustBe: "El retiro debe ser de al menos 1.5",
    Time: "Tiempo",
    TopSpins: "Mejores probabilidades",
    TopWins: "Ganancias",
    tr: "TR",
    UnknowError: "Error del Servidor",
    Unregister: "Cancelar apuesta",
    UnregisterFreeBet: "Cancelar apuesta gratuita",
    Unregistered: "Apuesta cancelada",
    User: "Jugador",
    UserName: "Nombre de Usuario",
    UserShort: "US",
    WaitingForNextRound: "Estás esperando la próxima ronda",
    Win: "Ganancias",
    WinAmount: "Ganancia",
    WinMultiplier: "Ganó Mult.",
    Winners: "Ganadores",
    WrongAmount: "Cantidad Incorrecta",
    WrongConfiguration: "Configuración Incorrecta",
    Year: "Año",
    Yes: "Si",
    YouAreNotRegistered: "No Estás Registrado en este juego",
    EnterText: "Ingrese Texto",
    PlayerNotFound: "Jugador no Encontrado",
    Jan: "Ene.",
    Feb: "Feb.",
    Mar: "Mar.",
    Apr: "Abri.",
    May: "May.",
    June: "Junio",
    July: "Julio",
    Aug: "Ago.",
    Sept: "Set.",
    Oct: "Oct.",
    Nov: "Nov.",
    Dec: "Dec.",
    BlockedUser: "Tu cuenta está bloqueada",
    LoadingProblem:
      "No se pudo cargar el juego, recarga la página.",
    January: "Enero",
    February: "Febrero",
    March: "Marzo",
    April: "Abril",
    MayLong: "Mayo",
    JuneLong: "Junio",
    JulyLong: "Julio",
    August: "Agosto",
    September: "Setiembre",
    Octomber: "Octubre",
    November: "Noviembre",
    December: "Diciembre",
    Auto: "Auto",
    Game: "Juego",
    BetHistory: "Historial de Apuestas",
    ViewTable: "Ver Tabla",
    TotalProfit: "Beneficio Total",
    WeakInternetAlert: "Conexión a Internet débil",
    PageWillReload: "La pagina se recargara",
    InSeconds: "Seconds",
}
  