import React, { FC, useState } from 'react';
import useTranslate from '../../config/language';
import { KEYWORDS } from '../../config/language/keywords';
import { useProfile } from '../../features/profile/configs/store/state';
import { ArrowRight } from '../Icons/ArrowRight';
// import List from "../Icons/List";
import Rule from '../Icons/Rule';
import Shield from '../Icons/Shield';
import {
    PopupWrap,
    MenuDropdown,
    MenuAvatar,
    UserName,
    MainMenu,
    Item,
    Left,
    ButtonWrapper,
    Button,
    Blur,
} from './Popup.styles';
import GameRulesModal from '../../features/profile/components/GameRulesModal/GameRulesModal';
import ProvablyFairModal from '../../features/profile/components/ProvablyFairModal/ProvablyFairModal';
import ProvablyFairDataModal from '../../features/profile/components/ProvablyFairDataModal';
import RoundModal from '../../features/profile/components/RoundModal/RoundModal';
import AvatarsModal from '../../features/profile/components/AvatarsModal/AvatarsModal';
import ProfileAvatar from './ProfileAvatar';
import VolumeHigh from '../Icons/VolumeHigh';
import ToggleInput from '../ToggleInput/ToggleInput';
import Music from '../Icons/Music';
import Video from '../Icons/Video';
import { useGameConfigs } from '../../config/store/state/app.state';
import {
    useToggleConfigAnimationService,
    useToggleConfigBackgroundMusicService,
    useToggleConfigVoiceService,
} from '../../features/profile/configs/store/services';
import { useSaveSettingsService } from '../../config/store/services';
import ClickAwayListener from '../../helpers/hooks/clickawayListener';
import BetsModal from '../../features/profile/components/BetsModal/BetsModal';
import BetsHistory from '../Icons/BetsHistory';

const Popup: FC<{
    isMenuOpen: boolean;
    setIsMenuOpen: (item: boolean) => void;
    bodyRef: any;
}> = ({ isMenuOpen, setIsMenuOpen, bodyRef }) => {
    const configs = useGameConfigs();
    const { Player } = useProfile();
    const translate = useTranslate();
    const handleAnimate = useToggleConfigAnimationService();
    const handleBackgroundMusic = useToggleConfigBackgroundMusicService();
    const handleVoice = useToggleConfigVoiceService();
    const saveGameSettings = useSaveSettingsService();
    const profile = useProfile().Player;

    // const [fullScreen, setFullScreen] = useState(false);

    const [modalOpen, setModalOpen] = useState({
        ProvablyFair: false,
        ProvablyFairData: false,
        GameRules: false,
        BetHistory: false,
    });
    const [roundModalOpen, setRoundModalOpen] = useState(false);
    const [changeAvatarOpen, setChangeAvatarOpen] = useState(false);

    const handleChangeBackgroundMusic = (on: boolean) => {
        handleBackgroundMusic(on);
        saveGameSettings({
            Avatar: profile?.A || 0,
            Sound: configs.voiceEffects,
            Music: on,
            Animate: configs.animate,
        });
    };

    const handleChangeVoiceEffects = (on: boolean) => {
        handleVoice(on);
        saveGameSettings({
            Avatar: profile?.A || 0,
            Sound: on,
            Music: configs.backgroundMusic,
            Animate: configs.animate,
        });
    };

    const handleChangeAnimations = (on: boolean) => {
        handleAnimate(on);
        saveGameSettings({
            Avatar: profile?.A || 0,
            Sound: configs.voiceEffects,
            Music: configs.backgroundMusic,
            Animate: on,
        });
    };

    return (
        <>
            <PopupWrap
                className={`${
                    Object.values(modalOpen).some(value => value === true) ||
                    roundModalOpen ||
                    isMenuOpen
                        ? 'blur'
                        : ''
                } ${modalOpen.BetHistory ? 'bets' : ''}`}
            >
                <Blur
                    onClick={() => {
                        setIsMenuOpen(false);
                        setModalOpen(state => ({
                            ...state,
                            GameRules: false,
                        }));
                        setModalOpen(state => ({
                            ...state,
                            ProvablyFairData: false,
                        }));
                        setModalOpen(() => ({
                            ProvablyFair: false,
                            GameRules: false,
                            ProvablyFairData: false,
                            BetHistory: false,
                        }))
                    }}
                />
      
                {isMenuOpen && (
                    <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
                        <MenuDropdown height={window.innerHeight}>
                            <MenuAvatar>
                                <ProfileAvatar avatarId={Player?.A || 0} />
                                <UserName>{Player && Player.N}</UserName>
                            </MenuAvatar>

                            <ButtonWrapper>
                                <Button
                                    onClick={() => {
                                        setIsMenuOpen(false);
                                        setChangeAvatarOpen(true);
                                    }}
                                >
                                    {translate(KEYWORDS.ChangeAvatar)}
                                </Button>
                            </ButtonWrapper>

                            <MainMenu>
                                <Item>
                                    <Left>
                                        <VolumeHigh className="main-menu-ic" />
                                        {translate(KEYWORDS.Sound)}
                                    </Left>

                                    <ToggleInput
                                        active={configs.voiceEffects}
                                        onChange={() =>
                                            handleChangeVoiceEffects(
                                                !configs.voiceEffects
                                            )
                                        }
                                    />
                                </Item>

                                <Item>
                                    <Left>
                                        <Music className="main-menu-ic" />
                                        {translate(KEYWORDS.Music)}
                                    </Left>

                                    <ToggleInput
                                        active={configs.backgroundMusic}
                                        onChange={() =>
                                            handleChangeBackgroundMusic(
                                                !configs.backgroundMusic
                                            )
                                        }
                                    />
                                </Item>

                                <Item>
                                    <Left>
                                        <Video className="main-menu-ic" />
                                        {translate(KEYWORDS.Animation)}
                                    </Left>

                                    <ToggleInput
                                        active={configs.animate}
                                        onChange={() =>
                                            handleChangeAnimations(
                                                !configs.animate
                                            )
                                        }
                                    />
                                </Item>

                                <Item
                                    onClick={() => {
                                        setIsMenuOpen(false);
                                        setModalOpen(() => ({
                                            ProvablyFair: true,
                                            ProvablyFairData: false,
                                            GameRules: false,
                                            BetHistory: false,
                                        }));
                                    }}
                                >
                                    <Left>
                                        <Shield className="main-menu-ic fill" />
                                        Provably Fair
                                    </Left>
                                    <ArrowRight />
                                </Item>

                                <Item
                                    onClick={() => {
                                        setIsMenuOpen(false);
                                        setModalOpen(() => ({
                                            ProvablyFair: false,
                                            ProvablyFairData: false,
                                            GameRules: true,
                                            BetHistory: false,
                                        }));
                                    }}
                                >
                                    <Left>
                                        <Rule className="main-menu-ic rules fill" />
                                        {translate(KEYWORDS.GameRules)}
                                    </Left>
                                    <ArrowRight />
                                </Item>

                                {/* <Item
                onClick={() => {
                  setIsMenuOpen(false);
                  setModalOpen(() => ({
                    ProvablyFair: false,
                    GameRules: false,
                    ProvablyFairData: true,
                    BetHistory: false,
                  }));
                }}
                className="mobile"
              >
                <Left>
                  <List className="main-menu-ic fill" />
                  {translate(KEYWORDS.Table)}
                </Left>
                <ArrowRight />
              </Item> */}

                                <Item
                                    className="mobile"
                                    onClick={() => {
                                        setIsMenuOpen(false);
                                        setModalOpen(() => ({
                                            ProvablyFair: false,
                                            GameRules: false,
                                            ProvablyFairData: false,
                                            BetHistory: true,
                                        }));
                                    }}
                                >
                                    <Left>
                                        <BetsHistory className="main-menu-ic fill" />
                                        {translate(KEYWORDS.BetHistory)}
                                    </Left>
                                    <ArrowRight />
                                </Item>

                                {/* {!(platform === "iOS") && (
                  <Item
                    className="mobile"
                    onClick={() =>
                      !fullScreen ? openFullscreen() : closeFullscreen()
                    }
                  >
                    <Left>FULL SCREEN</Left>
                  </Item>
                )} */}
                            </MainMenu>
                        </MenuDropdown>
                    </ClickAwayListener>
                )}

                {modalOpen.GameRules && (
                    <GameRulesModal
                        close={() =>
                            setModalOpen(state => ({
                                ...state,
                                GameRules: false,
                            }))
                        }
                    />
                )}

                {modalOpen.ProvablyFair && (
                    <ProvablyFairModal
                        openData={() =>
                            setModalOpen(state => ({
                                ...state,
                                ProvablyFair: false,
                                ProvablyFairData: true,
                            }))
                        }
                        close={() =>
                            setModalOpen(state => ({
                                ...state,
                                ProvablyFair: false,
                            }))
                        }
                    />
                )}

                {modalOpen.ProvablyFairData && (
                    <ProvablyFairDataModal
                        back={() =>
                            setModalOpen(() => ({
                                ProvablyFair: true,
                                GameRules: false,
                                ProvablyFairData: false,
                                BetHistory: false,
                            }))
                        }
                        close={() =>
                            setModalOpen(state => ({
                                ...state,
                                ProvablyFairData: false,
                            }))
                        }
                    />
                )}

                {modalOpen.BetHistory && (
                    <ClickAwayListener
                        onClickAway={() =>
                            setModalOpen(state => ({
                                ...state,
                                BetHistory: false,
                            }))
                        }
                    >
                        <BetsModal
                            close={() =>
                                setModalOpen(state => ({
                                    ...state,
                                    BetHistory: false,
                                }))
                            }
                        />
                    </ClickAwayListener>
                )}

                {roundModalOpen && (
                    <RoundModal close={() => setRoundModalOpen(false)} />
                )}
            </PopupWrap>
            {changeAvatarOpen && (
                <AvatarsModal closeModal={() => setChangeAvatarOpen(false)} />
            )}
        </>
    );
};

export default Popup;
