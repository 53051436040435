import { useMaxMinConfig } from "../features/actionPanel/configs/state";

const currencies: any = {
    'BDT' :	'৳',
    'BYN' :	'Rbl',
    'MDL' :	'L',
    'TJS' :	'TJS',
    'TRY' :	'₺',
    'TZS' :	'TSh',
    'UXBT' :	'UXBT',
    'AUD' :	'$',
    'AZN' :	'₼',
    'BRL' :	'R$',
    'CAD' :	'$',
    'EUR' :	'€',
    'INR' :	'₹',
    'IDR' :	'Rp',
    'IRR' :	'﷼',
    'KZT' :	'лв',
    'KGS' :	'лв',
    'MYR' :	'RM',
    'MXN' :	'$',
    'NPR' :	'₨',
    'NZD' :	'$',
    'NGN' :	'₦',
    'NOK' :	'kr',
    'PKR' :	'₨',
    'PEN' :	'S/.',
    'PLN' :	'zł',
    'LKR' :	'₨',
    'SEK' :	'kr',
    'THB' :	'฿',
    'UAH' :	'₴',
    'GBP' :	'£',
    'USD' :	'$',
    'UZS' :	'лв',
    'VND' :	'₫',
    'GEL' :	'₾',
}

export const CurrencySymbol = (): string => {
    const minMaxValue = useMaxMinConfig();
    const result = minMaxValue && minMaxValue?.Ccy ? currencies[minMaxValue?.Ccy] : '₾'
    return result
}
