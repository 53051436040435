import React, { FC } from "react";
import styled from "styled-components";
import ArrowLeft from "../../../components/Icons/ArrowLeft";

export const BackWrapper = styled.div`
  position: absolute;
  display: block;
  left: 6px;
  top: 17px;

  .popup-back-ic {
    width: 8px;
    height: 14px;
    fill: #ffffff;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      fill: #17ae43;
    }
  }

  @media (min-width: ${1366}px) {
    top: 37px;
    left: 4px;

    .popup-back-ic {
      width: 24px;
      height: 24px;
    }
  }

  @media (min-width: ${1920}px) {
    top: 21px;
    left: 4px;

    .popup-back-ic {
      width: 19px;
      height: 24px;
    }
  }
`;

const Back: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <BackWrapper onClick={onClick}>
      <ArrowLeft className="popup-back-ic" />
    </BackWrapper>
  );
};

export default Back;
