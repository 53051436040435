import React, { memo } from "react";
import styled from "styled-components";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-right: 6px;
`;

const FreeBetsHandler = () => {
  const translate = useTranslate();
  return (
    <>
      <Title>{translate(KEYWORDS.FreeBet)}</Title>
    </>
  );
};

export default memo(FreeBetsHandler);
