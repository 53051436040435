import React, { FC } from "react";
import { IconProps } from "./types";

export const BetsHistory: FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      //   xmlns:svgjs="http://svgjs.com/svgjs"
      //   width="512"
      //   height="512"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      //   style="enable-background:new 0 0 512 512"
      //   xml:space="preserve"
      //   class=""
      className={className}
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg" fill="rgb(0,0,0)">
          <path
            d="m13.1 1a10.927 10.927 0 0 0 -10.534 8.223l-.732-1.107a1 1 0 1 0 -1.668 1.1l2.2 3.334a1.084 1.084 0 0 0 .634.425 1.024 1.024 0 0 0 .756-.145l3.3-2.223a1 1 0 1 0 -1.115-1.659l-1.501 1.012a8.909 8.909 0 1 1 8.66 11.04 8.892 8.892 0 0 1 -7.281-3.822 1 1 0 1 0 -1.64 1.143 10.881 10.881 0 0 0 19.821-6.321 10.963 10.963 0 0 0 -10.9-11z"
            fill="#6d5cce"
            // data-original="#000000"
            // class=""
          ></path>
          <path
            d="m13 5.95a1 1 0 0 0 -1 1v5.05a1.04 1.04 0 0 0 .293.707l3 3.027a1.013 1.013 0 0 0 1.414.007 1 1 0 0 0 .006-1.414l-2.713-2.738v-4.639a1 1 0 0 0 -1-1z"
            fill="#6d5cce"
            // data-original="#000000"
            // class=""
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default BetsHistory;
