import React, { FC } from "react";
import styled from "styled-components";
import { TOP_LIST_FILTER_INDEXES } from "../configs/tools";
import { TopWinnings } from "../../../../constants/interfaces/TopWinnings";
import WinningTableItem from "../components/WinningTableItem";

const Container = styled.div`
  padding: 18px 0px 15px;
  padding-top: 10px;
`;

const TableOverflow = styled.div`
  max-height: calc(88vh - 242px);
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 2px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    // border: 3px solid transparent;
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  @media (min-width: ${800}px) {
    height: calc(100vh - 260px);
    max-height: none;
  }

  @media (min-width: ${1366}px) {
    height: calc(100vh - 320px);
  }

  @media (min-width: ${1920}px) {
    height: calc(100vh - 296px);
  }
`;

const Table = styled.div`
  padding: 0 10px;
`;

const WinningsList: FC<{
  items?: TopWinnings;
  selectedFilter: TOP_LIST_FILTER_INDEXES;
}> = ({ items, selectedFilter }) => {
  return (
    <Container>
      <TableOverflow>
        <Table>
          {items &&
            items[selectedFilter].map((game, index) => {
              return <WinningTableItem key={index} game={game} />;
            })}
        </Table>
      </TableOverflow>
    </Container>
  );
};

export default WinningsList;
