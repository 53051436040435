import React, { FC } from "react";
import PagePopup from "../../../../components/PagePopup/PagePopup";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
import Lock from "../../../../components/Icons/Lock";
import Hash from "../../../../components/Icons/Hash";
import {
  Scroll,
  Item,
  Label,
  Code,
  InfoText,
  RoundLab,
  Row,
  Key,
} from "./RoundModal.styles";

const RoundModal: FC<{ close: () => void }> = ({ close }) => {
  const translate = useTranslate();

  return (
    <PagePopup
      title={`${translate(KEYWORDS.Round)} 773451`}
      close={close}
      round
    >
      <Scroll>
        <Item className="first">
          <Label>{translate(KEYWORDS.ResultsHash)}</Label>
          <Code>
            <Hash className="info-icon" />
            <InfoText>320036c4d3bb35888fd3cb3acc59e69</InfoText>
          </Code>
        </Item>

        <Item>
          <Label>{translate(KEYWORDS.CheckResult)}</Label>
          <Code>
            <Lock className="lock" />

            <Row>
              <RoundLab>{translate(KEYWORDS.Key)}</RoundLab>
              <RoundLab>{translate(KEYWORDS.Result)}</RoundLab>
            </Row>
            <Row className="bordered">
              <Key>320036c4d3bb35888fd3cb3acc</Key>
              <Key className="coeff">2.97x</Key>
            </Row>
          </Code>
        </Item>
      </Scroll>
    </PagePopup>
  );
};

export default RoundModal;
