import React, { FC } from "react";
import ArrowRight from "../../../../components/Icons/ArrowRight";
import List from "../../../../components/Icons/List";
import ShieldPF from "../../../../components/Icons/ShieldPF";
import PagePopup from "../../../../components/PagePopup/PagePopup";
import useTranslate from "../../../../config/language";
import { KEYWORDS } from "../../../../config/language/keywords";
import {
  LinkTo,
  Link,
  ContentWrapper,
  Questions,
  Item,
  Title,
  Desc,
} from "./ProvablyFairModal.styles";

const ProvablyFairModal: FC<{ openData: () => void; close: () => void }> = ({
  openData,
  close,
}) => {
  const translate = useTranslate();

  return (
    <PagePopup
      title="PROVABLY FAIR"
      icon={<ShieldPF className="popup-head-ic" />}
      close={close}
      pf={true}
    >
      <LinkTo onClick={openData}>
        <Link>
          <List className="popup-link-ic" />
          {translate(KEYWORDS.ViewTable)}
          <ArrowRight className="popup-link-arrow" />
        </Link>
      </LinkTo>

      <ContentWrapper height={window.innerHeight}>
        <Questions>
          <Item className="questions-item-first">
            <Title>{translate(KEYWORDS.CheckFair)}</Title>
            <Desc>{translate(KEYWORDS.HowToCheckFair)}</Desc>
          </Item>

          <Item>
            <Title>{translate(KEYWORDS.GameKey)}</Title>
            <Desc>{translate(KEYWORDS.HowToCheckGameKey)}</Desc>
          </Item>

          <Item>
            <Title>{translate(KEYWORDS.Examples)}</Title>
            <Desc>{translate(KEYWORDS.ExampleForFair)}</Desc>
          </Item>

          <Item>
            <Title>{translate(KEYWORDS.CheckHash)}</Title>
            <Desc>{translate(KEYWORDS.HowToCheckHash)}</Desc>
          </Item>

          {/* <Item>
            <Title>{translate(KEYWORDS.GameKey)}</Title>
            <Desc>{translate(KEYWORDS.HowToCheckGameKey)}</Desc>
          </Item> */}
        </Questions>
      </ContentWrapper>
    </PagePopup>
  );
};

export default ProvablyFairModal;
