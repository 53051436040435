import React, { FC } from "react";
import { ListStatus, ListUser } from "../../../../constants/interfaces/List";
import { roundNumber } from "../../../../helpers/functions/round-number";
import { CurrencySymbol } from "../../../../constants/currency";
import { TableRow, StyledTd, Coeff } from "./RenderMyBetsTableRows.styles";
import { useIsMobile } from "../../../../helpers/hooks/useIsMobile";

const RenderMyBetsTableRows: FC<{ items: Partial<ListUser>[] }> = ({
  items,
}): any => {
  const isMobile = useIsMobile();
  const CURRENCY = CurrencySymbol()

  return (
    items &&
    items.map((game, index) => {
      return (
        <TableRow
          key={JSON.stringify(game + `${index}`)}
          className={`${
            game.status === ListStatus.WON ? "table-bets-tr-highlight" : ""
          } ${isMobile ? "gradient-fixed" : ""}`}
        >
          <StyledTd className="bets-my-date">{game.time}</StyledTd>

          <StyledTd className="bets-my-bet">
            {roundNumber(game.bet)}
            {CURRENCY}
          </StyledTd>

          <StyledTd className="bets-my-coeffc">
            <Coeff>{game.odds ? `${game.odds}X` : "-"}</Coeff>
          </StyledTd>

          <StyledTd className="bets-my-profit">
            {game.profit
              ? `${roundNumber(game.profit)}${CURRENCY}`
              : "-"}
          </StyledTd>
        </TableRow>
      );
    })
  );
};

export default RenderMyBetsTableRows;
