import React, { FC } from "react";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import {
  AutoLabel,
  ButtonInput,
  Column,
  Columns,
  SliderHandle,
  Tab,
} from "../../../styles";
import Range from "../../../Range";

const RegisterPanel: FC<{
  handleRegister(): void;
  disabled?: boolean;
  amount: number;
  setAmount(amount: number | null): void;
  minValue: number;
  maxValue: number;
  autoCashoutMinValue?: number;
  autoCashutMaxValue: number;
  autoCashOutValue: number;
  handleAutoCashOutValueChange(amount: number | null): void;
}> = ({
  handleRegister,
  disabled,
  amount,
  setAmount,
  minValue,
  maxValue,
  autoCashoutMinValue,
  autoCashutMaxValue,
  autoCashOutValue,
  handleAutoCashOutValueChange,
}) => {
  const translate = useTranslate();

  return (
    <Tab className="game-nums-twocolumcn-content">
      <Columns>
        <Column>
          <NumberInput
            value={amount}
            onChange={setAmount}
            minValue={minValue}
            maxValue={maxValue}
            maxLength={maxValue.toString().length + 3}
            disabled={disabled}
            className="two-columns first"
          />
          <AutoLabel className="first">
            {translate(KEYWORDS.AutoBetting)}
          </AutoLabel>

          <Range
            className="game-numslider-twocolumn"
            value={amount}
            baseHalfPoint={20}
            min={minValue}
            max={maxValue}
            onChange={setAmount}
            disabled={disabled}
          />
        </Column>

        <Column>
          <NumberInput
            suffix={" x"}
            value={autoCashOutValue}
            onChange={handleAutoCashOutValueChange}
            minValue={autoCashoutMinValue}
            maxValue={autoCashutMaxValue}
            maxLength={autoCashutMaxValue.toString().length + 3}
            disabled={disabled}
            className="two-columns"
            step={0.1}
            without
          />
         
          <AutoLabel>{translate(KEYWORDS.AutoCashouting)}</AutoLabel>

          <SliderHandle
            type="range"
            className="game-numslider-twocolumn"
            min={autoCashoutMinValue}
            max={1001}
            value={autoCashOutValue}
            onChange={(e) =>
              handleAutoCashOutValueChange(Math.round(+e.target.value))
            }
            disabled={disabled}
          />
        </Column>
      </Columns>

      <ButtonInput
        text={translate(KEYWORDS.Bet)}
        onClick={handleRegister}
        disabled={disabled}
        className="register-bet"
      />
    </Tab>
  );
};

export default RegisterPanel;
