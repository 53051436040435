import {
  ACTIONS,
  boardAddedAction,
  boardRemovedAction,
  cashOutAction,
  currentMultiplierAction,
  gameCreatedAction,
  gameCrushedAction,
  gameErrorAction,
  gameStartedAction,
  initializeGameSettingsAction,
  initializeGameStateAction,
  loadGameAction,
  pendingAction,
  playerBalanceChangeAction,
  playerCashedOutAction,
  playerRegisteredAction,
  PlayerRegisterErrorStatuses,
  playerUnregisteredAction,
  registerAction,
  registerFailAction,
  registerSuccessAction,
  saveSettingsAction,
  setLaunchingAction,
  toggleEnableFreeBetsAction,
  unregisterAction,
  unregisterActionFail,
  unregisterActionSuccess,
  unregisterLocalAction,
  pingAction,
  initializeTenantAction,
} from "../actions/app.actions";
import { Reducer } from "react";
import {
  GAME_STATE,
  GAME_STATE_BY_INDEX,
} from "../../../constants/interfaces/Game";
import { ListStatus, ListUser } from "../../../constants/interfaces/List";
import { GameState, RegisterState } from "../state/app.state";
import { formatUsername } from "../../../helpers/functions/format-username";
import {
  initializeMyBetsAction,
  MY_BETS_ACTIONS,
} from "../../../features/topBoards/myBets/configs/store/actions";
import {
  GAME_CONFIG,
  initializeMinMaxValue,
} from "../../../features/actionPanel/configs/actions";
import {
  BETS_ACTIONS,
  initializeCurrentBetsAction,
  initializeLastBetsAction,
} from "../../../features/topBoards/bets/configs/store/actions";
import {
  calculateCashedOutAmount,
  mapCurrentBetsValues,
  mapLastBetsValues,
} from "../../../features/topBoards/bets/configs/helpers";
import {
  initializeTopMultiplierCashOutsAction,
  initializeTopOddsAction,
  initializeTopWinningsAction,
  TOP_BETS_ACTIONS,
} from "../../../features/topBoards/topBets/configs/store/actions";
import {
  GAMES_HISTORY_ACTIONS,
  initializeGamesHistoryAction,
} from "../../../features/oddsList/configs/store/actions";
import { GameHistory } from "../../../constants/interfaces/GameHistory";
import {
  initializeNextGamesAction,
  NEXT_GAMES_ACTIONS,
} from "../../../features/nextGames/configs/store/actions";
import {
  initializeProfileAction,
  PROFILE_ACTIONS,
  toggleConfigAnimationAction,
  toggleConfigBackgroundMusicAction,
  toggleConfigVoiceAction,
} from "../../../features/profile/configs/store/actions";
import { roundNumber } from "../../../helpers/functions/round-number";
import { ErrorListNames } from "../../../components/Modals/ErrorList";
import { ETenantType } from "../../../constants/interfaces/Tenant";

type GameActions =
  | ReturnType<typeof gameCreatedAction>
  | ReturnType<typeof gameStartedAction>
  | ReturnType<typeof gameCrushedAction>
  | ReturnType<typeof setLaunchingAction>
  | ReturnType<typeof currentMultiplierAction>
  | ReturnType<typeof playerRegisteredAction>
  | ReturnType<typeof playerUnregisteredAction>
  | ReturnType<typeof playerCashedOutAction>
  | ReturnType<typeof registerAction>
  | ReturnType<typeof registerSuccessAction>
  | ReturnType<typeof registerFailAction>
  | ReturnType<typeof pendingAction>
  | ReturnType<typeof unregisterAction>
  | ReturnType<typeof unregisterLocalAction>
  | ReturnType<typeof unregisterActionSuccess>
  | ReturnType<typeof unregisterActionFail>
  | ReturnType<typeof cashOutAction>
  | ReturnType<typeof boardAddedAction>
  | ReturnType<typeof boardRemovedAction>
  | ReturnType<typeof playerBalanceChangeAction>
  | ReturnType<typeof initializeCurrentBetsAction>
  | ReturnType<typeof initializeLastBetsAction>
  | ReturnType<typeof initializeMyBetsAction>
  | ReturnType<typeof initializeMinMaxValue>
  | ReturnType<typeof initializeTopOddsAction>
  | ReturnType<typeof initializeTopWinningsAction>
  | ReturnType<typeof initializeTopMultiplierCashOutsAction>
  | ReturnType<typeof initializeGamesHistoryAction>
  | ReturnType<typeof initializeNextGamesAction>
  | ReturnType<typeof initializeProfileAction>
  | ReturnType<typeof toggleConfigBackgroundMusicAction>
  | ReturnType<typeof toggleConfigAnimationAction>
  | ReturnType<typeof toggleConfigVoiceAction>
  | ReturnType<typeof toggleEnableFreeBetsAction>
  | ReturnType<typeof loadGameAction>
  | ReturnType<typeof initializeGameStateAction>
  | ReturnType<typeof initializeTenantAction>
  | ReturnType<typeof saveSettingsAction>
  | ReturnType<typeof initializeGameSettingsAction>
  | ReturnType<typeof gameErrorAction>
  | ReturnType<typeof pingAction>;

const initialAppState: GameState = {
  currentBets: [],
  lastBets: [],
  gameHistory: [],
  top: {
    odds: undefined,
    winnings: undefined,
    multiCashOut: undefined,
  },
  configs: {
    animate: true,
    backgroundMusic: false,
    voiceEffects: false,
  },
  status: { state: GAME_STATE.INITIAL, duration: 6 },
  boards: [
    { PanelIndex: 0, registerState: RegisterState.UNREGISTERED },
    { PanelIndex: 1, registerState: RegisterState.UNREGISTERED },
    { PanelIndex: 2, registerState: RegisterState.UNREGISTERED },
    { PanelIndex: 3, registerState: RegisterState.UNREGISTERED },
    { PanelIndex: 4, registerState: RegisterState.UNREGISTERED },
  ],
  profile: {
    Player: {
      I: 0,
      N: "",
      A: "",
      B: false,
    },
    B: {
      WalletBalance: 0,
      FreeSpinBalance: [],
    },
  },
  freeBetsEnabled: false,
  loading: true,
  errors: {
    isError: false,
    type: ErrorListNames.Disconnect,
  },
  tenant: {
    Type: ETenantType.Local,
  },
  handlecashoutState: 0,
};

export const gameReducers: Reducer<GameState, GameActions> = (
  state: GameState = initialAppState,
  action: GameActions
): GameState => {
  switch (action.type) {
    case ACTIONS.GAME_CREATED: {
      const { GameId, NextGames } = action.payload;

      let updatedGameHistory = [...state.gameHistory];

      updatedGameHistory = updatedGameHistory
        .filter((obj, index) => {
          return (
            index ===
            updatedGameHistory.findIndex((o) => obj.TimeStamp === o.TimeStamp)
          );
        })
        .sort(
          (a, b) =>
            new Date(b.TimeStamp || "").valueOf() -
            new Date(a.TimeStamp || "").valueOf()
        );

      if (state.lastGame) {
        updatedGameHistory.unshift(state.lastGame);
        if (updatedGameHistory.length > 25) {
          updatedGameHistory.pop();
        }
      }

      return {
        ...state,
        status: {
          ...state.status,
          id: GameId,
          state: GAME_STATE.COUNTDOWN,
          multiplier: null,
        },
        lastBets:
          state.currentBets.length > 0 ? state.currentBets : state.lastBets,
        currentBets: [],
        nextGames: NextGames,
        gameHistory: updatedGameHistory,
      };
    }
    case ACTIONS.GAME_STARTED: {
      const updatedBoards = [...state.boards].map((board) => {
        if (board.registerState === RegisterState.UNREGISTER_INITIATED) {
          return {
            ...board,
            registerState: RegisterState.REGISTERED,
            wonLastGame: false,
          };
        } else if (board.registerState !== RegisterState.REGISTERED) {
          return {
            ...board,
            registerState: RegisterState.UNREGISTERED,
            wonLastGame: false,
          };
        } else {
          return { ...board, wonLastGame: false };
        }
      });

      return {
        ...state,
        status: {
          ...state.status,
          state: GAME_STATE.RUNNING,
          id: action.payload.GameId,
          multiplier: 1,
          durationLeft: 0,
        },
        boards: updatedBoards,
      };
    }
    case ACTIONS.GAME_CRUSHED: {
      const { Multiplier, HashKey, GameId, Hash } = action.payload;
      const updatedCurrentBets: ListUser[] = state.currentBets.map((user) => {
        if (user.status !== ListStatus.WON) {
          return {
            ...user,
            status: ListStatus.LOST,
          };
        } else {
          return user;
        }
      });

      const GameHistoryItem: GameHistory = {
        ID: GameId,
        CrashHash: Hash,
        CrashSource: HashKey.trim(),
        BustedAt: Multiplier,
        TimeStamp: new Date().toISOString(),
      };

      const updatedBoards = state.boards.map((board) => {
        if (board.registerState === RegisterState.REGISTERED) {
          return {
            ...board,
            registerState: RegisterState.UNREGISTERED,
          };
        } else {
          return board;
        }
      });

      return {
        ...state,
        status: {
          ...state.status,
          state: GAME_STATE.CRUSHED,
          multiplier: action.payload.Multiplier,
          hashKey: action.payload.HashKey,
        },
        lastGame: GameHistoryItem,
        currentBets: updatedCurrentBets,
        currentGame: state?.nextGames
          ? state?.nextGames[0] || undefined
          : undefined,
        boards: updatedBoards,
        handlecashoutState: 0,
      };
    }
    case ACTIONS.SET_LAUNCHING: {
      return {
        ...state,
        status: {
          ...state.status,
          state: GAME_STATE.LAUNCHING,
          multiplier: 1,
        },
      };
    }
    case ACTIONS.CURRENT_MULTIPLIER: {
      return {
        ...state,
        status: {
          ...state.status,
          state: GAME_STATE.RUNNING,
          multiplier: action.payload.M,
        },
      };
    }
    case ACTIONS.PLAYER_REGISTERED: {
      const { P, BI, PI, YouRegisteredOnGame, EC } = action.payload;
      const updatedCurrentBets: ListUser[] = state.currentBets;
      const isMe = P.I === state.profile.MyID;
      const registeredPlayer: ListUser = {
        id: P.I,
        avatar: P.A,
        username: isMe ? P.N : formatUsername(P.N),
        bet: BI,
        status: ListStatus.DEFAULT,
        PanelIndex: PI,
      };
      if (YouRegisteredOnGame && EC === PlayerRegisterErrorStatuses.Success) {
        const { PlayerBalance, CurrentPlayer } = YouRegisteredOnGame;
        updatedCurrentBets.unshift(registeredPlayer);
        const updatedBoards = [...state.boards].map((board) => {
          if (board.PanelIndex === CurrentPlayer.PanelIndex) {
            return {
              ...board,
              registerState: RegisterState.REGISTERED,
            };
          } else return board;
        });
        const updatedMyBets = state.myBets ? [...state.myBets] : [];

        updatedMyBets.unshift({
          ID: action.payload.P.I,
          BuyIn: action.payload.BI,
          CashOut: 0,
          Timestamp: new Date().toISOString(),
          GameID: action.payload.GID,
          PanelIndex: action.payload.PI,
        });

        return {
          ...state,
          currentBets: JSON.parse(JSON.stringify(updatedCurrentBets)),
          profile: {
            ...state.profile,
            B: PlayerBalance,
          },
          boards: updatedBoards,
          myBets: updatedMyBets,
        };
      } else if (
        YouRegisteredOnGame &&
        EC !== PlayerRegisterErrorStatuses.Success
      ) {
        const { CurrentPlayer } = YouRegisteredOnGame;
        const updatedBoards = [...state.boards].map((board) => {
          if (board.PanelIndex === CurrentPlayer.PanelIndex) {
            return {
              ...board,
              registerState: RegisterState.UNREGISTERED,
            };
          } else return board;
        });
        return {
          ...state,
          currentBets: JSON.parse(JSON.stringify(updatedCurrentBets)),
          boards: updatedBoards,
        };
      } else {
        updatedCurrentBets.push(registeredPlayer);
        return {
          ...state,
          currentBets: JSON.parse(JSON.stringify(updatedCurrentBets)),
        };
      }
    }
    case ACTIONS.PLAYER_UNREGISTERED: {
      const { P, PI, YouUnRegisteredOnGame } = action.payload;

      const updatedCurrentBets = state.currentBets.filter(
        (user) => user.id !== P.I || (user.id === P.I && user.PanelIndex !== PI)
      );

      if (YouUnRegisteredOnGame) {
        const { CurrentPlayer } = YouUnRegisteredOnGame;
        const updatedBoards = [...state.boards].map((board) => {
          if (board.PanelIndex === CurrentPlayer.PanelIndex) {
            return {
              ...board,
              registerState: RegisterState.UNREGISTERED,
            };
          } else return board;
        });

        let updatedMyBets = state.myBets ? [...state.myBets] : [];
        updatedMyBets = updatedMyBets.filter(
          (myBet) =>
            myBet.GameID !== YouUnRegisteredOnGame.GameId ||
            (myBet.GameID === YouUnRegisteredOnGame.GameId &&
              myBet.PanelIndex !== CurrentPlayer.PanelIndex)
        );

        return {
          ...state,
          currentBets: updatedCurrentBets,
          profile: {
            ...state.profile,
            B: YouUnRegisteredOnGame.PlayerBalance || state.profile.B,
          },
          boards: updatedBoards,
          myBets: updatedMyBets,
        };
      } else {
        return {
          ...state,
          currentBets: updatedCurrentBets,
        };
      }
    }
    case ACTIONS.PLAYER_CASHED_OUT: {
      const { M, PID, YouCashedOut, PI, EC } = action.payload;
      const updatedCurrentBets: ListUser[] = state.currentBets.map((user) => {
        if (user.id === PID && user.PanelIndex === PI) {
          const profit = M * user.bet;
          return {
            ...user,
            status: ListStatus.WON,
            odds: M,
            profit: roundNumber(profit),
          };
        } else {
          return user;
        }
      });

      if (EC && EC > 0) {
        const updatedBoards = [...state.boards].map((board) => {
          if (board.PanelIndex === PI) {
            return {
              ...board,
              registerState: RegisterState.UNREGISTERED,
              wonLastGame: false,
            };
          } else return board;
        });

        return {
          ...state,
          boards: updatedBoards,
        };
      }

      if (YouCashedOut) {
        const { CashedOutPlayer } = YouCashedOut;
        const cashoutState = state.handlecashoutState + 1;

        const updatedBoards = [...state.boards].map((board) => {
          if (board.PanelIndex === CashedOutPlayer.PanelIndex) {
            return {
              ...board,
              registerState: RegisterState.UNREGISTERED,
              wonLastGame: true,
            };
          } else return board;
        });

        let updatedMyBets = state.myBets ? [...state.myBets] : [];
        updatedMyBets = updatedMyBets.map((myBet) => {
          if (
            myBet.PanelIndex === CashedOutPlayer.PanelIndex &&
            myBet.GameID === YouCashedOut.GameId
          ) {
            return {
              ...myBet,
              CashOut: calculateCashedOutAmount(CashedOutPlayer),
            };
          } else {
            return myBet;
          }
        });

        return {
          ...state,
          currentBets: updatedCurrentBets,
          profile: {
            ...state.profile,
            B: YouCashedOut.PlayerBalance || state.profile.B,
          },
          boards: updatedBoards,
          myBets: updatedMyBets,
          handlecashoutState: cashoutState,
        };
      } else {
        return {
          ...state,
          currentBets: updatedCurrentBets,
        };
      }
    }
    case ACTIONS.BOARD_ADDED: {
      const updatedBoards = [...state.boards];

      const exists = updatedBoards.filter(
        (board) => board.PanelIndex === action.payload.PanelIndex
      )[0];

      if (!exists) {
        updatedBoards.push(action.payload);
      }

      return {
        ...state,
        boards: updatedBoards,
      };
    }
    case ACTIONS.BOARD_REMOVED: {
      const updatedBoards = [...state.boards].filter(
        (board) => board.PanelIndex !== action.payload.PanelIndex
      );
      return {
        ...state,
        boards: updatedBoards,
      };
    }
    case ACTIONS.BALANCE_CHANGE: {
      return {
        ...state,
        profile: {
          ...state.profile,
          B: action.payload.PlayerBalance,
        },
      };
    }
    case ACTIONS.PENDING: {
      let updatedBoards = [...state.boards].map((board) => {
        if (board.PanelIndex === action.payload.PanelIndex) {
          return {
            registerState: RegisterState.PENDING,
            ...action.payload,
            wonLastGame: board.wonLastGame,
          };
        } else {
          return board;
        }
      });
      return {
        ...state,
        boards: updatedBoards,
      };
    }
    case ACTIONS.REGISTER: {
      let updatedBoards = [...state.boards].map((board) => {
        if (board.PanelIndex === action.payload.PanelIndex) {
          return {
            registerState: RegisterState.REGISTER_INITIATED,
            ...action.payload,
            wonLastGame: board.wonLastGame,
          };
        } else {
          return board;
        }
      });
      return {
        ...state,
        boards: updatedBoards,
      };
    }
    case ACTIONS.UNREGISTER:
    case ACTIONS.UNREGISTER_LOCAL: {
      const updatedBoards = [...state.boards].map((board) => {
        if (board.PanelIndex === action.payload.PanelIndex) {
          return {
            PanelIndex: board.PanelIndex,
            registerState:
              board.registerState === RegisterState.PENDING ||
              board.registerState === RegisterState.REGISTER_INITIATED
                ? RegisterState.UNREGISTERED
                : RegisterState.UNREGISTER_INITIATED,
            wonLastGame: board.wonLastGame,
          };
        } else {
          return board;
        }
      });
      return {
        ...state,
        boards: updatedBoards,
      };
    }
    case ACTIONS.CASH_OUT: {
      const updatedBoards = [...state.boards].map((board) => {
        if (board.PanelIndex === action.payload.PanelIndex) {
          return {
            PanelIndex: board.PanelIndex,
            registerState: RegisterState.CASH_OUT_INITIATED,
          };
        } else {
          return board;
        }
      });
      return {
        ...state,
        boards: updatedBoards,
      };
    }
    case BETS_ACTIONS.INITIALIZE_CURRENT_BETS: {
      const { currentBets, currentBetsCashedOut } = action;
      return {
        ...state,
        currentBets: mapCurrentBetsValues(currentBets, currentBetsCashedOut),
      };
    }
    case BETS_ACTIONS.INITIALIZE_LAST_BETS: {
      const { lastBets, lastBetsCashedOut } = action;
      return {
        ...state,
        lastBets: mapLastBetsValues(lastBets, lastBetsCashedOut),
      };
    }
    case MY_BETS_ACTIONS.INITIALIZE_MY_BETS: {
      const { myBets } = action;
      return {
        ...state,
        myBets: myBets,
      };
    }
    case GAME_CONFIG.INITIALIZE_GAME_CONFIG: {
      const { minMaxValue } = action;
      return {
        ...state,
        minMaxValue: minMaxValue,
      };
    }
    case TOP_BETS_ACTIONS.INITIALIZE_TOP_ODDS: {
      return {
        ...state,
        top: {
          ...state.top,
          odds: action.payload,
        },
      };
    }
    case TOP_BETS_ACTIONS.INITIALIZE_TOP_WINNINGS: {
      return {
        ...state,
        top: {
          ...state.top,
          winnings: action.payload,
        },
      };
    }
    case TOP_BETS_ACTIONS.INITIALIZE_TOP_MULTIPLIER_CASH_OUTS: {
      return {
        ...state,
        top: {
          ...state.top,
          multiCashOut: action.payload,
        },
      };
    }
    case GAMES_HISTORY_ACTIONS.INITIALIZE_GAMES_HISTORY: {
      return {
        ...state,
        gameHistory: action.history,
      };
    }
    case NEXT_GAMES_ACTIONS.INITIALIZE_NEXT_GAMES: {
      const nextGames = action.games;
      return {
        ...state,
        nextGames: nextGames,
        currentGame: action.games[0] || undefined,
      };
    }
    case PROFILE_ACTIONS.INITIALIZE_PROFILE: {
      return {
        ...state,
        profile: action.profile,
      };
    }
    case PROFILE_ACTIONS.TOGGLE_CONFIG_VOICE: {
      return {
        ...state,
        configs: {
          ...state.configs,
          voiceEffects: action.enable,
        },
      };
    }
    case PROFILE_ACTIONS.TOGGLE_CONFIG_ANIMATION: {
      return {
        ...state,
        configs: {
          ...state.configs,
          animate: action.enable,
        },
      };
    }
    case PROFILE_ACTIONS.TOGGLE_CONFIG_BACKGROUND_MUSIC: {
      return {
        ...state,
        configs: {
          ...state.configs,
          backgroundMusic: action.enable,
        },
      };
    }
    case ACTIONS.TOGGLE_ENABLE_FREE_BETS: {
      return {
        ...state,
        freeBetsEnabled: action.enabled,
      };
    }
    case ACTIONS.SAVE_GAME_SETTINGS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          Player: {
            ...state.profile.Player,
            A: action.payload.Avatar,
          },
        },
      };
    }
    case ACTIONS.LOAD_GAME: {
      const leftDuration =
        state.status.durationLeft && state.status.durationLeft > action.payload
          ? state.status.durationLeft - action.payload
          : 0;
      return {
        ...state,
        loading: false,
        status: {
          ...state.status,
          durationLeft: leftDuration,
        },
      };
    }
    case ACTIONS.INITIALIZE_GAME_STATE: {
      return {
        ...state,
        status: {
          ...state.status,
          multiplier: action.data.Multiplier,
          state: GAME_STATE_BY_INDEX[action.data.Status],
          duration: action.data.Duration,
          durationLeft: action.data.LeftDuration,
        },
      };
    }
    case ACTIONS.INITIALIZE_TENANT_STATE: {
      return {
        ...state,
        tenant: {
          Type: action.data,
        },
      };
    }
    case ACTIONS.INITIALIZE_GAME_SETTINGS: {
      return {
        ...state,
        configs: {
          ...state.configs,
          backgroundMusic: action.data.Music,
          voiceEffects: action.data.Sound,
        },
      };
    }
    case ACTIONS.GAME_ERROR: {
      return {
        ...state,
        errors: {
          isError: true,
          type: action.errorType,
        },
      };
    }
    default:
      return state;
  }
};

export default gameReducers;
