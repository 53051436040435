import React, { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 8px;
  line-height: 10px;
  background: #2eb550;
  border: 1px solid #214834;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 6px 0px #17ae43cc;
  text-align: center;
  padding-top: 5px;
  z-index: 1;
`;

const Counter: FC<{ count: number; diameter?: number }> = ({
  count,
  diameter,
}) => {
  return <Container>{count}</Container>;
};

export default Counter;
