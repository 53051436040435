const CURRENCY_SYMBOL = '___';

export const portugal = {
    AccountBanned:  "Sua conta foi bloqueada!",
    AllBets:  "Todas as apostas:",
    AlreadyRegistered:  "Você já fez uma aposta",
    Animation:  "Animação",
    AutoBet:  "APOSTA AUTOMÁTICA",
    AutoBetting:  "Aposta Automática",
    AutoCashout:  "SAQUE AUTOMÁTICO",
    AutoCashouting:  "Saque Automático",
    Balance:  "EQUILÍBRIO",
    BalanceShort:  "BAL",
    Banned:  "BANIDO",
    BaseBet:  "APOSTA BASE",
    Bet:  "Aposta",
    BetMaximum: `A aposta deve ser no máximo ${CURRENCY_SYMBOL}`,
    BetMustBe: `A aposta deve ser mínima de ${CURRENCY_SYMBOL}`,
    Bets:  "Apostas",
    Stakes:  "Apostas",
    BuyIn:  "APOSTA",
    Cashout:  "Saque",
    CashoutFromGame:  "Saque",
    CashoutMaxMustBe:  "O saque deve ser máximo",
    CashoutMustBe:  "O saque deve ser no mínimo 1,01",
    ChangeAvatar:  "Mudar Avatar",
    CheckFair:  "1. O que é verificar o ímpar?",
    CheckHash:  "3. Como verificar o código de credibilidade?",
    CheckResult:  "Verifique o Resultado",
    Circulation:  "#",
    Clear:  "CLARO",
    Crashed:  "CHANCE",
    CurrentBets:  "APOSTAS",
    Day:  "Dia",
    DearUsers:  "Desculpe pelo inconveniente",
    en:  "EN",
    Error:  "Error",
    ExampleForFair: 
     "Número do jogo - 9088 Probabilidades - 1,06 Tempo de geração - 12/03/2019_16:09 Número único do jogo - Fv+5TVHK2621A== Como resultado, a chave do jogo será: 9088_1.06_Rockit_12/03/2019_16:09_Fvv+5TVHK2621A= = E o código de credibilidade gerado pelo algoritmo SHA256: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d",
    Examples:  "Exemplo:",
    FairGame:  "Jogo Justo",
    Fill:  "Preencher",
    FreeBet:  "Aposta Grátis",
    GameIsStopped:  "O jogo foi interrompido",
    GameKey:  "2. O que significa uma chave de jogo e um código de credibilidade?",
    ServerConnectionTemp:  "Conectando ao servidor.",
    SameUser:  "O jogo está aberto em outra aba",
    GameRules:  "Regras do Jogo",
    GameStart:  "Começando o Jogo",
    GameStarted:  "O Jogo começou - boa sorte",
    GameStatusIsNotRegistering:  "Aceitação de apostas concluída",
    GameWillResume:  "Tempo até o currículo",
    GotoHome:  "LAR",
    Hash:  "Código hash",
    History:  "História",
    HowToCheckFair:
     "Este método permite-nos garantir que as probabilidades do jogo (o número em que o jogo termina) são pré-determinadas antes dos jogadores registarem as suas apostas. Existem 5 jogos pré-determinados com antecedência a qualquer momento. A seguir está um código Hash de 5 rodadas anteriores e 5 rodadas futuras.",
    HowToCheckGameKey: 
     "As probabilidades do jogo (o número em que o jogo terminará) são pré-determinadas, com 5 jogos de antecedência. Uma chave do jogo é gerada no servidor do jogo, que consiste nos seguintes parâmetros: [Número do jogo] _ [Probabilidades] _ [Rockit] _ [Tempo de geração] _ [Número exclusivo do jogo]",
    HowToCheckHash: 
     "Após o término do jogo, uma chave de jogo estará disponível que você pode criptografar em qualquer calculadora SHA256 online e compará-la com o código de credibilidade fornecido para os próximos 5 jogos.",
    IncreaseBy:  "AUMENTAR EM",
    Jackpot:  "JACKPOT",
    ka:  "KA",
    ua:  "UA",
    Key:  "Chave",
    LanguageChangeAcception:  "Tem certeza de que deseja alterar o idioma?",
    LanguageChangeWarning:  "Pressionar o botão desligará o jogo atual",
    LastGames:  "Últimos jogos",
    LastHand:  "Última mão",
    LastTopSpins:  "MELHORES MULTIPLIERS EM 24 HORAS",
    LastTopWins:  "MELHORES VITÓRIAS EM 24 HORAS",
    LeaveMessage:  "Digite sua mensagem",
    Login:  "CONECTE-SE",
    LoginFailed:  "Falha no Login",
    MainPage:  "Página Principal",
    ManualBet:  "MANUAL DE APOSTA",
    MinFreebetCashout:  "Min. ímpar 1,5",
    Month:  "Mês",
    MultiplierCashout:  "Mult. Saque",
    Music:  "Música",
    My:  "Minhas Apostas",
    No:  "No",
    NotEnoughMoney:  "Dinheiro insuficiente",
    OnLoss:  "NA PERDA",
    OnWin:  "NA GANHA",
    OpenHere:  "Abra aqui",
    PlaceYourBet:  "FAÇA SUAS APOSTAS",
    Player:  "Jogador",
    Rating:  "TOP",
    Register:  "Fazer uma aposta",
    Registration:  "FAÇA SUA APOSTA",
    Reload:  "Recarregar",
    Result:  "Resultado",
    ResultsHash:  "Código Hash do Resultado",
    ReturnToBaseBet:  "RETORNAR À APOSTA BASE",
    ReturnToCash:  "Voltar ao dinheiro",
    Round:  "Redondo",
    ru:  "RU",
    GameRules1:  "Os jogadores podem fazer apostas antes do início da rodada.",
    GameRules2: 
     "As probabilidades começam a aumentar quando o jogo começa e duram até que ele se esgote.",
    GameRules3:  "O ponto de burnout é gerado aleatoriamente.",
    GameRules4: 
     "Um jogador deve completar um saque dos ganhos antes do esgotamento.",
    GameRules5: 
     "As apostas que não forem sacadas antes do esgotamento serão consideradas perdidas.",
    GameRules6: 
     "A aposta de um jogador será multiplicada pelas probabilidades com que a aposta foi sacada ao pagar antes do esgotamento.",
     GameRules7: `As probabilidades começam a aumentar a partir de 1.0. No caso de burnout a 1.0, todas as apostas são consideradas perdidas. Por exemplo, um jogador faz uma aposta de 5${CURRENCY_SYMBOL} e saca o dinheiro em 2,0, ele receberá 2*5=10${CURRENCY_SYMBOL}. Se as probabilidades esgotarem em menos de 2,0, por exemplo em 1,75, neste caso, o jogador perde toda a aposta de 5${CURRENCY_SYMBOL}. Em caso de saída do jogo, a aposta será automaticamente sacada de acordo com a multiplicação da aposta e o valor das odds naquele momento`, 
    GameRules8: 
     "Em caso de saída do jogo, a aposta será automaticamente descontada, com base no produto da aposta e no coeficiente de altura atual. O encerramento de uma sessão de jogo por qualquer motivo será considerado como encerramento do jogo.",
    GameRules9: 
    " Um jogador pode fazer duas apostas ao mesmo tempo e sacá-las separadamente.",
    GameRules10: 
    " O jogador pode fazer uma aposta nas condições desejadas, através de apostas automáticas. Na janela existente é possível determinar o valor da aposta, o coeficiente de saque, o valor máximo de saque. Além disso, as condições da próxima aposta podem ser determinadas antecipadamente em caso de vitória ou derrota.",
    GameRules11:  "Os ganhos são refletidos automaticamente no saldo do jogador.",
    GameRules12: 
     "O jogador pode ver o histórico do jogo clicando no botão ‘Minhas Apostas’.",
    GameRules13: 
     "Em caso de erro comprovado no jogo, a equipa do jogo reserva-se o direito de reter ou corrigir os ganhos estimados do jogador.",
    GameRules16:  "Limites do jogo:",
     GameRules17: `aposta mínima - 0,50${CURRENCY_SYMBOL}`,
     GameRules18: `aposta máxima - 400${CURRENCY_SYMBOL}`,
     GameRules19: `A vitória máxima de um jogador (aposta*probabilidades) - 50.000${CURRENCY_SYMBOL}, quando a aposta multiplicada pelas probabilidades atingir 50.000${CURRENCY_SYMBOL}, o sistema sacará automaticamente a aposta ativa`,
     GameRules20:
     `Quando as apostas feitas por mais de dois jogadores multiplicadas pelas probabilidades atingirem 150.000${CURRENCY_SYMBOL} em uma rodada, o sistema pagará automaticamente todas as apostas ativas.`,
    GameRules21:  "O multiplier mínimo do jogo é 1.",
    GameRules22:  "O multiplier máximo do jogo é 1.102.008.",
    GameRules23:  "O RTP do jogo é de 95,05%",
    GameRules24:
     "O multiplier mínimo de saque automático é x1,1 e o multiplicador máximo de saque automático é x1.102.008.",
    GameRules25: 
     "O multiplier mínimo de saque é x1,01 e o multiplicador máximo de saque é x1.102.008.",
    GameRules26:
     "O jogador pode encontrar as últimas probabilidades do jogo na seção superior da página.",
    GameRules27: 
    " O jogador pode ver a classificação dos jogadores clicando no botão 'Classificação'.",
    GameRules28: 
     "Caso o coeficiente de saque e o coeficiente de colisão sejam iguais, o jogador ganha.",
    GameRules29:
    " No caso de abandonar o jogo voluntariamente, a aposta será automaticamente descontada, com base no produto da aposta e no coeficiente de altura atual. Em caso de desconexão da rede, uma contagem regressiva de 10 a 1 será exibida, e o jogador poderá optar por sacar ou continuar caso a conectividade seja retomada. Em caso de não ação, o jogo será considerado como perda da aposta",
    Save:  "Salvar",
    ServerConnection:  "Conectando ao servidor",
    InternetConnectionLost: 
     "Houve um problema com sua conexão com a Internet. Atualize a página.",
    ServerError:  "Erro de servidor",
    SessionHasExpired:  "A sessão expirou",
    SitesUpdate:  "MANUTENÇÃO AGENDADA!",
    Sound:  "Sonido",
    Statistics:  "Estatisticas",
    StopIf:  "PARE SE APOSTAR >",
    Submit:  "Enviar",
    SuccessfulRegistration:  "Aposta feita",
    TechnicalWork:  "O jogo que você solicitou estará de volta em breve.",
    TicketCashoutMustBe:  "O saque deve ser de pelo menos 1,5",
    Time:  "Tempo",
    TopSpins:  "Principais probabilidades",
    TopWins:  "Ganhos",
    tr:  "TR",
    UnknowError:  "Erro de Servidor",
    Unregister:  "Cancelar aposta",
    UnregisterFreeBet:  "Cancelar aposta grátis",
    Unregistered:  "Aposta cancelada",
    User:  "Jogador",
    UserName:  "Nome de Usuário",
    UserShort:  "US",
    WaitingForNextRound:  "Você está esperando pela próxima rodada",
    Win:  "Ganhos",
    WinAmount:  "Lucro",
    WinMultiplier:  "Ganhou Mult.",
    Winners:  "Vencedores",
    WrongAmount:  "Quantidade Errada",
    WrongConfiguration:  "Configuração Errada",
    Year:  "Ano",
    Yes:  "Si",
    YouAreNotRegistered:  "Você não está registrado neste jogo",
    EnterText:  "Insira o Texto",
    PlayerNotFound:  "Jogador não Encontrado",
    Jan:  "Jan.",
    Feb:  "Fev.",
    Mar:  "Mar.",
    Apr:  "Abri.",
    May:  "Maio",
    June:  "Junho",
    July:  "Julho",
    Aug:  "Ago.",
    Sept:  "Set.",
    Oct:  "Out.",
    Nov:  "Nov.",
    Dec:  "Dec.",
    BlockedUser:  "Sua conta está bloqueada",
    LoadingProblem:  "Falha ao carregar o jogo, recarregue a página.",
    January:  "Janeiro",
    February:  "Fevereiro",
    March:  "Março",
    April:  "Abril",
    MayLong:  "Maio",
    JuneLong:  "Junho",
    JulyLong:  "Julho",
    August:  "Agosto",
    September:  "Setembro",
    Octomber:  "Outubro",
    November:  "Novembro",
    December:  "Decembro",
    Auto:  "Auto",
    Game:  "Jogo",
    BetHistory:  "Histórico de apostas",
    ViewTable:  "Ver Tabela",
    TotalProfit:  "Lucro Total",
    WeakInternetAlert:  "Conexão de internet fraca",
    PageWillReload:  "A página será recarregada",
    InSeconds:  "Segundos",
};


