import React, { memo } from 'react';
import { CloudContainer } from '../spaceship.styles';
import Cloud from '../../../assets/images/Cloud.png';
import Cloud2 from '../../../assets/images/Cloud2.png';

const CloudsComp = () => {
    return (
        <>
            <CloudContainer
                style={{ zIndex: 14 }}
                initial={{ scale: 2, x: 0, y: 100 }}
                animate={{ scale: 1, x: -600, y: 600 }}
                transition={{ duration: 10 }}
            >
                <img src={Cloud} alt="Cloud1" />
            </CloudContainer>

            <CloudContainer
                style={{ zIndex: 13, opacity: 0.3 }}
                initial={{ scale: 1, x: 120, y: -200 }}
                animate={{ scale: 1, x: -600, y: 600 }}
                transition={{ duration: 14 }}
            >
                <img src={Cloud2} alt="Cloud2" />
            </CloudContainer>
        </>
    );
};

export default memo(CloudsComp);
