import React, { FC, useEffect, useState } from "react";
import { Howl } from "howler";
import styled from "styled-components";
import GIF from "../../../../assets/images/countdown/Counter.gif";

import Three from "../../../../assets/sounds/countdown/three.wav";
import Two from "../../../../assets/sounds/countdown/two.wav";
import One from "../../../../assets/sounds/countdown/one.wav";
import Rockit from "../../../../assets/sounds/countdown/rockit.wav";
import { useGameConfigs } from "../../../../config/store/state/app.state";

const songs = [Three, Two, One, Rockit];

const LoadingShipContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledExplosionImageLoader = styled.div<{ width?: number }>`
  width: 100%;
  height: 100%;
  bckground: green;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  > img {
    object-fit: cover;
    height: 70%;
  }
`;

// const sw = window.innerWidth;
// const sh = window.innerHeight;

export const Preparing: FC<{ countDown: number }> = ({ countDown }) => {
  //   const [images, setImages] = useState<any>([]);
  const configs = useGameConfigs();
  //   const isMobile = useIsMobile();
  const [show, setShow] = useState(false);
  const [gif, setGif] = useState("");

  const reloadGif = () => {
    let timer: any;

    setGif("");

    timer = setTimeout(() => {
      setShow(true);
      setGif(GIF);
    }, 400);

    return () => {
      clearTimeout(timer);
    };
  };

  const gifCut = () => {
    let timer: any;

    timer = setTimeout(() => {
      setShow(false);
    }, 5200);

    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    reloadGif();
    gifCut();
  }, []);


  useEffect(() => {
    if (configs.voiceEffects) {
      let timer: any;

      timer = setTimeout(() => {
        const sound1 = new Howl({
          src: songs[0],
          volume: 0.9,
          loop: false,
        });
        const sound2 = new Howl({
          src: songs[1],
          volume: 0.9,
          loop: false,
        });
        const sound3 = new Howl({
          src: songs[2],
          volume: 0.9,
          loop: false,
        });
        // const sound4 = new Howl({
        //   src: songs[3],
        //   volume: 0.9,
        //   loop: false,
        // });
        sound1.play();
        sound1.on("end", () => sound2.play());
        sound2.on("end", () => {
          sound3.play();
          // sound4.play();
        });
      }, 1950);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [configs.voiceEffects]);

  return (
    <LoadingShipContainer>
      <StyledExplosionImageLoader>
        {show && <img src={gif} alt="Logo"></img>}
      </StyledExplosionImageLoader>
    </LoadingShipContainer>
  );
};
