import styled from "styled-components";
import { motion } from "framer-motion";

interface IAstro {
  random?: number;
}

export const Astronaut = styled(motion.div)<IAstro>`
  img {
    position: absolute;
    width: auto;
    top: ${(props) => (props.random === 2 ? '-61px' : props.random === 3 ? "-66px" : "-63px")};
    left: ${(props) => (props.random === 2 ? '-61px' : props.random === 3 ? "-64px" : "-59px")};
    height: 70px;
    z-index: 999;

    @media (min-width: ${800}px) {
      top: ${(props) => (props.random === 2 ? '-71px' : props.random === 3 ? "-74px" : "-79px")};
      left: ${(props) => (props.random === 2 ? '-71px' : props.random === 3 ? "-73px" : "-69px")};
    }

    @media (min-width: ${1366}px) {
      height: auto;
      top: ${(props) => (props.random === 2 ? '-106px' : props.random === 3 ? "-104px" : "-108px")};
      left: ${(props) => (props.random === 2 ? '-141px' : props.random === 3 ? "-144px" : "-139px")};
    }
  }
`;

export const MuAstronaut = styled(motion.div)`
  img {
    position: absolute;
    width: auto;
    top: -60px;
    left: -80px;
    height: 78px;
    z-index: 999;

    @media (min-width: ${800}px) {
      top: -70px;
      left: -80px;
    }

    @media (min-width: ${1366}px) {
      height: auto;
      top: -120px;
      left: -160px;
    }
  }
`;



export const ShipContainer = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  z-index: 2;
`;

export const SateliteSmall = styled(motion.div)`
  opacity: 0.8;
  position: absolute;
  z-index: 1;

  img {
    width: 30vw;

    @media (max-width: ${680}px) {
      width: 200px;
    }
  }
`;

export const X1 = styled(motion.div)`
  position: absolute;
`;

export const CloudContainer = styled(motion.div)`
  position: absolute;
  left: -200px;
  bottom: -200px;
`;
