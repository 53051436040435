import styled from "styled-components";

export const MenuIc = styled.div`
  height: 100%;
  max-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  div:nth-child(1) {
    width: 12px;
  }

  div:nth-child(2) {
    margin: 5px 0;
  }

  &.open {
    div:nth-child(1) {
      width: 24px;
      transform: rotate(-45deg) translate(-9px, 9px);
    }

    div:nth-child(2) {
      opacity: 0;
    }

    div:last-child {
      transform: rotate(45deg) translate(-9px, -9px);
    }
  }

  @media (min-width: ${800}px) {
    div:nth-child(1) {
      width: 23px;
    }

    div:nth-child(2) {
      margin: 6px 0;
    }

    &.open {
      div:nth-child(1) {
        width: 35px;
        transform: rotate(-45deg) translate(-11px, 13px);
      }

      div:last-child {
        transform: rotate(45deg) translate(-10px, -12.5px);
      }
    }
  }
`;

export const MenuIcLine = styled.div`
  height: 3px;
  width: 24px;
  background: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: 0.2s;

  @media (min-width: ${800}px) {
    height: 3.5px;
    width: 35px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
  }
`;

export const Close = styled.div`
  width: 34px;
  height: 24px;
  position: relative;
  cursor: pointer;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:before {
    display: block;
    content: "";
    width: 28px;
    height: 2px;
    background: #ffffff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    transform: rotate(45deg);
    position: absolute;
    top: 9px;
    left: -3px;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:after {
    display: block;
    content: "";
    width: 28px;
    height: 2px;
    background: #ffffff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    transform: rotate(-45deg);
    position: absolute;
    top: 9px;
    left: -3px;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:hover:before,
  &:hover:after {
    background: #6d5cce;
  }

  @media (min-width: ${800}px) {
    &:before {
      width: 22px;
      top: 12px;
      left: 1px;
    }

    &:after {
      width: 22px;
      top: 12px;
      left: 1px;
    }
  }

  @media (min-width: ${1366}px) {
    &:before {
      width: 28px;
      top: 9px;
      left: -3px;
    }

    &:after {
      width: 28px;
      top: 9px;
      left: -3px;
    }
  }
`;
