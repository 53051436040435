import React, { FC } from "react";
import styled from "styled-components";
import Counter from "../Counter/Counter";
import { getQuery } from "../../helpers/hooks/useQuery";
const lang = getQuery(window.location.search, "lang");

const StyledSelectButton = styled.button<{
  selected?: boolean;
  disabled?: boolean;
}>`
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  background: #161037;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  color: #ffffff;
  padding: 6px 8px 6px 6px;
  position: relative;
  margin: 12px 12.5px 0;
  border: ${(props) =>
    props.selected ? "1px solid rgba(56, 46, 112, 0.3)" : "1px solid #161037"};

  &:hover {
    cursor: pointer;
  }
  font-family: "FiraGo", sans-serif;

  &.roboto {
    font-family: "Roboto", sans-serif;
  }

  &.montserrat {
    font-family: "Montserrat", sans-serif;
  }
`;

const SelectButton: FC<{
  text: string;
  selected?: boolean;
  count?: number;
  onClick?(): void;
  disabled?: boolean;
  className?: string;
}> = ({ text, selected, count, onClick, disabled, className }) => {
  return (
    <StyledSelectButton
      selected={selected}
      onClick={onClick}
      disabled={disabled}
      // className={`${
      //   lang ? (lang === "en" ? "roboto" : "montserrat") : ""
      // } ${className}`}
      className={`${(lang === "ru" || lang === "uk") && "montserrat"} ${
        lang === "en" && "roboto"
      } ${(!lang || lang === "ka") && ""} ${className}`}
    >
      {text}
      {count && <Counter count={count} />}
    </StyledSelectButton>
  );
};

export default SelectButton;
