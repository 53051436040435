import React, { FC } from "react";
import { IconProps } from "./types";

export const Close: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.968387 0.968387C1.03806 0.898542 1.12082 0.843128 1.21194 0.805318C1.30305 0.767508 1.40074 0.748047 1.49939 0.748047C1.59804 0.748047 1.69572 0.767508 1.78684 0.805318C1.87796 0.843128 1.96072 0.898542 2.03039 0.968387L5.99939 4.93889L9.96839 0.968387C10.0381 0.898655 10.1209 0.843341 10.212 0.805602C10.3031 0.767863 10.4008 0.74844 10.4994 0.74844C10.598 0.74844 10.6957 0.767863 10.7868 0.805602C10.8779 0.843341 10.9607 0.898655 11.0304 0.968387C11.1001 1.03812 11.1554 1.1209 11.1932 1.21201C11.2309 1.30312 11.2503 1.40077 11.2503 1.49939C11.2503 1.598 11.2309 1.69565 11.1932 1.78676C11.1554 1.87787 11.1001 1.96066 11.0304 2.03039L7.05989 5.99939L11.0304 9.96839C11.1001 10.0381 11.1554 10.1209 11.1932 10.212C11.2309 10.3031 11.2503 10.4008 11.2503 10.4994C11.2503 10.598 11.2309 10.6957 11.1932 10.7868C11.1554 10.8779 11.1001 10.9607 11.0304 11.0304C10.9607 11.1001 10.8779 11.1554 10.7868 11.1932C10.6957 11.2309 10.598 11.2503 10.4994 11.2503C10.4008 11.2503 10.3031 11.2309 10.212 11.1932C10.1209 11.1554 10.0381 11.1001 9.96839 11.0304L5.99939 7.05989L2.03039 11.0304C1.96066 11.1001 1.87787 11.1554 1.78676 11.1932C1.69565 11.2309 1.598 11.2503 1.49939 11.2503C1.40077 11.2503 1.30312 11.2309 1.21201 11.1932C1.1209 11.1554 1.03812 11.1001 0.968387 11.0304C0.898655 10.9607 0.843341 10.8779 0.805602 10.7868C0.767863 10.6957 0.74844 10.598 0.74844 10.4994C0.74844 10.4008 0.767863 10.3031 0.805602 10.212C0.843341 10.1209 0.898655 10.0381 0.968387 9.96839L4.93889 5.99939L0.968387 2.03039C0.898542 1.96072 0.843128 1.87796 0.805318 1.78684C0.767508 1.69572 0.748047 1.59804 0.748047 1.49939C0.748047 1.40074 0.767508 1.30305 0.805318 1.21194C0.843128 1.12082 0.898542 1.03806 0.968387 0.968387Z" />
    </svg>
  );
};

export default Close;
