import styled from "styled-components";

export const AvatarContainer = styled.div<{ src: string }>`
  @media (min-width: ${800}px) {
    height: 46px;
    width: 46px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    float: left;
    margin-left: 5px;
    background: url(${(props) => props.src}) no-repeat center center;
    background-size: cover;
  }

  @media (min-width: ${1366}px) {
    margin-left: 13px;
  }
`;
