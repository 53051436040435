import styled, { css, keyframes } from 'styled-components';

export const animateStars1 = keyframes`
  from {
    transform: translateY(0);
    opacity: 0.8;
  }

  to {
    transform: translateY(2000px);
    // opacity: 0;
  }

`;

export const animateStars2 = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(2000px);
  }
`;

export const animateStars3 = keyframes`
 from {
    transform: translateY(0);
  }

  to {
    transform: translateY(2000px);
  }
`;

export const StarsOne = styled.div<{ animate?: boolean }>`
    transform: translateY(0);
    opacity: 1;
    width: 1.46px;
    height: 1px;
    background: transparent;
    animation: ${props =>
        props.animate
            ? css`
                  ${animateStars1} 43s linear infinite;
              `
            : ''};

    &::after {
        content: ' ';
        position: absolute;
        ${props =>
            props.animate ? ` top: -2000px;` : `top: 0; left: 1500px;`};
        width: 1.4px;
        height: 1px;
        box-shadow: 1226px 1621px #fe49a4, 1021px 1311px #fe49a4,
            589px 396px #fe49a4, 106px 950px #fe49a4, 1628px 685px #fe49a4,
            1982px 1853px #fe49a4, 683px 1196px #fe49a4, 885px 147px #fe49a4,
            140px 572px #fe49a4, 619px 1786px #fe49a4, 158px 727px #fe49a4,
            1051px 1759px #fe49a4, 304px 515px #fe49a4, 1107px 1063px #fe49a4,
            1409px 293px #fe49a4, 844px 1928px #fe49a4, 1297px 766px #fe49a4,
            769px 53px #fe49a4, 1796px 1499px #fe49a4, 1096px 1588px #fe49a4,
            6px 1066px #fe49a4, 954px 1823px #fe49a4, 449px 1318px #fe49a4,
            742px 324px #fe49a4, 1360px 430px #fe49a4, 474px 517px #fe49a4,
            1638px 553px #fe49a4, 65px 362px #fe49a4, 1668px 305px #fe49a4,
            618px 1791px #fe49a4, 1248px 698px #fe49a4, 526px 1505px #fe49a4,
            443px 1738px #fe49a4, 968px 744px #fe49a4, 1509px 1343px #fe49a4,
            940px 891px #fe49a4, 1426px 313px #fe49a4, 86px 1904px #fe49a4,
            1852px 914px #fe49a4, 1774px 1138px #fe49a4, 1406px 378px #fe49a4,
            82px 384px #fe49a4, 1195px 1561px #fe49a4, 472px 1994px #fe49a4,
            887px 708px #fe49a4, 1078px 680px #fe49a4, 1715px 140px #fe49a4,
            333px 1224px #fe49a4, 1538px 412px #fe49a4, 1476px 644px #fe49a4,
            976px 149px #fe49a4, 938px 1127px #fe49a4, 531px 1088px #fe49a4,
            996px 403px #fe49a4, 279px 1233px #fe49a4, 1761px 1297px #fe49a4,
            1126px 1184px #fe49a4, 1417px 375px #fe49a4, 1085px 1137px #fe49a4,
            669px 987px #fe49a4, 1149px 1963px #fe49a4, 1097px 880px #fe49a4,
            1591px 85px #fe49a4, 1858px 884px #fe49a4, 1187px 1483px #fe49a4,
            1246px 415px #fe49a4, 126px 516px #fe49a4, 1824px 672px #fe49a4,
            178px 250px #fe49a4, 1675px 146px #fe49a4, 1827px 816px #fe49a4,
            803px 992px #fe49a4, 1703px 1664px #fe49a4, 1658px 970px #fe49a4,
            1607px 1896px #fe49a4, 268px 499px #fe49a4, 465px 395px #fe49a4,
            287px 468px #fe49a4, 980px 1675px #fe49a4, 1959px 1989px #fe49a4,
            429px 1263px #fe49a4, 1419px 1703px #fe49a4, 302px 335px #fe49a4,
            1470px 698px #fe49a4, 431px 1784px #fe49a4, 1397px 1168px #fe49a4,
            585px 143px #fe49a4, 1163px 455px #fe49a4, 1007px 1355px #fe49a4,
            764px 1147px #fe49a4, 1528px 1835px #fe49a4, 1298px 1629px #fe49a4,
            438px 971px #fe49a4, 1940px 1307px #fe49a4, 1767px 1080px #fe49a4,
            1700px 1582px #fe49a4, 911px 709px #fe49a4, 1733px 1916px #fe49a4,
            1650px 1153px #fe49a4, 1491px 1908px #fe49a4, 1221px 1065px #fe49a4,
            389px 749px #fe49a4, 576px 602px #fe49a4, 84px 1595px #fe49a4,
            1887px 1748px #fe49a4, 1293px 1674px #fe49a4, 1372px 986px #fe49a4,
            834px 1280px #fe49a4, 241px 777px #fe49a4, 1122px 1540px #fe49a4,
            908px 1032px #fe49a4, 1715px 14px #fe49a4, 732px 1014px #fe49a4,
            1888px 766px #fe49a4, 1433px 1174px #fe49a4, 1273px 1889px #fe49a4,
            337px 301px #fe49a4, 1449px 1878px #fe49a4, 946px 1411px #fe49a4,
            965px 181px #fe49a4, 1508px 537px #fe49a4, 977px 1648px #fe49a4,
            696px 1932px #fe49a4, 601px 305px #fe49a4, 1734px 186px #fe49a4,
            1962px 1776px #fe49a4, 965px 791px #fe49a4, 1563px 422px #fe49a4,
            1427px 822px #fe49a4, 1540px 599px #fe49a4, 1246px 1681px #fe49a4,
            1271px 1136px #fe49a4, 1411px 641px #fe49a4, 1108px 1981px #fe49a4,
            961px 1884px #fe49a4, 788px 631px #fe49a4, 172px 783px #fe49a4,
            1729px 455px #fe49a4, 1682px 1051px #fe49a4, 911px 1455px #fe49a4,
            1652px 489px #fe49a4, 880px 94px #fe49a4, 59px 747px #fe49a4,
            417px 223px #fe49a4, 332px 1397px #fe49a4, 699px 659px #fe49a4,
            693px 728px #fe49a4, 1232px 208px #fe49a4, 1514px 774px #fe49a4,
            1070px 633px #fe49a4, 1768px 165px #fe49a4, 1261px 1666px #fe49a4,
            35px 1979px #fe49a4, 54px 1408px #fe49a4, 820px 745px #fe49a4,
            1732px 147px #fe49a4, 1282px 1121px #fe49a4, 1640px 1px #fe49a4,
            1932px 632px #fe49a4, 895px 31px #fe49a4, 1751px 266px #fe49a4,
            746px 54px #fe49a4, 1197px 986px #fe49a4, 1032px 658px #fe49a4,
            234px 1526px #fe49a4, 1267px 1176px #fe49a4, 1280px 357px #fe49a4,
            1333px 381px #fe49a4, 1739px 1375px #fe49a4, 888px 1325px #fe49a4,
            704px 550px #fe49a4, 20px 359px #fe49a4, 987px 1218px #fe49a4,
            794px 1724px #fe49a4, 774px 590px #fe49a4, 1024px 227px #fe49a4,
            1487px 400px #fe49a4, 1446px 627px #fe49a4, 984px 1342px #fe49a4,
            966px 703px #fe49a4, 105px 1673px #fe49a4, 1225px 1267px #fe49a4,
            119px 1202px #fe49a4, 1458px 273px #fe49a4, 1057px 1167px #fe49a4,
            116px 735px #fe49a4, 665px 1021px #fe49a4, 476px 1830px #fe49a4,
            145px 906px #fe49a4, 502px 596px #fe49a4, 1629px 645px #fe49a4,
            728px 1972px #fe49a4, 342px 605px #fe49a4, 1331px 179px #fe49a4,
            707px 1684px #fe49a4, 388px 1757px #fe49a4, 805px 73px #fe49a4,
            659px 180px #fe49a4, 848px 915px #fe49a4, 182px 1550px #fe49a4,
            1955px 155px #fe49a4, 973px 1546px #fe49a4, 1061px 1579px #fe49a4,
            52px 687px #fe49a4, 1104px 1352px #fe49a4, 408px 1386px #fe49a4,
            1888px 1692px #fe49a4, 956px 1018px #fe49a4, 1355px 1568px #fe49a4,
            1505px 1550px #fe49a4, 1182px 705px #fe49a4, 959px 1050px #fe49a4,
            498px 1432px #fe49a4, 301px 647px #fe49a4, 443px 334px #fe49a4,
            1402px 461px #fe49a4, 147px 1270px #fe49a4, 391px 1572px #fe49a4,
            1017px 341px #fe49a4, 68px 1770px #fe49a4, 235px 860px #fe49a4,
            505px 816px #fe49a4, 1312px 986px #fe49a4, 1079px 1293px #fe49a4,
            324px 611px #fe49a4, 1179px 1011px #fe49a4, 277px 434px #fe49a4,
            1767px 252px #fe49a4, 1775px 1487px #fe49a4, 1705px 19px #fe49a4,
            326px 721px #fe49a4, 378px 70px #fe49a4, 895px 1736px #fe49a4,
            1570px 365px #fe49a4, 513px 615px #fe49a4, 1092px 147px #fe49a4,
            1318px 1932px #fe49a4, 1592px 1734px #fe49a4, 699px 918px #fe49a4,
            111px 388px #fe49a4, 1067px 890px #fe49a4, 1169px 332px #fe49a4,
            358px 481px #fe49a4, 1630px 750px #fe49a4, 179px 1787px #fe49a4,
            355px 489px #fe49a4, 852px 821px #fe49a4, 868px 364px #fe49a4,
            386px 1700px #fe49a4, 799px 1420px #fe49a4, 1472px 644px #fe49a4,
            1552px 905px #fe49a4, 48px 1998px #fe49a4, 1850px 51px #fe49a4,
            416px 678px #fe49a4, 759px 1046px #fe49a4, 1451px 1951px #fe49a4,
            101px 1610px #fe49a4, 1469px 912px #fe49a4, 1214px 1292px #fe49a4,
            1368px 1018px #fe49a4, 484px 470px #fe49a4, 816px 322px #fe49a4,
            1821px 1326px #fe49a4, 327px 1936px #fe49a4, 752px 550px #fe49a4,
            1215px 1924px #fe49a4, 180px 122px #fe49a4, 784px 1924px #fe49a4,
            1597px 336px #fe49a4, 248px 1429px #fe49a4, 4px 181px #fe49a4,
            1801px 1613px #fe49a4, 1581px 1719px #fe49a4, 640px 1391px #fe49a4,
            960px 302px #fe49a4, 1483px 1207px #fe49a4, 520px 1119px #fe49a4,
            25px 1754px #fe49a4, 545px 317px #fe49a4, 1573px 751px #fe49a4,
            1415px 2px #fe49a4, 101px 761px #fe49a4, 679px 291px #fe49a4,
            1785px 676px #fe49a4, 1256px 136px #fe49a4, 855px 197px #fe49a4,
            1399px 1973px #fe49a4, 1243px 429px #fe49a4, 1281px 571px #fe49a4,
            839px 531px #fe49a4, 789px 1980px #fe49a4, 877px 783px #fe49a4,
            1526px 1850px #fe49a4, 644px 1283px #fe49a4, 1982px 1953px #fe49a4,
            1109px 1232px #fe49a4, 1532px 643px #fe49a4, 823px 1287px #fe49a4,
            667px 34px #fe49a4, 24px 1469px #fe49a4, 1029px 1130px #fe49a4,
            1645px 254px #fe49a4, 1054px 1000px #fe49a4, 1341px 254px #fe49a4,
            184px 188px #fe49a4, 1160px 1521px #fe49a4, 348px 1083px #fe49a4,
            1436px 1796px #fe49a4, 441px 361px #fe49a4, 1239px 575px #fe49a4,
            1055px 140px #fe49a4, 1552px 502px #fe49a4, 607px 864px #fe49a4,
            967px 1883px #fe49a4, 1407px 1955px #fe49a4, 405px 1148px #fe49a4,
            604px 992px #fe49a4, 1116px 506px #fe49a4, 13px 832px #fe49a4,
            295px 189px #fe49a4, 964px 1175px #fe49a4, 1988px 52px #fe49a4,
            935px 209px #fe49a4, 17px 142px #fe49a4, 770px 333px #fe49a4,
            682px 633px #fe49a4, 510px 466px #fe49a4, 366px 79px #fe49a4,
            199px 954px #fe49a4, 882px 277px #fe49a4, 744px 655px #fe49a4,
            37px 1659px #fe49a4, 1217px 1885px #fe49a4, 1107px 597px #fe49a4,
            1060px 1335px #fe49a4, 773px 601px #fe49a4, 1939px 1632px #fe49a4,
            1607px 1774px #fe49a4, 1531px 1550px #fe49a4, 1042px 60px #fe49a4,
            850px 1502px #fe49a4, 1670px 492px #fe49a4, 1357px 1654px #fe49a4,
            1279px 1521px #fe49a4, 633px 1956px #fe49a4, 1122px 1981px #fe49a4,
            661px 657px #fe49a4, 521px 1132px #fe49a4, 1593px 813px #fe49a4,
            1204px 1463px #fe49a4, 1112px 1495px #fe49a4, 1755px 367px #fe49a4,
            1685px 1805px #fe49a4, 1982px 196px #fe49a4, 484px 51px #fe49a4,
            1041px 330px #fe49a4, 1487px 563px #fe49a4, 311px 1872px #fe49a4,
            330px 976px #fe49a4, 6px 1754px #fe49a4, 1447px 1182px #fe49a4,
            1926px 148px #fe49a4, 1640px 490px #fe49a4, 1548px 1357px #fe49a4,
            1052px 1340px #fe49a4, 1083px 56px #fe49a4, 1890px 339px #fe49a4,
            277px 1609px #fe49a4, 892px 1792px #fe49a4, 1322px 86px #fe49a4,
            1509px 1515px #fe49a4, 1650px 1722px #fe49a4, 334px 497px #fe49a4,
            888px 143px #fe49a4, 1325px 259px #fe49a4, 1330px 1505px #fe49a4,
            1374px 645px #fe49a4, 1184px 937px #fe49a4, 911px 1044px #fe49a4,
            32px 1257px #fe49a4, 297px 1884px #fe49a4, 1031px 963px #fe49a4,
            411px 916px #fe49a4, 243px 1305px #fe49a4, 898px 200px #fe49a4,
            1550px 156px #fe49a4, 159px 716px #fe49a4, 240px 1130px #fe49a4,
            1046px 1905px #fe49a4, 1405px 1277px #fe49a4, 275px 124px #fe49a4,
            964px 795px #fe49a4, 1270px 1387px #fe49a4, 1391px 1880px #fe49a4,
            484px 1027px #fe49a4, 1681px 1706px #fe49a4, 1665px 697px #fe49a4,
            1073px 1078px #fe49a4, 1808px 1313px #fe49a4, 1669px 1266px #fe49a4,
            1965px 747px #fe49a4, 1734px 972px #fe49a4, 1549px 1434px #fe49a4,
            945px 146px #fe49a4, 1211px 1383px #fe49a4, 1921px 1637px #fe49a4,
            271px 1725px #fe49a4, 739px 1725px #fe49a4, 1634px 1827px #fe49a4,
            961px 1131px #fe49a4, 513px 839px #fe49a4, 1553px 1725px #fe49a4,
            82px 1667px #fe49a4, 3px 1797px #fe49a4, 517px 1511px #fe49a4,
            1644px 929px #fe49a4, 120px 1638px #fe49a4, 579px 1168px #fe49a4,
            147px 680px #fe49a4, 61px 1146px #fe49a4, 884px 598px #fe49a4,
            630px 1324px #fe49a4, 1709px 1340px #fe49a4, 1332px 1142px #fe49a4,
            1990px 1397px #fe49a4, 854px 1527px #fe49a4, 1293px 634px #fe49a4,
            1063px 506px #fe49a4, 1361px 668px #fe49a4, 926px 347px #fe49a4,
            145px 1031px #fe49a4, 1289px 1519px #fe49a4, 1977px 1560px #fe49a4,
            70px 1353px #fe49a4, 653px 1634px #fe49a4, 1182px 340px #fe49a4,
            1264px 1127px #fe49a4, 1246px 40px #fe49a4, 428px 691px #fe49a4,
            1531px 1817px #fe49a4, 431px 1906px #fe49a4, 1406px 1665px #fe49a4,
            597px 1588px #fe49a4, 1273px 355px #fe49a4, 995px 1018px #fe49a4,
            1319px 528px #fe49a4, 1454px 715px #fe49a4, 1587px 1052px #fe49a4,
            921px 193px #fe49a4, 1012px 479px #fe49a4, 689px 1019px #fe49a4,
            1307px 420px #fe49a4, 1153px 108px #fe49a4, 1343px 775px #fe49a4,
            1236px 1061px #fe49a4, 1117px 1099px #fe49a4, 308px 533px #fe49a4,
            1262px 509px #fe49a4, 830px 24px #fe49a4, 426px 695px #fe49a4,
            1148px 1397px #fe49a4, 1016px 1719px #fe49a4, 1142px 1280px #fe49a4,
            1841px 310px #fe49a4, 1890px 1708px #fe49a4, 1399px 589px #fe49a4,
            913px 1291px #fe49a4, 955px 1445px #fe49a4, 431px 491px #fe49a4,
            580px 1002px #fe49a4, 796px 186px #fe49a4, 445px 1634px #fe49a4,
            1543px 1377px #fe49a4, 1853px 57px #fe49a4, 34px 620px #fe49a4,
            1056px 923px #fe49a4, 1027px 134px #fe49a4, 1248px 1252px #fe49a4,
            756px 1855px #fe49a4, 143px 654px #fe49a4, 253px 347px #fe49a4,
            181px 943px #fe49a4, 1044px 1774px #fe49a4, 367px 1925px #fe49a4,
            1125px 854px #fe49a4, 1276px 141px #fe49a4, 422px 413px #fe49a4,
            1261px 973px #fe49a4, 1030px 1349px #fe49a4, 1349px 1850px #fe49a4,
            552px 1286px #fe49a4, 606px 888px #fe49a4, 464px 1922px #fe49a4,
            1799px 260px #fe49a4, 1984px 788px #fe49a4, 1210px 450px #fe49a4,
            1288px 1530px #fe49a4, 911px 1896px #fe49a4, 689px 4px #fe49a4,
            806px 162px #fe49a4, 1201px 604px #fe49a4, 908px 531px #fe49a4,
            137px 1123px #fe49a4, 1165px 496px #fe49a4, 1567px 1797px #fe49a4,
            614px 504px #fe49a4, 1551px 1165px #fe49a4, 1309px 1032px #fe49a4,
            1321px 26px #fe49a4, 1372px 326px #fe49a4, 1762px 1159px #fe49a4,
            728px 5px #fe49a4, 1364px 459px #fe49a4, 1333px 918px #fe49a4,
            1082px 1732px #fe49a4, 286px 319px #fe49a4, 1636px 1220px #fe49a4,
            350px 966px #fe49a4, 124px 219px #fe49a4, 184px 1654px #fe49a4,
            1195px 160px #fe49a4, 1690px 737px #fe49a4, 1386px 472px #fe49a4,
            1522px 1911px #fe49a4, 1082px 1129px #fe49a4, 491px 67px #fe49a4,
            826px 393px #fe49a4, 643px 632px #fe49a4, 1934px 1461px #fe49a4,
            1325px 316px #fe49a4, 934px 1722px #fe49a4, 1044px 879px #fe49a4,
            1109px 1482px #fe49a4, 1040px 642px #fe49a4, 801px 509px #fe49a4,
            1352px 1583px #fe49a4, 160px 339px #fe49a4, 1111px 1731px #fe49a4,
            1650px 1190px #fe49a4, 1804px 1019px #fe49a4, 745px 604px #fe49a4,
            1391px 1108px #fe49a4, 344px 708px #fe49a4, 395px 111px #fe49a4,
            913px 1180px #fe49a4, 537px 1036px #fe49a4, 1048px 1611px #fe49a4,
            1926px 155px #fe49a4, 392px 193px #fe49a4, 1980px 1465px #fe49a4,
            1853px 1349px #fe49a4, 204px 1317px #fe49a4, 1342px 1956px #fe49a4,
            785px 1564px #fe49a4, 1478px 612px #fe49a4, 1459px 491px #fe49a4,
            1767px 265px #fe49a4, 1032px 495px #fe49a4, 946px 1196px #fe49a4,
            607px 285px #fe49a4, 887px 1940px #fe49a4, 1618px 962px #fe49a4,
            85px 801px #fe49a4, 1828px 525px #fe49a4, 523px 1927px #fe49a4,
            280px 1676px #fe49a4, 551px 1133px #fe49a4, 1888px 936px #fe49a4,
            875px 863px #fe49a4, 395px 1954px #fe49a4, 155px 590px #fe49a4,
            1924px 262px #fe49a4, 1039px 1430px #fe49a4, 1356px 1816px #fe49a4,
            978px 671px #fe49a4, 1968px 1044px #fe49a4, 1693px 15px #fe49a4,
            266px 1506px #fe49a4, 1061px 224px #fe49a4, 690px 1132px #fe49a4,
            1149px 313px #fe49a4, 744px 898px #fe49a4, 848px 1851px #fe49a4,
            296px 761px #fe49a4, 55px 624px #fe49a4, 573px 131px #fe49a4,
            483px 1869px #fe49a4, 157px 1304px #fe49a4, 496px 489px #fe49a4,
            729px 1730px #fe49a4, 163px 1023px #fe49a4, 1065px 1426px #fe49a4,
            1058px 865px #fe49a4, 1204px 1762px #fe49a4, 73px 527px #fe49a4,
            668px 1237px #fe49a4, 1px 259px #fe49a4, 1027px 107px #fe49a4,
            1347px 842px #fe49a4, 720px 819px #fe49a4, 97px 1047px #fe49a4,
            474px 1262px #fe49a4, 1406px 1163px #fe49a4, 1620px 1312px #fe49a4,
            292px 423px #fe49a4, 1579px 1735px #fe49a4, 687px 305px #fe49a4,
            1810px 1822px #fe49a4, 1499px 241px #fe49a4, 1578px 1308px #fe49a4,
            770px 1441px #fe49a4, 398px 646px #fe49a4, 1186px 1423px #fe49a4,
            6px 201px #fe49a4, 1684px 1057px #fe49a4, 1252px 1352px #fe49a4,
            1649px 317px #fe49a4, 746px 1777px #fe49a4, 537px 97px #fe49a4,
            347px 1613px #fe49a4, 1709px 1896px #fe49a4, 134px 957px #fe49a4,
            755px 340px #fe49a4, 107px 1057px #fe49a4, 263px 1459px #fe49a4,
            1559px 1124px #fe49a4, 1082px 19px #fe49a4, 557px 795px #fe49a4,
            596px 455px #fe49a4, 1877px 1535px #fe49a4, 457px 320px #fe49a4,
            532px 270px #fe49a4, 773px 1991px #fe49a4, 644px 1840px #fe49a4,
            777px 1413px #fe49a4, 726px 203px #fe49a4, 20px 740px #fe49a4,
            655px 909px #fe49a4, 396px 1998px #fe49a4, 235px 954px #fe49a4,
            1737px 264px #fe49a4, 670px 1532px #fe49a4, 498px 159px #fe49a4,
            540px 1997px #fe49a4, 1792px 1327px #fe49a4, 880px 1023px #fe49a4,
            1917px 1938px #fe49a4, 1859px 148px #fe49a4, 1147px 1781px #fe49a4,
            428px 1876px #fe49a4, 1952px 605px #fe49a4, 655px 80px #fe49a4,
            1500px 1545px #fe49a4, 1656px 644px #fe49a4, 615px 247px #fe49a4,
            795px 1881px #fe49a4, 1750px 586px #fe49a4, 148px 1490px #fe49a4,
            1434px 74px #fe49a4, 417px 1969px #fe49a4, 962px 1526px #fe49a4,
            835px 962px #fe49a4, 330px 1767px #fe49a4, 1557px 435px #fe49a4,
            240px 341px #fe49a4, 1857px 1589px #fe49a4, 765px 281px #fe49a4,
            1452px 405px #fe49a4, 1480px 1724px #fe49a4, 950px 1094px #fe49a4,
            710px 1388px #fe49a4, 1333px 1919px #fe49a4, 706px 1589px #fe49a4,
            1476px 1465px #fe49a4, 744px 291px #fe49a4, 123px 870px #fe49a4,
            1406px 428px #fe49a4, 727px 1609px #fe49a4, 1149px 1838px #fe49a4,
            78px 169px #fe49a4, 1088px 284px #fe49a4, 670px 399px #fe49a4,
            711px 1923px #fe49a4, 42px 1720px #fe49a4, 667px 570px #fe49a4,
            1274px 693px #fe49a4, 1056px 644px #fe49a4, 1937px 793px #fe49a4,
            1200px 602px #fe49a4, 1493px 755px #fe49a4, 545px 870px #fe49a4,
            887px 270px #fe49a4, 1290px 164px #fe49a4, 265px 1185px #fe49a4;
    }

    box-shadow: 1226px 1621px #fe49a4, 1021px 1311px #fe49a4,
        589px 396px #fe49a4, 106px 950px #fe49a4, 1628px 685px #fe49a4,
        1982px 1853px #fe49a4, 683px 1196px #fe49a4, 885px 147px #fe49a4,
        140px 572px #fe49a4, 619px 1786px #fe49a4, 158px 727px #fe49a4,
        1051px 1759px #fe49a4, 304px 515px #fe49a4, 1107px 1063px #fe49a4,
        1409px 293px #fe49a4, 844px 1928px #fe49a4, 1297px 766px #fe49a4,
        769px 53px #fe49a4, 1796px 1499px #fe49a4, 1096px 1588px #fe49a4,
        6px 1066px #fe49a4, 954px 1823px #fe49a4, 449px 1318px #fe49a4,
        742px 324px #fe49a4, 1360px 430px #fe49a4, 474px 517px #fe49a4,
        1638px 553px #fe49a4, 65px 362px #fe49a4, 1668px 305px #fe49a4,
        618px 1791px #fe49a4, 1248px 698px #fe49a4, 526px 1505px #fe49a4,
        443px 1738px #fe49a4, 968px 744px #fe49a4, 1509px 1343px #fe49a4,
        940px 891px #fe49a4, 1426px 313px #fe49a4, 86px 1904px #fe49a4,
        1852px 914px #fe49a4, 1774px 1138px #fe49a4, 1406px 378px #fe49a4,
        82px 384px #fe49a4, 1195px 1561px #fe49a4, 472px 1994px #fe49a4,
        887px 708px #fe49a4, 1078px 680px #fe49a4, 1715px 140px #fe49a4,
        333px 1224px #fe49a4, 1538px 412px #fe49a4, 1476px 644px #fe49a4,
        976px 149px #fe49a4, 938px 1127px #fe49a4, 531px 1088px #fe49a4,
        996px 403px #fe49a4, 279px 1233px #fe49a4, 1761px 1297px #fe49a4,
        1126px 1184px #fe49a4, 1417px 375px #fe49a4, 1085px 1137px #fe49a4,
        669px 987px #fe49a4, 1149px 1963px #fe49a4, 1097px 880px #fe49a4,
        1591px 85px #fe49a4, 1858px 884px #fe49a4, 1187px 1483px #fe49a4,
        1246px 415px #fe49a4, 126px 516px #fe49a4, 1824px 672px #fe49a4,
        178px 250px #fe49a4, 1675px 146px #fe49a4, 1827px 816px #fe49a4,
        803px 992px #fe49a4, 1703px 1664px #fe49a4, 1658px 970px #fe49a4,
        1607px 1896px #fe49a4, 268px 499px #fe49a4, 465px 395px #fe49a4,
        287px 468px #fe49a4, 980px 1675px #fe49a4, 1959px 1989px #fe49a4,
        429px 1263px #fe49a4, 1419px 1703px #fe49a4, 302px 335px #fe49a4,
        1470px 698px #fe49a4, 431px 1784px #fe49a4, 1397px 1168px #fe49a4,
        585px 143px #fe49a4, 1163px 455px #fe49a4, 1007px 1355px #fe49a4,
        764px 1147px #fe49a4, 1528px 1835px #fe49a4, 1298px 1629px #fe49a4,
        438px 971px #fe49a4, 1940px 1307px #fe49a4, 1767px 1080px #fe49a4,
        1700px 1582px #fe49a4, 911px 709px #fe49a4, 1733px 1916px #fe49a4,
        1650px 1153px #fe49a4, 1491px 1908px #fe49a4, 1221px 1065px #fe49a4,
        389px 749px #fe49a4, 576px 602px #fe49a4, 84px 1595px #fe49a4,
        1887px 1748px #fe49a4, 1293px 1674px #fe49a4, 1372px 986px #fe49a4,
        834px 1280px #fe49a4, 241px 777px #fe49a4, 1122px 1540px #fe49a4,
        908px 1032px #fe49a4, 1715px 14px #fe49a4, 732px 1014px #fe49a4,
        1888px 766px #fe49a4, 1433px 1174px #fe49a4, 1273px 1889px #fe49a4,
        337px 301px #fe49a4, 1449px 1878px #fe49a4, 946px 1411px #fe49a4,
        965px 181px #fe49a4, 1508px 537px #fe49a4, 977px 1648px #fe49a4,
        696px 1932px #fe49a4, 601px 305px #fe49a4, 1734px 186px #fe49a4,
        1962px 1776px #fe49a4, 965px 791px #fe49a4, 1563px 422px #fe49a4,
        1427px 822px #fe49a4, 1540px 599px #fe49a4, 1246px 1681px #fe49a4,
        1271px 1136px #fe49a4, 1411px 641px #fe49a4, 1108px 1981px #fe49a4,
        961px 1884px #fe49a4, 788px 631px #fe49a4, 172px 783px #fe49a4,
        1729px 455px #fe49a4, 1682px 1051px #fe49a4, 911px 1455px #fe49a4,
        1652px 489px #fe49a4, 880px 94px #fe49a4, 59px 747px #fe49a4,
        417px 223px #fe49a4, 332px 1397px #fe49a4, 699px 659px #fe49a4,
        693px 728px #fe49a4, 1232px 208px #fe49a4, 1514px 774px #fe49a4,
        1070px 633px #fe49a4, 1768px 165px #fe49a4, 1261px 1666px #fe49a4,
        35px 1979px #fe49a4, 54px 1408px #fe49a4, 820px 745px #fe49a4,
        1732px 147px #fe49a4, 1282px 1121px #fe49a4, 1640px 1px #fe49a4,
        1932px 632px #fe49a4, 895px 31px #fe49a4, 1751px 266px #fe49a4,
        746px 54px #fe49a4, 1197px 986px #fe49a4, 1032px 658px #fe49a4,
        234px 1526px #fe49a4, 1267px 1176px #fe49a4, 1280px 357px #fe49a4,
        1333px 381px #fe49a4, 1739px 1375px #fe49a4, 888px 1325px #fe49a4,
        704px 550px #fe49a4, 20px 359px #fe49a4, 987px 1218px #fe49a4,
        794px 1724px #fe49a4, 774px 590px #fe49a4, 1024px 227px #fe49a4,
        1487px 400px #fe49a4, 1446px 627px #fe49a4, 984px 1342px #fe49a4,
        966px 703px #fe49a4, 105px 1673px #fe49a4, 1225px 1267px #fe49a4,
        119px 1202px #fe49a4, 1458px 273px #fe49a4, 1057px 1167px #fe49a4,
        116px 735px #fe49a4, 665px 1021px #fe49a4, 476px 1830px #fe49a4,
        145px 906px #fe49a4, 502px 596px #fe49a4, 1629px 645px #fe49a4,
        728px 1972px #fe49a4, 342px 605px #fe49a4, 1331px 179px #fe49a4,
        707px 1684px #fe49a4, 388px 1757px #fe49a4, 805px 73px #fe49a4,
        659px 180px #fe49a4, 848px 915px #fe49a4, 182px 1550px #fe49a4,
        1955px 155px #fe49a4, 973px 1546px #fe49a4, 1061px 1579px #fe49a4,
        52px 687px #fe49a4, 1104px 1352px #fe49a4, 408px 1386px #fe49a4,
        1888px 1692px #fe49a4, 956px 1018px #fe49a4, 1355px 1568px #fe49a4,
        1505px 1550px #fe49a4, 1182px 705px #fe49a4, 959px 1050px #fe49a4,
        498px 1432px #fe49a4, 301px 647px #fe49a4, 443px 334px #fe49a4,
        1402px 461px #fe49a4, 147px 1270px #fe49a4, 391px 1572px #fe49a4,
        1017px 341px #fe49a4, 68px 1770px #fe49a4, 235px 860px #fe49a4,
        505px 816px #fe49a4, 1312px 986px #fe49a4, 1079px 1293px #fe49a4,
        324px 611px #fe49a4, 1179px 1011px #fe49a4, 277px 434px #fe49a4,
        1767px 252px #fe49a4, 1775px 1487px #fe49a4, 1705px 19px #fe49a4,
        326px 721px #fe49a4, 378px 70px #fe49a4, 895px 1736px #fe49a4,
        1570px 365px #fe49a4, 513px 615px #fe49a4, 1092px 147px #fe49a4,
        1318px 1932px #fe49a4, 1592px 1734px #fe49a4, 699px 918px #fe49a4,
        111px 388px #fe49a4, 1067px 890px #fe49a4, 1169px 332px #fe49a4,
        358px 481px #fe49a4, 1630px 750px #fe49a4, 179px 1787px #fe49a4,
        355px 489px #fe49a4, 852px 821px #fe49a4, 868px 364px #fe49a4,
        386px 1700px #fe49a4, 799px 1420px #fe49a4, 1472px 644px #fe49a4,
        1552px 905px #fe49a4, 48px 1998px #fe49a4, 1850px 51px #fe49a4,
        416px 678px #fe49a4, 759px 1046px #fe49a4, 1451px 1951px #fe49a4,
        101px 1610px #fe49a4, 1469px 912px #fe49a4, 1214px 1292px #fe49a4,
        1368px 1018px #fe49a4, 484px 470px #fe49a4, 816px 322px #fe49a4,
        1821px 1326px #fe49a4, 327px 1936px #fe49a4, 752px 550px #fe49a4,
        1215px 1924px #fe49a4, 180px 122px #fe49a4, 784px 1924px #fe49a4,
        1597px 336px #fe49a4, 248px 1429px #fe49a4, 4px 181px #fe49a4,
        1801px 1613px #fe49a4, 1581px 1719px #fe49a4, 640px 1391px #fe49a4,
        960px 302px #fe49a4, 1483px 1207px #fe49a4, 520px 1119px #fe49a4,
        25px 1754px #fe49a4, 545px 317px #fe49a4, 1573px 751px #fe49a4,
        1415px 2px #fe49a4, 101px 761px #fe49a4, 679px 291px #fe49a4,
        1785px 676px #fe49a4, 1256px 136px #fe49a4, 855px 197px #fe49a4,
        1399px 1973px #fe49a4, 1243px 429px #fe49a4, 1281px 571px #fe49a4,
        839px 531px #fe49a4, 789px 1980px #fe49a4, 877px 783px #fe49a4,
        1526px 1850px #fe49a4, 644px 1283px #fe49a4, 1982px 1953px #fe49a4,
        1109px 1232px #fe49a4, 1532px 643px #fe49a4, 823px 1287px #fe49a4,
        667px 34px #fe49a4, 24px 1469px #fe49a4, 1029px 1130px #fe49a4,
        1645px 254px #fe49a4, 1054px 1000px #fe49a4, 1341px 254px #fe49a4,
        184px 188px #fe49a4, 1160px 1521px #fe49a4, 348px 1083px #fe49a4,
        1436px 1796px #fe49a4, 441px 361px #fe49a4, 1239px 575px #fe49a4,
        1055px 140px #fe49a4, 1552px 502px #fe49a4, 607px 864px #fe49a4,
        967px 1883px #fe49a4, 1407px 1955px #fe49a4, 405px 1148px #fe49a4,
        604px 992px #fe49a4, 1116px 506px #fe49a4, 13px 832px #fe49a4,
        295px 189px #fe49a4, 964px 1175px #fe49a4, 1988px 52px #fe49a4,
        935px 209px #fe49a4, 17px 142px #fe49a4, 770px 333px #fe49a4,
        682px 633px #fe49a4, 510px 466px #fe49a4, 366px 79px #fe49a4,
        199px 954px #fe49a4, 882px 277px #fe49a4, 744px 655px #fe49a4,
        37px 1659px #fe49a4, 1217px 1885px #fe49a4, 1107px 597px #fe49a4,
        1060px 1335px #fe49a4, 773px 601px #fe49a4, 1939px 1632px #fe49a4,
        1607px 1774px #fe49a4, 1531px 1550px #fe49a4, 1042px 60px #fe49a4,
        850px 1502px #fe49a4, 1670px 492px #fe49a4, 1357px 1654px #fe49a4,
        1279px 1521px #fe49a4, 633px 1956px #fe49a4, 1122px 1981px #fe49a4,
        661px 657px #fe49a4, 521px 1132px #fe49a4, 1593px 813px #fe49a4,
        1204px 1463px #fe49a4, 1112px 1495px #fe49a4, 1755px 367px #fe49a4,
        1685px 1805px #fe49a4, 1982px 196px #fe49a4, 484px 51px #fe49a4,
        1041px 330px #fe49a4, 1487px 563px #fe49a4, 311px 1872px #fe49a4,
        330px 976px #fe49a4, 6px 1754px #fe49a4, 1447px 1182px #fe49a4,
        1926px 148px #fe49a4, 1640px 490px #fe49a4, 1548px 1357px #fe49a4,
        1052px 1340px #fe49a4, 1083px 56px #fe49a4, 1890px 339px #fe49a4,
        277px 1609px #fe49a4, 892px 1792px #fe49a4, 1322px 86px #fe49a4,
        1509px 1515px #fe49a4, 1650px 1722px #fe49a4, 334px 497px #fe49a4,
        888px 143px #fe49a4, 1325px 259px #fe49a4, 1330px 1505px #fe49a4,
        1374px 645px #fe49a4, 1184px 937px #fe49a4, 911px 1044px #fe49a4,
        32px 1257px #fe49a4, 297px 1884px #fe49a4, 1031px 963px #fe49a4,
        411px 916px #fe49a4, 243px 1305px #fe49a4, 898px 200px #fe49a4,
        1550px 156px #fe49a4, 159px 716px #fe49a4, 240px 1130px #fe49a4,
        1046px 1905px #fe49a4, 1405px 1277px #fe49a4, 275px 124px #fe49a4,
        964px 795px #fe49a4, 1270px 1387px #fe49a4, 1391px 1880px #fe49a4,
        484px 1027px #fe49a4, 1681px 1706px #fe49a4, 1665px 697px #fe49a4,
        1073px 1078px #fe49a4, 1808px 1313px #fe49a4, 1669px 1266px #fe49a4,
        1965px 747px #fe49a4, 1734px 972px #fe49a4, 1549px 1434px #fe49a4,
        945px 146px #fe49a4, 1211px 1383px #fe49a4, 1921px 1637px #fe49a4,
        271px 1725px #fe49a4, 739px 1725px #fe49a4, 1634px 1827px #fe49a4,
        961px 1131px #fe49a4, 513px 839px #fe49a4, 1553px 1725px #fe49a4,
        82px 1667px #fe49a4, 3px 1797px #fe49a4, 517px 1511px #fe49a4,
        1644px 929px #fe49a4, 120px 1638px #fe49a4, 579px 1168px #fe49a4,
        147px 680px #fe49a4, 61px 1146px #fe49a4, 884px 598px #fe49a4,
        630px 1324px #fe49a4, 1709px 1340px #fe49a4, 1332px 1142px #fe49a4,
        1990px 1397px #fe49a4, 854px 1527px #fe49a4, 1293px 634px #fe49a4,
        1063px 506px #fe49a4, 1361px 668px #fe49a4, 926px 347px #fe49a4,
        145px 1031px #fe49a4, 1289px 1519px #fe49a4, 1977px 1560px #fe49a4,
        70px 1353px #fe49a4, 653px 1634px #fe49a4, 1182px 340px #fe49a4,
        1264px 1127px #fe49a4, 1246px 40px #fe49a4, 428px 691px #fe49a4,
        1531px 1817px #fe49a4, 431px 1906px #fe49a4, 1406px 1665px #fe49a4,
        597px 1588px #fe49a4, 1273px 355px #fe49a4, 995px 1018px #fe49a4,
        1319px 528px #fe49a4, 1454px 715px #fe49a4, 1587px 1052px #fe49a4,
        921px 193px #fe49a4, 1012px 479px #fe49a4, 689px 1019px #fe49a4,
        1307px 420px #fe49a4, 1153px 108px #fe49a4, 1343px 775px #fe49a4,
        1236px 1061px #fe49a4, 1117px 1099px #fe49a4, 308px 533px #fe49a4,
        1262px 509px #fe49a4, 830px 24px #fe49a4, 426px 695px #fe49a4,
        1148px 1397px #fe49a4, 1016px 1719px #fe49a4, 1142px 1280px #fe49a4,
        1841px 310px #fe49a4, 1890px 1708px #fe49a4, 1399px 589px #fe49a4,
        913px 1291px #fe49a4, 955px 1445px #fe49a4, 431px 491px #fe49a4,
        580px 1002px #fe49a4, 796px 186px #fe49a4, 445px 1634px #fe49a4,
        1543px 1377px #fe49a4, 1853px 57px #fe49a4, 34px 620px #fe49a4,
        1056px 923px #fe49a4, 1027px 134px #fe49a4, 1248px 1252px #fe49a4,
        756px 1855px #fe49a4, 143px 654px #fe49a4, 253px 347px #fe49a4,
        181px 943px #fe49a4, 1044px 1774px #fe49a4, 367px 1925px #fe49a4,
        1125px 854px #fe49a4, 1276px 141px #fe49a4, 422px 413px #fe49a4,
        1261px 973px #fe49a4, 1030px 1349px #fe49a4, 1349px 1850px #fe49a4,
        552px 1286px #fe49a4, 606px 888px #fe49a4, 464px 1922px #fe49a4,
        1799px 260px #fe49a4, 1984px 788px #fe49a4, 1210px 450px #fe49a4,
        1288px 1530px #fe49a4, 911px 1896px #fe49a4, 689px 4px #fe49a4,
        806px 162px #fe49a4, 1201px 604px #fe49a4, 908px 531px #fe49a4,
        137px 1123px #fe49a4, 1165px 496px #fe49a4, 1567px 1797px #fe49a4,
        614px 504px #fe49a4, 1551px 1165px #fe49a4, 1309px 1032px #fe49a4,
        1321px 26px #fe49a4, 1372px 326px #fe49a4, 1762px 1159px #fe49a4,
        728px 5px #fe49a4, 1364px 459px #fe49a4, 1333px 918px #fe49a4,
        1082px 1732px #fe49a4, 286px 319px #fe49a4, 1636px 1220px #fe49a4,
        350px 966px #fe49a4, 124px 219px #fe49a4, 184px 1654px #fe49a4,
        1195px 160px #fe49a4, 1690px 737px #fe49a4, 1386px 472px #fe49a4,
        1522px 1911px #fe49a4, 1082px 1129px #fe49a4, 491px 67px #fe49a4,
        826px 393px #fe49a4, 643px 632px #fe49a4, 1934px 1461px #fe49a4,
        1325px 316px #fe49a4, 934px 1722px #fe49a4, 1044px 879px #fe49a4,
        1109px 1482px #fe49a4, 1040px 642px #fe49a4, 801px 509px #fe49a4,
        1352px 1583px #fe49a4, 160px 339px #fe49a4, 1111px 1731px #fe49a4,
        1650px 1190px #fe49a4, 1804px 1019px #fe49a4, 745px 604px #fe49a4,
        1391px 1108px #fe49a4, 344px 708px #fe49a4, 395px 111px #fe49a4,
        913px 1180px #fe49a4, 537px 1036px #fe49a4, 1048px 1611px #fe49a4,
        1926px 155px #fe49a4, 392px 193px #fe49a4, 1980px 1465px #fe49a4,
        1853px 1349px #fe49a4, 204px 1317px #fe49a4, 1342px 1956px #fe49a4,
        785px 1564px #fe49a4, 1478px 612px #fe49a4, 1459px 491px #fe49a4,
        1767px 265px #fe49a4, 1032px 495px #fe49a4, 946px 1196px #fe49a4,
        607px 285px #fe49a4, 887px 1940px #fe49a4, 1618px 962px #fe49a4,
        85px 801px #fe49a4, 1828px 525px #fe49a4, 523px 1927px #fe49a4,
        280px 1676px #fe49a4, 551px 1133px #fe49a4, 1888px 936px #fe49a4,
        875px 863px #fe49a4, 395px 1954px #fe49a4, 155px 590px #fe49a4,
        1924px 262px #fe49a4, 1039px 1430px #fe49a4, 1356px 1816px #fe49a4,
        978px 671px #fe49a4, 1968px 1044px #fe49a4, 1693px 15px #fe49a4,
        266px 1506px #fe49a4, 1061px 224px #fe49a4, 690px 1132px #fe49a4,
        1149px 313px #fe49a4, 744px 898px #fe49a4, 848px 1851px #fe49a4,
        296px 761px #fe49a4, 55px 624px #fe49a4, 573px 131px #fe49a4,
        483px 1869px #fe49a4, 157px 1304px #fe49a4, 496px 489px #fe49a4,
        729px 1730px #fe49a4, 163px 1023px #fe49a4, 1065px 1426px #fe49a4,
        1058px 865px #fe49a4, 1204px 1762px #fe49a4, 73px 527px #fe49a4,
        668px 1237px #fe49a4, 1px 259px #fe49a4, 1027px 107px #fe49a4,
        1347px 842px #fe49a4, 720px 819px #fe49a4, 97px 1047px #fe49a4,
        474px 1262px #fe49a4, 1406px 1163px #fe49a4, 1620px 1312px #fe49a4,
        292px 423px #fe49a4, 1579px 1735px #fe49a4, 687px 305px #fe49a4,
        1810px 1822px #fe49a4, 1499px 241px #fe49a4, 1578px 1308px #fe49a4,
        770px 1441px #fe49a4, 398px 646px #fe49a4, 1186px 1423px #fe49a4,
        6px 201px #fe49a4, 1684px 1057px #fe49a4, 1252px 1352px #fe49a4,
        1649px 317px #fe49a4, 746px 1777px #fe49a4, 537px 97px #fe49a4,
        347px 1613px #fe49a4, 1709px 1896px #fe49a4, 134px 957px #fe49a4,
        755px 340px #fe49a4, 107px 1057px #fe49a4, 263px 1459px #fe49a4,
        1559px 1124px #fe49a4, 1082px 19px #fe49a4, 557px 795px #fe49a4,
        596px 455px #fe49a4, 1877px 1535px #fe49a4, 457px 320px #fe49a4,
        532px 270px #fe49a4, 773px 1991px #fe49a4, 644px 1840px #fe49a4,
        777px 1413px #fe49a4, 726px 203px #fe49a4, 20px 740px #fe49a4,
        655px 909px #fe49a4, 396px 1998px #fe49a4, 235px 954px #fe49a4,
        1737px 264px #fe49a4, 670px 1532px #fe49a4, 498px 159px #fe49a4,
        540px 1997px #fe49a4, 1792px 1327px #fe49a4, 880px 1023px #fe49a4,
        1917px 1938px #fe49a4, 1859px 148px #fe49a4, 1147px 1781px #fe49a4,
        428px 1876px #fe49a4, 1952px 605px #fe49a4, 655px 80px #fe49a4,
        1500px 1545px #fe49a4, 1656px 644px #fe49a4, 615px 247px #fe49a4,
        795px 1881px #fe49a4, 1750px 586px #fe49a4, 148px 1490px #fe49a4,
        1434px 74px #fe49a4, 417px 1969px #fe49a4, 962px 1526px #fe49a4,
        835px 962px #fe49a4, 330px 1767px #fe49a4, 1557px 435px #fe49a4,
        240px 341px #fe49a4, 1857px 1589px #fe49a4, 765px 281px #fe49a4,
        1452px 405px #fe49a4, 1480px 1724px #fe49a4, 950px 1094px #fe49a4,
        710px 1388px #fe49a4, 1333px 1919px #fe49a4, 706px 1589px #fe49a4,
        1476px 1465px #fe49a4, 744px 291px #fe49a4, 123px 870px #fe49a4,
        1406px 428px #fe49a4, 727px 1609px #fe49a4, 1149px 1838px #fe49a4,
        78px 169px #fe49a4, 1088px 284px #fe49a4, 670px 399px #fe49a4,
        711px 1923px #fe49a4, 42px 1720px #fe49a4, 667px 570px #fe49a4,
        1274px 693px #fe49a4, 1056px 644px #fe49a4, 1937px 793px #fe49a4,
        1200px 602px #fe49a4, 1493px 755px #fe49a4, 545px 870px #fe49a4,
        887px 270px #fe49a4, 1290px 164px #fe49a4, 265px 1185px #fe49a4;
`;

export const StarsTwo = styled.div<{ animate?: boolean }>`
    opacity: 0.8;
    width: 1.3px;
    height: 1.3px;
    background: transparent;
    ${props => (props.animate ? `transform: translateY(0);` : '')};
    animation: ${props =>
        props.animate
            ? css`
                  ${animateStars2} 15s linear infinite;
              `
            : ''};

    &::after {
        content: ' ';
        position: absolute;
        ${props =>
            props.animate ? ` top: -2000px;` : `top: 0; left: 1500px;`};
        width: 1.3px;
        height: 1.3px;
        box-shadow: 1226px 1621px #fe49a4, 1021px 1311px #fe49a4,
            589px 396px #fe49a4, 106px 950px #fe49a4, 683px 1196px #fe49a4,
            885px 147px #fe49a4, 140px 572px #fe49a4, 1414px 202px #fe49a4,
            245px 366px #fe49a4, 619px 1786px #fe49a4, 158px 727px #fe49a4,
            1051px 1759px #fe49a4, 304px 515px #fe49a4, 1107px 1063px #fe49a4,
            1409px 293px #fe49a4, 844px 1928px #fe49a4, 1297px 766px #fe49a4,
            769px 53px #fe49a4, 1796px 1499px #fe49a4, 1096px 1588px #fe49a4,
            6px 1066px #fe49a4, 954px 1823px #fe49a4, 449px 1318px #fe49a4,
            742px 324px #fe49a4, 1360px 430px #fe49a4, 474px 517px #fe49a4,
            1638px 553px #fe49a4, 65px 362px #fe49a4, 1668px 305px #fe49a4,
            880px 94px #fe49a4, 59px 747px #fe49a4, 417px 223px #fe49a4,
            332px 1397px #fe49a4, 699px 659px #fe49a4, 693px 728px #fe49a4,
            1232px 208px #fe49a4, 1514px 774px #fe49a4, 1070px 633px #fe49a4,
            1768px 165px #fe49a4, 1261px 1666px #fe49a4, 35px 1979px #fe49a4,
            54px 1408px #fe49a4, 820px 745px #fe49a4, 1732px 147px #fe49a4,
            1282px 1121px #fe49a4, 1640px 1px #fe49a4, 1932px 632px #fe49a4,
            895px 31px #fe49a4, 1751px 266px #fe49a4, 746px 54px #fe49a4,
            1197px 986px #fe49a4, 1032px 658px #fe49a4, 234px 1526px #fe49a4,
            1267px 1176px #fe49a4, 1280px 357px #fe49a4, 1333px 381px #fe49a4,
            1739px 1375px #fe49a4, 888px 1325px #fe49a4, 704px 550px #fe49a4,
            20px 359px #fe49a4, 987px 1218px #fe49a4, 794px 1724px #fe49a4,
            774px 590px #fe49a4, 1024px 227px #fe49a4, 1487px 400px #fe49a4,
            1446px 627px #fe49a4, 984px 1342px #fe49a4, 966px 703px #fe49a4,
            105px 1673px #fe49a4, 1225px 1267px #fe49a4, 119px 1202px #fe49a4,
            1458px 273px #fe49a4, 1057px 1167px #fe49a4, 116px 735px #fe49a4,
            665px 1021px #fe49a4, 476px 1830px #fe49a4, 145px 906px #fe49a4,
            502px 596px #fe49a4, 1629px 645px #fe49a4, 728px 1972px #fe49a4,
            342px 605px #fe49a4, 1331px 179px #fe49a4, 707px 1684px #fe49a4,
            388px 1757px #fe49a4, 805px 73px #fe49a4, 659px 180px #fe49a4,
            848px 915px #fe49a4, 182px 1550px #fe49a4, 1955px 155px #fe49a4,
            973px 1546px #fe49a4, 1061px 1579px #fe49a4, 52px 687px #fe49a4,
            1104px 1352px #fe49a4, 408px 1386px #fe49a4, 1888px 1692px #fe49a4,
            956px 1018px #fe49a4, 1355px 1568px #fe49a4, 1505px 1550px #fe49a4,
            1182px 705px #fe49a4, 959px 1050px #fe49a4, 498px 1432px #fe49a4,
            301px 647px #fe49a4, 443px 334px #fe49a4, 1402px 461px #fe49a4,
            147px 1270px #fe49a4, 391px 1572px #fe49a4, 1017px 341px #fe49a4,
            68px 1770px #fe49a4, 235px 860px #fe49a4, 505px 816px #fe49a4,
            1312px 986px #fe49a4, 1079px 1293px #fe49a4, 324px 611px #fe49a4,
            1179px 1011px #fe49a4, 277px 434px #fe49a4, 1767px 252px #fe49a4,
            1775px 1487px #fe49a4, 1705px 19px #fe49a4, 326px 721px #fe49a4,
            378px 70px #fe49a4, 895px 1736px #fe49a4, 1570px 365px #fe49a4,
            513px 615px #fe49a4, 1092px 147px #fe49a4, 1318px 1932px #fe49a4,
            1592px 1734px #fe49a4, 699px 918px #fe49a4, 111px 388px #fe49a4,
            1067px 890px #fe49a4, 1169px 332px #fe49a4, 358px 481px #fe49a4,
            1630px 750px #fe49a4, 179px 1787px #fe49a4, 355px 489px #fe49a4,
            852px 821px #fe49a4, 868px 364px #fe49a4, 386px 1700px #fe49a4,
            799px 1420px #fe49a4, 1472px 644px #fe49a4, 1552px 905px #fe49a4,
            48px 1998px #fe49a4, 1850px 51px #fe49a4, 416px 678px #fe49a4,
            759px 1046px #fe49a4, 1451px 1951px #fe49a4, 101px 1610px #fe49a4,
            1469px 912px #fe49a4, 1214px 1292px #fe49a4, 1368px 1018px #fe49a4,
            484px 470px #fe49a4, 816px 322px #fe49a4, 1821px 1326px #fe49a4,
            682px 633px #fe49a4, 510px 466px #fe49a4, 366px 79px #fe49a4,
            199px 954px #fe49a4, 882px 277px #fe49a4, 744px 655px #fe49a4,
            37px 1659px #fe49a4, 1217px 1885px #fe49a4, 1107px 597px #fe49a4,
            1060px 1335px #fe49a4, 773px 601px #fe49a4, 1939px 1632px #fe49a4,
            1607px 1774px #fe49a4, 1531px 1550px #fe49a4, 1042px 60px #fe49a4,
            850px 1502px #fe49a4, 1670px 492px #fe49a4, 1357px 1654px #fe49a4,
            1279px 1521px #fe49a4, 633px 1956px #fe49a4, 1122px 1981px #fe49a4,
            661px 657px #fe49a4, 521px 1132px #fe49a4, 1593px 813px #fe49a4,
            1204px 1463px #fe49a4, 1112px 1495px #fe49a4, 1755px 367px #fe49a4,
            1685px 1805px #fe49a4, 1982px 196px #fe49a4, 484px 51px #fe49a4,
            1041px 330px #fe49a4, 1487px 563px #fe49a4, 311px 1872px #fe49a4,
            330px 976px #fe49a4, 6px 1754px #fe49a4, 1447px 1182px #fe49a4,
            1926px 148px #fe49a4, 1640px 490px #fe49a4, 1548px 1357px #fe49a4,
            1052px 1340px #fe49a4, 1083px 56px #fe49a4, 1890px 339px #fe49a4,
            277px 1609px #fe49a4, 892px 1792px #fe49a4, 1322px 86px #fe49a4,
            1509px 1515px #fe49a4, 1650px 1722px #fe49a4, 334px 497px #fe49a4,
            888px 143px #fe49a4, 1325px 259px #fe49a4, 1330px 1505px #fe49a4,
            1374px 645px #fe49a4, 1184px 937px #fe49a4, 911px 1044px #fe49a4,
            32px 1257px #fe49a4, 297px 1884px #fe49a4, 1031px 963px #fe49a4,
            411px 916px #fe49a4, 243px 1305px #fe49a4, 898px 200px #fe49a4,
            1550px 156px #fe49a4, 159px 716px #fe49a4, 240px 1130px #fe49a4,
            1046px 1905px #fe49a4, 1405px 1277px #fe49a4, 275px 124px #fe49a4,
            964px 795px #fe49a4, 1270px 1387px #fe49a4, 1391px 1880px #fe49a4,
            484px 1027px #fe49a4, 1681px 1706px #fe49a4, 1665px 697px #fe49a4,
            1073px 1078px #fe49a4, 1808px 1313px #fe49a4, 1669px 1266px #fe49a4,
            1965px 747px #fe49a4, 1734px 972px #fe49a4, 1549px 1434px #fe49a4,
            945px 146px #fe49a4, 1211px 1383px #fe49a4, 1921px 1637px #fe49a4,
            271px 1725px #fe49a4, 739px 1725px #fe49a4, 1634px 1827px #fe49a4,
            961px 1131px #fe49a4, 513px 839px #fe49a4, 1553px 1725px #fe49a4,
            82px 1667px #fe49a4, 3px 1797px #fe49a4, 517px 1511px #fe49a4,
            1644px 929px #fe49a4, 120px 1638px #fe49a4, 579px 1168px #fe49a4,
            147px 680px #fe49a4, 61px 1146px #fe49a4, 884px 598px #fe49a4,
            630px 1324px #fe49a4, 1709px 1340px #fe49a4, 1332px 1142px #fe49a4,
            1990px 1397px #fe49a4, 854px 1527px #fe49a4, 1293px 634px #fe49a4,
            1063px 506px #fe49a4, 1361px 668px #fe49a4, 926px 347px #fe49a4,
            145px 1031px #fe49a4, 1289px 1519px #fe49a4, 1977px 1560px #fe49a4,
            70px 1353px #fe49a4, 653px 1634px #fe49a4, 1182px 340px #fe49a4,
            1264px 1127px #fe49a4, 1246px 40px #fe49a4, 428px 691px #fe49a4,
            1531px 1817px #fe49a4, 431px 1906px #fe49a4, 1406px 1665px #fe49a4,
            597px 1588px #fe49a4, 1273px 355px #fe49a4, 995px 1018px #fe49a4,
            1319px 528px #fe49a4, 1454px 715px #fe49a4, 1587px 1052px #fe49a4,
            921px 193px #fe49a4, 1012px 479px #fe49a4, 689px 1019px #fe49a4,
            1307px 420px #fe49a4, 1153px 108px #fe49a4, 1343px 775px #fe49a4,
            1236px 1061px #fe49a4, 1117px 1099px #fe49a4, 308px 533px #fe49a4,
            1262px 509px #fe49a4, 830px 24px #fe49a4, 426px 695px #fe49a4,
            1148px 1397px #fe49a4, 1016px 1719px #fe49a4, 1142px 1280px #fe49a4,
            1841px 310px #fe49a4, 1890px 1708px #fe49a4, 1399px 589px #fe49a4,
            913px 1291px #fe49a4, 955px 1445px #fe49a4, 431px 491px #fe49a4,
            580px 1002px #fe49a4, 796px 186px #fe49a4, 445px 1634px #fe49a4,
            1543px 1377px #fe49a4, 1853px 57px #fe49a4, 34px 620px #fe49a4,
            1056px 923px #fe49a4, 1027px 134px #fe49a4, 1248px 1252px #fe49a4,
            756px 1855px #fe49a4, 143px 654px #fe49a4, 253px 347px #fe49a4,
            181px 943px #fe49a4, 1044px 1774px #fe49a4, 367px 1925px #fe49a4,
            1125px 854px #fe49a4, 1276px 141px #fe49a4, 422px 413px #fe49a4,
            1261px 973px #fe49a4, 1030px 1349px #fe49a4, 1349px 1850px #fe49a4,
            552px 1286px #fe49a4, 606px 888px #fe49a4, 464px 1922px #fe49a4,
            1799px 260px #fe49a4, 1984px 788px #fe49a4, 1210px 450px #fe49a4,
            1288px 1530px #fe49a4, 911px 1896px #fe49a4, 689px 4px #fe49a4,
            806px 162px #fe49a4, 1201px 604px #fe49a4, 908px 531px #fe49a4,
            137px 1123px #fe49a4, 1165px 496px #fe49a4, 1567px 1797px #fe49a4,
            614px 504px #fe49a4, 1551px 1165px #fe49a4, 1309px 1032px #fe49a4,
            1321px 26px #fe49a4, 1372px 326px #fe49a4, 1762px 1159px #fe49a4,
            728px 5px #fe49a4, 1364px 459px #fe49a4, 1333px 918px #fe49a4,
            1082px 1732px #fe49a4, 286px 319px #fe49a4, 1636px 1220px #fe49a4,
            350px 966px #fe49a4, 124px 219px #fe49a4, 184px 1654px #fe49a4,
            1195px 160px #fe49a4, 1690px 737px #fe49a4, 1386px 472px #fe49a4,
            1522px 1911px #fe49a4, 1082px 1129px #fe49a4, 491px 67px #fe49a4,
            826px 393px #fe49a4, 643px 632px #fe49a4, 1934px 1461px #fe49a4,
            1325px 316px #fe49a4, 934px 1722px #fe49a4, 1044px 879px #fe49a4,
            1109px 1482px #fe49a4, 1040px 642px #fe49a4, 801px 509px #fe49a4,
            1352px 1583px #fe49a4, 160px 339px #fe49a4, 1111px 1731px #fe49a4,
            1650px 1190px #fe49a4, 1804px 1019px #fe49a4, 745px 604px #fe49a4,
            1391px 1108px #fe49a4, 344px 708px #fe49a4, 395px 111px #fe49a4,
            913px 1180px #fe49a4, 537px 1036px #fe49a4, 1048px 1611px #fe49a4,
            1926px 155px #fe49a4, 392px 193px #fe49a4, 1980px 1465px #fe49a4,
            1853px 1349px #fe49a4, 204px 1317px #fe49a4, 1342px 1956px #fe49a4,
            785px 1564px #fe49a4, 1478px 612px #fe49a4, 1459px 491px #fe49a4,
            1767px 265px #fe49a4, 1032px 495px #fe49a4, 946px 1196px #fe49a4,
            607px 285px #fe49a4, 887px 1940px #fe49a4, 1618px 962px #fe49a4,
            85px 801px #fe49a4, 1828px 525px #fe49a4, 523px 1927px #fe49a4,
            280px 1676px #fe49a4, 551px 1133px #fe49a4, 1888px 936px #fe49a4,
            875px 863px #fe49a4, 395px 1954px #fe49a4, 155px 590px #fe49a4,
            1924px 262px #fe49a4, 1039px 1430px #fe49a4, 1356px 1816px #fe49a4,
            978px 671px #fe49a4, 1968px 1044px #fe49a4, 1693px 15px #fe49a4,
            266px 1506px #fe49a4, 1061px 224px #fe49a4, 690px 1132px #fe49a4,
            1149px 313px #fe49a4, 744px 898px #fe49a4, 848px 1851px #fe49a4,
            296px 761px #fe49a4, 55px 624px #fe49a4, 573px 131px #fe49a4,
            483px 1869px #fe49a4, 157px 1304px #fe49a4, 496px 489px #fe49a4,
            729px 1730px #fe49a4, 163px 1023px #fe49a4, 1065px 1426px #fe49a4,
            1058px 865px #fe49a4, 1204px 1762px #fe49a4, 73px 527px #fe49a4,
            668px 1237px #fe49a4, 1px 259px #fe49a4, 1027px 107px #fe49a4,
            1347px 842px #fe49a4, 720px 819px #fe49a4, 97px 1047px #fe49a4,
            474px 1262px #fe49a4, 1406px 1163px #fe49a4, 1620px 1312px #fe49a4,
            292px 423px #fe49a4, 1579px 1735px #fe49a4, 687px 305px #fe49a4,
            1810px 1822px #fe49a4, 1499px 241px #fe49a4, 1578px 1308px #fe49a4,
            770px 1441px #fe49a4, 398px 646px #fe49a4, 1186px 1423px #fe49a4,
            6px 201px #fe49a4, 1684px 1057px #fe49a4, 1252px 1352px #fe49a4,
            1649px 317px #fe49a4, 746px 1777px #fe49a4, 537px 97px #fe49a4,
            347px 1613px #fe49a4, 1709px 1896px #fe49a4, 134px 957px #fe49a4,
            755px 340px #fe49a4, 107px 1057px #fe49a4, 263px 1459px #fe49a4,
            1559px 1124px #fe49a4, 1082px 19px #fe49a4, 557px 795px #fe49a4,
            596px 455px #fe49a4, 1877px 1535px #fe49a4, 457px 320px #fe49a4,
            532px 270px #fe49a4, 773px 1991px #fe49a4, 644px 1840px #fe49a4,
            777px 1413px #fe49a4, 726px 203px #fe49a4, 20px 740px #fe49a4,
            655px 909px #fe49a4, 396px 1998px #fe49a4, 235px 954px #fe49a4,
            1737px 264px #fe49a4, 670px 1532px #fe49a4, 498px 159px #fe49a4,
            540px 1997px #fe49a4, 1792px 1327px #fe49a4, 880px 1023px #fe49a4,
            1917px 1938px #fe49a4, 1859px 148px #fe49a4, 1147px 1781px #fe49a4,
            428px 1876px #fe49a4, 1952px 605px #fe49a4, 655px 80px #fe49a4,
            1500px 1545px #fe49a4, 1656px 644px #fe49a4, 615px 247px #fe49a4,
            795px 1881px #fe49a4, 1750px 586px #fe49a4, 148px 1490px #fe49a4,
            1434px 74px #fe49a4, 417px 1969px #fe49a4, 962px 1526px #fe49a4,
            835px 962px #fe49a4, 330px 1767px #fe49a4, 1557px 435px #fe49a4,
            240px 341px #fe49a4, 1857px 1589px #fe49a4, 765px 281px #fe49a4,
            1452px 405px #fe49a4, 1480px 1724px #fe49a4, 950px 1094px #fe49a4,
            710px 1388px #fe49a4, 1333px 1919px #fe49a4, 706px 1589px #fe49a4,
            1476px 1465px #fe49a4, 744px 291px #fe49a4, 123px 870px #fe49a4,
            1406px 428px #fe49a4, 727px 1609px #fe49a4, 1149px 1838px #fe49a4,
            78px 169px #fe49a4, 1088px 284px #fe49a4, 670px 399px #fe49a4,
            711px 1923px #fe49a4, 42px 1720px #fe49a4, 667px 570px #fe49a4,
            1274px 693px #fe49a4, 1056px 644px #fe49a4, 1937px 793px #fe49a4,
            1200px 602px #fe49a4, 1493px 755px #fe49a4, 545px 870px #fe49a4,
            887px 270px #fe49a4, 1290px 164px #fe49a4, 265px 1185px #fe49a4;
    }

    box-shadow: 1226px 1621px #fe49a4, 1021px 1311px #fe49a4,
        589px 396px #fe49a4, 106px 950px #fe49a4, 683px 1196px #fe49a4,
        885px 147px #fe49a4, 140px 572px #fe49a4, 1414px 202px #fe49a4,
        245px 366px #fe49a4, 619px 1786px #fe49a4, 158px 727px #fe49a4,
        1051px 1759px #fe49a4, 304px 515px #fe49a4, 1107px 1063px #fe49a4,
        1409px 293px #fe49a4, 844px 1928px #fe49a4, 1297px 766px #fe49a4,
        769px 53px #fe49a4, 1796px 1499px #fe49a4, 1096px 1588px #fe49a4,
        6px 1066px #fe49a4, 954px 1823px #fe49a4, 449px 1318px #fe49a4,
        742px 324px #fe49a4, 1360px 430px #fe49a4, 474px 517px #fe49a4,
        1638px 553px #fe49a4, 65px 362px #fe49a4, 1668px 305px #fe49a4,
        880px 94px #fe49a4, 59px 747px #fe49a4, 417px 223px #fe49a4,
        332px 1397px #fe49a4, 699px 659px #fe49a4, 693px 728px #fe49a4,
        1232px 208px #fe49a4, 1514px 774px #fe49a4, 1070px 633px #fe49a4,
        1768px 165px #fe49a4, 1261px 1666px #fe49a4, 35px 1979px #fe49a4,
        54px 1408px #fe49a4, 820px 745px #fe49a4, 1732px 147px #fe49a4,
        1282px 1121px #fe49a4, 1640px 1px #fe49a4, 1932px 632px #fe49a4,
        895px 31px #fe49a4, 1751px 266px #fe49a4, 746px 54px #fe49a4,
        1197px 986px #fe49a4, 1032px 658px #fe49a4, 234px 1526px #fe49a4,
        1267px 1176px #fe49a4, 1280px 357px #fe49a4, 1333px 381px #fe49a4,
        1739px 1375px #fe49a4, 888px 1325px #fe49a4, 704px 550px #fe49a4,
        20px 359px #fe49a4, 987px 1218px #fe49a4, 794px 1724px #fe49a4,
        774px 590px #fe49a4, 1024px 227px #fe49a4, 1487px 400px #fe49a4,
        1446px 627px #fe49a4, 984px 1342px #fe49a4, 966px 703px #fe49a4,
        105px 1673px #fe49a4, 1225px 1267px #fe49a4, 119px 1202px #fe49a4,
        1458px 273px #fe49a4, 1057px 1167px #fe49a4, 116px 735px #fe49a4,
        665px 1021px #fe49a4, 476px 1830px #fe49a4, 145px 906px #fe49a4,
        502px 596px #fe49a4, 1629px 645px #fe49a4, 728px 1972px #fe49a4,
        342px 605px #fe49a4, 1331px 179px #fe49a4, 707px 1684px #fe49a4,
        388px 1757px #fe49a4, 805px 73px #fe49a4, 659px 180px #fe49a4,
        848px 915px #fe49a4, 182px 1550px #fe49a4, 1955px 155px #fe49a4,
        973px 1546px #fe49a4, 1061px 1579px #fe49a4, 52px 687px #fe49a4,
        1104px 1352px #fe49a4, 408px 1386px #fe49a4, 1888px 1692px #fe49a4,
        956px 1018px #fe49a4, 1355px 1568px #fe49a4, 1505px 1550px #fe49a4,
        1182px 705px #fe49a4, 959px 1050px #fe49a4, 498px 1432px #fe49a4,
        301px 647px #fe49a4, 443px 334px #fe49a4, 1402px 461px #fe49a4,
        147px 1270px #fe49a4, 391px 1572px #fe49a4, 1017px 341px #fe49a4,
        68px 1770px #fe49a4, 235px 860px #fe49a4, 505px 816px #fe49a4,
        1312px 986px #fe49a4, 1079px 1293px #fe49a4, 324px 611px #fe49a4,
        1179px 1011px #fe49a4, 277px 434px #fe49a4, 1767px 252px #fe49a4,
        1775px 1487px #fe49a4, 1705px 19px #fe49a4, 326px 721px #fe49a4,
        378px 70px #fe49a4, 895px 1736px #fe49a4, 1570px 365px #fe49a4,
        513px 615px #fe49a4, 1092px 147px #fe49a4, 1318px 1932px #fe49a4,
        1592px 1734px #fe49a4, 699px 918px #fe49a4, 111px 388px #fe49a4,
        1067px 890px #fe49a4, 1169px 332px #fe49a4, 358px 481px #fe49a4,
        1630px 750px #fe49a4, 179px 1787px #fe49a4, 355px 489px #fe49a4,
        852px 821px #fe49a4, 868px 364px #fe49a4, 386px 1700px #fe49a4,
        799px 1420px #fe49a4, 1472px 644px #fe49a4, 1552px 905px #fe49a4,
        48px 1998px #fe49a4, 1850px 51px #fe49a4, 416px 678px #fe49a4,
        759px 1046px #fe49a4, 1451px 1951px #fe49a4, 101px 1610px #fe49a4,
        1469px 912px #fe49a4, 1214px 1292px #fe49a4, 1368px 1018px #fe49a4,
        484px 470px #fe49a4, 816px 322px #fe49a4, 1821px 1326px #fe49a4,
        682px 633px #fe49a4, 510px 466px #fe49a4, 366px 79px #fe49a4,
        199px 954px #fe49a4, 882px 277px #fe49a4, 744px 655px #fe49a4,
        37px 1659px #fe49a4, 1217px 1885px #fe49a4, 1107px 597px #fe49a4,
        1060px 1335px #fe49a4, 773px 601px #fe49a4, 1939px 1632px #fe49a4,
        1607px 1774px #fe49a4, 1531px 1550px #fe49a4, 1042px 60px #fe49a4,
        850px 1502px #fe49a4, 1670px 492px #fe49a4, 1357px 1654px #fe49a4,
        1279px 1521px #fe49a4, 633px 1956px #fe49a4, 1122px 1981px #fe49a4,
        661px 657px #fe49a4, 521px 1132px #fe49a4, 1593px 813px #fe49a4,
        1204px 1463px #fe49a4, 1112px 1495px #fe49a4, 1755px 367px #fe49a4,
        1685px 1805px #fe49a4, 1982px 196px #fe49a4, 484px 51px #fe49a4,
        1041px 330px #fe49a4, 1487px 563px #fe49a4, 311px 1872px #fe49a4,
        330px 976px #fe49a4, 6px 1754px #fe49a4, 1447px 1182px #fe49a4,
        1926px 148px #fe49a4, 1640px 490px #fe49a4, 1548px 1357px #fe49a4,
        1052px 1340px #fe49a4, 1083px 56px #fe49a4, 1890px 339px #fe49a4,
        277px 1609px #fe49a4, 892px 1792px #fe49a4, 1322px 86px #fe49a4,
        1509px 1515px #fe49a4, 1650px 1722px #fe49a4, 334px 497px #fe49a4,
        888px 143px #fe49a4, 1325px 259px #fe49a4, 1330px 1505px #fe49a4,
        1374px 645px #fe49a4, 1184px 937px #fe49a4, 911px 1044px #fe49a4,
        32px 1257px #fe49a4, 297px 1884px #fe49a4, 1031px 963px #fe49a4,
        411px 916px #fe49a4, 243px 1305px #fe49a4, 898px 200px #fe49a4,
        1550px 156px #fe49a4, 159px 716px #fe49a4, 240px 1130px #fe49a4,
        1046px 1905px #fe49a4, 1405px 1277px #fe49a4, 275px 124px #fe49a4,
        964px 795px #fe49a4, 1270px 1387px #fe49a4, 1391px 1880px #fe49a4,
        484px 1027px #fe49a4, 1681px 1706px #fe49a4, 1665px 697px #fe49a4,
        1073px 1078px #fe49a4, 1808px 1313px #fe49a4, 1669px 1266px #fe49a4,
        1965px 747px #fe49a4, 1734px 972px #fe49a4, 1549px 1434px #fe49a4,
        945px 146px #fe49a4, 1211px 1383px #fe49a4, 1921px 1637px #fe49a4,
        271px 1725px #fe49a4, 739px 1725px #fe49a4, 1634px 1827px #fe49a4,
        961px 1131px #fe49a4, 513px 839px #fe49a4, 1553px 1725px #fe49a4,
        82px 1667px #fe49a4, 3px 1797px #fe49a4, 517px 1511px #fe49a4,
        1644px 929px #fe49a4, 120px 1638px #fe49a4, 579px 1168px #fe49a4,
        147px 680px #fe49a4, 61px 1146px #fe49a4, 884px 598px #fe49a4,
        630px 1324px #fe49a4, 1709px 1340px #fe49a4, 1332px 1142px #fe49a4,
        1990px 1397px #fe49a4, 854px 1527px #fe49a4, 1293px 634px #fe49a4,
        1063px 506px #fe49a4, 1361px 668px #fe49a4, 926px 347px #fe49a4,
        145px 1031px #fe49a4, 1289px 1519px #fe49a4, 1977px 1560px #fe49a4,
        70px 1353px #fe49a4, 653px 1634px #fe49a4, 1182px 340px #fe49a4,
        1264px 1127px #fe49a4, 1246px 40px #fe49a4, 428px 691px #fe49a4,
        1531px 1817px #fe49a4, 431px 1906px #fe49a4, 1406px 1665px #fe49a4,
        597px 1588px #fe49a4, 1273px 355px #fe49a4, 995px 1018px #fe49a4,
        1319px 528px #fe49a4, 1454px 715px #fe49a4, 1587px 1052px #fe49a4,
        921px 193px #fe49a4, 1012px 479px #fe49a4, 689px 1019px #fe49a4,
        1307px 420px #fe49a4, 1153px 108px #fe49a4, 1343px 775px #fe49a4,
        1236px 1061px #fe49a4, 1117px 1099px #fe49a4, 308px 533px #fe49a4,
        1262px 509px #fe49a4, 830px 24px #fe49a4, 426px 695px #fe49a4,
        1148px 1397px #fe49a4, 1016px 1719px #fe49a4, 1142px 1280px #fe49a4,
        1841px 310px #fe49a4, 1890px 1708px #fe49a4, 1399px 589px #fe49a4,
        913px 1291px #fe49a4, 955px 1445px #fe49a4, 431px 491px #fe49a4,
        580px 1002px #fe49a4, 796px 186px #fe49a4, 445px 1634px #fe49a4,
        1543px 1377px #fe49a4, 1853px 57px #fe49a4, 34px 620px #fe49a4,
        1056px 923px #fe49a4, 1027px 134px #fe49a4, 1248px 1252px #fe49a4,
        756px 1855px #fe49a4, 143px 654px #fe49a4, 253px 347px #fe49a4,
        181px 943px #fe49a4, 1044px 1774px #fe49a4, 367px 1925px #fe49a4,
        1125px 854px #fe49a4, 1276px 141px #fe49a4, 422px 413px #fe49a4,
        1261px 973px #fe49a4, 1030px 1349px #fe49a4, 1349px 1850px #fe49a4,
        552px 1286px #fe49a4, 606px 888px #fe49a4, 464px 1922px #fe49a4,
        1799px 260px #fe49a4, 1984px 788px #fe49a4, 1210px 450px #fe49a4,
        1288px 1530px #fe49a4, 911px 1896px #fe49a4, 689px 4px #fe49a4,
        806px 162px #fe49a4, 1201px 604px #fe49a4, 908px 531px #fe49a4,
        137px 1123px #fe49a4, 1165px 496px #fe49a4, 1567px 1797px #fe49a4,
        614px 504px #fe49a4, 1551px 1165px #fe49a4, 1309px 1032px #fe49a4,
        1321px 26px #fe49a4, 1372px 326px #fe49a4, 1762px 1159px #fe49a4,
        728px 5px #fe49a4, 1364px 459px #fe49a4, 1333px 918px #fe49a4,
        1082px 1732px #fe49a4, 286px 319px #fe49a4, 1636px 1220px #fe49a4,
        350px 966px #fe49a4, 124px 219px #fe49a4, 184px 1654px #fe49a4,
        1195px 160px #fe49a4, 1690px 737px #fe49a4, 1386px 472px #fe49a4,
        1522px 1911px #fe49a4, 1082px 1129px #fe49a4, 491px 67px #fe49a4,
        826px 393px #fe49a4, 643px 632px #fe49a4, 1934px 1461px #fe49a4,
        1325px 316px #fe49a4, 934px 1722px #fe49a4, 1044px 879px #fe49a4,
        1109px 1482px #fe49a4, 1040px 642px #fe49a4, 801px 509px #fe49a4,
        1352px 1583px #fe49a4, 160px 339px #fe49a4, 1111px 1731px #fe49a4,
        1650px 1190px #fe49a4, 1804px 1019px #fe49a4, 745px 604px #fe49a4,
        1391px 1108px #fe49a4, 344px 708px #fe49a4, 395px 111px #fe49a4,
        913px 1180px #fe49a4, 537px 1036px #fe49a4, 1048px 1611px #fe49a4,
        1926px 155px #fe49a4, 392px 193px #fe49a4, 1980px 1465px #fe49a4,
        1853px 1349px #fe49a4, 204px 1317px #fe49a4, 1342px 1956px #fe49a4,
        785px 1564px #fe49a4, 1478px 612px #fe49a4, 1459px 491px #fe49a4,
        1767px 265px #fe49a4, 1032px 495px #fe49a4, 946px 1196px #fe49a4,
        607px 285px #fe49a4, 887px 1940px #fe49a4, 1618px 962px #fe49a4,
        85px 801px #fe49a4, 1828px 525px #fe49a4, 523px 1927px #fe49a4,
        280px 1676px #fe49a4, 551px 1133px #fe49a4, 1888px 936px #fe49a4,
        875px 863px #fe49a4, 395px 1954px #fe49a4, 155px 590px #fe49a4,
        1924px 262px #fe49a4, 1039px 1430px #fe49a4, 1356px 1816px #fe49a4,
        978px 671px #fe49a4, 1968px 1044px #fe49a4, 1693px 15px #fe49a4,
        266px 1506px #fe49a4, 1061px 224px #fe49a4, 690px 1132px #fe49a4,
        1149px 313px #fe49a4, 744px 898px #fe49a4, 848px 1851px #fe49a4,
        296px 761px #fe49a4, 55px 624px #fe49a4, 573px 131px #fe49a4,
        483px 1869px #fe49a4, 157px 1304px #fe49a4, 496px 489px #fe49a4,
        729px 1730px #fe49a4, 163px 1023px #fe49a4, 1065px 1426px #fe49a4,
        1058px 865px #fe49a4, 1204px 1762px #fe49a4, 73px 527px #fe49a4,
        668px 1237px #fe49a4, 1px 259px #fe49a4, 1027px 107px #fe49a4,
        1347px 842px #fe49a4, 720px 819px #fe49a4, 97px 1047px #fe49a4,
        474px 1262px #fe49a4, 1406px 1163px #fe49a4, 1620px 1312px #fe49a4,
        292px 423px #fe49a4, 1579px 1735px #fe49a4, 687px 305px #fe49a4,
        1810px 1822px #fe49a4, 1499px 241px #fe49a4, 1578px 1308px #fe49a4,
        770px 1441px #fe49a4, 398px 646px #fe49a4, 1186px 1423px #fe49a4,
        6px 201px #fe49a4, 1684px 1057px #fe49a4, 1252px 1352px #fe49a4,
        1649px 317px #fe49a4, 746px 1777px #fe49a4, 537px 97px #fe49a4,
        347px 1613px #fe49a4, 1709px 1896px #fe49a4, 134px 957px #fe49a4,
        755px 340px #fe49a4, 107px 1057px #fe49a4, 263px 1459px #fe49a4,
        1559px 1124px #fe49a4, 1082px 19px #fe49a4, 557px 795px #fe49a4,
        596px 455px #fe49a4, 1877px 1535px #fe49a4, 457px 320px #fe49a4,
        532px 270px #fe49a4, 773px 1991px #fe49a4, 644px 1840px #fe49a4,
        777px 1413px #fe49a4, 726px 203px #fe49a4, 20px 740px #fe49a4,
        655px 909px #fe49a4, 396px 1998px #fe49a4, 235px 954px #fe49a4,
        1737px 264px #fe49a4, 670px 1532px #fe49a4, 498px 159px #fe49a4,
        540px 1997px #fe49a4, 1792px 1327px #fe49a4, 880px 1023px #fe49a4,
        1917px 1938px #fe49a4, 1859px 148px #fe49a4, 1147px 1781px #fe49a4,
        428px 1876px #fe49a4, 1952px 605px #fe49a4, 655px 80px #fe49a4,
        1500px 1545px #fe49a4, 1656px 644px #fe49a4, 615px 247px #fe49a4,
        795px 1881px #fe49a4, 1750px 586px #fe49a4, 148px 1490px #fe49a4,
        1434px 74px #fe49a4, 417px 1969px #fe49a4, 962px 1526px #fe49a4,
        835px 962px #fe49a4, 330px 1767px #fe49a4, 1557px 435px #fe49a4,
        240px 341px #fe49a4, 1857px 1589px #fe49a4, 765px 281px #fe49a4,
        1452px 405px #fe49a4, 1480px 1724px #fe49a4, 950px 1094px #fe49a4,
        710px 1388px #fe49a4, 1333px 1919px #fe49a4, 706px 1589px #fe49a4,
        1476px 1465px #fe49a4, 744px 291px #fe49a4, 123px 870px #fe49a4,
        1406px 428px #fe49a4, 727px 1609px #fe49a4, 1149px 1838px #fe49a4,
        78px 169px #fe49a4, 1088px 284px #fe49a4, 670px 399px #fe49a4,
        711px 1923px #fe49a4, 42px 1720px #fe49a4, 667px 570px #fe49a4,
        1274px 693px #fe49a4, 1056px 644px #fe49a4, 1937px 793px #fe49a4,
        1200px 602px #fe49a4, 1493px 755px #fe49a4, 545px 870px #fe49a4,
        887px 270px #fe49a4, 1290px 164px #fe49a4, 265px 1185px #fe49a4;
`;

export const StarsThree = styled.div<{ animate?: boolean }>`
    transform: ${props =>
        props.animate ? 'translateY(0)' : 'translateY(-1000px)'};
    opacity: 0.5;
    width: 4px;
    height: 3px;
    border-radius: 50%;
    border: 1px solid rgba(256, 256, 256, 0.4);
    background: transparent;
    animation: ${props =>
        props.animate
            ? css`
                  ${animateStars3} 16s linear infinite;
              `
            : ''};

    &::after {
        content: ' ';
        position: absolute;
        top: ${props => (props.animate ? '-2000px' : '0')};
        ${props => (props.animate ? '' : `left: 1500px`)};
        width: 4px;
        height: 3px;
        border-radius: 50%;
        background: transparent;
        box-shadow: 1226px 1621px #fe49a4, 1021px 1311px #fe49a4,
            589px 396px #fe49a4, 106px 950px #fe49a4, 683px 1196px #fe49a4,
            885px 147px #fe49a4, 140px 572px #fe49a4, 1414px 202px #fe49a4,
            245px 366px #fe49a4, 619px 1786px #fe49a4, 158px 727px #fe49a4,
            1051px 1759px #fe49a4, 304px 515px #fe49a4, 1107px 1063px #fe49a4,
            1409px 293px #fe49a4, 844px 1928px #fe49a4, 1297px 766px #fe49a4,
            769px 53px #fe49a4, 1796px 1499px #fe49a4, 1096px 1588px #fe49a4,
            6px 1066px #fe49a4, 954px 1823px #fe49a4, 449px 1318px #fe49a4,
            742px 324px #fe49a4, 1360px 430px #fe49a4, 474px 517px #fe49a4,
            1638px 553px #fe49a4, 65px 362px #fe49a4, 1668px 305px #fe49a4,
            618px 1791px #fe49a4, 1248px 698px #fe49a4, 526px 1505px #fe49a4,
            443px 1738px #fe49a4, 968px 744px #fe49a4, 1509px 1343px #fe49a4,
            940px 891px #fe49a4, 1426px 313px #fe49a4, 86px 1904px #fe49a4,
            1852px 914px #fe49a4, 1774px 1138px #fe49a4, 1406px 378px #fe49a4,
            82px 384px #fe49a4, 1195px 1561px #fe49a4, 472px 1994px #fe49a4,
            887px 708px #fe49a4, 1078px 680px #fe49a4, 1715px 140px #fe49a4,
            333px 1224px #fe49a4, 1538px 412px #fe49a4, 1476px 644px #fe49a4,
            976px 149px #fe49a4, 938px 1127px #fe49a4, 531px 1088px #fe49a4,
            996px 403px #fe49a4, 279px 1233px #fe49a4, 1761px 1297px #fe49a4,
            1126px 1184px #fe49a4, 1417px 375px #fe49a4, 1085px 1137px #fe49a4,
            669px 987px #fe49a4, 1149px 1963px #fe49a4, 1097px 880px #fe49a4,
            1591px 85px #fe49a4, 1858px 884px #fe49a4, 1187px 1483px #fe49a4,
            1246px 415px #fe49a4, 126px 516px #fe49a4, 1824px 672px #fe49a4,
            178px 250px #fe49a4, 1675px 146px #fe49a4, 1827px 816px #fe49a4,
            803px 992px #fe49a4, 1703px 1664px #fe49a4, 1658px 970px #fe49a4,
            1607px 1896px #fe49a4, 268px 499px #fe49a4, 465px 395px #fe49a4,
            287px 468px #fe49a4, 980px 1675px #fe49a4, 1959px 1989px #fe49a4,
            429px 1263px #fe49a4, 1419px 1703px #fe49a4, 302px 335px #fe49a4,
            1470px 698px #fe49a4, 431px 1784px #fe49a4, 1397px 1168px #fe49a4,
            585px 143px #fe49a4, 1163px 455px #fe49a4, 1007px 1355px #fe49a4,
            764px 1147px #fe49a4, 1528px 1835px #fe49a4, 1298px 1629px #fe49a4,
            438px 971px #fe49a4, 1940px 1307px #fe49a4, 1767px 1080px #fe49a4,
            1700px 1582px #fe49a4, 911px 709px #fe49a4, 1733px 1916px #fe49a4,
            1650px 1153px #fe49a4, 1491px 1908px #fe49a4, 1221px 1065px #fe49a4,
            389px 749px #fe49a4, 576px 602px #fe49a4, 84px 1595px #fe49a4,
            1887px 1748px #fe49a4, 1293px 1674px #fe49a4, 1372px 986px #fe49a4,
            834px 1280px #fe49a4, 241px 777px #fe49a4, 1122px 1540px #fe49a4,
            908px 1032px #fe49a4, 1715px 14px #fe49a4, 732px 1014px #fe49a4,
            1888px 766px #fe49a4, 1433px 1174px #fe49a4, 1273px 1889px #fe49a4,
            337px 301px #fe49a4, 1449px 1878px #fe49a4, 946px 1411px #fe49a4,
            965px 181px #fe49a4, 1508px 537px #fe49a4, 977px 1648px #fe49a4,
            696px 1932px #fe49a4, 601px 305px #fe49a4, 1734px 186px #fe49a4,
            1962px 1776px #fe49a4, 965px 791px #fe49a4, 1563px 422px #fe49a4,
            1427px 822px #fe49a4, 1540px 599px #fe49a4, 1246px 1681px #fe49a4,
            1271px 1136px #fe49a4, 1411px 641px #fe49a4, 1108px 1981px #fe49a4,
            961px 1884px #fe49a4, 788px 631px #fe49a4, 172px 783px #fe49a4,
            1729px 455px #fe49a4, 1682px 1051px #fe49a4, 911px 1455px #fe49a4,
            1652px 489px #fe49a4, 880px 94px #fe49a4, 59px 747px #fe49a4,
            417px 223px #fe49a4, 332px 1397px #fe49a4, 699px 659px #fe49a4,
            693px 728px #fe49a4, 1232px 208px #fe49a4, 1514px 774px #fe49a4,
            1070px 633px #fe49a4, 1768px 165px #fe49a4, 1261px 1666px #fe49a4,
            35px 1979px #fe49a4, 54px 1408px #fe49a4, 820px 745px #fe49a4,
            1732px 147px #fe49a4, 1282px 1121px #fe49a4, 1640px 1px #fe49a4,
            1932px 632px #fe49a4, 895px 31px #fe49a4, 1751px 266px #fe49a4,
            746px 54px #fe49a4, 1197px 986px #fe49a4, 1032px 658px #fe49a4,
            234px 1526px #fe49a4, 1267px 1176px #fe49a4, 1280px 357px #fe49a4,
            1333px 381px #fe49a4, 1739px 1375px #fe49a4, 888px 1325px #fe49a4,
            704px 550px #fe49a4, 20px 359px #fe49a4, 987px 1218px #fe49a4,
            794px 1724px #fe49a4, 774px 590px #fe49a4, 1024px 227px #fe49a4,
            1487px 400px #fe49a4, 1446px 627px #fe49a4, 984px 1342px #fe49a4,
            966px 703px #fe49a4, 105px 1673px #fe49a4, 1225px 1267px #fe49a4,
            119px 1202px #fe49a4, 1458px 273px #fe49a4, 1057px 1167px #fe49a4,
            116px 735px #fe49a4, 665px 1021px #fe49a4, 476px 1830px #fe49a4,
            145px 906px #fe49a4, 502px 596px #fe49a4, 1629px 645px #fe49a4,
            728px 1972px #fe49a4, 342px 605px #fe49a4, 1331px 179px #fe49a4,
            707px 1684px #fe49a4, 388px 1757px #fe49a4, 805px 73px #fe49a4,
            659px 180px #fe49a4, 848px 915px #fe49a4, 182px 1550px #fe49a4,
            1955px 155px #fe49a4, 973px 1546px #fe49a4, 1061px 1579px #fe49a4,
            52px 687px #fe49a4, 1104px 1352px #fe49a4, 408px 1386px #fe49a4,
            1888px 1692px #fe49a4, 956px 1018px #fe49a4, 1355px 1568px #fe49a4,
            1505px 1550px #fe49a4, 1182px 705px #fe49a4, 959px 1050px #fe49a4,
            498px 1432px #fe49a4, 301px 647px #fe49a4, 443px 334px #fe49a4,
            1402px 461px #fe49a4, 147px 1270px #fe49a4, 391px 1572px #fe49a4,
            1017px 341px #fe49a4, 68px 1770px #fe49a4, 235px 860px #fe49a4,
            505px 816px #fe49a4, 1312px 986px #fe49a4, 1079px 1293px #fe49a4,
            324px 611px #fe49a4, 1179px 1011px #fe49a4, 277px 434px #fe49a4,
            1767px 252px #fe49a4, 1775px 1487px #fe49a4, 1705px 19px #fe49a4,
            326px 721px #fe49a4, 378px 70px #fe49a4, 895px 1736px #fe49a4,
            1570px 365px #fe49a4, 513px 615px #fe49a4, 1092px 147px #fe49a4,
            1318px 1932px #fe49a4, 1592px 1734px #fe49a4, 699px 918px #fe49a4,
            111px 388px #fe49a4, 1067px 890px #fe49a4, 1169px 332px #fe49a4,
            358px 481px #fe49a4, 1630px 750px #fe49a4, 179px 1787px #fe49a4,
            355px 489px #fe49a4, 852px 821px #fe49a4, 868px 364px #fe49a4,
            386px 1700px #fe49a4, 799px 1420px #fe49a4, 1472px 644px #fe49a4,
            1552px 905px #fe49a4, 48px 1998px #fe49a4, 1850px 51px #fe49a4,
            416px 678px #fe49a4, 759px 1046px #fe49a4, 1451px 1951px #fe49a4,
            101px 1610px #fe49a4, 1469px 912px #fe49a4, 1214px 1292px #fe49a4,
            1368px 1018px #fe49a4, 484px 470px #fe49a4, 816px 322px #fe49a4,
            1821px 1326px #fe49a4, 327px 1936px #fe49a4, 752px 550px #fe49a4,
            1215px 1924px #fe49a4, 180px 122px #fe49a4, 784px 1924px #fe49a4,
            1597px 336px #fe49a4, 248px 1429px #fe49a4, 4px 181px #fe49a4,
            1801px 1613px #fe49a4, 1581px 1719px #fe49a4, 640px 1391px #fe49a4,
            960px 302px #fe49a4, 1483px 1207px #fe49a4, 520px 1119px #fe49a4,
            25px 1754px #fe49a4, 545px 317px #fe49a4, 1573px 751px #fe49a4,
            1415px 2px #fe49a4, 101px 761px #fe49a4, 679px 291px #fe49a4,
            1785px 676px #fe49a4, 1256px 136px #fe49a4, 855px 197px #fe49a4,
            1399px 1973px #fe49a4, 1243px 429px #fe49a4, 1281px 571px #fe49a4,
            839px 531px #fe49a4, 789px 1980px #fe49a4, 877px 783px #fe49a4,
            1526px 1850px #fe49a4, 644px 1283px #fe49a4, 1982px 1953px #fe49a4,
            1109px 1232px #fe49a4, 1532px 643px #fe49a4, 823px 1287px #fe49a4,
            667px 34px #fe49a4, 24px 1469px #fe49a4, 1029px 1130px #fe49a4,
            1645px 254px #fe49a4, 1054px 1000px #fe49a4, 1341px 254px #fe49a4,
            184px 188px #fe49a4, 1160px 1521px #fe49a4, 348px 1083px #fe49a4,
            1436px 1796px #fe49a4, 441px 361px #fe49a4, 1239px 575px #fe49a4,
            1055px 140px #fe49a4, 1552px 502px #fe49a4, 607px 864px #fe49a4,
            967px 1883px #fe49a4, 1407px 1955px #fe49a4, 405px 1148px #fe49a4,
            604px 992px #fe49a4, 1116px 506px #fe49a4, 13px 832px #fe49a4,
            295px 189px #fe49a4, 964px 1175px #fe49a4, 1988px 52px #fe49a4,
            935px 209px #fe49a4, 17px 142px #fe49a4, 770px 333px #fe49a4,
            682px 633px #fe49a4, 510px 466px #fe49a4, 366px 79px #fe49a4,
            199px 954px #fe49a4, 882px 277px #fe49a4, 744px 655px #fe49a4,
            37px 1659px #fe49a4, 1217px 1885px #fe49a4, 1107px 597px #fe49a4,
            1060px 1335px #fe49a4, 773px 601px #fe49a4, 1939px 1632px #fe49a4,
            1607px 1774px #fe49a4, 1531px 1550px #fe49a4, 1042px 60px #fe49a4,
            850px 1502px #fe49a4, 1670px 492px #fe49a4, 1357px 1654px #fe49a4,
            1279px 1521px #fe49a4, 633px 1956px #fe49a4, 1122px 1981px #fe49a4,
            661px 657px #fe49a4, 521px 1132px #fe49a4, 1593px 813px #fe49a4,
            1204px 1463px #fe49a4, 1112px 1495px #fe49a4, 1755px 367px #fe49a4,
            1685px 1805px #fe49a4, 1982px 196px #fe49a4, 484px 51px #fe49a4,
            1041px 330px #fe49a4, 1487px 563px #fe49a4, 311px 1872px #fe49a4,
            330px 976px #fe49a4, 6px 1754px #fe49a4, 1447px 1182px #fe49a4,
            1926px 148px #fe49a4, 1640px 490px #fe49a4, 1548px 1357px #fe49a4,
            1052px 1340px #fe49a4, 1083px 56px #fe49a4, 1890px 339px #fe49a4,
            277px 1609px #fe49a4, 892px 1792px #fe49a4, 1322px 86px #fe49a4,
            1509px 1515px #fe49a4, 1650px 1722px #fe49a4, 334px 497px #fe49a4,
            888px 143px #fe49a4, 1325px 259px #fe49a4, 1330px 1505px #fe49a4,
            1374px 645px #fe49a4, 1184px 937px #fe49a4, 911px 1044px #fe49a4,
            32px 1257px #fe49a4, 297px 1884px #fe49a4, 1031px 963px #fe49a4,
            411px 916px #fe49a4, 243px 1305px #fe49a4, 898px 200px #fe49a4,
            1550px 156px #fe49a4, 159px 716px #fe49a4, 240px 1130px #fe49a4,
            1046px 1905px #fe49a4, 1405px 1277px #fe49a4, 275px 124px #fe49a4,
            964px 795px #fe49a4, 1270px 1387px #fe49a4, 1391px 1880px #fe49a4,
            484px 1027px #fe49a4, 1681px 1706px #fe49a4, 1665px 697px #fe49a4,
            1073px 1078px #fe49a4, 1808px 1313px #fe49a4, 1669px 1266px #fe49a4,
            1965px 747px #fe49a4, 1734px 972px #fe49a4, 1549px 1434px #fe49a4,
            945px 146px #fe49a4, 1211px 1383px #fe49a4, 1921px 1637px #fe49a4,
            271px 1725px #fe49a4, 739px 1725px #fe49a4, 1634px 1827px #fe49a4,
            961px 1131px #fe49a4, 513px 839px #fe49a4, 1553px 1725px #fe49a4,
            82px 1667px #fe49a4, 3px 1797px #fe49a4, 517px 1511px #fe49a4,
            1644px 929px #fe49a4, 120px 1638px #fe49a4, 579px 1168px #fe49a4,
            147px 680px #fe49a4, 61px 1146px #fe49a4, 884px 598px #fe49a4,
            630px 1324px #fe49a4, 1709px 1340px #fe49a4, 1332px 1142px #fe49a4,
            1990px 1397px #fe49a4, 854px 1527px #fe49a4, 1293px 634px #fe49a4,
            1063px 506px #fe49a4, 1361px 668px #fe49a4, 926px 347px #fe49a4,
            145px 1031px #fe49a4, 1289px 1519px #fe49a4, 1977px 1560px #fe49a4,
            70px 1353px #fe49a4, 653px 1634px #fe49a4, 1182px 340px #fe49a4,
            1264px 1127px #fe49a4, 1246px 40px #fe49a4, 428px 691px #fe49a4,
            1531px 1817px #fe49a4, 431px 1906px #fe49a4, 1406px 1665px #fe49a4,
            597px 1588px #fe49a4, 1273px 355px #fe49a4, 995px 1018px #fe49a4,
            1319px 528px #fe49a4, 1454px 715px #fe49a4, 1587px 1052px #fe49a4,
            921px 193px #fe49a4, 1012px 479px #fe49a4, 689px 1019px #fe49a4,
            1307px 420px #fe49a4, 1153px 108px #fe49a4, 1343px 775px #fe49a4,
            1236px 1061px #fe49a4, 1117px 1099px #fe49a4, 308px 533px #fe49a4,
            1262px 509px #fe49a4, 830px 24px #fe49a4, 426px 695px #fe49a4,
            1148px 1397px #fe49a4, 1016px 1719px #fe49a4, 1142px 1280px #fe49a4,
            1841px 310px #fe49a4, 1890px 1708px #fe49a4, 1399px 589px #fe49a4,
            913px 1291px #fe49a4, 955px 1445px #fe49a4, 431px 491px #fe49a4,
            580px 1002px #fe49a4, 796px 186px #fe49a4, 445px 1634px #fe49a4,
            1543px 1377px #fe49a4, 1853px 57px #fe49a4, 34px 620px #fe49a4,
            1056px 923px #fe49a4, 1027px 134px #fe49a4, 1248px 1252px #fe49a4,
            756px 1855px #fe49a4, 143px 654px #fe49a4, 253px 347px #fe49a4,
            181px 943px #fe49a4, 1044px 1774px #fe49a4, 367px 1925px #fe49a4,
            1125px 854px #fe49a4, 1276px 141px #fe49a4, 422px 413px #fe49a4,
            1261px 973px #fe49a4, 1030px 1349px #fe49a4, 1349px 1850px #fe49a4,
            552px 1286px #fe49a4, 606px 888px #fe49a4, 464px 1922px #fe49a4,
            1799px 260px #fe49a4, 1984px 788px #fe49a4, 1210px 450px #fe49a4,
            1288px 1530px #fe49a4, 911px 1896px #fe49a4, 689px 4px #fe49a4,
            806px 162px #fe49a4, 1201px 604px #fe49a4, 908px 531px #fe49a4,
            137px 1123px #fe49a4, 1165px 496px #fe49a4, 1567px 1797px #fe49a4,
            614px 504px #fe49a4, 1551px 1165px #fe49a4, 1309px 1032px #fe49a4,
            1321px 26px #fe49a4, 1372px 326px #fe49a4, 1762px 1159px #fe49a4,
            728px 5px #fe49a4, 1364px 459px #fe49a4, 1333px 918px #fe49a4,
            1082px 1732px #fe49a4, 286px 319px #fe49a4, 1636px 1220px #fe49a4,
            350px 966px #fe49a4, 124px 219px #fe49a4, 184px 1654px #fe49a4,
            1195px 160px #fe49a4, 1690px 737px #fe49a4, 1386px 472px #fe49a4,
            1522px 1911px #fe49a4, 1082px 1129px #fe49a4, 491px 67px #fe49a4,
            826px 393px #fe49a4, 643px 632px #fe49a4, 1934px 1461px #fe49a4,
            1325px 316px #fe49a4, 934px 1722px #fe49a4, 1044px 879px #fe49a4,
            1109px 1482px #fe49a4, 1040px 642px #fe49a4, 801px 509px #fe49a4,
            1352px 1583px #fe49a4, 160px 339px #fe49a4, 1111px 1731px #fe49a4,
            1650px 1190px #fe49a4, 1804px 1019px #fe49a4, 745px 604px #fe49a4,
            1391px 1108px #fe49a4, 344px 708px #fe49a4, 395px 111px #fe49a4,
            913px 1180px #fe49a4, 537px 1036px #fe49a4, 1048px 1611px #fe49a4,
            1926px 155px #fe49a4, 392px 193px #fe49a4, 1980px 1465px #fe49a4,
            1853px 1349px #fe49a4, 204px 1317px #fe49a4, 1342px 1956px #fe49a4,
            785px 1564px #fe49a4, 1478px 612px #fe49a4, 1459px 491px #fe49a4,
            1767px 265px #fe49a4, 1032px 495px #fe49a4, 946px 1196px #fe49a4,
            607px 285px #fe49a4, 887px 1940px #fe49a4, 1618px 962px #fe49a4,
            85px 801px #fe49a4, 1828px 525px #fe49a4, 523px 1927px #fe49a4,
            280px 1676px #fe49a4, 551px 1133px #fe49a4, 1888px 936px #fe49a4,
            875px 863px #fe49a4, 395px 1954px #fe49a4, 155px 590px #fe49a4,
            1924px 262px #fe49a4, 1039px 1430px #fe49a4, 1356px 1816px #fe49a4,
            978px 671px #fe49a4, 1968px 1044px #fe49a4, 1693px 15px #fe49a4,
            266px 1506px #fe49a4, 1061px 224px #fe49a4, 690px 1132px #fe49a4,
            1149px 313px #fe49a4, 744px 898px #fe49a4, 848px 1851px #fe49a4,
            296px 761px #fe49a4, 55px 624px #fe49a4, 573px 131px #fe49a4,
            483px 1869px #fe49a4, 157px 1304px #fe49a4, 496px 489px #fe49a4,
            729px 1730px #fe49a4, 163px 1023px #fe49a4, 1065px 1426px #fe49a4,
            1058px 865px #fe49a4, 1204px 1762px #fe49a4, 73px 527px #fe49a4,
            668px 1237px #fe49a4, 1px 259px #fe49a4, 1027px 107px #fe49a4,
            1347px 842px #fe49a4, 720px 819px #fe49a4, 97px 1047px #fe49a4,
            474px 1262px #fe49a4, 1406px 1163px #fe49a4, 1620px 1312px #fe49a4,
            292px 423px #fe49a4, 1579px 1735px #fe49a4, 687px 305px #fe49a4,
            1810px 1822px #fe49a4, 1499px 241px #fe49a4, 1578px 1308px #fe49a4,
            770px 1441px #fe49a4, 398px 646px #fe49a4, 1186px 1423px #fe49a4,
            6px 201px #fe49a4, 1684px 1057px #fe49a4, 1252px 1352px #fe49a4,
            1649px 317px #fe49a4, 746px 1777px #fe49a4, 537px 97px #fe49a4,
            347px 1613px #fe49a4, 1709px 1896px #fe49a4, 134px 957px #fe49a4,
            755px 340px #fe49a4, 107px 1057px #fe49a4, 263px 1459px #fe49a4,
            1559px 1124px #fe49a4, 1082px 19px #fe49a4, 557px 795px #fe49a4,
            596px 455px #fe49a4, 1877px 1535px #fe49a4, 457px 320px #fe49a4,
            532px 270px #fe49a4, 773px 1991px #fe49a4, 644px 1840px #fe49a4,
            777px 1413px #fe49a4, 726px 203px #fe49a4, 20px 740px #fe49a4,
            655px 909px #fe49a4, 396px 1998px #fe49a4, 235px 954px #fe49a4,
            1737px 264px #fe49a4, 670px 1532px #fe49a4, 498px 159px #fe49a4,
            540px 1997px #fe49a4, 1792px 1327px #fe49a4, 880px 1023px #fe49a4,
            1917px 1938px #fe49a4, 1859px 148px #fe49a4, 1147px 1781px #fe49a4,
            428px 1876px #fe49a4, 1952px 605px #fe49a4, 655px 80px #fe49a4,
            1500px 1545px #fe49a4, 1656px 644px #fe49a4, 615px 247px #fe49a4,
            795px 1881px #fe49a4, 1750px 586px #fe49a4, 148px 1490px #fe49a4,
            1434px 74px #fe49a4, 417px 1969px #fe49a4, 962px 1526px #fe49a4,
            835px 962px #fe49a4, 330px 1767px #fe49a4, 1557px 435px #fe49a4,
            240px 341px #fe49a4, 1857px 1589px #fe49a4, 765px 281px #fe49a4,
            1452px 405px #fe49a4, 1480px 1724px #fe49a4, 950px 1094px #fe49a4,
            710px 1388px #fe49a4, 1333px 1919px #fe49a4, 706px 1589px #fe49a4,
            1476px 1465px #fe49a4, 744px 291px #fe49a4, 123px 870px #fe49a4,
            1406px 428px #fe49a4, 727px 1609px #fe49a4, 1149px 1838px #fe49a4,
            78px 169px #fe49a4, 1088px 284px #fe49a4, 670px 399px #fe49a4,
            711px 1923px #fe49a4, 42px 1720px #fe49a4, 667px 570px #fe49a4,
            1274px 693px #fe49a4, 1056px 644px #fe49a4, 1937px 793px #fe49a4,
            1200px 602px #fe49a4, 1493px 755px #fe49a4, 545px 870px #fe49a4,
            887px 270px #fe49a4, 1290px 164px #fe49a4, 265px 1185px #fe49a4;
    }

    box-shadow: 1226px 1621px #fe49a4, 1021px 1311px #fe49a4,
        589px 396px #fe49a4, 106px 950px #fe49a4, 683px 1196px #fe49a4,
        885px 147px #fe49a4, 140px 572px #fe49a4, 1414px 202px #fe49a4,
        245px 366px #fe49a4, 619px 1786px #fe49a4, 158px 727px #fe49a4,
        1051px 1759px #fe49a4, 304px 515px #fe49a4, 1107px 1063px #fe49a4,
        1409px 293px #fe49a4, 844px 1928px #fe49a4, 1297px 766px #fe49a4,
        769px 53px #fe49a4, 1796px 1499px #fe49a4, 1096px 1588px #fe49a4,
        6px 1066px #fe49a4, 954px 1823px #fe49a4, 449px 1318px #fe49a4,
        742px 324px #fe49a4, 1360px 430px #fe49a4, 474px 517px #fe49a4,
        1638px 553px #fe49a4, 65px 362px #fe49a4, 1668px 305px #fe49a4,
        618px 1791px #fe49a4, 1248px 698px #fe49a4, 526px 1505px #fe49a4,
        443px 1738px #fe49a4, 968px 744px #fe49a4, 1509px 1343px #fe49a4,
        940px 891px #fe49a4, 1426px 313px #fe49a4, 86px 1904px #fe49a4,
        1852px 914px #fe49a4, 1774px 1138px #fe49a4, 1406px 378px #fe49a4,
        82px 384px #fe49a4, 1195px 1561px #fe49a4, 472px 1994px #fe49a4,
        887px 708px #fe49a4, 1078px 680px #fe49a4, 1715px 140px #fe49a4,
        333px 1224px #fe49a4, 1538px 412px #fe49a4, 1476px 644px #fe49a4,
        976px 149px #fe49a4, 938px 1127px #fe49a4, 531px 1088px #fe49a4,
        996px 403px #fe49a4, 279px 1233px #fe49a4, 1761px 1297px #fe49a4,
        1126px 1184px #fe49a4, 1417px 375px #fe49a4, 1085px 1137px #fe49a4,
        669px 987px #fe49a4, 1149px 1963px #fe49a4, 1097px 880px #fe49a4,
        1591px 85px #fe49a4, 1858px 884px #fe49a4, 1187px 1483px #fe49a4,
        1246px 415px #fe49a4, 126px 516px #fe49a4, 1824px 672px #fe49a4,
        178px 250px #fe49a4, 1675px 146px #fe49a4, 1827px 816px #fe49a4,
        803px 992px #fe49a4, 1703px 1664px #fe49a4, 1658px 970px #fe49a4,
        1607px 1896px #fe49a4, 268px 499px #fe49a4, 465px 395px #fe49a4,
        287px 468px #fe49a4, 980px 1675px #fe49a4, 1959px 1989px #fe49a4,
        429px 1263px #fe49a4, 1419px 1703px #fe49a4, 302px 335px #fe49a4,
        1470px 698px #fe49a4, 431px 1784px #fe49a4, 1397px 1168px #fe49a4,
        585px 143px #fe49a4, 1163px 455px #fe49a4, 1007px 1355px #fe49a4,
        764px 1147px #fe49a4, 1528px 1835px #fe49a4, 1298px 1629px #fe49a4,
        438px 971px #fe49a4, 1940px 1307px #fe49a4, 1767px 1080px #fe49a4,
        1700px 1582px #fe49a4, 911px 709px #fe49a4, 1733px 1916px #fe49a4,
        1650px 1153px #fe49a4, 1491px 1908px #fe49a4, 1221px 1065px #fe49a4,
        389px 749px #fe49a4, 576px 602px #fe49a4, 84px 1595px #fe49a4,
        1887px 1748px #fe49a4, 1293px 1674px #fe49a4, 1372px 986px #fe49a4,
        834px 1280px #fe49a4, 241px 777px #fe49a4, 1122px 1540px #fe49a4,
        908px 1032px #fe49a4, 1715px 14px #fe49a4, 732px 1014px #fe49a4,
        1888px 766px #fe49a4, 1433px 1174px #fe49a4, 1273px 1889px #fe49a4,
        337px 301px #fe49a4, 1449px 1878px #fe49a4, 946px 1411px #fe49a4,
        965px 181px #fe49a4, 1508px 537px #fe49a4, 977px 1648px #fe49a4,
        696px 1932px #fe49a4, 601px 305px #fe49a4, 1734px 186px #fe49a4,
        1962px 1776px #fe49a4, 965px 791px #fe49a4, 1563px 422px #fe49a4,
        1427px 822px #fe49a4, 1540px 599px #fe49a4, 1246px 1681px #fe49a4,
        1271px 1136px #fe49a4, 1411px 641px #fe49a4, 1108px 1981px #fe49a4,
        961px 1884px #fe49a4, 788px 631px #fe49a4, 172px 783px #fe49a4,
        1729px 455px #fe49a4, 1682px 1051px #fe49a4, 911px 1455px #fe49a4,
        1652px 489px #fe49a4, 880px 94px #fe49a4, 59px 747px #fe49a4,
        417px 223px #fe49a4, 332px 1397px #fe49a4, 699px 659px #fe49a4,
        693px 728px #fe49a4, 1232px 208px #fe49a4, 1514px 774px #fe49a4,
        1070px 633px #fe49a4, 1768px 165px #fe49a4, 1261px 1666px #fe49a4,
        35px 1979px #fe49a4, 54px 1408px #fe49a4, 820px 745px #fe49a4,
        1732px 147px #fe49a4, 1282px 1121px #fe49a4, 1640px 1px #fe49a4,
        1932px 632px #fe49a4, 895px 31px #fe49a4, 1751px 266px #fe49a4,
        746px 54px #fe49a4, 1197px 986px #fe49a4, 1032px 658px #fe49a4,
        234px 1526px #fe49a4, 1267px 1176px #fe49a4, 1280px 357px #fe49a4,
        1333px 381px #fe49a4, 1739px 1375px #fe49a4, 888px 1325px #fe49a4,
        704px 550px #fe49a4, 20px 359px #fe49a4, 987px 1218px #fe49a4,
        794px 1724px #fe49a4, 774px 590px #fe49a4, 1024px 227px #fe49a4,
        1487px 400px #fe49a4, 1446px 627px #fe49a4, 984px 1342px #fe49a4,
        966px 703px #fe49a4, 105px 1673px #fe49a4, 1225px 1267px #fe49a4,
        119px 1202px #fe49a4, 1458px 273px #fe49a4, 1057px 1167px #fe49a4,
        116px 735px #fe49a4, 665px 1021px #fe49a4, 476px 1830px #fe49a4,
        145px 906px #fe49a4, 502px 596px #fe49a4, 1629px 645px #fe49a4,
        728px 1972px #fe49a4, 342px 605px #fe49a4, 1331px 179px #fe49a4,
        707px 1684px #fe49a4, 388px 1757px #fe49a4, 805px 73px #fe49a4,
        659px 180px #fe49a4, 848px 915px #fe49a4, 182px 1550px #fe49a4,
        1955px 155px #fe49a4, 973px 1546px #fe49a4, 1061px 1579px #fe49a4,
        52px 687px #fe49a4, 1104px 1352px #fe49a4, 408px 1386px #fe49a4,
        1888px 1692px #fe49a4, 956px 1018px #fe49a4, 1355px 1568px #fe49a4,
        1505px 1550px #fe49a4, 1182px 705px #fe49a4, 959px 1050px #fe49a4,
        498px 1432px #fe49a4, 301px 647px #fe49a4, 443px 334px #fe49a4,
        1402px 461px #fe49a4, 147px 1270px #fe49a4, 391px 1572px #fe49a4,
        1017px 341px #fe49a4, 68px 1770px #fe49a4, 235px 860px #fe49a4,
        505px 816px #fe49a4, 1312px 986px #fe49a4, 1079px 1293px #fe49a4,
        324px 611px #fe49a4, 1179px 1011px #fe49a4, 277px 434px #fe49a4,
        1767px 252px #fe49a4, 1775px 1487px #fe49a4, 1705px 19px #fe49a4,
        326px 721px #fe49a4, 378px 70px #fe49a4, 895px 1736px #fe49a4,
        1570px 365px #fe49a4, 513px 615px #fe49a4, 1092px 147px #fe49a4,
        1318px 1932px #fe49a4, 1592px 1734px #fe49a4, 699px 918px #fe49a4,
        111px 388px #fe49a4, 1067px 890px #fe49a4, 1169px 332px #fe49a4,
        358px 481px #fe49a4, 1630px 750px #fe49a4, 179px 1787px #fe49a4,
        355px 489px #fe49a4, 852px 821px #fe49a4, 868px 364px #fe49a4,
        386px 1700px #fe49a4, 799px 1420px #fe49a4, 1472px 644px #fe49a4,
        1552px 905px #fe49a4, 48px 1998px #fe49a4, 1850px 51px #fe49a4,
        416px 678px #fe49a4, 759px 1046px #fe49a4, 1451px 1951px #fe49a4,
        101px 1610px #fe49a4, 1469px 912px #fe49a4, 1214px 1292px #fe49a4,
        1368px 1018px #fe49a4, 484px 470px #fe49a4, 816px 322px #fe49a4,
        1821px 1326px #fe49a4, 327px 1936px #fe49a4, 752px 550px #fe49a4,
        1215px 1924px #fe49a4, 180px 122px #fe49a4, 784px 1924px #fe49a4,
        1597px 336px #fe49a4, 248px 1429px #fe49a4, 4px 181px #fe49a4,
        1801px 1613px #fe49a4, 1581px 1719px #fe49a4, 640px 1391px #fe49a4,
        960px 302px #fe49a4, 1483px 1207px #fe49a4, 520px 1119px #fe49a4,
        25px 1754px #fe49a4, 545px 317px #fe49a4, 1573px 751px #fe49a4,
        1415px 2px #fe49a4, 101px 761px #fe49a4, 679px 291px #fe49a4,
        1785px 676px #fe49a4, 1256px 136px #fe49a4, 855px 197px #fe49a4,
        1399px 1973px #fe49a4, 1243px 429px #fe49a4, 1281px 571px #fe49a4,
        839px 531px #fe49a4, 789px 1980px #fe49a4, 877px 783px #fe49a4,
        1526px 1850px #fe49a4, 644px 1283px #fe49a4, 1982px 1953px #fe49a4,
        1109px 1232px #fe49a4, 1532px 643px #fe49a4, 823px 1287px #fe49a4,
        667px 34px #fe49a4, 24px 1469px #fe49a4, 1029px 1130px #fe49a4,
        1645px 254px #fe49a4, 1054px 1000px #fe49a4, 1341px 254px #fe49a4,
        184px 188px #fe49a4, 1160px 1521px #fe49a4, 348px 1083px #fe49a4,
        1436px 1796px #fe49a4, 441px 361px #fe49a4, 1239px 575px #fe49a4,
        1055px 140px #fe49a4, 1552px 502px #fe49a4, 607px 864px #fe49a4,
        967px 1883px #fe49a4, 1407px 1955px #fe49a4, 405px 1148px #fe49a4,
        604px 992px #fe49a4, 1116px 506px #fe49a4, 13px 832px #fe49a4,
        295px 189px #fe49a4, 964px 1175px #fe49a4, 1988px 52px #fe49a4,
        935px 209px #fe49a4, 17px 142px #fe49a4, 770px 333px #fe49a4,
        682px 633px #fe49a4, 510px 466px #fe49a4, 366px 79px #fe49a4,
        199px 954px #fe49a4, 882px 277px #fe49a4, 744px 655px #fe49a4,
        37px 1659px #fe49a4, 1217px 1885px #fe49a4, 1107px 597px #fe49a4,
        1060px 1335px #fe49a4, 773px 601px #fe49a4, 1939px 1632px #fe49a4,
        1607px 1774px #fe49a4, 1531px 1550px #fe49a4, 1042px 60px #fe49a4,
        850px 1502px #fe49a4, 1670px 492px #fe49a4, 1357px 1654px #fe49a4,
        1279px 1521px #fe49a4, 633px 1956px #fe49a4, 1122px 1981px #fe49a4,
        661px 657px #fe49a4, 521px 1132px #fe49a4, 1593px 813px #fe49a4,
        1204px 1463px #fe49a4, 1112px 1495px #fe49a4, 1755px 367px #fe49a4,
        1685px 1805px #fe49a4, 1982px 196px #fe49a4, 484px 51px #fe49a4,
        1041px 330px #fe49a4, 1487px 563px #fe49a4, 311px 1872px #fe49a4,
        330px 976px #fe49a4, 6px 1754px #fe49a4, 1447px 1182px #fe49a4,
        1926px 148px #fe49a4, 1640px 490px #fe49a4, 1548px 1357px #fe49a4,
        1052px 1340px #fe49a4, 1083px 56px #fe49a4, 1890px 339px #fe49a4,
        277px 1609px #fe49a4, 892px 1792px #fe49a4, 1322px 86px #fe49a4,
        1509px 1515px #fe49a4, 1650px 1722px #fe49a4, 334px 497px #fe49a4,
        888px 143px #fe49a4, 1325px 259px #fe49a4, 1330px 1505px #fe49a4,
        1374px 645px #fe49a4, 1184px 937px #fe49a4, 911px 1044px #fe49a4,
        32px 1257px #fe49a4, 297px 1884px #fe49a4, 1031px 963px #fe49a4,
        411px 916px #fe49a4, 243px 1305px #fe49a4, 898px 200px #fe49a4,
        1550px 156px #fe49a4, 159px 716px #fe49a4, 240px 1130px #fe49a4,
        1046px 1905px #fe49a4, 1405px 1277px #fe49a4, 275px 124px #fe49a4,
        964px 795px #fe49a4, 1270px 1387px #fe49a4, 1391px 1880px #fe49a4,
        484px 1027px #fe49a4, 1681px 1706px #fe49a4, 1665px 697px #fe49a4,
        1073px 1078px #fe49a4, 1808px 1313px #fe49a4, 1669px 1266px #fe49a4,
        1965px 747px #fe49a4, 1734px 972px #fe49a4, 1549px 1434px #fe49a4,
        945px 146px #fe49a4, 1211px 1383px #fe49a4, 1921px 1637px #fe49a4,
        271px 1725px #fe49a4, 739px 1725px #fe49a4, 1634px 1827px #fe49a4,
        961px 1131px #fe49a4, 513px 839px #fe49a4, 1553px 1725px #fe49a4,
        82px 1667px #fe49a4, 3px 1797px #fe49a4, 517px 1511px #fe49a4,
        1644px 929px #fe49a4, 120px 1638px #fe49a4, 579px 1168px #fe49a4,
        147px 680px #fe49a4, 61px 1146px #fe49a4, 884px 598px #fe49a4,
        630px 1324px #fe49a4, 1709px 1340px #fe49a4, 1332px 1142px #fe49a4,
        1990px 1397px #fe49a4, 854px 1527px #fe49a4, 1293px 634px #fe49a4,
        1063px 506px #fe49a4, 1361px 668px #fe49a4, 926px 347px #fe49a4,
        145px 1031px #fe49a4, 1289px 1519px #fe49a4, 1977px 1560px #fe49a4,
        70px 1353px #fe49a4, 653px 1634px #fe49a4, 1182px 340px #fe49a4,
        1264px 1127px #fe49a4, 1246px 40px #fe49a4, 428px 691px #fe49a4,
        1531px 1817px #fe49a4, 431px 1906px #fe49a4, 1406px 1665px #fe49a4,
        597px 1588px #fe49a4, 1273px 355px #fe49a4, 995px 1018px #fe49a4,
        1319px 528px #fe49a4, 1454px 715px #fe49a4, 1587px 1052px #fe49a4,
        921px 193px #fe49a4, 1012px 479px #fe49a4, 689px 1019px #fe49a4,
        1307px 420px #fe49a4, 1153px 108px #fe49a4, 1343px 775px #fe49a4,
        1236px 1061px #fe49a4, 1117px 1099px #fe49a4, 308px 533px #fe49a4,
        1262px 509px #fe49a4, 830px 24px #fe49a4, 426px 695px #fe49a4,
        1148px 1397px #fe49a4, 1016px 1719px #fe49a4, 1142px 1280px #fe49a4,
        1841px 310px #fe49a4, 1890px 1708px #fe49a4, 1399px 589px #fe49a4,
        913px 1291px #fe49a4, 955px 1445px #fe49a4, 431px 491px #fe49a4,
        580px 1002px #fe49a4, 796px 186px #fe49a4, 445px 1634px #fe49a4,
        1543px 1377px #fe49a4, 1853px 57px #fe49a4, 34px 620px #fe49a4,
        1056px 923px #fe49a4, 1027px 134px #fe49a4, 1248px 1252px #fe49a4,
        756px 1855px #fe49a4, 143px 654px #fe49a4, 253px 347px #fe49a4,
        181px 943px #fe49a4, 1044px 1774px #fe49a4, 367px 1925px #fe49a4,
        1125px 854px #fe49a4, 1276px 141px #fe49a4, 422px 413px #fe49a4,
        1261px 973px #fe49a4, 1030px 1349px #fe49a4, 1349px 1850px #fe49a4,
        552px 1286px #fe49a4, 606px 888px #fe49a4, 464px 1922px #fe49a4,
        1799px 260px #fe49a4, 1984px 788px #fe49a4, 1210px 450px #fe49a4,
        1288px 1530px #fe49a4, 911px 1896px #fe49a4, 689px 4px #fe49a4,
        806px 162px #fe49a4, 1201px 604px #fe49a4, 908px 531px #fe49a4,
        137px 1123px #fe49a4, 1165px 496px #fe49a4, 1567px 1797px #fe49a4,
        614px 504px #fe49a4, 1551px 1165px #fe49a4, 1309px 1032px #fe49a4,
        1321px 26px #fe49a4, 1372px 326px #fe49a4, 1762px 1159px #fe49a4,
        728px 5px #fe49a4, 1364px 459px #fe49a4, 1333px 918px #fe49a4,
        1082px 1732px #fe49a4, 286px 319px #fe49a4, 1636px 1220px #fe49a4,
        350px 966px #fe49a4, 124px 219px #fe49a4, 184px 1654px #fe49a4,
        1195px 160px #fe49a4, 1690px 737px #fe49a4, 1386px 472px #fe49a4,
        1522px 1911px #fe49a4, 1082px 1129px #fe49a4, 491px 67px #fe49a4,
        826px 393px #fe49a4, 643px 632px #fe49a4, 1934px 1461px #fe49a4,
        1325px 316px #fe49a4, 934px 1722px #fe49a4, 1044px 879px #fe49a4,
        1109px 1482px #fe49a4, 1040px 642px #fe49a4, 801px 509px #fe49a4,
        1352px 1583px #fe49a4, 160px 339px #fe49a4, 1111px 1731px #fe49a4,
        1650px 1190px #fe49a4, 1804px 1019px #fe49a4, 745px 604px #fe49a4,
        1391px 1108px #fe49a4, 344px 708px #fe49a4, 395px 111px #fe49a4,
        913px 1180px #fe49a4, 537px 1036px #fe49a4, 1048px 1611px #fe49a4,
        1926px 155px #fe49a4, 392px 193px #fe49a4, 1980px 1465px #fe49a4,
        1853px 1349px #fe49a4, 204px 1317px #fe49a4, 1342px 1956px #fe49a4,
        785px 1564px #fe49a4, 1478px 612px #fe49a4, 1459px 491px #fe49a4,
        1767px 265px #fe49a4, 1032px 495px #fe49a4, 946px 1196px #fe49a4,
        607px 285px #fe49a4, 887px 1940px #fe49a4, 1618px 962px #fe49a4,
        85px 801px #fe49a4, 1828px 525px #fe49a4, 523px 1927px #fe49a4,
        280px 1676px #fe49a4, 551px 1133px #fe49a4, 1888px 936px #fe49a4,
        875px 863px #fe49a4, 395px 1954px #fe49a4, 155px 590px #fe49a4,
        1924px 262px #fe49a4, 1039px 1430px #fe49a4, 1356px 1816px #fe49a4,
        978px 671px #fe49a4, 1968px 1044px #fe49a4, 1693px 15px #fe49a4,
        266px 1506px #fe49a4, 1061px 224px #fe49a4, 690px 1132px #fe49a4,
        1149px 313px #fe49a4, 744px 898px #fe49a4, 848px 1851px #fe49a4,
        296px 761px #fe49a4, 55px 624px #fe49a4, 573px 131px #fe49a4,
        483px 1869px #fe49a4, 157px 1304px #fe49a4, 496px 489px #fe49a4,
        729px 1730px #fe49a4, 163px 1023px #fe49a4, 1065px 1426px #fe49a4,
        1058px 865px #fe49a4, 1204px 1762px #fe49a4, 73px 527px #fe49a4,
        668px 1237px #fe49a4, 1px 259px #fe49a4, 1027px 107px #fe49a4,
        1347px 842px #fe49a4, 720px 819px #fe49a4, 97px 1047px #fe49a4,
        474px 1262px #fe49a4, 1406px 1163px #fe49a4, 1620px 1312px #fe49a4,
        292px 423px #fe49a4, 1579px 1735px #fe49a4, 687px 305px #fe49a4,
        1810px 1822px #fe49a4, 1499px 241px #fe49a4, 1578px 1308px #fe49a4,
        770px 1441px #fe49a4, 398px 646px #fe49a4, 1186px 1423px #fe49a4,
        6px 201px #fe49a4, 1684px 1057px #fe49a4, 1252px 1352px #fe49a4,
        1649px 317px #fe49a4, 746px 1777px #fe49a4, 537px 97px #fe49a4,
        347px 1613px #fe49a4, 1709px 1896px #fe49a4, 134px 957px #fe49a4,
        755px 340px #fe49a4, 107px 1057px #fe49a4, 263px 1459px #fe49a4,
        1559px 1124px #fe49a4, 1082px 19px #fe49a4, 557px 795px #fe49a4,
        596px 455px #fe49a4, 1877px 1535px #fe49a4, 457px 320px #fe49a4,
        532px 270px #fe49a4, 773px 1991px #fe49a4, 644px 1840px #fe49a4,
        777px 1413px #fe49a4, 726px 203px #fe49a4, 20px 740px #fe49a4,
        655px 909px #fe49a4, 396px 1998px #fe49a4, 235px 954px #fe49a4,
        1737px 264px #fe49a4, 670px 1532px #fe49a4, 498px 159px #fe49a4,
        540px 1997px #fe49a4, 1792px 1327px #fe49a4, 880px 1023px #fe49a4,
        1917px 1938px #fe49a4, 1859px 148px #fe49a4, 1147px 1781px #fe49a4,
        428px 1876px #fe49a4, 1952px 605px #fe49a4, 655px 80px #fe49a4,
        1500px 1545px #fe49a4, 1656px 644px #fe49a4, 615px 247px #fe49a4,
        795px 1881px #fe49a4, 1750px 586px #fe49a4, 148px 1490px #fe49a4,
        1434px 74px #fe49a4, 417px 1969px #fe49a4, 962px 1526px #fe49a4,
        835px 962px #fe49a4, 330px 1767px #fe49a4, 1557px 435px #fe49a4,
        240px 341px #fe49a4, 1857px 1589px #fe49a4, 765px 281px #fe49a4,
        1452px 405px #fe49a4, 1480px 1724px #fe49a4, 950px 1094px #fe49a4,
        710px 1388px #fe49a4, 1333px 1919px #fe49a4, 706px 1589px #fe49a4,
        1476px 1465px #fe49a4, 744px 291px #fe49a4, 123px 870px #fe49a4,
        1406px 428px #fe49a4, 727px 1609px #fe49a4, 1149px 1838px #fe49a4,
        78px 169px #fe49a4, 1088px 284px #fe49a4, 670px 399px #fe49a4,
        711px 1923px #fe49a4, 42px 1720px #fe49a4, 667px 570px #fe49a4,
        1274px 693px #fe49a4, 1056px 644px #fe49a4, 1937px 793px #fe49a4,
        1200px 602px #fe49a4, 1493px 755px #fe49a4, 545px 870px #fe49a4,
        887px 270px #fe49a4, 1290px 164px #fe49a4, 265px 1185px #fe49a4;
`;
