import styled from "styled-components";

export const PopupWrap = styled.div<{ height: number }>`
  margin: -5vh auto 30px;
  position: relative;
  top: auto;
  background: #020606;
  left: 0;
  width: 307px;
  padding: 0 15px;
  right: 0;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  max-height: 80vh;
  // overflow: auto;
  overflow: hiden;

  &.round-popup {
    width: 813px;
    margin-top: 12vh;
  }

  &.pf {
    margin-bottom: 30px;
    padding: 0;
  }

  @media (min-width: ${800}px) {
    width: 600px;
  }

  @media (min-width: ${1366}px) {
    width: 866px;
    margin-top: 44px;
    margin-bottom: 46px;
  }

  @media (min-width: ${1920}px) {
    padding: 0 32px;
  }
`;

export const Head = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  font-feature-settings: "case" on;
  padding: 15px 50px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;

  .popup-head-ic {
    position: absolute;
    left: 2px;
    top: 17px;
    width: 20px;
    height: 20px;
    fill: #6d5cce;
  }

  .popup-head-ic.table {
    display: none;
  }

  &.round-head {
    font-size: 12px;
    font-feature-settings: "case" off;
    padding: 15px 20px 8px 15px;
    min-height: 24px;

    .popup-head-ic {
      fill: #ffffff;
      width: 24px;
      height: 24px;
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.pf {
    margin-right: 15px;
    margin-left: 15px;
  }

  @media (min-width: ${1366}px) {
    font-size: 27px;
    line-height: 30px;
    padding: 50px 50px 0px;
    border-bottom: 0;
    margin-bottom: 10px;

    .popup-head-ic {
      position: static;
      width: 31.5px;
      height: 31.5px;
      margin-right: 15px;
      vertical-align: top;
      margin-top: 1px;
    }

    .popup-head-ic.table {
      display: inline-block;
    }

    &.round-head {
      font-size: 22px;
      line-height: 26px;
      padding: 31px 20px 30px 15px;

      .popup-head-ic {
        display: none;
      }
    }
  }

  @media (min-width: ${1920}px) {
    font-size: 42px;
    line-height: 50px;
    padding: 71px 50px 0px;
    border-bottom: 0;
    margin-bottom: 10px;

    .popup-head-ic {
      position: static;
      width: 43px;
      height: 43px;
      vertical-align: top;
      margin-top: 3px;
    }
  }
`;

export const Title = styled.div`
  display: inline-block;

  &.round-title {
    color: #2eb550;
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: -4px;
  top: 10px;
  display: block;
  padding: 10px;

  .popup-close-ic {
    width: 12px;
    height: 12px;
    fill: #ffffff;
    vertical-align: top;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      fill: #ca0505;
    }
  }

  @media (min-width: ${1366}px) {
    right: 10px;
    top: 27px;

    .popup-close-ic {
      width: 24px;
      height: 24px;
    }
  }

  @media (min-width: ${1920}px) {
    right: -6px;
    top: 25px;

    .popup-close-ic {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Content = styled.div`
  padding-top: 18px;
`;
