import React, { FC, memo } from "react";
// import { IMAGES } from "./Images";
import ShipAnimation from "./ShipAnimation";

const Ship: FC<{
  width?: number;
  animateFire?: boolean;
  animateShip?: boolean;
}> = () => {
  return <ShipAnimation />;
};

export default memo(Ship);
