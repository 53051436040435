import React, { FC, memo, useState } from "react";
import { useProfile } from "./configs/store/state";
import useTranslate from "../../config/language";
import { KEYWORDS } from "../../config/language/keywords";
import { Info, Text, Balance, AvatarContainer } from "./Profile.styles";
import AvatarsModal from "./components/AvatarsModal/AvatarsModal";
import UserAvatar from "../../components/UserAvatar";
import { CurrencySymbol } from '../../constants/currency';

const WebProfileSection: FC = () => {
  const profile = useProfile();
  const translate = useTranslate();
  const numberFormatter = Intl.NumberFormat("en-US");

  const [changeAvatarOpen, setChangeAvatarOpen] = useState(false);
  const handleOpenChangeAvatarModal = () => {
    setChangeAvatarOpen(true);
  };

  const Currency = CurrencySymbol();

  return (
    <Info>
      <Text>
        <Balance>{translate(KEYWORDS.Balance)}</Balance>

        {profile.B && profile.B
          ? numberFormatter
              .format(profile.B.WalletBalance)
              .toString()
              .replace(",", "‘")
          : 0}
        {Currency}
      </Text>

      <AvatarContainer onClick={handleOpenChangeAvatarModal}>
        <UserAvatar avatarId={profile?.Player?.A || 0} />
      </AvatarContainer>

      {changeAvatarOpen && (
        <AvatarsModal closeModal={() => setChangeAvatarOpen(false)} />
      )}
    </Info>
  );
};

export default memo(WebProfileSection);
