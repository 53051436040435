import styled from "styled-components";

export const Container = styled.div``;

export const TableOverflow = styled.div`
  max-height: calc(88vh - 242px);
  overflow-y: auto;
  position: relative;
  padding: 0 8px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  .table-body {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .table-bets {
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    border-spacing: 0;
    border: 0;
    border-collapse: collapse;
    text-align: center;
    margin-bottom: 34px;
    margin-top: 3px;
    font-feature-settings: "case" off;
  }

  @media (min-width: ${800}px) {
    height: calc(100vh - 200px);
    max-height: none;

    .table-bets {
      font-size: 8px;
      line-height: 10px;
    }
  }

  @media (min-width: ${1366}px) {
    height: calc(100vh - 290px);
    padding: 0 10px;

    .table-bets {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media (min-width: ${1920}px) {
    height: calc(100vh - 296px);
  }
`;

export const TableHeaderCell = styled.th`
  font-weight: normal;
  padding: 10px 5px 3px;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(3) {
    text-align: right;
  }

  &.rating-date {
    width: 17%;
  }

  &.coeffc-grren {
    color: #2eb550;
    width: 6%;
  }

  &.rating-pf {
    width: 19%;
  }

  @media (min-width: ${800}px) {
    &.rating-date {
      width: 48%;
    }
  }

  @media (min-width: ${1366}px) {
    padding: 10px 13px 11px;

    &.rating-date {
      width: 50%;
    }

    &.coeffc-grren {
      width: 30%;
    }

    &.rating-pf {
      width: 29%;
    }
  }
`;
