const CURRENCY_SYMBOL = '___';

export const english = {
  AccountBanned: "Your accountb has been blocked!",
  AllBets: "All Bets:",
  AlreadyRegistered: "You have already placed a bet",
  Animation: "Animation",
  AutoBet: "AUTO BET",
  AutoBetting: "Auto Bet",
  AutoCashout: "AUTO CASHOUT",
  AutoCashouting: "Auto Cashout",
  Balance: "BALANCE",
  BalanceShort: "BAL",
  Banned: "BANNED",
  BaseBet: "BASE BET",
  Bet: "Bet",
  BetMaximum: `Bet Must Be Maximum ${CURRENCY_SYMBOL}`,
  BetMustBe: `Bet Must Be Minimum ${CURRENCY_SYMBOL}`,
  Bets: "Bets",
  Stakes: "Bets",
  BuyIn: "BET",
  Cashout: "Cashout",
  CashoutFromGame: "Cashout",
  CashoutMaxMustBe: "Cashout Must Be Maximum ",
  CashoutMustBe: "Cashout must be Minimum 1.01",
  ChangeAvatar: "Change avatar",
  CheckFair: "1. What is checking the odd?",
  CheckHash: "3. How to check credibility code?",
  CheckResult: "Check Result",
  Circulation: "#",
  Clear: "CLEAR",
  Crashed: "ODD",
  CurrentBets: "BETS",
  Day: "Day",
  DearUsers: "Sorry for inconvenience",
  en: "EN",
  Error: "Error",
  ExampleForFair:
    "Game number - 9088 Odds - 1.06 Generation time - 12/03/2019_16:09 Unique game number - Fv+5TVHK2621A== As a result, the key to the game will be: 9088_1.06_Rockit_12/03/2019_16:09_Fvv+5TVHK2621A== And the credibility code generated by the SHA256 algorithm: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d",
  Examples: "Example:",
  FairGame: "Fair Game",
  Fill: "Fill",
  FreeBet: "FreeBet",
  GameIsStopped: "Game Is Stopped",
  GameKey: "2. What does a game key and credibility code mean?",
  ServerConnectionTemp: "Connecting to server.",
  SameUser: "The game is open in another tab",
  GameRules: "Game Rules",
  GameStart: "Starting Game",
  GameStarted: "Game took start - Good Luck",
  GameStatusIsNotRegistering: "Accepting bets finished",
  GameWillResume: "Time Till Resume",
  GotoHome: "HOME",
  Hash: "Hash code",
  History: "History ",
  HowToCheckFair:
    "This method allows us to make sure that the odds of the game (the number on which the game is over) is predetermined before players register their bets. There are 5 predetermined games in advance at any given time. The following is a Hash Code of 5 past and 5 upcoming rounds.",
  HowToCheckGameKey:
    "The odds of the game (the number on which the game will be over) are predetermined, 5 games in advance. A game key is generated on the game server, which consists of the following parameters: [Game Number] _ [Odds] _ [Rockit] _ [Generation Time] _ [Unique Game Number]",
  HowToCheckHash:
    "After the game is over, a game key will be available that you can encrypt on any online SHA256 calculator and then compare it to the credibility code given for the next 5 games.",
  IncreaseBy: "INCREASE BY",
  Jackpot: "JACKPOT",
  ka: "KA",
  ua: "UA",
  Key: "Key",
  LanguageChangeAcception: "Are you sure you want to change language? ",
  LanguageChangeWarning: "Pressing the button will turn off the current game",
  LastGames: "Last games",
  LastHand: "Last Hand",
  LastTopSpins: "TOP MULTIPLIERS IN 24 HOURS",
  LastTopWins: "TOP WINS IN 24 HOURS",
  LeaveMessage: "Type your message",
  Login: "LOGIN",
  LoginFailed: "Login failed",
  MainPage: "Main Page",
  ManualBet: "MANUAL BET",
  MinFreebetCashout: "Min. odd 1.5",
  Month: "Month",
  MultiplierCashout: "Mult. Cashout",
  Music: "Music",
  My: "My Bets",
  No: "No",
  NotEnoughMoney: "Not Enough Money",
  OnLoss: "ON LOSS",
  OnWin: "ON WIN",
  OpenHere: "Open Here",
  PlaceYourBet: "PLACE YOUR BETS",
  Player: "Player",
  Rating: "TOP",
  Register: "Place a bet",
  Registration: "PLACE YOUR BETS",
  Reload: "Reload",
  Result: "Result",
  ResultsHash: "Result's Hash Code",
  ReturnToBaseBet: "RETURN TO BASE BET",
  ReturnToCash: "Return to cash",
  Round: "Round",
  ru: "RU",
  GameRules1: "Players can place bets before the round starts.",
  GameRules2:
    "Odds start to increase when the game starts and lasts until it’s burnt out.",
  GameRules3: "The burnout point is generated randomly.",
  GameRules4:
    "A player should complete a cashout of the winning before the burnout.",
  GameRules5:
    "Bets that are not cashed out before the burnout will be considered as lost.",
  GameRules6:
    "A player’s bet will be multiplied by the odds at which the bet was cashed out when paying out before the burnout.",
  GameRules7: `Odds start to increase from 1.0. In the case of burnout at 1.0, all bets are considered lost. For example, a player places a bet of 5${CURRENCY_SYMBOL} and cashouts the cash at 2.0, he/she will receive 2*5=10${CURRENCY_SYMBOL}. If odds burnout at less than 2.0, for example at 1.75, in this case, a player loses the whole bet of 5${CURRENCY_SYMBOL}. In case of leaving the game, the bet will be automatically cashed out according to the multiplication of the bet and the height of odds at that moment`,
  GameRules8:
    "In case of leaving the game, the bet will be automatically cashed, based on the product of the bet and the current height coefficient. Termination of a game session for any reason shall be deemed as quitting the game.",
  GameRules9:
    "A player can place two bets at the same time and cash them out separately.",
  GameRules10:
    "The player can make a bet under the desired conditions, through automatic betting. In the existing window, it is possible to determine the amount of the bet, the withdrawal coefficient, the maximum amount of withdrawal. Also, the conditions of the next bet can be determined in advance in case of winning or losing.",
  GameRules11: "Winnings are automatically reflected on a player’s balance.",
  GameRules12:
    "The player can see the game history by clicking on the 'My Bets' button.",
  GameRules13:
    "In case of a proven error in the game, the game side reserves the right to withhold or correct the player’s estimated winnings.",
  GameRules16: "Game limits:",
  GameRules17: `minimum bet - 0.10${CURRENCY_SYMBOL}`,
  GameRules18: `maximum bet - 200${CURRENCY_SYMBOL}`,
  GameRules19: `A player’s maximum win (bet*odds) - 30,000${CURRENCY_SYMBOL}, when bet multiplied by odds reaches 30,000₾${CURRENCY_SYMBOL}, the system will automatically cash out the active bet`,
  GameRules20:
    `When bets placed by above two players multiplied by the odds will reach ${CURRENCY_SYMBOL}100,000 at one round, the system will automatically payout all active bets.`,
  GameRules21: "The minimum multiplier of the game is 1.",
  GameRules22: "The maximum multipliyerof the game is 1,102,008.",
  GameRules23: "Game RTP is 95.05%",
  GameRules24:
    "Min multiplier of auto-cashout is x1.1, and the max multiplier of auto-cashout is x1,102,008.",
  GameRules25:
    "Min multiplier of cashout is x1.01, and the max multiplier of cashout is x1,102,008.",
  GameRules26:
    "The player can find the latest odds of the game in the top section of the page.",
  GameRules27:
    "The player can see the rating of players by clicking on the 'Rating' button.",
  GameRules28:
    "In case, cashout coefficient and crash coefficient are the same, the player wins.",
  GameRules29:
    "In case of leaving the game voluntarily, the bet will be automatically cashed, based on the product of the bet and the current height coefficient. In case of a network disconnection,  a countdown from 10 to 1 would be displayed, and the player can opt to cash out or continue if the connectivity is resumed. In case of no action, the game shall be deemed as forfeiting the stake",
  Save: "Save",
  ServerConnection: "Connecting to server",
  InternetConnectionLost:
    "There was a problem with your internet connection, please refresh the page.",
  ServerError: "Server  Error",
  SessionHasExpired: "Session Has Expired",
  SitesUpdate: "SCHEDULED MAINTENANCE!",
  Sound: "Sound",
  Statistics: "Statistics",
  StopIf: "STOP IF BET >",
  Submit: "Submit",
  SuccessfulRegistration: "Bet placed",
  TechnicalWork: "the game you requested will be back soon.",
  TicketCashoutMustBe: "Cashout must be at least 1.5",
  Time: "Time",
  TopSpins: "Top Odds",
  TopWins: "Winnings",
  tr: "TR",
  UnknowError: "Server Error",
  Unregister: "Cancel bet",
  UnregisterFreeBet: "Cancel FreeBet",
  Unregistered: "Bet cancelled",
  User: "Player",
  UserName: "User Name",
  UserShort: "US",
  WaitingForNextRound: "You Are Waiting For Next Round",
  Win: "Winnings",
  WinAmount: "Profit",
  WinMultiplier: "Won Mult.",
  Winners: "Winners",
  WrongAmount: "Wrong Amount",
  WrongConfiguration: "Wrong Configuration",
  Year: "Year",
  Yes: "Yes",
  YouAreNotRegistered: "You Are Not Registered On This Game",
  EnterText: "Enter Text",
  PlayerNotFound: "Player Not Found",
  Jan: "Jan.",
  Feb: "Feb.",
  Mar: "Mar.",
  Apr: "Apr.",
  May: "May",
  June: "June",
  July: "July",
  Aug: "Aug.",
  Sept: "Sept.",
  Oct: "Oct.",
  Nov: "Nov.",
  Dec: "Dec.",
  BlockedUser: "Your account is blocked",
  LoadingProblem: "Failed to load the game, please reload the page.",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  MayLong: "May",
  JuneLong: "June",
  JulyLong: "July",
  August: "August",
  September: "September",
  Octomber: "Octomber",
  November: "November",
  December: "December",
  Auto: "Auto",
  Game: "Game",
  BetHistory: "Bet History",
  ViewTable: "View Table",
  TotalProfit: "Total Profit",

  ////
  WeakInternetAlert: "Weak internet connection",
  PageWillReload: "The page will reload",
  InSeconds: "Seconds",
};
