import React, { FC, memo, useEffect, useState } from "react";
import { Preparing } from "../../spaceship/states/preparing";
import { useGameStatusState } from "../../../config/store/state/app.state";
import { calculateGameDuration } from "../tools";
import { useSetLaunchingService } from "../../../config/store/services";

const GameCoolDown: FC = () => {
  const gameStatus = useGameStatusState();
  const setLaunching = useSetLaunchingService();
  const [countDown, setCountDown] = useState(
    calculateGameDuration(gameStatus.durationLeft || gameStatus.duration)
  );

  useEffect(() => {
    if (countDown <= 0) {
      setLaunching();
      return;
    }
    const interval = setInterval(() => {
      setCountDown(countDown - 100);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [countDown, setCountDown, setLaunching]);

  return <Preparing countDown={countDown} />;
};

export default memo(GameCoolDown);
