/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
// import ErrorMessages from "../errorMessages/ErrorMessages";
import {
  RegisterState,
  useBoards,
  useFreeBetsState,
} from "../../config/store/state/app.state";
// import ActionBoardContainer from "./components/ActionBoard";
// import AutoBoardContainer from "./components/AutoBoard";
import FreeBetBoardContainer from "./components/FreeBetBoard";
import { StyledActionPanel, GameBoardWrap } from "./styles";
// import { ACTION_PANEL_NAVIGATION } from "./tools";
import Actions from "./components/Actions";
import { KEYWORDS } from "../../config/language/keywords";
import styled from "styled-components";
import { useIsMobile } from "../../helpers/hooks/useIsMobile";
import { useToggleEnableFreeBetsService } from "../../config/store/services";
import ToggleInput from "../../components/ToggleInput/ToggleInput";
import useTranslate from "../../config/language";
import { useBalance } from "../profile/configs/store/state";

const ActionPanel = () => {
  const translate = useTranslate();
  const isMobile = useIsMobile();
  const freeBetsEnabled = useFreeBetsState();
  const freeBetsBalance = useBalance().FreeSpinBalance || [];
  const freeSpinTotalCount = freeBetsBalance
    .map((freeSpin) => freeSpin.Count)
    .reduce((a, b) => a + b, 0);
  const isFreeBetsBalanceEmpty =
    freeBetsBalance.length < 1 || freeSpinTotalCount < 1;
  const handleToggleFreeBets = useToggleEnableFreeBetsService();

  const boards = useBoards();
  const disableToggle =
    boards.filter((board) => board.registerState !== RegisterState.UNREGISTERED)
      .length > 0;

  useEffect(() => {
    if (!isFreeBetsBalanceEmpty) {
      handleToggleFreeBets(true)
    }
  }, [isFreeBetsBalanceEmpty])

  const ItemContainer = styled.div`
    position: absolute;
    top: 12px;
    right: 6px;
    z-index: 2;
  `;

  const SwitchLab = styled.div`
    font-size: 10px;
    line-height: 10px;
    color: #ffffff;
    font-feature-settings: "case" on;
    display: inline-block;
    margin-right: 9px;
    vertical-align: middle;
  `;

  return (
    <StyledActionPanel>
      <GameBoardWrap>
        {isMobile && (
          <ItemContainer>
            {isFreeBetsBalanceEmpty ? (
              <span />
            ) : (
              <>
                <SwitchLab>{translate(KEYWORDS.FreeBet)}</SwitchLab>
                <ToggleInput
                  active={freeBetsEnabled}
                  disabled={disableToggle}
                  onChange={() => handleToggleFreeBets(!freeBetsEnabled)}
                />
              </>
            )}
          </ItemContainer>
        )}

        {freeBetsEnabled ? <FreeBetBoardContainer /> : <Actions />}
      </GameBoardWrap>
    </StyledActionPanel>
  );
};

export default ActionPanel;
