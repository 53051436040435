import styled from "styled-components";

export const TabsNav = styled.div`
  text-align: center;
  margin: 14px 0 10px;

  @media (min-width: ${1366}px) {
    margin: 0px;
    position: absolute;
    top: 72px;
    left: 0;
    width: 100%;
  }

  @media (min-width: ${1920}px) {
    top: 63px;
  }
`;

export const StyledNavigation = styled.ul`
  display: inline-block;
  border: 1px solid #160f37;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  font-size: 0px;
  list-style: none;
  color: rgba(255, 255, 255, 0.3);
  overflow: hidden;

  text-transform: uppercase;
  font-feature-settings: "case" on;
`;

export const StyledNavigationItem = styled.li<{
  active: boolean;
}>`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
  font-feature-settings: "case" on;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    .tabs-nav-item-link {
      border-radius: 0 16px 16px 0;
    }
  }

  &:nth-child(2) {
    .tabs-nav-item-link {
      border-radius: 16px;
    }
  }

  &:last-child {
    .tabs-nav-item-link {
      border-radius: 16px 0 0 16px;
    }
  }

  .tabs-nav-item-link {
    color: rgba(255, 255, 255, 0.3);
    display: block;
    // border-radius: 16px;
    // -webkit-border-radius: 16px;
    min-width: 73px;
    font-size: 10px;
    line-height: 12px;
    padding: 4px 9px;

    &:hover {
      color: #6d5cce;
    }
  }

  // @media (min-width: ${800}px) {
  //   .tabs-nav-item-link {
  //     &:hover {
  //       color: #6d5cce;
  //     }
  //   }
  // }

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 16px;

    .tabs-nav-item-link {
      min-width: 81px;
      padding: 8px;
      font-size: 14px;
      line-height: 16px;
      min-width: 100px;
    }
  }

  @media (min-width: ${1920}px) {
    font-size: 16px;
    line-height: 19px;

    .tabs-nav-item-link {
      padding: 8px 8px 7px 8px;
      min-width: 104px;
    }
  }

  ${(props) =>
    props.active &&
    `
  .tabs-nav-item-link {
	color: #ffffff;
	background: #201739;


        @media (min-width: ${800}px) {
            &:hover {
                color: #ffffff;
            }
        }
    }
  `}
`;

export const Inner = styled.div``;

export const BetsNav = styled.ul`
  font-size: 0;
  list-style: none;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-feature-settings: "case" on;
  // margin: 0 -8px;

  @media (min-width: ${800}px) {
    margin: 6px 5px 0 -4px;
  }

  @media (min-width: ${1366}px) {
    // border-bottom: 2px solid #1b1e1e;
    margin: 0 10px 59px 10px;
  }
`;

export const StyledBetsNavigationItem = styled.li<{ active: boolean }>`
  display: inline-block;
  width: 33.3%;
  text-align: center;
  opacity: 0.5;
  margin-bottom: -1px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: uppercase;

  @media (min-width: ${800}px) {
    font-size: 10px;
    line-height: 12px;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  @media (min-width: ${1366}px) {
    font-size: 14px;
    line-height: 17px;
    border-bottom: 2px solid #1b1e1e;

    .bets-nav-link {
      padding: 0px 4px 9px 4px;
    }
  }

  ${(props) =>
    props.active &&
    `
    opacity: 1;
	border-bottom: 1px solid rgba(109, 92, 206, 1);
  font-weight: 500;

    @media (min-width: ${800}px) {
        border-bottom: 0px solid rgba(109, 92, 206, 1);

        // .bets-nav-link {
        //     border-bottom: 2px solid rgba(109, 92, 206, 1);
        // }
    }

    @media (min-width: ${1366}px) {
         border-bottom: 2px solid #6D5CCE;

        // .bets-nav-link {
        //     border-bottom: 0;
        // }
    }
    `}
`;
