import React, { FC } from "react";
import Shield from "../../../components/Icons/Shield";
import PagePopup from "../../../components/PagePopup/PagePopup";
import {
  useGameHistory,
  useNextGames,
} from "../../../config/store/state/app.state";
import { GameHistory } from "../../../constants/interfaces/GameHistory";
import Back from "./Back";
import ProvablyFairTable from "./ProvablyFairTable";

const ProvablyFairDataModal: FC<{ back: () => void; close: () => void }> = ({
  back,
  close,
}) => {
  let nextGames = useNextGames();
  const gameHistory: Partial<GameHistory>[] = useGameHistory();

  const reversedNextGames: Partial<GameHistory>[] = [...nextGames]
    .reverse()
    .map((game) => {
      return {
        ID: game.GameID,
        CrashHash: game.Hash,
      };
    });
  const mergedArrays = reversedNextGames.concat(gameHistory.slice(0, 5));

  return (
    <PagePopup
      title="PROVABLY FAIR"
      close={close}
      icon={<Shield className="popup-head-ic table" />}
      back={<Back onClick={back} />}
    >
      <ProvablyFairTable items={mergedArrays} />
    </PagePopup>
  );
};

export default ProvablyFairDataModal;
