import React, { FC } from "react";
import NumberInput from "../../../../../components/NumberInput/NumberInput";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { Tab, ButtonInput } from "../../../styles";
import Range from "../../../Range";

const RegisterPanel: FC<{
  amount: number;
  setAmount(amount: number | null): void;
  handleRegister(): void;
  // toggleBoard(): void;
  icon: React.ElementType;
  minValue: number;
  maxValue: number;
}> = ({
  amount,
  setAmount,
  handleRegister,
  // toggleBoard,
  icon: Icon,
  minValue,
  maxValue,
}) => {
  const translate = useTranslate();

  return (
    <Tab>
      <NumberInput
        value={amount}
        onChange={setAmount}
        minValue={minValue}
        maxValue={maxValue}
        maxLength={maxValue.toString().length + 3}
      />

      <Range
        value={amount}
        baseHalfPoint={20}
        min={minValue}
        max={maxValue}
        onChange={setAmount}
      />

      <ButtonInput
        text={translate(KEYWORDS.Bet)}
        onClick={handleRegister}
        className="register-bet"
      />
    </Tab>
  );
};

export default RegisterPanel;
