import React, { createContext, FC, useEffect, useState } from 'react';
import { dictionaryList, LANGUAGES } from './tools';
import { getQuery, useQuery } from '../../helpers/hooks/useQuery';
import { LANGUAGE_KEY } from '../../constants/constants';
let lang = getQuery(window.location.search, "lang");
if (
    lang !== "ka" &&
    lang !== "en" &&
    lang !== "ru" &&
    lang !== "uk" &&
    lang !== "tr" &&
    lang !== "pt" &&
    lang !== "es" &&
    lang !== "fr" &&
    lang
  ) {
    lang = "en" as LANGUAGES;
  }

export const LanguageContext = createContext({
    lang: lang,
    dictionary: lang,
});

const LanguageProvider: FC = ({ children }) => {
    const [userLanguage, setUserLanguage] = useState<LANGUAGES>(LANGUAGES.ka);
    let currentLanguage: LANGUAGES = useQuery(LANGUAGE_KEY) as LANGUAGES;

    if (
        currentLanguage !== "ka" &&
        currentLanguage !== "en" &&
        currentLanguage !== "ru" &&
        currentLanguage !== "uk" &&
        currentLanguage !== "tr" &&
        currentLanguage !== "pt" &&
        currentLanguage !== "es" &&
        currentLanguage !== "fr" &&
        currentLanguage
      ) {
        currentLanguage = "en" as LANGUAGES;
      }

    useEffect(() => {
        if (
            currentLanguage &&
            Object.values(LANGUAGES).includes(currentLanguage)
        ) {
            setUserLanguage(currentLanguage);
        }
    }, [currentLanguage, setUserLanguage]);

    const provider = {
        lang: userLanguage,
        dictionary: dictionaryList[userLanguage],
    };

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;
