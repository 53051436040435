import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  position: relative;
  overflow-x: hidden;
  display: inline-block;
  vertical-align: middle;
`;

export const Switch = styled(motion.div)<{
  active: boolean;
  disabled?: boolean;
}>`
  height: 16px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: ${(props) => (props.active ? "flex-end" : "flex-start")};
  align-items: center;

  &:disabled {
    pointer-events: none;
  }
`;

export const Stick = styled(motion.div)<{ active: boolean }>`
  width: 24px;
  height: 9px;
  border-radius: 30px;
  display: flex;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#3BCD02" : "#6B6B6B")};
  opacity: 0.24;

  @media (min-width: ${1366}px) {
    width: 30px;
    height: 12px;
  }
`;

export const Bullet = styled(motion.div)<{ active: boolean }>`
  position: absolute;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 12px;
  width: 12.8px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  display: inline-block;

  cursor: pointer;
  background-color: ${(props) => (props.active ? "#2EB550" : "#4E4A4A")};

  @media (min-width: ${1366}px) {
    height: 16px;
    width: 16px;
  }
`;
