export enum KEYWORDS {
  AccountBanned = "AccountBanned",
  AllBets = "AllBets",
  AlreadyRegistered = "AlreadyRegistered",
  Animation = "Animation",
  AutoBet = "AutoBet",
  AutoBetting = "AutoBetting",
  AutoCashout = "AutoCashout",
  AutoCashouting = "AutoCashouting",
  Balance = "Balance",
  BalanceShort = "BalanceShort",
  Banned = "Banned",
  BaseBet = "BaseBet",
  Bet = "Bet",
  BetMaximum = "BetMaximum",
  BetMustBe = "BetMustBe",
  Bets = "Bets",
  Stakes = "Stakes",

  BuyIn = "BuyIn",
  Cashout = "Cashout",
  CashoutFromGame = "CashoutFromGame",
  CashoutMaxMustBe = "CashoutMaxMustBe",
  CashoutMustBe = "CashoutMustBe",
  ChangeAvatar = "ChangeAvatar",
  CheckFair = "CheckFair",
  CheckHash = "CheckHash",
  CheckResult = "CheckResult",
  Circulation = "Circulation",
  Clear = "Clear",
  Crashed = "Crashed",
  CurrentBets = "CurrentBets",
  Day = "Day",
  DearUsers = "DearUsers",
  Error = "Error",
  ExampleForFair = "ExampleForFair",
  Examples = "Examples",
  FairGame = "FairGame",
  Fill = "Fill",
  FreeBet = "FreeBet",
  GameIsStopped = "GameIsStopped",
  GameKey = "GameKey",
  ServerConnectionTemp = "ServerConnectionTemp",
  InternetConnectionLost = "InternetConnectionLost",
  GameRules = "GameRules",
  GameStart = "GameStart",
  GameStarted = "GameStarted",
  GameStatusIsNotRegistering = "GameStatusIsNotRegistering",
  GameWillResume = "GameWillResume",
  GotoHome = "GotoHome",
  Hash = "Hash",
  History = "History",
  HowToCheckFair = "HowToCheckFair",
  HowToCheckGameKey = "HowToCheckGameKey",
  HowToCheckHash = "HowToCheckHash",
  IncreaseBy = "IncreaseBy",
  Jackpot = "Jackpot",
  Key = "Key",
  LanguageChangeAcception = "LanguageChangeAcception",
  LanguageChangeWarning = "LanguageChangeWarning",
  LastGames = "LastGames",
  LastHand = "LastHand",
  LastTopSpins = "LastTopSpins",
  LastTopWins = "LastTopWins",
  LeaveMessage = "LeaveMessage",
  Login = "Login",
  LoginFailed = "LoginFailed",
  MainPage = "MainPage",
  ManualBet = "ManualBet",
  MinFreebetCashout = "MinFreebetCashout",
  Month = "Month",
  MultiplierCashout = "MultiplierCashout",
  Music = "Music",
  My = "My",
  No = "No",
  NotEnoughMoney = "NotEnoughMoney",
  OnLoss = "OnLoss",
  OnWin = "OnWin",
  OpenHere = "OpenHere",
  PlaceYourBet = "PlaceYourBet",
  Player = "Player",
  Rating = "Rating",
  Register = "Register",
  Registration = "Registration",
  Reload = "Reload",
  Result = "Result",
  ResultsHash = "ResultsHash",
  ReturnToBaseBet = "ReturnToBaseBet",
  ReturnToCash = "ReturnToCash",
  Round = "Round",
  Rules1 = "Rules1",
  Rules2 = "Rules2",
  Rules3 = "Rules3",
  Rules4 = "Rules4",
  Rules5 = "Rules5",
  Rules6 = "Rules6",
  Rules61 = "Rules61",
  Rules62 = "Rules62",
  Rules7 = "Rules7",
  Rules8 = "Rules8",
  Rules9 = "Rules9",
  GameRules1 = "GameRules1",
  GameRules2 = "GameRules2",
  GameRules3 = "GameRules3",
  GameRules4 = "GameRules4",
  GameRules5 = "GameRules5",
  GameRules6 = "GameRules6",
  GameRules7 = "GameRules7",
  GameRules8 = "GameRules8",
  GameRules9 = "GameRules9",
  GameRules10 = "GameRules10",
  GameRules11 = "GameRules11",
  GameRules12 = "GameRules12",
  GameRules13 = "GameRules13",
  GameRules14 = "GameRules14",
  GameRules15 = "GameRules15",
  GameRules16 = "GameRules16",
  GameRules17 = "GameRules17",
  GameRules18 = "GameRules18",
  GameRules19 = "GameRules19",
  GameRules20 = "GameRules20",
  GameRules21 = "GameRules21",
  GameRules22 = "GameRules22",
  GameRules23 = "GameRules23",
  GameRules24 = "GameRules24",
  GameRules25 = "GameRules25",
  GameRules26 = "GameRules26",
  GameRules27 = "GameRules27",
  GameRules28 = "GameRules28",
  GameRules29 = "GameRules29",
  GameRules30 = "GameRules30",
  Rules10 = "Rules10",
  Rules11 = "Rules11",
  Rules12 = "Rules12",
  Rules13 = "Rules13",
  Rules14 = "Rules14",
  Rules14_1 = "Rules14_1",
  Rules14_2 = "Rules14_2",
  Rules15 = "Rules15",
  Rules16 = "Rules16",
  Rules17 = "Rules17",
  Rules18 = "Rules18",
  Rules19 = "Rules19",
  Rules20 = "Rules20",
  Save = "Save",
  ServerConnection = "ServerConnection",
  ServerError = "ServerError",
  SessionHasExpired = "SessionHasExpired",
  SitesUpdate = "SitesUpdate",
  Sound = "Sound",
  Statistics = "Statistics",
  StopIf = "StopIf",
  Submit = "Submit",
  SuccessfulRegistration = "SuccessfulRegistration",
  TechnicalWork = "TechnicalWork",
  TicketCashoutMustBe = "TicketCashoutMustBe",
  Time = "Time",
  TopSpins = "TopSpins",
  TopWins = "TopWins",
  UnknowError = "UnknowError",
  Unregister = "Unregister",
  UnregisterFreeBet = "UnregisterFreeBet",
  Unregistered = "Unregistered",
  User = "User",
  UserName = "UserName",
  UserShort = "UserShort",
  WaitingForNextRound = "WaitingForNextRound",
  Win = "Win",
  WinAmount = "WinAmount",
  WinMultiplier = "WinMultiplier",
  Winners = "Winners",
  WrongAmount = "WrongAmount",
  WrongConfiguration = "WrongConfiguration",
  Year = "Year",
  Yes = "Yes",
  YouAreNotRegistered = "YouAreNotRegistered",
  EnterText = "EnterText",
  PlayerNotFound = "PlayerNotFound",
  Jan = "Jan",
  Feb = "Feb",
  Mar = "Mar",
  Apr = "Apr",
  May = "May",
  June = "June",
  July = "July",
  Aug = "Aug",
  Sept = "Sept",
  Oct = "Oct",
  Nov = "Nov",
  Dec = "Dec",
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  MayLong = "May",
  JuneLong = "June",
  JulyLong = "July",
  August = "August",
  September = "September",
  Octomber = "Octomber",
  November = "November",
  December = "December",
  BlockedUser = "BlockedUser",
  LoadingProblem = "LoadingProblem",
  SameUser = "SameUser",
  Table = "Table",
  ViewTable = "ViewTable",
  TotalProfit = "TotalProfit",
  Game = "Game",
  Auto = "Auto",
  Failed = "Failed",
  BetHistory = "BetHistory",
  WeakInternetAlert = "WeakInternetAlert",
  PageWillReload = "PageWillReload",
  InSeconds = "InSeconds",
}
